// eslint-disable-next-line import/prefer-default-export
export function errorHandler(errorx: any) {
  const error = errorx?.response?.data;
  const hasErrorField = Object.keys(error).includes('error');
  if (hasErrorField) {
    return `
            <h6>${error.message}</h6>
            <ul>
            ${error.error
              .map(
                (item: any) =>
                  `<li>
                ${item?.errorName} : ${item?.details}
              </li> `,
              )
              .join('')}
            </ul>
          `;
  }
  if (error.message) return error.message;
  if (error.detail) return error.detail;
  return 'Error Occurred !';
}
