/* eslint-disable import/prefer-default-export */
import { api, authenticated } from '.';

export const getActivityLogs = (params: Record<string, any>) => {
  return authenticated(api).get('/activity-log/', { params });
};

export const getUserList = () => {
  return authenticated(api).get('/user/user-list/');
};

export const getRolesList = () => {
  return authenticated(api).get('/user/role-list/');
};
