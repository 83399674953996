import { portfolioProfiles } from '@Store/slices/portfolioProfiles';

export const {
  setSelectedTab,
  setSelectedProvinceOptions,
  setComparisonIndicatorsOptions,
  removeComparisonIndicatorsOptions,
  removeSelectedProvinceOptions,
  setFetchData,
  setCompareByTab,
  setSelectedProvince,
  setSelectedMunicipality,
  setSelectedDistrictOption,
  removeSelectedMunicipalityOptions,
  removeSelectedDistrict,
  removeSelectedProvince,
  emptyAllData,
  setSelectedMunicipalityData,
} = portfolioProfiles.actions;
