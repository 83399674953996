import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';

interface IEmailBoxProps {
  removeEmail: () => void;
  email: string;
}
const EmailsBox = ({ removeEmail, email }: IEmailBoxProps) => {
  return (
    <>
      <FlexRow className="naxatw-items-center naxatw-gap-1 naxatw-rounded-[6rem] naxatw-border !naxatw-border-[#D7D7D7] naxatw-bg-[#F5F5F5] naxatw-p-2">
        <p className="naxatw-text-xs naxatw-text-[#545454]">{email}</p>
        <Icon
          name="close"
          className="naxatw-cursor-pointer !naxatw-text-base naxatw-text-[#545454]"
          onClick={removeEmail}
        />
      </FlexRow>
    </>
  );
};

export default EmailsBox;
