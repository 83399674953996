import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ChartHeader from '../ChartHeader';
import ChartLegend from '../ChartLegend';
import ChartContainer from '../ChartContainer';

interface IChartRendererProps {
  data: Record<string, any>;
}

const ChartRenderer = ({ data }: IChartRendererProps) => {
  const {
    id,
    title,
    chartType,
    queryFn,
    yLabel = '',
    params,
    xLabel = '',
    name = '',
    hasFullScreen = true,
    switchTabData,
    sankeyChartData,
    className,
    height,
    // if you want to convert x-axis to curreny pass true, works in vertical barchart
    currencyConvert,
    xLabelClassname,
    barSize,
    needLegend,
    subTitle,
    chartId,
    showPound = false,
    hasDetails = false,
    hoverTitle,
    yLabelClassNames,
    showYAxisIntOnly = false,
  } = data;
  const { data: chartData, isLoading } = useQuery({
    queryKey: [id, queryFn, params],
    queryFn: () => queryFn(params),
    select: res => {
      const response = res?.data;
      if (id === 'project-status') {
        return response?.map((status: Record<string, any>) => ({
          name: status?.program_status,
          value: status?.count,
        }));
      }
      if (id === 'Geographical Location vs Programs') {
        return response?.map((status: Record<string, any>) => ({
          name: status?.name,
          value: status?.count,
        }));
      }
      if (id === 'budget-province') {
        return response?.map((status: Record<string, any>) => ({
          name: status?.name,
          value: status?.budget,
        }));
      }
      if (id === 'budget-sector') {
        return response?.map((status: Record<string, any>) => ({
          name: status?.name,
          size: status?.budget,
        }));
      }
      if (
        name === 'top-sectors-by-budget-allocation' ||
        name === 'top-partners-by-budget-allocation'
      ) {
        return response?.map((sector: Record<string, any>) => ({
          name: sector?.name,
          value: sector?.budget,
          fullName: sector?.name,
        }));
      }
      if (id === 'top-programmes-by-budget-allocation') {
        return response?.map((sector: Record<string, any>) => ({
          name: sector?.abbreviation ? sector?.abbreviation : sector?.name,
          value: sector?.budget,
          fullName: sector?.name,
        }));
      }
      if (id === 'provincial-profile-status') {
        return response?.map((status: Record<string, any>) => ({
          name: status?.program_status,
          value: status?.count,
        }));
      }
      if (id === 'top-five-sector-trend-component') {
        return response?.map((sector: Record<string, any>) => ({
          name: sector?.name,
          size: sector?.count,
        }));
      }
      if (id === 'top-five-sector-trend-programme') {
        return response?.map((sector: Record<string, any>) => ({
          name: sector?.name,
          size: sector?.count,
        }));
      }
      if (
        name === 'top-partners-by-project-programme' ||
        name === 'top-sectors-by-programme-project'
      ) {
        return response?.map((partner: Record<string, any>) => ({
          name: partner?.name,
          value: partner?.count,
        }));
      }
      if (
        id === 'stakeholders-engaged-in-province' ||
        id === 'stakeholders-engaged-in-federal' ||
        id === 'programme-vs-projects' ||
        id === 'key-dps-provincial-profile' ||
        id === 'keystakeholders-provincial-profile'
      ) {
        return response;
      }
      return response;
    },
  });

  if (isLoading) {
    return (
      <div className="naxatw-p-5">
        <Skeleton
          className="naxatw-bg-primary-50 naxatw-h-full
         naxatw-w-full naxatw-rounded-lg naxatw-shadow-sm"
        />
      </div>
    );
  }

  function renderChart() {
    switch (chartType) {
      case 'donut':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="donut"
            chartTitle={title}
            hasDownloadBtn
            header={ChartHeader}
            fillWithType
            legend={ChartLegend}
            data={chartData || []}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
          />
        );
      case 'horizontalBar':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="horizontalBar"
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            currencyConvert={currencyConvert}
            xLabelClassname={xLabelClassname}
          />
        );
      case 'bar':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="bar"
            yLabel={yLabel}
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            fillWithType
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            xLabel={xLabel}
            height={height}
            xLabelClassname={xLabelClassname}
            barSize={barSize}
            needLegend={needLegend}
            hoverTitle={hoverTitle}
            yLabelClassNames={yLabelClassNames}
            showYAxisIntOnly={showYAxisIntOnly}
          />
        );
      case 'line':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="line"
            yLabel={yLabel}
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            fillWithType
            legend={ChartLegend}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            xLabelClassname={xLabelClassname}
          />
        );
      case 'treeMap':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="treeMap"
            yLabel={yLabel}
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            fillWithType
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            xLabelClassname={xLabelClassname}
          />
        );
      case 'verticalBar':
        return (
          <ChartContainer
            header={ChartHeader}
            type="verticalBar"
            data={chartData || []}
            chartTitle={title}
            subTitle={subTitle}
            hasDownloadBtn
            hasHeader
            fill={['#B7E7E4']}
            xLabel={xLabel}
            yLabel={yLabel}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            currencyConvert={currencyConvert}
            xLabelClassname={xLabelClassname}
            hoverTitle={hoverTitle}
          />
        );
      case 'sankeyChart':
        return (
          <ChartContainer
            header={ChartHeader}
            type="sankeyChart"
            data={chartData || []}
            chartTitle={title}
            subTitle={subTitle}
            hasDownloadBtn
            hasHeader
            fill={['#B7E7E4']}
            xLabel={xLabel}
            yLabel={yLabel}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            sankeyChartData={sankeyChartData}
            className={className}
            height={height}
            xLabelClassname={xLabelClassname}
          />
        );
      case 'stackedBarChart':
        return (
          <ChartContainer
            header={ChartHeader}
            type="stackedBarChart"
            data={chartData || []}
            chartTitle={title}
            subTitle={subTitle}
            hasDownloadBtn
            hasHeader
            fill={['#B7E7E4']}
            xLabel={xLabel}
            yLabel={yLabel}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            xLabelClassname={xLabelClassname}
          />
        );
      case 'pieChart':
        return (
          <ChartContainer
            header={ChartHeader}
            type="pieChart"
            data={chartData || []}
            chartTitle={title}
            chartId={chartId}
            subTitle={subTitle}
            showPound={showPound}
            hasDownloadBtn
            hasHeader
            legend={ChartLegend}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            hasDetails={hasDetails}
          />
        );

      default:
        return <></>;
    }
  }

  return renderChart();
};

export default ChartRenderer;
