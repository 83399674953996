/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import Checkbox from '@Components/common/FormUI/CheckBox';
import Icon from '@Components/common/Icon';
import { ChevronDown } from 'lucide-react';
import {
  getFilterRepoProgrammeList,
  getFilterRepoSectorList,
  getFilterRepoFileTypeList,
} from '@Services/mainDashboard';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import isEmpty from '@Utils/isEmpty';

import {
  postFilterParams,
  setMainDashboardStates,
  toggleFilterState,
  resetFilterState,
  setDateRangeState,
  setFilterCount,
} from '@Store/actions/mainDashboard';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { convertKnowledgeLibraryFilterData } from '@Utils/convertFilterData';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import MultipleDatePicker from '@Components/common/FormUI/MultipleDatePicker/MultipleDatePicker';
import { categories, excludeFilterItems } from '@Constants/filters';
import FilterSearchCategory from '@Components/MainDashboard/Filters/FilterSearchCategory';

const KnowledgeRepoFilters = () => {
  const dispatch = useDispatch();

  const isInitialFilterStateFetch = useRef(true);

  const [searchFilterTerm, setSearchFilterTerm] = useState('');
  const [seacrhSector, setSearchSector] = useState('');
  const [programmeFilterCount, setProgrammeFilterCount] = useState(0);
  const [sectorFilterCount, setSectorFilterCount] = useState(0);
  const [activeAccordion, setActiveAccordion] = useState(1);

  const mappedFilterCount: Record<string, any> = {
    Programme: programmeFilterCount,
    Sector: sectorFilterCount,
  };

  const filterState = useTypedSelector(
    state => state.mainDashboard.filterState,
  );

  const startDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.startDate,
  );
  const endDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.endDate,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const startDateParams = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDateParams = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const fileTypeParams = useTypedSelector(
    state => state.mainDashboard.filterParams.fileType,
  );

  const [searchFilter, handleFilterChange] = useDebouncedInput({
    ms: 400,
    init: searchFilterTerm,
    onChange: debouncedEvent =>
      setSearchFilterTerm(debouncedEvent.target.value),
  });

  const [searchSectorTerm, handleSectorChange] = useDebouncedInput({
    ms: 400,
    init: seacrhSector,
    onChange: debouncedEvent => setSearchSector(debouncedEvent.target.value),
  });

  function updateFilterState(fetchedState: Record<string, any>[]) {
    return filterState?.map(currentCategory => {
      const fetchedCategory = fetchedState.find(
        category => category.id === currentCategory.id,
      );

      if (!fetchedCategory) {
        return currentCategory;
      }
      const currentSubcategoryMap: Record<string, any> = {};
      currentCategory.subcategories?.forEach((subcategory: { id: string }) => {
        currentSubcategoryMap[subcategory.id] = subcategory;
      });

      const updatedSubcategories = fetchedCategory.subcategories
        .map((fetchedSubcategory: Record<string, any>) => {
          const existingSubcategory =
            currentSubcategoryMap[fetchedSubcategory.id];

          if (existingSubcategory) {
            return {
              ...existingSubcategory,
              filterItems: fetchedSubcategory.filterItems?.map(
                (fetchedFilter: Record<string, any>) => {
                  const existingFilter = existingSubcategory?.filterItems.find(
                    (filter: Record<string, any>) =>
                      filter.id === fetchedFilter.id,
                  );

                  return (
                    existingFilter || {
                      ...fetchedFilter,
                      checked: !!existingSubcategory.checked,
                    }
                  );
                },
              ),
            };
          }
          return { ...fetchedSubcategory };
        })
        ?.sort((a: Record<string, any>, b: Record<string, any>) => {
          return b.checked - a.checked;
        });

      return {
        ...currentCategory,
        subcategories: updatedSubcategories,
      };
    });
  }

  // check single filter count
  const checkSingleFilter = useCallback(
    (category: string) => {
      const result = filterState?.find(
        filterItem => filterItem.category === category,
      );
      const checkedCount =
        result?.subcategories.filter((i: any) => i.checked).length || 0;
      switch (category) {
        case 'Programme':
          setProgrammeFilterCount(checkedCount);
          break;
        case 'Sector':
          setSectorFilterCount(checkedCount);
          break;
        default:
          break;
      }
    },
    [filterState],
  );

  // api calls
  // Programme
  const { data: programData, isSuccess: isProgramSuccess } = useQuery({
    queryKey: [
      'get-repo-filter-program-list',
      searchFilter,
      sectorGroupParams,
      startDateParams,
      endDateParams,
      fileTypeParams,
    ],
    queryFn: () =>
      getFilterRepoProgrammeList({
        program: searchFilter,
        sector_id: sectorGroupParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams ? fileTypeParams.join(',') : '',
      }),
    select: res => res?.data,
  });

  // Sector
  const { data: sectorData, isSuccess: isSectorSuccess } = useQuery({
    queryKey: [
      'get-filter-sector-list',
      searchSectorTerm,
      programmeFilterParams,
      projectFilterParams,
      startDateParams,
      endDateParams,
      fileTypeParams,
    ],
    queryFn: () =>
      getFilterRepoSectorList({
        sector: searchSectorTerm,
        program_id: programmeFilterParams?.join(','),
        project_id: activeAccordion === 2 ? '' : projectFilterParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams ? fileTypeParams.join(',') : '',
      }),
    select: res => res?.data,
  });

  // file type
  const { data: fileTypeData, isSuccess: isFileTypeSuccess } = useQuery({
    queryKey: [
      'get-repo-file-type',
      programmeFilterParams,
      projectFilterParams,
      sectorGroupParams,
      startDateParams,
      endDateParams,
    ],
    queryFn: () =>
      getFilterRepoFileTypeList({
        program_id: programmeFilterParams?.join(','),
        project_id: activeAccordion === 3 ? '' : projectFilterParams?.join(','),
        sector_id: sectorGroupParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
      }),
    select: res => res?.data,
  });

  useEffect(() => {
    if (isProgramSuccess && isSectorSuccess && isFileTypeSuccess) {
      const responseObject = {
        program: [...programData],
        sector: [...sectorData],
        file_type: [...fileTypeData],
      };
      const filterData = convertKnowledgeLibraryFilterData(responseObject);
      if (isInitialFilterStateFetch.current) {
        dispatch(setMainDashboardStates({ filterState: filterData }));
        isInitialFilterStateFetch.current = false;
        return;
      }
      const updatedFilter = updateFilterState(filterData);
      dispatch(setMainDashboardStates({ filterState: updatedFilter }));
    }
  }, [
    isProgramSuccess,
    isSectorSuccess,
    isFileTypeSuccess,
    programData,
    sectorData,
    fileTypeData,
  ]);

  useEffect(() => {
    categories.forEach(category => checkSingleFilter(category));
  }, [filterState, checkSingleFilter]);

  useEffect(() => {
    if (isEmpty(filterState as [])) return;
    dispatch(setFilterCount());
    dispatch(postFilterParams());
  }, [filterState, dispatch, endDate]);

  useEffect(() => {
    return () => {
      dispatch(resetFilterState());
    };
  }, []);

  return (
    <div className="filter-col naxatw-h-full naxatw-min-w-[15rem] naxatw-rounded-[0.8rem] naxatw-border-[1.124px] naxatw-border-solid naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
      {/* ------------------ filter header  --------------------> */}
      <div className="filter-header naxatw-flex naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-border-solid naxatw-border-b-[#E0E0E0] naxatw-px-3 naxatw-py-2">
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-gray-800">
            All Filters
          </p>
        </div>

        <button
          type="button"
          className="naxatw-group naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-4 naxatw-py-2"
          onClick={() => {
            dispatch(resetFilterState());
          }}
        >
          <Icon
            name="restart_alt"
            className="naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700"
          />

          <span className="naxatw-mb-1 naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-[0] naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700">
            Clear All
          </span>
        </button>
      </div>
      {/* ------------------ filter body  --------------------> */}

      <div className="filter-body scrollbar naxatw-overflow-y-scroll naxatw-px-4 naxatw-py-3">
        <div className="naxatw-mb-4">
          <MultipleDatePicker
            startDate={startDate}
            setStartDate={(date: Date) =>
              dispatch(setDateRangeState({ type: 'startDate', date }))
            }
            endDate={endDate}
            setEndDate={(date: Date) =>
              dispatch(setDateRangeState({ type: 'endDate', date }))
            }
            clearDateRange={() =>
              dispatch(setDateRangeState({ type: 'clear' }))
            }
            enableButton
          />
        </div>
        <Accordion type="single" defaultValue="item-1" collapsible>
          {filterState?.map((filterData: Record<string, any>) => {
            return (
              <AccordionItem
                value={`item-${filterData?.id}`}
                key={`${filterData?.id}-id`}
                className="naxatw-border-b-[#E0E0E0] naxatw-py-3 first:naxatw-pt-0"
              >
                <AccordionTrigger
                  className="naxatw-h-[1.5rem] !naxatw-py-0 hover:!naxatw-no-underline"
                  onClick={() => setActiveAccordion(filterData.id)}
                >
                  <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                    <p className="naxatw-text-base naxatw-font-medium naxatw-leading-[0] naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                      {filterData?.category}
                    </p>
                    {mappedFilterCount[filterData?.category] >= 1 && (
                      <div className="naxatw-flex naxatw-h-5 naxatw-w-5 naxatw-items-center naxatw-justify-center naxatw-rounded-[100px] naxatw-bg-[#F1F1F1] naxatw-p-[1px]">
                        <p className="naxatw-tooltip !naxatw-text-matt-100">
                          {mappedFilterCount[filterData?.category]}
                        </p>
                      </div>
                    )}
                  </div>

                  <ChevronDown
                    className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                    style={{
                      strokeWidth: '3px',
                    }}
                  />
                </AccordionTrigger>
                <AccordionContent className="acc-content">
                  {filterData?.category === 'Programme' && (
                    <FilterSearchCategory
                      category={`Search ${filterData?.category}`}
                      value={searchFilter}
                      onChange={handleFilterChange}
                    />
                  )}
                  {filterData?.category === 'Sector' && (
                    <FilterSearchCategory
                      category={`Search ${filterData?.category}`}
                      value={searchSectorTerm}
                      onChange={handleSectorChange}
                    />
                  )}
                  <div className="subcategories-container scrollbar naxatw-max-h-[175px] naxatw-overflow-y-auto">
                    {filterData?.subcategories ? (
                      <Accordion
                        type="single"
                        collapsible
                        className="naxatw-mr-1"
                      >
                        {filterData?.subcategories?.map(
                          (subCatData: Record<string, any>) => (
                            <AccordionItem
                              value={`sub-item-${subCatData?.id}`}
                              key={subCatData?.id}
                              className="!naxatw-border-none naxatw-py-[6px] naxatw-pl-3 first:naxatw-mt-2"
                            >
                              <AccordionTrigger className="naxatw-justify-between !naxatw-py-0 hover:!naxatw-no-underline">
                                <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                                  <Checkbox
                                    className="filter-checkbox"
                                    checked={subCatData?.checked}
                                    onChange={() => {
                                      dispatch(
                                        toggleFilterState({
                                          categoryId: subCatData?.categoryId,
                                          subcategoryId: subCatData?.id,
                                        }),
                                      );
                                    }}
                                  />
                                  <p
                                    className="naxatw-line-clamp-1 naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-matt-100"
                                    title={subCatData?.subcategory}
                                  >
                                    {subCatData?.subcategory}
                                  </p>
                                </div>
                                {excludeFilterItems?.includes(
                                  filterData.category,
                                ) ? null : (
                                  <ChevronDown
                                    className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                                    style={{
                                      strokeWidth: '2px',
                                    }}
                                  />
                                )}
                              </AccordionTrigger>

                              {!isEmpty(subCatData?.filterItems) ? (
                                <AccordionContent className="naxatw-mt-2 naxatw-gap-1">
                                  {subCatData?.filterItems?.map(
                                    (filterItem: Record<string, any>) => {
                                      return (
                                        <div
                                          key={filterItem?.filter}
                                          className="naxatw-mb-1 naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-pl-[1.625rem] last:naxatw-mb-0"
                                        >
                                          <Checkbox
                                            className="filter-checkbox"
                                            checked={filterItem?.checked}
                                            onChange={() => {
                                              dispatch(
                                                toggleFilterState({
                                                  categoryId:
                                                    subCatData?.categoryId,
                                                  subcategoryId: subCatData?.id,
                                                  filterId: filterItem?.id,
                                                }),
                                              );
                                            }}
                                          />
                                          <p>{filterItem?.filter}</p>
                                        </div>
                                      );
                                    },
                                  )}
                                </AccordionContent>
                              ) : null}
                            </AccordionItem>
                          ),
                        )}
                      </Accordion>
                    ) : null}
                  </div>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default hasErrorBoundary(KnowledgeRepoFilters);
