/* eslint-disable import/prefer-default-export */
export const adminHeaderOptions = [
  {
    id: 2,
    title: 'Project Registration',
    value: 'projects',
  },
  {
    id: 3,
    title: 'Project Data',
    value: 'project-data',
  },
];
