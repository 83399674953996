import Skeleton from '@Components/RadixComponents/Skeleton';
import { v4 as uuidv4 } from 'uuid';

interface ICheckBoxSkeletonProps {
  numRows?: number;
  className?: string;
}

const CheckBoxSkeleton = ({
  numRows = 2,
  className,
}: ICheckBoxSkeletonProps) => {
  const skeletonRows = Array.from({ length: numRows });
  return (
    <>
      <div className="naxatw-flex naxatw-h-full !naxatw-w-full  naxatw-max-w-full naxatw-flex-col naxatw-rounded-lg naxatw-py-5">
        <div className="naxatw-space-y-4">
          {skeletonRows.map(() => (
            <div
              key={uuidv4()}
              className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-justify-start naxatw-space-y-4"
            >
              <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-space-x-2">
                <Skeleton
                  className={`naxatw-h-4 naxatw-w-4  naxatw-border naxatw-border-gray-200 ${className}`}
                />

                <Skeleton className=" naxatw-h-4 naxatw-w-full  naxatw-border naxatw-border-gray-200" />
              </div>
              <div className="naxatw-ml-6 naxatw-flex naxatw-items-center naxatw-justify-start naxatw-space-x-2">
                <Skeleton
                  className={`naxatw-h-4 naxatw-w-4 naxatw-border naxatw-border-gray-200 ${className}`}
                />
                <Skeleton className=" naxatw-h-4 naxatw-w-[50%]  naxatw-border naxatw-border-gray-200" />
              </div>
              <div className="naxatw-ml-6 naxatw-flex naxatw-items-center naxatw-justify-start naxatw-space-x-2">
                <Skeleton
                  className={`naxatw-h-4 naxatw-w-4  naxatw-border naxatw-border-gray-200 ${className}`}
                />
                <Skeleton className=" naxatw-h-4 naxatw-w-[50%]  naxatw-border naxatw-border-gray-200" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CheckBoxSkeleton;
