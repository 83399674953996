import { CaseReducer, createSlice } from '@reduxjs/toolkit';

interface IActivityLogsStates {
  filterState: {
    startDate: any;
    endDate: any;
    role: string;
    user: string;
  };
}
const initialState: IActivityLogsStates = {
  filterState: {
    startDate: '',
    endDate: '',
    role: '',
    user: '',
  },
};

const setStartDate: CaseReducer<IActivityLogsStates> = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    filterState: {
      ...state.filterState,
      startDate: payload,
    },
  };
};

const setEndDate: CaseReducer<IActivityLogsStates> = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    filterState: {
      ...state.filterState,
      endDate: payload,
    },
  };
};

const setRole: CaseReducer<IActivityLogsStates> = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    filterState: {
      ...state.filterState,
      role: payload,
    },
  };
};

const setUser: CaseReducer<IActivityLogsStates> = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    filterState: {
      ...state.filterState,
      user: payload,
    },
  };
};

const clearFilterState: CaseReducer<IActivityLogsStates> = state => {
  return {
    ...state,
    filterState: {
      startDate: '',
      endDate: '',
      role: '',
      user: '',
    },
  };
};

const acitivityLogsSlice = createSlice({
  name: 'activityLogs',
  initialState,
  reducers: {
    setStartDate,
    setEndDate,
    setRole,
    setUser,
    clearFilterState,
  },
});

export { acitivityLogsSlice };
export default acitivityLogsSlice.reducer;
