import { api, authenticated } from '.';

export const getSectorDropdownList = () =>
  authenticated(api).get('/sector-group-dropdown/');

export const getFileDropdownList = (params: Record<string, any>) =>
  authenticated(api).get('/choice/', { params });

export const getProgramDropdownList = () =>
  authenticated(api).get('/program-dropdown/');

export const getComponentDropdownList = () =>
  authenticated(api).get('/component-dropdown/');

export const getPublicKnowledgeRepositoryData = (params: Record<string, any>) =>
  authenticated(api).get('/knowledge-repository/', { params });

export const getKnowRepoDocuemntLink = (params: Record<string, any>) =>
  authenticated(api).get('/generate-document-url/', { params });
