import { useRef } from 'react';
import CaptureComponent from '@Components/common/Charts/CaptureComponent';
import ChartCardHeader from './Header';
import ProvincialProritiesTable from './Table/ProvincialProritiesTable';

const ProvincialHeaderContent = ({ title }: { title: string }) => {
  return (
    <div className="header-content naxatw-pt-[0.375rem]">
      <p className="naxatw-subtitle-lg naxatw-text-matt-100">{title}</p>
    </div>
  );
};

const ProvincialPriorities = () => {
  const prioritiesRef = useRef(null);

  return (
    <div
      className="priorities-chart-container naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white"
      ref={prioritiesRef}
    >
      <div className="naxatw-flex naxatw-flex-col">
        <ChartCardHeader
          title="Provincial Priorities Alignment with new BEK Programmes"
          iconClick={() =>
            CaptureComponent({
              componentRef: prioritiesRef,
              captureName: 'Provincial Priorities',
            })
          }
          headerContent={
            <ProvincialHeaderContent title="Provincial Priorities Alignment with BEK Programme" />
          }
          content={<ProvincialProritiesTable />}
        />
        <div className="naxatw-w-full naxatw-bg-primary-200" />
        <ProvincialProritiesTable />
      </div>
    </div>
  );
};

export default ProvincialPriorities;
