import ExploreByGeography from '@Components/ExploreByGeography';

const ExploreByGeographyPage = () => {
  return (
    <div className="naxatw-min-h-[calc(100vh-63px)] naxatw-bg-[#F4F7FE] lg:naxatw-overflow-hidden">
      <ExploreByGeography />
    </div>
  );
};

export default ExploreByGeographyPage;
