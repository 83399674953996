export interface IChildLinkData {
  id: number;
  name: string;
  icon: string;
  shortDescription: string;
  link: string;
}

export interface INavbarLinkData {
  id: number;
  name: string;
  link?: string;
  children?: IChildLinkData[];
}

export const navbarData: INavbarLinkData[] = [
  {
    id: 1,
    name: 'BEK Footprint',
    link: '/dashboard/map',
  },
  {
    id: 2,
    name: 'Portfolio Profiles',
    link: '/explore-by/geography/',
  },

  {
    id: 3,
    name: 'Knowledge Library',
    link: '/knowledge-library',
  },
  {
    id: 4,
    name: 'Data Bank',
    link: '/data-bank/report',
  },
  {
    id: 5,
    name: 'Tutorial',
    link: '/dvs-tutorial',
  },
];
