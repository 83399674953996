import Icon from '@Components/common/Icon';

interface ChartSectionProps {
  icon: string;
  className?: string;
  iconClassName?: string;
  handleClick?: any;
}

const ChartButtons = ({
  icon,
  className,
  iconClassName,
  handleClick,
}: ChartSectionProps) => {
  return (
    <button
      className={`prev-btn naxatw-flex naxatw-h-[2.75rem] naxatw-w-[2.75rem] naxatw-items-center naxatw-justify-center naxatw-self-center naxatw-rounded-[50%] naxatw-bg-white naxatw-p-3 naxatw-shadow-sm lg:naxatw-h-[3.75rem] lg:naxatw-w-[3.75rem] ${className}`}
      onClick={handleClick || (() => {})}
      type="button"
    >
      <Icon
        name={icon}
        className={`naxatw-flex naxatw-text-[2rem] naxatw-font-normal naxatw-leading-10 naxatw-text-primary-600 ${iconClassName}`}
      />
    </button>
  );
};

export default ChartButtons;
