type KnowledgeLibraryIconProps = {
  hover?: boolean;
};

const KnowledgeLibrary = ({ hover }: KnowledgeLibraryIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
    >
      <g clipPath="url(#clip0_7845_45168)">
        <path
          d="M18.2152 14.9689C18.2152 14.7949 18.2152 14.661 18.2152 14.5272C18.2152 12.7365 18.2152 10.9446 18.2152 9.15399C18.2152 8.52082 17.8215 8.09412 17.2471 8.09537C16.674 8.09662 16.2855 8.52332 16.2855 9.15899C16.2855 10.9709 16.2855 12.7828 16.2855 14.5947C16.2855 14.7073 16.2855 14.82 16.2855 14.9664C15.4477 14.9664 14.6346 14.9689 13.8227 14.9601C13.7646 14.9601 13.6678 14.8813 13.6549 14.825C13.5116 14.1993 13.1101 13.7651 12.6093 13.3734C8.42721 10.1037 9.09066 3.78583 13.8627 1.3908C18.4527 -0.912879 24.0534 1.98518 24.6032 6.96793C24.8898 9.56317 23.9553 11.7092 21.8874 13.3772C21.4034 13.7676 21.002 14.1893 20.8496 14.7999C20.8329 14.8675 20.7141 14.9589 20.6405 14.9601C19.848 14.9714 19.0542 14.9676 18.2165 14.9676L18.2152 14.9689Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M16.283 32.5913H15.8841C11.2347 32.5913 6.58537 32.5913 1.93599 32.5913C1.09828 32.5913 0.751059 32.2497 0.749768 31.4301C0.749768 30.8995 0.745896 30.3677 0.749768 29.8371C0.754931 29.2315 1.15378 28.8411 1.77981 28.8411C4.93705 28.8386 8.093 28.8198 11.2502 28.8499C13.1012 28.8674 14.7224 29.5306 16.1074 30.7218C16.1875 30.7906 16.2701 30.9033 16.2726 30.9984C16.2881 31.5164 16.2804 32.0357 16.2804 32.5913H16.283Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M18.2148 32.5916C18.2148 32.0285 18.2071 31.4992 18.2226 30.9711C18.2252 30.8873 18.3116 30.7884 18.3852 30.7246C19.7431 29.5559 21.3308 28.8802 23.1443 28.8551C26.3428 28.8126 29.5427 28.8389 32.7425 28.8414C33.3414 28.8414 33.739 29.243 33.7442 29.8337C33.7493 30.4068 33.748 30.9786 33.7442 31.5517C33.7403 32.2149 33.3505 32.5903 32.6586 32.5903C28.7501 32.5903 24.8417 32.5903 20.9345 32.5903C20.0439 32.5903 19.1532 32.5903 18.2135 32.5903L18.2148 32.5916Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M16.2848 28.484C15.1748 27.7995 14.0454 27.3302 12.814 27.1363C12.2021 27.0399 11.5787 26.9786 10.9604 26.9749C8.14908 26.9586 5.33777 26.9674 2.52646 26.9674H2.11857C2.11857 26.3767 2.08243 25.8061 2.13019 25.2418C2.1702 24.7675 2.60649 24.4672 3.14087 24.4672C4.8576 24.4647 6.57434 24.4672 8.29107 24.4672C9.35338 24.4672 10.4157 24.456 11.478 24.4697C13.2347 24.4935 14.7604 25.0966 16.0654 26.2353C16.219 26.3692 16.2965 26.5056 16.29 26.7159C16.2745 27.2852 16.2848 27.8558 16.2848 28.4852V28.484Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M32.3945 26.968H31.984C29.2153 26.968 26.4466 26.9793 23.6779 26.9643C21.7727 26.9543 20.0095 27.4035 18.3909 28.382C18.3483 28.407 18.3031 28.4283 18.2153 28.4734C18.2153 27.8014 18.2089 27.1707 18.2231 26.5413C18.2244 26.4562 18.3108 26.3574 18.3831 26.2911C19.6507 25.1486 21.1493 24.4979 22.8815 24.4779C25.7147 24.4441 28.548 24.4654 31.38 24.4679C31.984 24.4679 32.3855 24.8633 32.3919 25.4489C32.3984 25.9382 32.3919 26.4262 32.3919 26.968H32.3945Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M16.2585 24.0074C15.1846 23.3392 14.059 22.905 12.8366 22.7072C12.7372 22.691 12.643 22.6234 12.5527 22.5683C11.2128 21.7462 9.7491 21.3408 8.16531 21.3433C6.94166 21.3446 5.71929 21.3446 4.49563 21.3433C3.83863 21.3433 3.40105 20.9717 3.3946 20.4148C3.38815 19.848 3.83217 19.4676 4.50725 19.4676C5.8161 19.4676 7.12624 19.4388 8.43508 19.4763C11.5304 19.5652 14.0564 20.8015 16.0249 23.1152C16.3217 23.4643 16.3153 23.4693 16.2585 24.0074Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M18.2436 23.9974C18.1493 23.612 18.2668 23.3417 18.4979 23.074C20.2817 21.0068 22.5315 19.7492 25.3093 19.5377C26.9202 19.4151 28.5465 19.4752 30.1665 19.4714C30.706 19.4702 31.1178 19.9181 31.1036 20.4287C31.0894 20.9405 30.6725 21.3371 30.1161 21.3396C28.9247 21.3471 27.7334 21.3559 26.5433 21.3396C24.8562 21.3159 23.3008 21.7225 21.8758 22.606C21.73 22.6961 21.5376 22.7286 21.3621 22.7624C20.3217 22.9663 19.3523 23.3405 18.4501 23.8823C18.3881 23.9186 18.3249 23.9511 18.2436 23.9962V23.9974Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M13.7043 16.8467H20.7403C21.1043 18.6924 20.0704 20.0038 18.3588 19.9712C17.5753 19.9562 16.7931 19.9725 16.0096 19.9675C14.6904 19.9587 13.7159 19.0152 13.7043 17.7376C13.7017 17.4411 13.7043 17.1445 13.7043 16.8467Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M7.45626 5.28968C7.40334 5.27716 7.26523 5.25589 7.13615 5.21084C6.38104 4.94681 5.62594 4.68153 4.87342 4.41C4.30935 4.20728 4.04345 3.7005 4.22803 3.19747C4.4139 2.69068 4.94699 2.45419 5.51493 2.64689C6.31263 2.91717 7.11033 3.19371 7.899 3.48777C8.374 3.66421 8.60118 4.10718 8.49792 4.56892C8.40111 5.00187 8.01129 5.29718 7.45626 5.29093V5.28968Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M5.63152 8.72097C5.22363 8.72097 4.81704 8.72472 4.40915 8.72097C3.81927 8.71346 3.38815 8.30928 3.3946 7.77372C3.39977 7.25192 3.82572 6.85149 4.39754 6.84649C5.23396 6.84023 6.07038 6.84023 6.90552 6.84649C7.47862 6.85149 7.902 7.25317 7.90458 7.77622C7.90845 8.30052 7.48636 8.70845 6.91713 8.71846C6.48859 8.72597 6.06006 8.71971 5.63023 8.71971L5.63152 8.72097Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M28.872 6.84452C29.2799 6.84452 29.6865 6.84076 30.0943 6.84452C30.67 6.85202 31.0947 7.24244 31.1037 7.76549C31.1128 8.30105 30.6842 8.71399 30.0943 8.71774C29.2579 8.724 28.4215 8.72525 27.5864 8.71774C27.0171 8.71274 26.5938 8.30481 26.5938 7.78175C26.5938 7.25995 27.0184 6.85453 27.5864 6.84452C28.0149 6.83701 28.4434 6.84326 28.8733 6.84326L28.872 6.84452Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M27.0186 10.2727C27.0573 10.2815 27.1761 10.2928 27.2845 10.3303C28.0809 10.6056 28.8786 10.8771 29.6699 11.1687C30.2185 11.3714 30.4637 11.917 30.2559 12.41C30.0726 12.8442 29.5679 13.1045 29.0903 12.9493C28.2281 12.669 27.3736 12.3637 26.5281 12.0396C26.0931 11.8719 25.8969 11.4177 26.0028 10.9797C26.106 10.558 26.4855 10.2715 27.0186 10.2715V10.2727Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M26.9834 5.29139C26.4813 5.29014 26.1018 4.99983 26.0011 4.57813C25.8992 4.15269 26.0838 3.69846 26.5033 3.53578C27.3681 3.20168 28.2419 2.88635 29.1261 2.60731C29.6218 2.45089 30.1161 2.74996 30.2749 3.2167C30.4298 3.67218 30.2259 4.17271 29.7509 4.35415C28.9054 4.67824 28.0444 4.96729 27.1887 5.26511C27.1099 5.29264 27.0196 5.28638 26.9834 5.29013V5.29139Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M5.16651 12.9857C4.68376 12.9895 4.29653 12.6929 4.19198 12.2562C4.09388 11.8483 4.30169 11.3815 4.71861 11.2226C5.5744 10.8948 6.43922 10.5857 7.31178 10.3029C7.77388 10.154 8.26567 10.4155 8.44896 10.8397C8.63354 11.2651 8.4851 11.8207 8.04624 11.9972C7.14786 12.3575 6.22237 12.6541 5.30592 12.9732C5.25687 12.9907 5.20007 12.9832 5.16522 12.9857H5.16651Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7845_45168">
          <rect
            width="33"
            height="32"
            fill="white"
            transform="translate(0.75 0.59082)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KnowledgeLibrary;
