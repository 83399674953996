/* eslint-disable import/prefer-default-export */
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { IMultiStepTitle } from '@Constants/Types/FormTypes/component';

export const ComponentFormFields: FormFieldProps[] = [
  {
    label: 'Select Programme',
    inputType: 'number',
    type: 'select',
    placeholder: 'Select Programme',
    id: 'program',
    name: 'program',
    isVisible: true,
    choose: 'id',
    required: true,
  },
  {
    label: 'Component Code',
    inputType: 'text',
    type: 'input',
    placeholder: 'Code',
    id: 'code',
    name: 'code',
    isVisible: true,
    required: true,
  },
  {
    label: 'Project Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Project Name',
    id: 'name',
    name: 'name',
    isVisible: true,
    required: true,
  },
  {
    label: 'Select First Tier Partner',
    inputType: 'number',
    type: 'select',
    placeholder: 'Select First Tier Partner',
    id: 'first_tier_partner',
    name: 'first_tier_partner',
    choose: 'value',
    isVisible: true,
    required: true,
  },
  {
    label: 'Project Abbreviation',
    inputType: 'text',
    type: 'input',
    placeholder: 'Project Abbreviation',
    id: 'abbreviation',
    name: 'abbreviation',
    // group: 'group-1',
    isVisible: true,
  },
  // {
  //   label: 'Project Code',
  //   inputType: 'text',
  //   type: 'input',
  //   placeholder: 'Project Code',
  //   id: 'project_code',
  //   name: 'project_code',
  //   group: 'group-1',
  //   isVisible: true,
  //   required: true,
  // },
  {
    label: 'Start Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Start Date',
    id: 'start_date',
    name: 'start_date',
    isVisible: true,
    group: 'group-2',
    required: true,
  },
  {
    label: 'End Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'End Date',
    id: 'end_date',
    name: 'end_date',
    isVisible: true,
    group: 'group-2',
    required: true,
  },
  {
    label: 'Agreement Modality',
    inputType: 'number',
    type: 'select',
    placeholder: 'Select Agreement Modality',
    id: 'aggrement_modality',
    name: 'aggrement_modality',
    isVisible: true,
    required: true,
  },
  // {
  //   label: 'From',
  //   inputType: 'text',
  //   type: 'select',
  //   placeholder: 'Choose Month',
  //   id: 'first_period_start_month',
  //   name: 'first_period_start_month',
  //   isVisible: true,
  //   group: 'group-3',
  //   required: true,
  // },
  // {
  //   label: 'To',
  //   inputType: 'text',
  //   type: 'select',
  //   placeholder: 'Choose Month',
  //   id: 'first_period_end_month',
  //   name: 'first_period_end_month',
  //   isVisible: true,
  //   group: 'group-3',
  //   required: true,
  // },
  // {
  //   label: 'From',
  //   inputType: 'text',
  //   type: 'select',
  //   placeholder: 'Choose Month',
  //   id: 'second_period_start_month',
  //   name: 'second_period_start_month',
  //   isVisible: true,
  //   group: 'group-4',
  //   required: true,
  // },
  // {
  //   label: 'To',
  //   inputType: 'text',
  //   type: 'select',
  //   placeholder: 'Choose Month',
  //   id: 'second_period_end_month',
  //   name: 'second_period_end_month',
  //   isVisible: true,
  //   group: 'group-4',
  //   required: true,
  // },
  // {
  //   label: 'Component Extended',
  //   inputType: 'text',
  //   type: 'select',
  //   choose: 'value',
  //   placeholder: 'Choose',
  //   id: 'is_extension',
  //   name: 'is_extension',
  //   isVisible: true,
  //   required: true,
  // },
  // {
  //   label: 'Extended Date',
  //   inputType: 'text',
  //   type: 'datePicker',
  //   placeholder: 'Choose',
  //   id: 'extended_date',
  //   name: 'extended_date',
  //   isVisible: true,
  // },
];

export const ComponentEstimatedBudgetAllocationFormFields: FormFieldProps[] =
  [];

export const tooltipInfo = [
  {
    title: 'Select Supplier',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Select Program',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Select Component',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

// const reportingInterval = ['first_period_end_month', 'second_period_end_month'];
// const allMonths = [
//   { id: 1, label: 'January', value: 1 },
//   { id: 2, label: 'February', value: 2 },
//   { id: 3, label: 'March', value: 3 },
//   { id: 4, label: 'April', value: 4 },
//   { id: 5, label: 'May', value: 5 },
//   { id: 6, label: 'June', value: 6 },
//   { id: 7, label: 'July', value: 7 },
//   { id: 8, label: 'August', value: 8 },
//   { id: 9, label: 'September', value: 9 },
//   { id: 10, label: 'October', value: 10 },
//   { id: 11, label: 'November', value: 11 },
//   { id: 12, label: 'December', value: 12 },
// ];

export const includeFieldButtons: string[] = ['program', 'first_tier_partner'];

export const typesOfExpenditure = [
  { id: 1, label: 'RDEL', value: 'rdel_expenditure' },
  { id: 2, label: 'CDEL', value: 'cdel_expenditure' },
  { id: 3, label: 'RDEL-ICF', value: 'rdel_icf_expenditure' },
  { id: 4, label: 'CDEL-ICF', value: 'cdel_icf_expenditure' },
];

export const isDonorList = [
  { label: 'Yes', value: 'true', id: 'Yes' },
  { label: 'No', value: 'false', id: 'Yes' },
];

export const budgetTypes = [
  {
    label: 'Off-Budget',
    value: 'Off-Budget',
  },
  {
    label: 'On-Budget',
    value: 'On-Budget',
  },
];

export const treasuryTypes = [
  {
    label: 'Off-Treasury',
    value: 'Off-Treasury',
  },
  {
    label: 'On-Treasury',
    value: 'On-Treasury',
  },
];

export const budgetBtn = ['Amount', 'Percentage'];

export const expenditureBtn = ['Amount', 'Percentage'];

export const MultiStepTitle: IMultiStepTitle[] = [
  { id: 1, name: 'Registration', value: 'basicInfo' },
  {
    id: 2,
    name: 'Budget',
    value: 'estimatedBudgetAllocation',
  },
  { id: 3, name: 'Sector', value: 'sectors' },
  { id: 4, name: 'Marker', value: 'markers' },
  { id: 5, name: 'Campaign Goals', value: 'campaignGoals' },
];
