import prepareFormData from '@Utils/prepareFormData';
import { api, authenticated } from '.';

export const postBasicInfo = (data: any) =>
  authenticated(api).post('/organization/', data);

export const getBasicInfoByID = (id: any) => {
  return authenticated(api).get(`/organization/${id}/`);
};

export const patchBasicInfo = (data: any, id: string | undefined) => {
  // const { id } = data;
  return authenticated(api).patch(`/organization/${id}/`, data);
};

export const postPersonDetail = (data: any) => {
  return authenticated(api).post(`/contact-info/`, data);
};
export const deletePersonDetailByID = (id: any) => {
  return authenticated(api).delete(`/contact-info/${id}/`);
};

export const getpersonDetailByID = (id: any) => {
  return authenticated(api).get(`/contact-info/?organization=${id}`);
};
export const patchPersonDetail = (data: any) => {
  const { id } = data;

  return authenticated(api).patch(
    `/contact-info/${id}/`,
    prepareFormData(data),
  );
};

export const getPartners = (params: Record<string, any>) => {
  return authenticated(api).get('/organization/', { params });
};

export const getOrganizationType = () =>
  authenticated(api).get('/organization-type-dropdown/');
export const deletePartner = (id: any) =>
  authenticated(api).delete(`/organization/${id}/`);
