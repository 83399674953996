/* eslint-disable react/no-danger */
import { useQuery } from '@tanstack/react-query';
import pdfSvg from '@Assets/images/pdf.svg';
import docSvg from '@Assets/images/doc.svg';
import textSvg from '@Assets/images/text.svg';
import xlsSvg from '@Assets/images/sheets.svg';
import csvSvg from '@Assets/images/csv.svg';
import { format, parseISO } from 'date-fns';
import getFileExtension from '@Utils/getFileExtension';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useRef, useState } from 'react';
import useSeeMoreButton from '@Hooks/useSeeMoreButton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { getKnowRepoDocuemntLink } from '@Services/publicKnowledgeRepository';
import DocumentShareModal from '../ShareModal';

function getFileLogo(fileType: string) {
  switch (fileType) {
    case 'pdf':
      return pdfSvg;
    case 'doc':
      return docSvg;
    case 'docx':
      return docSvg;
    case 'txt':
      return textSvg;
    case 'xls':
      return xlsSvg;
    case 'xlsx':
      return xlsSvg;
    case 'csv':
      return csvSvg;
    default:
      return pdfSvg;
  }
}

const KnowledgeCard = ({ repository }: { repository: Record<string, any> }) => {
  const summaryRef = useRef(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showDocGenerateLink, setShwDocGenerateLink] = useState(false);

  const {
    programs,
    file_type: fileType,
    published_date: date,
    summary,
    tags,
    id,
    thematic_field: thematicField,
    document_link: dLink,
    documents,
  } = repository;

  // handle knowDoc Link fectch
  const { data: knoDocLink } = useQuery({
    queryKey: ['generate-document-link'],
    queryFn: () => getKnowRepoDocuemntLink({ id }),
    enabled: showDocGenerateLink,
    select: response => response?.data?.zip_url,
  });

  const allTags = tags?.split(',')?.filter((tag: string) => tag !== '');
  const { isOpen, setIsOpen, showSeeMoreBtn } = useSeeMoreButton(summaryRef);

  return (
    <>
      <div className="naxatw-w-full naxatw-border-b naxatw-border-[#D7D7D7] naxatw-p-5 last:naxatw-border-b-0">
        <FlexRow className="naxatw-mb-3 naxatw-items-center naxatw-justify-between">
          <p className="naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-[#0B2E62]">
            {repository?.title || '-'}
          </p>

          <div
            className="share-content naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-4 naxatw-py-2"
            onClick={() => {
              setIsShareModalOpen(true);
              setShwDocGenerateLink(true);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <ToolTip
              message="Share this document"
              name="share"
              className="naxatw-text-[1.5rem] !naxatw-text-secondary-500"
            />
            <span className="naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-5 naxatw-text-secondary-500">
              Share
            </span>
          </div>
        </FlexRow>
        <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-2 lg:naxatw-grid-cols-[2fr_3fr]">
          <div>
            <div
              className={`top-details ${
                !isEmpty(thematicField) ||
                programs?.[0]
                  ?.knowledge_repository_program__associate_program__name
                  ? 'naxatw-mb-6 '
                  : ''
              } naxatw-flex naxatw-flex-col naxatw-gap-2`}
            >
              {/* program */}
              {programs?.[0]
                ?.knowledge_repository_program__associate_program__name ? (
                <div className="naxatw-flex naxatw-items-start naxatw-gap-2">
                  <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                    Programme:{' '}
                  </p>
                  <FlexColumn className="naxatw-gap-[0.625rem]">
                    {programs?.map((programmeDetails: any) => (
                      <FlexColumn
                        key={
                          programmeDetails?.knowledge_repository_program__associate_program__id
                        }
                      >
                        <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                          {
                            programmeDetails?.knowledge_repository_program__associate_program__name
                          }
                        </p>
                        <FlexRow className="naxatw-flex-wrap naxatw-items-center">
                          {programmeDetails?.projects?.map(
                            (project: any, index: number) => {
                              return (
                                <div
                                  key={project?.id}
                                  className="naxatw-flex naxatw-items-center"
                                >
                                  <p className="naxatw-mr-[0.5rem] naxatw-text-xs naxatw-font-medium naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-matt-100 last:naxatw-mr-0">
                                    {project?.name}
                                  </p>
                                  {programmeDetails?.projects?.length > 0 &&
                                    index <
                                      (programmeDetails?.projects?.length ||
                                        0) -
                                        1 && (
                                      <span className="naxatw-pr-[0.5rem] naxatw-text-[#9FBEE4]">
                                        |
                                      </span>
                                    )}
                                </div>
                              );
                            },
                          )}
                        </FlexRow>
                      </FlexColumn>
                    ))}
                  </FlexColumn>
                </div>
              ) : null}

              {/* sector */}
              {isEmpty(thematicField) ? null : (
                <div className="naxatw-flex naxatw-items-start naxatw-gap-2">
                  <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                    Sector:{' '}
                  </p>

                  <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center">
                    {thematicField?.map(
                      (sectorItem: Record<string, any>, index: number) => (
                        <p
                          key={sectorItem.id}
                          className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100"
                        >
                          {sectorItem.thematic_field__name}
                          {index < thematicField.length - 1 && (
                            <span className="naxatw-px-1">|</span>
                          )}
                        </p>
                      ),
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="bottom-details naxatw-flex naxatw-flex-col naxatw-gap-6">
              {/* date */}
              <div className="naxatw-flex naxatw-items-center naxatw-gap-3 naxatw-text-xs naxatw-font-medium">
                <p className="naxatw-text-nowrap naxatw-rounded-[50px] naxatw-bg-[#CFE0F1] naxatw-px-2 naxatw-py-1 naxatw-text-matt-200">
                  {fileType || '-'}
                </p>
                <div className="naxatw-flex naxatw-items-center naxatw-gap-1">
                  <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                    Uploaded on:
                  </p>
                  <span className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                    {' '}
                    {format(parseISO(date), 'MMMM d, yyyy') || '-'}
                  </span>
                </div>
              </div>
              {/* document links */}
              {dLink ? (
                <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                  <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                    Document Link:{' '}
                  </p>
                  <span
                    className="naxatw-cursor-pointer naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100 hover:naxatw-underline"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => {
                      if (dLink) {
                        window.open(dLink, '_blank', 'noopener,noreferrer');
                      }
                    }}
                  >
                    {dLink || '-'}
                  </span>
                </div>
              ) : null}

              {/* download */}
              {isEmpty(documents) ? null : (
                <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-gap-2">
                  <p className="naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-matt-200">
                    Download
                  </p>
                  {repository?.documents &&
                    repository?.documents.map((doc: Record<string, any>) => (
                      <div key={doc.id} className="naxatw-tooltip">
                        <a
                          href={doc.file}
                          download
                          target="_blank"
                          className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-text-xs naxatw-text-[#475467]"
                          rel="noreferrer"
                        >
                          <img
                            src={
                              getFileLogo(
                                getFileExtension(doc?.file)?.split('?')[0] ||
                                  '-',
                              ) || '-'
                            }
                            alt="file"
                            className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
                          />
                          <span className="naxatw-tooltiptext">
                            {doc.file.split('/').pop()}
                          </span>
                        </a>
                      </div>
                    ))}
                </div>
              )}

              {/* tags */}

              {tags ? (
                <div className="tags naxatw-flex naxatw-items-center naxatw-gap-3">
                  <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                    Tags:
                  </p>
                  <div className="tags-container naxatw-flex naxatw-items-center naxatw-gap-1">
                    {!isEmpty(allTags)
                      ? allTags.map((tag: string) => {
                          return (
                            <div
                              className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-rounded-[3.125rem] naxatw-border naxatw-border-primary-300 naxatw-px-2 naxatw-py-1 naxatw-capitalize"
                              key={tag}
                            >
                              <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                                {tag}
                              </p>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* summary */}
          <div className="summary">
            <div
              className={`editor-content naxatw-text-xs naxatw-font-normal naxatw-leading-4 naxatw-text-matt-200 ${isOpen ? '' : 'naxatw-line-clamp-[15]'}`}
              ref={summaryRef}
              dangerouslySetInnerHTML={{
                __html: summary || '<span>No summary to show.</span>',
              }}
            />
            {showSeeMoreBtn && (
              <button
                type="button"
                className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F]"
                onClick={() => setIsOpen(!isOpen)}
              >
                See {isOpen ? 'Less' : 'More'}
              </button>
            )}
          </div>
        </div>
      </div>
      {isShareModalOpen && (
        <DocumentShareModal
          handleClose={() => setIsShareModalOpen(false)}
          id={id}
          isShareModalOpen={isShareModalOpen}
          knowledgeRepDoc={knoDocLink}
        />
      )}
    </>
  );
};

export default hasErrorBoundary(KnowledgeCard);
