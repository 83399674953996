import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { useNavigate } from 'react-router-dom';

interface IVerifyEmailProps {
  image: any;
  title: string;
  description: string;
}

const VerifyEmail = ({ image, title, description }: IVerifyEmailProps) => {
  const navigate = useNavigate();
  return (
    <FlexColumn className="naxatw-gap-10 naxatw-py-10">
      <img
        src={image}
        alt="Email"
        className="naxatw-mx-auto naxatw-w-[6.25rem]"
      />
      <FlexColumn className="naxatw-gap-3">
        <h6 className="naxatw-text-center naxatw-text-black naxatw-opacity-[87%]">
          {title}
        </h6>
        <p className="naxatw-text-center naxatw-text-[0.875rem] naxatw-text-black naxatw-opacity-[87%]">
          {description}
        </p>
      </FlexColumn>
      <Button variant="link" onClick={() => navigate('/login')}>
        Back To Login
      </Button>
    </FlexColumn>
  );
};

export default VerifyEmail;
