import { api, authenticated } from '.';

export const signInUser = (data: any) => api.post('/user/sign-in/', data);

export const checkUserProfile = () =>
  authenticated(api).get('/user/check-user/');

export const checkUserProfilePermission = (id: number) =>
  authenticated(api).get(`/user/list_user_permissions/?group_id=${id}`);

export const logoutUser = () => api.post('/user/logout/');

export const signUpUser = (payload: Record<string, any>) => {
  return api.post(`/user/sign-up/`, payload);
};

// fetches province list
export const getProvinceList = () => {
  return authenticated(api).get('/province/');
};

export const updateUserProfile = (payload: Record<string, any>) => {
  return authenticated(api).post(`/user/edit-account/`, payload);
};

export const updateUserPassword = (payload: Record<string, any>) => {
  return authenticated(api).post(`/user/change-password/`, payload);
};
