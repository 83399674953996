import { api, authenticated } from '.';

export const getComponentList = (params: Record<string, any>) =>
  authenticated(api).get('/project/', { params });

export const getComponentByID = (id: any) => {
  return authenticated(api).get(`/project/${id}/`);
};
export const postComponent = (data: Record<string, any>) => {
  return authenticated(api).post('/project/', data);
};
export const postComponentSector = (data: Record<string, any>) => {
  return authenticated(api).post('/project-sector/', data);
};
export const patchComponentSector = (payload: Record<string, any>) => {
  const { id } = payload;
  return authenticated(api).patch(`/project-sector/${id}/`, payload);
};
export const postComponenMarker = (data: Record<string, any>) => {
  return authenticated(api).post('/project-marker/', data);
};
export const patchComponenMarker = (payload: Record<string, any>) => {
  const id = payload?.id || '';
  return authenticated(api).patch(`/project-marker/${id}/`, payload);
};
export const deleteComponenMarker = (id: any) => {
  return authenticated(api).delete(`/project-marker/${id}/`);
};
export const getMarkerComponentByID = (id: any) =>
  authenticated(api).get(`/project-marker/?project=${id}`);

export const patchComponent = (payload: Record<string, any>) => {
  const id = payload?.id || '';
  return authenticated(api).patch(`/project/${id}/`, payload);
};
export const deleteComponentByID = (id: any) =>
  authenticated(api).delete(`/project/${id}/`);

export const getSectorList = (params: Record<string, any>) =>
  authenticated(api).get('/get-project-sector/', { params });

export const getMarkerList = (params: Record<string, any>) =>
  authenticated(api).get('/get_project_marker/', { params });

export const getFirstTierPartner = () =>
  authenticated(api).get('/organization-dropdown/');

export const getSectorComponentByID = (id: any) =>
  authenticated(api).get(`/project-sector/?project=${id}`);

export const deleteComponentSectorByID = (id: any) => {
  return authenticated(api).delete(`/project-sector/${id}/`);
};

export const getProgramList = () => {
  return authenticated(api).get('/program-dropdown/');
};

//  gets intervention level list
export const getInterventionLevel = () =>
  authenticated(api).get('/choice/?type=intervention_level');

// gets province list
export const getProvinceList = () => authenticated(api).get('/province/');
// get indicators list
export const getIndicatorList = () =>
  authenticated(api).get('/get-component-indicator/');
// post component-budget
export const postComponentBudget = (data: Record<string, any>) =>
  authenticated(api).post('/project-budget/', data, {
    headers: { 'Content-Type': 'application/json' },
  });

// post component-indicator
export const postComponentIndicator = (data: Record<string, any>) =>
  authenticated(api).post('/project-indicator/', data);

// get estmiated budget data by id
export const getEstimatedBugetComponentByID = (id: any) =>
  authenticated(api).get(`/project-budget/?project=${id}`);

export const getIndicatorsComponentByID = (id: any) =>
  authenticated(api).get(`/project-indicator/?project=${id}`);

// patch estimated-budget
export const patchComponentEstimatedBudget = (payload: Record<string, any>) => {
  const { id, ...params } = payload;
  return authenticated(api).patch(`/project-budget/${id}/`, params, {
    headers: { 'Content-Type': 'application/json' },
  });
};

// del estimated-budget
export const deleteComponentEstimatedBudget = (id: any) => {
  return authenticated(api).delete(`/project-budget/${id}/`);
};

// del component indicator
export const deleteComponentIndicator = (id: any) => {
  return authenticated(api).delete(`/project-indicator/${id}/`);
};

// get campaign goals list
export const getCampaignGoals = (params: Record<string, any>) => {
  return authenticated(api).get(`/project-campaign-goals/`, { params });
};

// post campaign goals list
export const postCampaignGoals = (data: Record<string, any>) => {
  return authenticated(api).post('/project-campaign-goal/', data, {
    headers: { 'Content-Type': 'application/json' },
  });
};

// get campagin goals by id
export const getCampaignGoalsByID = (id: any) =>
  authenticated(api).get(`/project-campaign-goal/?project__id=${id}`);

// delete campaign goals
export const deleteCampaignGoal = (id: any) => {
  return authenticated(api).delete(`/project-campaign-goal/${id}/`);
};

export const getAgreementModality = () => {
  return authenticated(api).get('/aggrement-modality/');
};

// get budget list
export const getBudgetList = () => {
  return authenticated(api).get('/choice/?type=budget_type');
};

// get treasury list type
export const getTreasuryList = () => {
  return authenticated(api).get('/choice/?type=treasury_type');
};

export const validateBasicInfoFields = (params: Record<string, any>) => {
  const { programId, componentCode, partnerId } = params;
  return authenticated(api).get(
    `/validate-project/?program_id=${programId}&project_code=${componentCode}&first_tier_partner_id=${partnerId}`,
  );
};
export const nothing = '';
