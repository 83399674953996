export const statusOptions = [
  {
    label: 'All',
  },
  {
    label: 'On-Going',
  },
  {
    label: 'Completed',
  },
];

export const mappedStatusParams: Record<string, string> = {
  All: '',
  'On-Going': 'Ongoing',
  Completed: 'Completed',
};

export const excludeALLmappedStatusParams: Record<string, string> = {
  'On-Going': 'Ongoing',
  Completed: 'Completed',
};
export const activeStatusOptions = ['Ongoing', 'Completed'];

export const categories = [
  'Programme',
  'First Tier Partner',
  'Sector',
  'Marker',
];
export const excludeFilterItems = ['First Tier Partner', 'Sector', 'File Type'];
export const filColHeight = '224';
