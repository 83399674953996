import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import KnowledgeRepository from '@Components/KnowledgeRepository';

const KnowledgeRepositoryPage = () => {
  return (
    <section className="naxatw-w-full naxatw-overflow-x-hidden naxatw-overflow-y-hidden naxatw-bg-primary-100 lg:naxatw-h-[calc(100vh-63px)]">
      <KnowledgeRepository />
    </section>
  );
};

export default hasErrorBoundary(KnowledgeRepositoryPage);
