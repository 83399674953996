import { useEffect, useState } from 'react';
import { useTypedSelector } from '@Store/hooks';
import IndicatorsDataTable from './Table';
import MapSection from './MapSection';
import TopBox from './TopBox';

const TopSection = () => {
  const activeViewAs = useTypedSelector(
    state => state.exploreByIndicators.map.activeViewAs,
  );
  const [selectedProvince, setSelectedProvince] = useState(
    activeViewAs === 'Map' ? 0 : 1,
  );

  useEffect(() => {
    setSelectedProvince(prev => (activeViewAs === 'Map' ? 0 : prev || 1));
  }, [activeViewAs]);

  return (
    <>
      <div className="naxatw-relative naxatw-h-[calc(100vh-10.5rem)] naxatw-w-full naxatw-self-stretch naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white naxatw-shadow-sm">
        <div className="naxatw-absolute naxatw-left-0 naxatw-right-0 naxatw-z-10 naxatw-w-full">
          <TopBox
            selectedProvince={selectedProvince}
            setSelectedProvince={setSelectedProvince}
          />
        </div>
        {activeViewAs.toLocaleLowerCase() === 'map' && (
          <MapSection selectedProvince={selectedProvince} />
        )}
        {activeViewAs.toLocaleLowerCase() === 'table' && (
          <div className="naxatw-absolute naxatw-top-32 naxatw-w-full">
            <IndicatorsDataTable selectedProvince={selectedProvince} />
          </div>
        )}
      </div>
    </>
  );
};

export default TopSection;
