import { Button } from '@Components/RadixComponents/Button';
import { IOverlayComponentProps } from '@Constants/interface';
import Icon from '../Icon';
import Alert from '../Alert';

interface IDeleteConfirmationOverlayProps extends IOverlayComponentProps {
  onDelete: () => any;
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
}
export default function DeleteConfirmationOverlay({
  onClose,
  onDelete,
  isLoading,
  isError,
  error,
}: IDeleteConfirmationOverlayProps) {
  return (
    <div
      id="file-editor"
      className="naxatw-w-min-h-[9.625rem] naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-min-w-[32rem] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-white naxatw-p-6 naxatw-transition-all naxatw-duration-200"
    >
      <div className="content naxatw-flex naxatw-flex-col naxatw-gap-4">
        <div className="head naxatw-flex naxatw-flex-col naxatw-gap-2">
          <h5 className="!naxatw-font-bold naxatw-text-matt-200">Delete</h5>
          <p className="naxatw-body-md naxatw-font-normal naxatw-text-matt-200">
            Are you sure want to delete?
          </p>
        </div>
        <div className="actions naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-end naxatw-gap-1">
          <Button
            variant="link"
            onClick={e => {
              e.stopPropagation();
              // onDelete();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="!naxatw-bg-other-red naxatw-flex naxatw-gap-2 hover:naxatw-bg-red-700
            hover:naxatw-font-bold"
            isLoading={isLoading}
            onClick={e => {
              e.stopPropagation();
              onDelete();
              // onClose();
            }}
          >
            <Icon name="delete" />
            <p className="naxatw-button">Delete</p>
          </Button>
        </div>
        {isError && <Alert message={error?.message} error />}
      </div>
    </div>
  );
}
