import Explore from '@Components/Explore';

const ExplorePage = () => {
  return (
    <section className="explore-page-section naxatw-overflow-x-hidden">
      <Explore />
    </section>
  );
};

export default ExplorePage;
