/* eslint-disable no-unused-vars */
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';

interface CustomAddButtonProps {
  onClick: any;
  message?: any;
}

const CustomAddButton = ({ onClick, message }: CustomAddButtonProps) => {
  return (
    <div>
      <ToolTip
        name="add"
        message={message}
        className="naxatw-text-icon-md naxatw-flex naxatw-h-9 naxatw-w-9 !naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-rounded-[50%] naxatw-text-matt-200 naxatw-transition naxatw-duration-200 hover:naxatw-bg-gray-100"
        iconClick={onClick || {}}
      />
    </div>
  );
};

export default CustomAddButton;
