/* eslint-disable camelcase */
interface ISectorsList {
  marker_id: number;
  marker_name: string;
}
interface ISectorsBoxDataProps {
  sectorGroupName: string;
  sectorGroupAbbreviation?: string;
  marker: ISectorsList[];
  className?: string;
}
const MarkerCardBox = ({
  sectorGroupName,
  sectorGroupAbbreviation,
  marker,
  className,
}: ISectorsBoxDataProps) => {
  return (
    <>
      <div className="naxatw-w-full naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-5 naxatw-shadow-sm">
        <div className="naxatw-flex naxatw-gap-5 ">
          <div
            className={`naxatw-flex naxatw-flex-col naxatw-items-start naxatw-gap-2 ${className} ${!sectorGroupAbbreviation ? 'naxatw-justify-center' : ''}`}
          >
            <h5 className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-text-[#06367D]">
              {sectorGroupAbbreviation}
            </h5>
            <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-matt-200">
              {sectorGroupName}
            </p>
          </div>
          <div className="naxatw-w-[1px] naxatw-bg-[#CFE0F1]" />
          <ul className="naxatw-flex naxatw-list-inside naxatw-list-disc naxatw-flex-col naxatw-items-start naxatw-justify-center naxatw-gap-1">
            {marker?.map((sectorData: ISectorsList) => (
              <li
                className="naxatw-text-[0.875rem] naxatw-text-[#0B2E62]"
                key={sectorData.marker_id}
              >
                {sectorData.marker_name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MarkerCardBox;
