import ExploreByIndicators from '@Components/ExploreByIndicators';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const ExploreByIndicatorsPage = () => {
  return (
    <div className="naxatw-min-h-[calc(100vh-99px)] naxatw-w-full naxatw-overflow-x-hidden naxatw-bg-[#F4F7FE]">
      <ExploreByIndicators />
    </div>
  );
};

export default hasErrorBoundary(ExploreByIndicatorsPage);
