import { FlexColumn } from '@Components/common/Layouts';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import { convertToCurrencySystem } from '@Utils/index';
import { ChevronDown } from 'lucide-react';

export default function PopupAccordion({
  data,
  title,
  listNumber,
}: {
  data: Record<string, any>[];
  title: string;
  listNumber: number;
}) {
  return (
    <AccordionItem
      value={title}
      className="naxatw-border-b-[0.0625px] naxatw-border-[#F1F1F1]"
    >
      <AccordionTrigger
        defaultChecked
        className="naxatw-h-3 naxatw-w-full naxatw-items-center naxatw-justify-between hover:!naxatw-no-underline"
      >
        <p className=" naxatw-line-clamp-1 naxatw-w-full naxatw-text-[0.875rem] naxatw-font-[500] naxatw-tracking-[-0.0175rem] naxatw-text-[#06367D]">
          {`${listNumber}. ${title}`}
        </p>
        <ChevronDown className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200" />
      </AccordionTrigger>
      <AccordionContent className="naxatw-mb-0">
        {data?.length > 0 ? (
          <FlexColumn>
            <p className="naxatw-text-xs naxatw-font-semibold naxatw-uppercase naxatw-tracking-[0.0375rem] naxatw-text-[#833177]">
              Projects
            </p>
            <div className=" naxatw-flex naxatw-w-full naxatw-flex-wrap naxatw-items-center naxatw-justify-start naxatw-gap-1">
              {data?.map(
                (accordionData: Record<string, any>, index: number) => (
                  <FlexColumn
                    key={accordionData?.name}
                    className="naxatw-w-full"
                  >
                    <FlexColumn gap={2}>
                      <p className="naxatw-mt-1 naxatw-line-clamp-1 naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-[#475467]">
                        {`${listNumber}.${index + 1} ${accordionData?.project_name}`}
                      </p>
                      <FlexColumn>
                        <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#06367D]">
                          Implementing Partner
                        </p>
                        <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#475467]">
                          {accordionData?.first_tier_partner}
                        </p>
                      </FlexColumn>
                      <FlexColumn>
                        <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#06367D]">
                          Sectors
                        </p>
                        <ul className="sectors-lists naxatw-list-disc naxatw-pl-4">
                          {accordionData?.sector?.map((sector: string) => {
                            return (
                              <li key={sector}>
                                <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#475467]">
                                  {sector}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </FlexColumn>
                      <div className="budget-info">
                        <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#06367D]">
                          Total Project Budget
                        </p>
                        <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#475467]">
                          &#163;{' '}
                          {convertToCurrencySystem(accordionData?.budget)}
                        </p>
                      </div>
                    </FlexColumn>
                    <div
                      className={`naxatw-mb-1 naxatw-mt-3 naxatw-h-[0.0625rem] naxatw-w-full naxatw-bg-[#F1F1F1] ${index === data.length - 1 ? 'naxatw-hidden' : ''} `}
                    />
                  </FlexColumn>
                ),
              )}
            </div>
          </FlexColumn>
        ) : (
          <p className="naxatw-text-xs naxatw-font-semibold naxatw-uppercase naxatw-tracking-[0.0375rem] naxatw-text-[#475467]">
            Components Not Listed
          </p>
        )}
      </AccordionContent>
    </AccordionItem>
  );
}
