/* eslint-disable no-nested-ternary */
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { motion } from 'framer-motion';

import { useSpring, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setCurrentVideo } from '@Store/actions/tutorials';
// import { useRef } from 'react';
import { getVideoTutorial } from '@Services/tutorials';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useNavigate } from 'react-router-dom';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { useQuery } from '@tanstack/react-query';
import VideoPlayer from './VideoPlayer';
import { RowVideoCards, ColumnVideoCards } from './VideoPlayer/VideoCards';

const VideoTutorial = () => {
  const dispatch = useTypedDispatch();
  const [isVideoBoxVisible, setIsVideoBoxVisible] = useState(false);
  const navigate = useNavigate();

  const { currentVideo } = useTypedSelector(state => state.tutorialsSlice);
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  const videoCardVariants = {
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1, // Slower animation
        staggerChildren: 0.2, // Delay for each child to create row-to-column effect
        delayChildren: 0.1, // Adds a slight delay before the first child animates
      },
    },
    hidden: {
      opacity: 0,
      x: '-100%',
      transition: { duration: 1 },
    },
  };

  const childVariants = {
    show: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: { duration: 0.25 },
    },
    hidden: {
      opacity: 0,
      x: '-50%',
      y: '50%',
      transition: { duration: 0.25 },
    },
  };
  const videoColumnCardVariants = {
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1, // Slower animation
        staggerChildren: 0.2, // Delay for each child to create row-to-column effect
        delayChildren: 0.1, // Adds a slight delay before the first child animates
      },
    },
    hidden: {
      opacity: 0,
      y: '-100%',
      transition: { duration: 1 },
    },
  };

  const columnChildVariants = {
    show: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: { duration: 0.25 },
    },
    hidden: {
      opacity: 0,
      x: '50%',
      y: '-50%',
      transition: { duration: 0.25 },
    },
  };

  const videoBoxVariants = {
    show: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.75, ease: 'easeOut' }, // Slowed down to 1.5 seconds
    },
    hidden: {
      opacity: 0,
      y: '100%',
      transition: { duration: 0.75, ease: 'easeIn' }, // Matching duration for hiding
    },
  };

  useEffect(() => {
    if (currentVideo.video) {
      setIsVideoBoxVisible(true);
    }
  }, [currentVideo]);

  const { data: videoTutorialData, isLoading: videoTutorialDataIsLoading } =
    useQuery({
      queryKey: ['video-tutorial-data'],
      queryFn: () => getVideoTutorial(),
      select: res => res.data,
    });
  return (
    <>
      {videoTutorialData?.length === 0 ? (
        <NoChartDataComponent />
      ) : (
        <animated.div style={{ ...springs }}>
          {isVideoBoxVisible ? (
            <div className="naxatw-m-auto naxatw-w-full naxatw-py-7">
              <div className="naxatw-mx-auto naxatw-flex naxatw-w-[95%] naxatw-flex-col naxatw-gap-4">
                <FlexRow className="naxatw-items-center naxatw-gap-2">
                  <ToolTip
                    name="arrow_back"
                    message="Go Back"
                    className="naxatw-flex naxatw-items-center naxatw-text-[1.5rem] naxatw-text-primary-700"
                    iconClick={() => {
                      dispatch(
                        setCurrentVideo({
                          description: '',
                          id: '',
                          title: '',
                          video: '',
                        }),
                      );
                      setIsVideoBoxVisible(false);
                      navigate('/dvs-tutorial/video-tutorial');
                    }}
                  />
                  <p className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-text-primary-700">
                    Video Tutorial
                  </p>
                </FlexRow>
                <div
                  className={`naxatw-grid naxatw-gap-4 ${videoTutorialData.length > 1 ? 'lg:naxatw-grid-cols-[1fr_30rem]' : 'naxatw-w-full'}`}
                >
                  <motion.div
                    variants={videoBoxVariants}
                    initial="hidden"
                    animate={isVideoBoxVisible ? 'show' : 'hidden'}
                  >
                    <VideoPlayer
                      src={currentVideo.video}
                      title={currentVideo.title}
                      description={currentVideo.description}
                    />
                  </motion.div>
                  <motion.div
                    variants={videoCardVariants}
                    animate={isVideoBoxVisible ? 'show' : 'hidden'}
                    initial="hidden"
                  >
                    <FlexColumn className="naxatw-gap-2">
                      {videoTutorialData.map((video: any) => {
                        if (video.id === +currentVideo.id) return null;
                        return (
                          <motion.div variants={childVariants} key={video.id}>
                            <RowVideoCards
                              description={video.description}
                              thumbnail={video.thumbnail}
                              title={video.title}
                              video={video.video}
                              onClick={() => dispatch(setCurrentVideo(video))}
                            />
                          </motion.div>
                        );
                      })}
                    </FlexColumn>
                  </motion.div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="naxatw-m-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-7">
                <div className="naxatw-mx-auto naxatw-w-11/12">
                  <FlexColumn className="naxatw-items-start naxatw-gap-4">
                    <FlexRow className="naxatw-items-center naxatw-gap-2">
                      <ToolTip
                        name="arrow_back"
                        message="Go Back"
                        className="naxatw-flex naxatw-items-center naxatw-text-[1.5rem] naxatw-text-primary-700"
                        iconClick={() => navigate('/dvs-tutorial')}
                      />
                      <p className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-text-primary-700">
                        Video Tutorial
                      </p>
                    </FlexRow>
                    {videoTutorialDataIsLoading ? (
                      <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-5">
                        <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                        <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                        <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                        <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                        <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                        <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                        <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                      </div>
                    ) : (
                      <motion.div
                        variants={videoColumnCardVariants}
                        initial="hidden"
                        animate={!isVideoBoxVisible ? 'show' : 'hidden'}
                        className="naxatw-w-full"
                      >
                        <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-1  naxatw-gap-5 sm:naxatw-grid-cols-2 lg:naxatw-grid-cols-3 xl:naxatw-grid-cols-4">
                          {videoTutorialData?.map((video: any) => (
                            <motion.div
                              variants={columnChildVariants}
                              key={video.id}
                            >
                              <ColumnVideoCards
                                key={video.id}
                                {...video}
                                onClick={() => dispatch(setCurrentVideo(video))}
                              />
                            </motion.div>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </FlexColumn>
                </div>
              </div>
            </>
          )}
        </animated.div>
      )}
    </>
  );
};

export default VideoTutorial;
