/* eslint-disable react/prop-types */
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@Utils/index';

const buttonVariants = cva(
  'naxatw-inline-flex naxatw-items-center naxatw-justify-center naxatw-rounded-md naxatw-text-sm naxatw-font-bold naxatw-transition-colors focus-visible:naxatw-outline-none focus-visible:naxatw-ring-2 focus-visible:ring-ring focus-visible:naxatw-ring-offset-2 disabled:naxatw-pointer-events-none',
  {
    variants: {
      variant: {
        primary:
          'naxatw-bg-secondary-500 naxatw-text-white hover:naxatw-bg-primary-600 disabled:naxatw-bg-grey-100 disabled:naxatw-text-grey-500 disabled:naxatw-border naxatw-border-grey-300',
        secondary:
          'naxatw-bg-white naxatw-text-secondary-500 naxatw-border naxatw-border-secondary-500 hover:naxatw-bg-secondary-100 disabled:naxatw-bg-grey-100 disabled:naxatw-text-grey-500 disabled:naxatw-border naxatw-border-grey-300',

        link: 'naxatw-text-secondary-500 hover:naxatw-text-primary-600 disabled:naxatw-bg-grey-100 disabled:naxatw-text-grey-500 disabled:naxatw-border naxatw-border-grey-300',

        dropDown:
          'naxatw-bg-white naxatw-text-grey-900 naxatw-border naxatw-border-grey-600 focus:naxatw-border-primary-500 naxatw-rounded-lg',
      },
      size: {
        normal:
          'naxatw-body-btn naxatw-h-[2.5rem] naxatw-py-3 naxatw-px-5 naxatw-gap-2 !naxatw-rounded-lg',
        sm: 'naxatw-body-btn naxatw-h-[2.25rem] naxatw-px-2 naxatw-py-4 naxatw-gap-1',
        // lg: 'naxatw-h-11 naxatw-px-8 naxatw-rounded-md',
        // 'lg-icon': 'naxatw-p-[0.625rem] naxatw-h-fit',
        // 'sm-icon': 'naxatw-p-[0.375rem] naxatw-h-fit',
        'drop-lg':
          'naxatw-h-[40px] naxatw-rounded-lg naxatw-py-[8px] naxatw-pl-3 naxatw-pr-2 naxatw-gap-2',
        'drop-sm':
          'naxatw-h-[36px]  naxatw-rounded-lg naxatw-py-[8px] naxatw-pl-[4px] naxatw-pr-[8px] naxatw-gap-1',
        'drop-tiny':
          'naxatw-h-[36px] naxatw-pl-[8px] !naxatw-border-none naxatw-py-[4px] naxatw-pr-[4px]',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'normal',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, isLoading = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {props.children}
        {isLoading ? <div className="spinner naxatw-ml-2" /> : ''}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
