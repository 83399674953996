/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { cn } from '@Utils/index';
import { ChevronDown } from 'lucide-react';
import { IDropdownAccordionProps } from '@Constants/interface';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import { v4 as uuid } from 'uuid';
import Icon from '@Components/common/Icon';
import { Button } from '@Components/RadixComponents/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@Components/RadixComponents/Popover';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import hasErrorBoundary from '../hasErrorBoundary';
import Searchbar from '../SearchBar';
import { FlexRow } from '../Layouts';
import Checkbox from '../FormUI/CheckBox';

function DropdownAccordion({
  options = [],
  checkedItems,
  onCheckboxChange,
  placeholder,
  id,
  className,
  disabled,
  isLoading = false,
  dropDownSize = 'drop-lg',
  placeholderClassName,
  hasLeftIcon,
  leftIconName,
  enableSearchBar = true,
  subGroupName,
  enableSingleParent = false,
}: IDropdownAccordionProps) {
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [filteredOptionsData, setFilteredOptionsData] = React.useState<any>([]);
  const [parentLength, setParentLength] = useState<number>(0);
  const [childrenLength, setChildrenLength] = useState<number>(0);

  // for search
  const [searchTextData, handleChangeData] = useDebouncedInput({
    ms: 400,
    init: searchText,
    onChange: debouncedEvent => setSearchText(debouncedEvent.target.value),
  });

  useEffect(() => {
    const filteredOptions = options?.filter(option =>
      option?.label?.toLowerCase()?.includes(searchText),
    );
    setFilteredOptionsData(filteredOptions);
  }, [searchText]);

  useEffect(() => {
    setFilteredOptionsData(options);
  }, [options]);

  // handle parent change
  const handleParentCheckBoxChange = (parentId: number) => {
    const parentKey = `parent_${parentId}`;
    const parent = options?.find(p => p.id === parentId);
    const isChecked = !checkedItems[parentKey];
    const newCheckedItems: any = { ...checkedItems, [parentKey]: isChecked };
    if (enableSingleParent) {
      if (isChecked === false) {
        parent?.[subGroupName || '']?.forEach((child: Record<string, any>) => {
          newCheckedItems[`child_${child.id}`] = false;
        });
      }
    } else {
      parent?.[subGroupName || '']?.forEach((child: Record<string, any>) => {
        newCheckedItems[`child_${child.id}`] = isChecked;
      });
    }

    onCheckboxChange(newCheckedItems);
  };

  // handle children change
  const handleChildCheckBoxChange = (parentId: number, childId: number) => {
    const childKey = `child_${childId}`;
    const parentKey = `parent_${parentId}`;
    const newCheckedItems: any = {
      ...checkedItems,
      [childKey]: !checkedItems[childKey],
    };
    const parent = options?.find(p => p.id === parentId);
    const anyChildrenChecked = parent?.[subGroupName || '']?.some(
      (child: Record<string, any>) => newCheckedItems[`child_${child.id}`],
    );
    newCheckedItems[parentKey] = anyChildrenChecked;
    onCheckboxChange(newCheckedItems);
  };

  useEffect(() => {
    if (!checkedItems) return;

    const parentLengthData = Object.keys(checkedItems).filter(
      key => key.startsWith('parent_') && checkedItems[key] === true,
    ).length;

    const childLengthData = Object.keys(checkedItems).filter(
      key => key.startsWith('child_') && checkedItems[key] === true,
    ).length;

    setParentLength(parentLengthData);
    setChildrenLength(childLengthData);
  }, [checkedItems]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          id={id}
          variant="dropDown"
          size={dropDownSize}
          role="combobox"
          disabled={disabled}
          aria-expanded={open}
          className={cn(
            'naxatw-group naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-2 naxatw-bg-white hover:naxatw-border-secondary-500 disabled:!naxatw-cursor-not-allowed',
            className,
          )}
          onClick={() => setOpen(true)}
        >
          <>
            <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-2">
              {hasLeftIcon && (
                <Icon
                  name={leftIconName ?? ''}
                  className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 naxatw-duration-200 group-hover:naxatw-text-secondary-500"
                />
              )}
              {checkedItems ? (
                <p className="naxatw-body-sm naxatw-text-matt-200">
                  {parentLength} Programmes & {childrenLength} Projects
                </p>
              ) : (
                <p
                  className={cn(
                    'naxatw-body-sm  naxatw-line-clamp-1  naxatw-text-start naxatw-text-matt-200',
                    placeholderClassName,
                  )}
                >
                  {placeholder || 'Choose'}
                </p>
              )}
            </div>
            <Icon
              name="expand_more"
              className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 naxatw-duration-200 group-hover:naxatw-text-secondary-500"
            />
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="naxatw-bg-white naxatw-p-[0px]"
        style={{ width: 'var(--radix-popover-trigger-width)' }}
      >
        {enableSearchBar ? (
          <Searchbar
            value={searchTextData}
            onChange={handleChangeData}
            isSmall
            className="searchbar-dropdown !naxatw-py-3 focus:!naxatw-border-grey-600"
            wrapperStyle="!naxatw-h-auto"
          />
        ) : null}
        <div className="scrollbar search-scrollbar naxatw-block naxatw-max-h-[12rem] naxatw-overflow-y-auto">
          {isLoading && <p>Loading ...</p>}

          <Accordion type="multiple">
            {filteredOptionsData?.map((parentItem: Record<string, any>) => {
              return (
                <AccordionItem
                  value={`item-${parentItem.id}`}
                  key={uuid()}
                  className="naxatw-px-3"
                >
                  <AccordionTrigger className="!naxatw-py-0 hover:!naxatw-no-underline">
                    <FlexRow className="naxatw-w-full naxatw-items-center naxatw-gap-2 ">
                      <Checkbox
                        label={parentItem.label}
                        id={`parent_${parentItem.id}`}
                        checked={!!checkedItems[`parent_${parentItem.id}`]}
                        onChange={() =>
                          handleParentCheckBoxChange(parentItem.id)
                        }
                        mainWrapperStyles="naxatw-pt-2 naxatw-pb-1"
                        labelClassName="naxatw-text-sm naxatw-font-medium naxatw-text-matt-100"
                      />
                    </FlexRow>
                    <ChevronDown
                      className="naxatw-h-3 naxatw-w-3 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                      style={{
                        strokeWidth: '3px',
                      }}
                    />
                  </AccordionTrigger>
                  <AccordionContent className="naxatw-mb-1 naxatw-px-8">
                    {parentItem?.subCategories?.map(
                      (childCateogry: Record<string, any>) => (
                        <div key={`${childCateogry.id}-${childCateogry.name}`}>
                          <Checkbox
                            id={`child_${childCateogry.id}}`}
                            label={childCateogry.label}
                            mainWrapperStyles="naxatw-py-1"
                            labelClassName="naxatw-text-wrap naxatw-text-sm naxatw-text-matt-100"
                            checked={
                              !!checkedItems[`child_${childCateogry.id}`]
                            }
                            onChange={() =>
                              handleChildCheckBoxChange(
                                parentItem?.id,
                                childCateogry.id,
                              )
                            }
                          />
                        </div>
                      ),
                    )}
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default hasErrorBoundary(DropdownAccordion);
