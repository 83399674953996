import { useEffect, useState } from 'react';
import AdminHeader from '@Components/common/AdminHeader';
import Portal from '@Components/common/Layouts/Portal';
import { useLocation, useNavigate } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useTypedSelector } from '@Store/hooks';
import MarkerValuesTable from './Table';
import MarkerValuesForm from './Form';

const MarkerValues = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [toggleForm, setToggleForm] = useState<boolean>(false);

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  return (
    <div className="naxatw-relative naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6">
      <AdminHeader
        title="Marker Values"
        registerButtonText="Add"
        onAddNewClick={() => {
          navigate('/data-bank/markers/markers-values/add');
        }}
        needAddNewButton={
          userGroupPermission?.includes('Can add marker value') ?? false
        }
      />

      {userGroupPermission?.includes('Can view marker value') ? (
        <MarkerValuesTable />
      ) : null}

      {toggleForm && (
        <Portal>
          <MarkerValuesForm
            onClose={() => {
              navigate('/data-bank/markers/markers-values');
            }}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(MarkerValues);
