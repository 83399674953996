import { useNavigate } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import ProvincialCommonCard from '../ProvincialCommonCard';

const ProvincialMinistry = ({
  selectedLocationId,
  ministryData,
  setToggleOn,
  location,
}: any) => {
  const navigate = useNavigate();

  return (
    <ProvincialCommonCard
      title={
        location === 'Federal' ? 'Federal Ministries' : 'Provincial Ministries'
      }
      redirect={() => {
        setToggleOn();
        navigate(
          `/data-bank/provincial-profile/ministry/edit/${selectedLocationId}?location=${location}`,
        );
      }}
    >
      {isEmpty(ministryData) ? (
        <NoChartDataComponent
          message="Federal Ministries are not yet available."
          messageStyles="naxatw-text-matt-200 naxatw-text-base"
        />
      ) : (
        <div className="scrollbar naxatw-overflow-y-scroll">
          {ministryData?.map((item: Record<string, any>) => (
            <div
              key={item.id}
              className="naxatw-border-b naxatw-border-b-[#E3E4E3] naxatw-py-3 first:naxatw-pt-0 last:naxatw-border-0 last:naxatw-pb-0"
            >
              <p className="naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-200">
                {item?.name || '-'}
              </p>
            </div>
          ))}
        </div>
      )}
    </ProvincialCommonCard>
  );
};

export default hasErrorBoundary(ProvincialMinistry);
