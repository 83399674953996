export default function Landingpage() {
  return (
    <>
      <div className="container">
        <img src="/logo.png" alt="logo" />
        <ul>
          <li>Data</li>

          <li>About</li>

          <li>img</li>
          <li>
            <button type="button">Login</button>
          </li>
        </ul>
      </div>
    </>
  );
}
