import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useTypedSelector } from '@Store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import ProgramsTable from '@Components/AdminPanel/Programs/ProgramsTable';
import AdminHeader from '@Components/common/AdminHeader';
import Portal from '@Components/common/Layouts/Portal';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ProgramForm from './ProgramForm';

const Programs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <div className="programme-form-container naxatw-h-full  naxatw-space-y-6">
      <AdminHeader
        title="Programme Registration"
        onAddNewClick={() => {
          navigate('/data-bank/programmes/add');
          setToggleForm(true);
        }}
        needAddNewButton={
          userGroupPermission?.includes('Can add program') ?? false
        }
        hasBulkUpDownload={false}
      />

      {userGroupPermission?.includes('Can view program') ? (
        <animated.div style={{ ...springs }} className="naxatw-h-full">
          <ProgramsTable />
        </animated.div>
      ) : null}

      {toggleForm && (
        <Portal>
          <ProgramForm
            onClose={() => {
              navigate('/data-bank/programmes');
              setToggleForm(false);
            }}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(Programs);
