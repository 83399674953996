import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import AdminHeader from '@Components/common/AdminHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import UserTable from './UserTable';
import UserForm from './UserForm';
import UserChangePasswordForm from './UserChangePasswordForm';

const UserManagement = () => {
  const navigate = useNavigate();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const [toggleChangePasswordForm, setChangePasswordForm] =
    useState<boolean>(false);
  const { pathname } = useLocation();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else if (pathname.includes('change-password')) {
      setChangePasswordForm(true);
    } else {
      setToggleForm(false);
      setChangePasswordForm(false);
    }
  }, [pathname]);

  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
    >
      <AdminHeader
        title="User Management"
        registerButtonText="Add User"
        onAddNewClick={() => {
          navigate('/data-bank/user-security/user-management/add');
        }}
      />
      <UserTable />

      {toggleForm && (
        <Portal>
          <UserForm
            onClose={() => {
              navigate('/data-bank/user-security/user-management');
            }}
          />
        </Portal>
      )}

      {toggleChangePasswordForm && (
        <Portal>
          <UserChangePasswordForm
            onClose={() => {
              navigate('/data-bank/user-security/user-management');
            }}
          />
        </Portal>
      )}
    </animated.div>
  );
};

export default hasErrorBoundary(UserManagement);
