/* eslint-disable import/prefer-default-export */
import { adminPdfSlice } from '@Store/slices/adminPdfSlice';

export const {
  setCheckBox,
  resetCheckBox,
  setCheckBoxProvincialProfile,
  resetCheckBoxProvincialProfile,
  setCheckBoxProgrammeDetails,
  resetCheckBoxProgrammeDetails,
} = adminPdfSlice.actions;
