import { useQuery } from '@tanstack/react-query';

import DataTable from '@Components/common/DataTable';
import {
  indicatorsColumnForMunicipality,
  indicatorsColumnForProvince,
} from '@Constants/exploreByIndicators';
import { contextualIndicatorByFederal } from '@Services/contextualIndicators';
import { useTypedSelector } from '@Store/hooks';

const IndicatorsDataTable = ({
  selectedProvince,
}: {
  selectedProvince: number;
}) => {
  const activeViewBy = useTypedSelector(
    state => state.exploreByIndicators.map.activeViewBy,
  );
  const selectedIndicator = useTypedSelector(
    state => state.exploreByIndicators.selectedIndicator,
  );

  const { data: indicatorsData, isFetching } = useQuery({
    queryKey: [
      'contextual-indicator-by-federal-for-table',
      activeViewBy,
      selectedIndicator,
      selectedProvince,
    ],
    queryFn: async () =>
      contextualIndicatorByFederal({
        indicator: selectedIndicator,
        by: activeViewBy,
        province: activeViewBy === 'municipality' ? selectedProvince : '',
      }),
    select: res => res.data,
  });

  const getFilteredColumn = (columnList: any[], selectedInd: string) => {
    if (!selectedInd) return columnList;
    const commonHeaders = [
      '',
      'province__name',
      'municipality__name',
      selectedInd,
    ];
    return columnList?.filter(column =>
      commonHeaders?.includes(column.accessorKey),
    );
  };

  return (
    <>
      <div className="naxatw-py-2">
        {isFetching ? (
          <div className="naxatw-flex naxatw-flex-col naxatw-gap-2 naxatw-px-4">
            <div className="naxatw-h-10 naxatw-animate-pulse naxatw-bg-slate-200" />
            {Array.from({ length: 10 })?.map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`loader-${index}`}
                className="naxatw-h-7 naxatw-animate-pulse naxatw-bg-slate-200"
              />
            ))}
          </div>
        ) : (
          <DataTable
            queryKey="programme details"
            queryFn={() => {}}
            columns={
              activeViewBy === 'province'
                ? getFilteredColumn(
                    indicatorsColumnForProvince,
                    selectedIndicator || '',
                  )
                : getFilteredColumn(
                    indicatorsColumnForMunicipality,
                    selectedIndicator || '',
                  )
            }
            className="naxatw-w-full !naxatw-border-[0] naxatw-px-2 naxatw-pb-3"
            demoData={indicatorsData}
            isPaginated={false}
            searchInput=""
            key={indicatorsData?.id}
          />
        )}
      </div>
    </>
  );
};

export default IndicatorsDataTable;
