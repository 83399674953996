import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import HeaderSwicthTab from '@Components/common/HeaderSwicthTab';
import { knowledgeLibrarytabOptions } from '@Constants/mainDashboard';
import CardSection from './CardSection';
import HeaderSection from './HeaderSection';
import KnowledgeLibFilters from './knowledgeLibFilters';

const KnowledgeRepository = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname || '');

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-mx-auto naxatw-h-full naxatw-max-w-[90rem] naxatw-bg-primary-100  naxatw-pb-[2.375rem] naxatw-pt-5"
    >
      <div className="naxatw-mx-auto naxatw-w-11/12">
        <HeaderSection />
        <HeaderSwicthTab
          headerOptions={knowledgeLibrarytabOptions || []}
          activeTab={activeTab}
          onChange={(path: string) => setActiveTab(path)}
        />
        <div className="naxatw-mt-4 naxatw-grid naxatw-w-full  naxatw-gap-5 lg:!naxatw-grid-cols-[3.3fr_1fr]">
          <CardSection activeTab={activeTab} />
          <KnowledgeLibFilters />
        </div>
      </div>
    </animated.div>
  );
};

export default hasErrorBoundary(KnowledgeRepository);
