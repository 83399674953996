export const ChartFills = [
  '#0664D2',
  '#5BB6FF',
  '#AFDED6',
  '#0077E4',
  '#00947C',
];

export const BarChartFills = ['#417EC9', '#417EC9', '#417EC9'];

export const StackedChartFills = ['#0664D2', '#FF9525', '#42b4a2'];

export const PieChartFills = ['#42B4A2', '#5BB6FF', '#AFDED6', '#0077E4'];

export const donutChartFills = [
  '#41588F',
  '#8091B4',
  '#BADFFF',
  '#5BB6FF',
  '#FFD85C',
  '#FF9525',
  '#AFDED6',
  '#0077E4',
];

export const HorizontalBarChartFills = [
  '#FF671F',
  '#418FDE',
  '#5CB8B2',
  '#FF8042',
];

export const LineChartFills = [
  '#F28E2C',
  '#76B7B2',
  '#E15759',
  '#4E79A7',
  '#59A14F',
];
