/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FlexRow } from '@Components/common/Layouts';

// import { useState } from 'react';
// import SwitchTab from '@Components/common/FormUI/SwitchTab';
// import Table from './Table';
import Map from './Map';

interface MapSectionProps {
  lat: number;
  lon: number;
  zoom?: number;
}
export default function MapSection({ lat, lon, zoom }: MapSectionProps) {
  // const [activeSwicthIcon, setActiveSwicthIcon] = useState('map');

  // const viewAsOptions = [{ label: 'map' }, { label: 'table' }];

  return (
    <div className="map-section naxatw-relative naxatw-h-full naxatw-w-full">
      <div className="naxatw-h-full naxatw-w-full naxatw-overflow-hidden naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
        <FlexRow className="naxatw-absolute naxatw-right-4 naxatw-top-[1.4rem] naxatw-z-[10] naxatw-overflow-hidden naxatw-rounded-lg">
          {/* <SwitchTab
            title="View As"
            options={viewAsOptions}
            activeLabel="map"
            defaultBg={false}
            defaultBehaviour={false}
            className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
            titleClassName="naxatw-text-sm"
            onChange={label => {
              setActiveSwicthIcon(label);
            }}
          /> */}
        </FlexRow>
        {/* {activeSwicthIcon === 'map' ? ( */}
        <Map lat={lat} lon={lon} zoom={zoom} />
        {/* ) : (
          <Table />
        )} */}
      </div>
    </div>
  );
}
