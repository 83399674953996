/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Input } from '@Components/common/FormUI';
import { useFormContext } from 'react-hook-form';

import ToolTip from '@Components/RadixComponents/ToolTip';
import InputLabel from '@Components/common/InputLabel';
import Icon from '@Components/common/Icon';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { removeObjectAtIndex } from '@Utils/index';
import { AxiosResponse } from 'axios';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

type inputIteratorType = {
  title: string;
  fieldArrayName: string;
  fieldKey: string;
  defaultField: Record<string, any>;
  deleteMutationFn: (id: number) => Promise<AxiosResponse<any, any>>;
  hasPopupOverlay?: boolean;
  errors: any;
};

const InputIterator = ({
  title,
  fieldArrayName,
  fieldKey,
  defaultField,
  deleteMutationFn,
  hasPopupOverlay = false,
  errors,
}: inputIteratorType) => {
  const { register, getValues, setValue, watch } = useFormContext();
  const fields: Record<string, any>[] = watch(fieldArrayName);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const {
    mutateAsync: deleteField,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: (id: number) => deleteMutationFn(id),
    onSuccess: () => {
      setOpenDeleteConfirmation(false);
      toast.success('Field Deleted Succesfully');
    },
    onError: () => {
      toast.error('Error Occured! Try again');
    },
  });

  const handleAddField = () => {
    setValue(fieldArrayName, [...fields, defaultField]);
  };

  const handleDelete = async () => {
    if (deleteIndex === null) return;
    try {
      const fieldValue: Record<string, any>[] = getValues()?.[fieldArrayName];
      const id: number = fieldValue?.[deleteIndex]?.id;

      if (id) {
        await deleteField(id);
      }
      setOpenDeleteConfirmation(false);
      const tempFields = removeObjectAtIndex(fields, deleteIndex);
      setValue(fieldArrayName, tempFields);
    } catch {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      {openDeleteConfirmation && (
        <Portal>
          {hasPopupOverlay && (
            <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-h-[100vh] naxatw-w-[100vw] naxatw-bg-black naxatw-opacity-30" />
          )}
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={handleDelete}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
      <FlexColumn className="=naxatw-w-full naxatw-gap-2">
        <FlexColumn className="naxatw-items-center naxatw-gap-2">
          {fields?.map((field, index: number) => (
            <>
              <div key={index} className="naxatw-w-full">
                <InputLabel label={title} tooltipMessage={title} />
                <FlexRow className="naxatw-mt-[0.469rem] naxatw-w-full naxatw-items-center naxatw-gap-10">
                  <Input
                    type="text"
                    {...register(`${fieldArrayName}.${index}.${fieldKey}`, {})}
                  />
                  {fields?.length > 1 && (
                    <ToolTip
                      name="delete"
                      message="Delete"
                      className="naxatw-text-xl naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-600"
                      iconClick={() => {
                        setOpenDeleteConfirmation(true);
                        setDeleteIndex(index);
                      }}
                    />
                  )}
                </FlexRow>
                {errors?.[index] && (
                  <ErrorMessage
                    message={errors?.[index]?.[fieldKey]?.message}
                  />
                )}
              </div>
            </>
          ))}
        </FlexColumn>
        <button
          className="naxatw-group naxatw-flex naxatw-w-fit naxatw-items-center naxatw-gap-1 naxatw-rounded-lg naxatw-border-[1px] naxatw-border-dashed naxatw-border-[#D0D5DD] naxatw-px-3 naxatw-py-2 naxatw-font-medium naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-border-secondary-500 hover:naxatw-bg-primary-100"
          type="button"
          tabIndex={0}
          onClick={e => {
            e.preventDefault();
            handleAddField();
          }}
        >
          <p className="naxatw-text-[0.875rem] group-hover:naxatw-text-matt-100">
            <span className="naxatw-text-base">+</span> Add More
          </p>
        </button>
      </FlexColumn>
    </>
  );
};

export default hasErrorBoundary(InputIterator);
