import { Input } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import { formatNumber } from '@Utils/index';
import { Controller, useFormContext } from 'react-hook-form';

interface ICustomBudgetInputProps {
  activeSelectedButton?: string;
  registerAmount?: string;
  registerPercentage?: string;
  labelName?: string;
}

const CustomBudgetInput = ({
  activeSelectedButton,
  registerAmount,
  registerPercentage,
  labelName,
}: ICustomBudgetInputProps) => {
  const { register, control, setValue } = useFormContext();

  // Function to handle changes and format the values correctly
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value.replace(/,/g, '')) || '';
    setValue(name, numericValue, { shouldDirty: true });
  };

  return (
    <div className="">
      <div className="naxatw-mb-1">
        <InputLabel label={labelName} />
      </div>
      <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
        <div className="naxatw-relative">
          <Controller
            name={`${registerAmount}`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                name={`${registerAmount}`}
                className="naxatw-pl-[30px] disabled:naxatw-cursor-not-allowed"
                value={formatNumber(field.value)}
                // onBlur={e => {
                //   handleInputChange(e);
                // }}
                // onChange={field.onChange}
                onChange={e => {
                  // field.onChange();
                  handleInputChange(e);
                }}
                disabled={activeSelectedButton === 'Percentage'}
              />
            )}
          />

          <span className="pound-symbol naxatw-t naxatw-label naxatw-pointer-events-none naxatw-absolute naxatw-left-4 naxatw-top-[50%] -naxatw-translate-y-2/4">
            £
          </span>
        </div>
        <div className="naxatw-relative naxatw-max-w-[5rem]">
          <Input
            type="number"
            className="naxatw-pr-[32px] disabled:naxatw-cursor-not-allowed"
            {...register(`${registerPercentage}`, {
              valueAsNumber: true,
            })}
            disabled={activeSelectedButton === 'Amount'}
          />
          <span className="percentage-symbol naxatw-label  naxatw-pointer-events-none naxatw-absolute naxatw-right-4 naxatw-top-[50%] -naxatw-translate-y-2/4">
            %
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomBudgetInput;
