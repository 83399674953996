/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import {
  KnowledgeRepoFormFields,
  knowledgeRepoDefaultValues,
  knowledgeRepoDocumentResponseType,
  knowledgeRepoFormType,
  knowledgeRepoFormTypeInformation,
  KnowledgeRepoContextualFormFields,
  includeProvinces,
} from '@Constants/FormConstants/knowledgeRepoConstants';
import groupFormElements, {
  compareObjects,
  getFileNameFromURL,
  prepareDataForDropdown,
} from '@Utils/index';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@Components/RadixComponents/Button';
import { useTypedSelector } from '@Store/hooks';
import {
  deleteKnowledgeRepositoryDoc,
  getChoiceDropdownList,
  getKnowledgeRepositoryById,
  getKnowledgeRepositoryDocById,
  getProgrammeProjectsDropDown,
  getThematicFieldOptions,
  patchKnowledgeRepository,
  postKnowledgeRepository,
  postKnowledgeRepositoryDoc,
  postKnowledgeRepositoryProgrammes,
  postKnowledgeRepositoryProjects,
  postKnowledgeRepositoryThematicField,
  deleteKnowledgeRepositoryProgramme,
  deleteKnowledgeRepositoryProject,
  deleteKnowledgeRepositoryThematicField,
} from '@Services/knowledgeRepository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setDropdownOptionsList } from '@Store/actions/knowledgeRepository';
import { useLocation, useParams } from 'react-router-dom';
import {
  KnowledgeRepositoryValidationSchema,
  KnowledgeRepositoryConceptualValidationSchema,
} from '@Validations/KnowledgeRepositoryForm';
import isEmpty from '@Utils/isEmpty';
import prepareFormData from '@Utils/prepareFormData';
import { toast } from 'react-toastify';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { animated, useSpring } from '@react-spring/web';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { getProvinceList } from '@Services/common';

type propType = {
  onClose: () => void;
};

const KnowledgeRepositoryForm = ({ onClose }: propType) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const [actualProgrammeProject, setActualProgrammeProject] = useState<
    Record<string, any>
  >({});
  const [beforeProgramme, setBeforeProgramme] = useState<Record<string, any>>(
    {},
  );
  const [beforeThematicFields, setBeforeThematicFields] = useState<
    Record<string, any>
  >({});
  const [beforeThematicFieldsIds, setBeforeThematicIds] = useState<number[]>(
    [],
  );
  const [activeFormType, setActiveFormType] = useState(
    'Programmatic Information',
  );

  const { id: knowledgeRepoId } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const userProfile = useTypedSelector(state => state?.common?.userProfile);

  const isContextualInformation = activeFormType === 'Contextual Information';

  const knowledgeRepoFormFields = groupFormElements(
    userProfile.group_name === 'PEF User'
      ? KnowledgeRepoContextualFormFields
      : isContextualInformation
        ? KnowledgeRepoContextualFormFields
        : KnowledgeRepoFormFields,
  );

  const knowledgeRepoDropdownOptions = useTypedSelector(
    state => state.knowledgeRepository.dropdownOptions,
  );

  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting, isDirty },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: knowledgeRepoDefaultValues,
    resolver: zodResolver(
      isContextualInformation
        ? KnowledgeRepositoryConceptualValidationSchema
        : KnowledgeRepositoryValidationSchema,
    ),
  });

  const documentToDelete = watch('documentToDelete');

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
  };

  const handleDropdownSuccess = (
    data: Record<string, any>[],
    key: string,
    labelKey: string,
  ) => {
    dispatch(
      setDropdownOptionsList({
        key,
        value:
          key === 'associate_program_project'
            ? [
                ...data,
                {
                  id: 1010101010,
                  name: 'Other',
                  label: 'Other',
                  subCategories: [],
                },
              ]
            : prepareDataForDropdown(data, labelKey),
      }),
    );
  };

  // dropdowns api calls
  const { isLoading: isThematicFieldLoading } = useQuery({
    queryKey: ['knowledgerepo-thematicfield-options'],
    queryFn: () => getThematicFieldOptions(),
    onError: () => toast.error('Failed to fetch thematic field options'),
    select: data => data?.data,
    onSuccess: data => {
      handleDropdownSuccess(data, 'thematic_field', 'name');
    },
  });

  const { isLoading: isFileTypeLoading } = useQuery({
    queryKey: ['knowledgerepo-filetype-options'],
    queryFn: () => getChoiceDropdownList('file_type'),
    onError: () => toast.error('Failed to fetch file type options'),
    select: data => {
      const response = data?.data;
      const updatedData = response?.map((datax: Record<string, any>) => {
        return {
          id: datax.name,
          name: datax.name,
        };
      });
      return updatedData;
    },
    onSuccess: data => {
      handleDropdownSuccess(data, 'file_type', 'name');
    },
  });

  const { isLoading: isReleaseTypeLoading } = useQuery({
    queryKey: ['knowledgerepo-releasetype-options'],
    queryFn: () => getChoiceDropdownList('release_type'),
    onError: () => toast.error('Failed to fetch file type options'),
    select: data => {
      const response = data?.data;
      const updatedData = response?.map((datax: Record<string, any>) => {
        return {
          id: datax.name,
          name: datax.name,
          value: datax.name,
          label: datax.name,
        };
      });
      return updatedData;
    },
    onSuccess: data => {
      handleDropdownSuccess(data, 'release_type', 'name');
    },
  });

  const { isLoading: isProvinceLoading, data: provinceList } = useQuery({
    queryKey: ['get-province-list'],
    queryFn: () => getProvinceList(),
    select: data =>
      data?.data?.filter((province: Record<string, any>) =>
        includeProvinces?.includes(province.name),
      ),

    onSuccess: data => {
      handleDropdownSuccess(data, 'province', 'name');
    },
  });

  const { isLoading: isProjectProgrammeLoading } = useQuery({
    queryKey: ['get-project-programme-list'],
    queryFn: () => getProgrammeProjectsDropDown(),
    select: res => {
      if (!res?.data) return [];
      return res.data.map((programData: Record<string, any>) => ({
        id: programData.program__id,
        name: programData.program__name,
        label: programData.program__name,
        subCategories: programData.subcategories.map(
          (subcategory: Record<string, any>) => ({
            id: subcategory.id,
            label: subcategory.label,
          }),
        ),
      }));
    },
    onSuccess: data => {
      handleDropdownSuccess(data, 'associate_program_project', '');
    },
  });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });
    const { other_program_project } = allValues;
    return { other_program_project, ...dirtyValues };
  };

  // delete project data
  const { mutateAsync: deletProjectData } = useMutation({
    mutationFn: (id: number) => deleteKnowledgeRepositoryProject(id),
  });

  // delete programme  data
  const { mutateAsync: deleteProgrammeData } = useMutation({
    mutationFn: (id: number) => deleteKnowledgeRepositoryProgramme(id),
  });

  // delete thematic field  data
  const { mutateAsync: deleteKnowledgeRepositoryThematicFieldData } =
    useMutation({
      mutationFn: (id: number) => deleteKnowledgeRepositoryThematicField(id),
    });

  // Patch knowledge repository
  const {
    mutateAsync: patchKnowledgeRepositoryData,
    isLoading: isPatchKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchKnowledgeRepository(knowledgeRepoId || '', payloadData),
    onError: () =>
      toast.error('Failed to update knowledge repository. Try again'),
  });

  // Post knowledge repository
  const {
    mutateAsync: postKnowledgeRepositoryData,
    isLoading: isPostKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepository(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });

  const {
    mutateAsync: postKnowledgeRepositoryProjectsData,
    isLoading: isPostKnowledgeRepositoryProjectsLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryProjects(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });

  // Mutation for posting knowledge repository data for programmes
  const {
    mutateAsync: postKnowledgeRepositoryProgrammesData,
    isLoading: isPostKnowledgeRepositoryProgrammesLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryProgrammes(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });

  // Mutation for posting knowledge repository data for thematic fields
  const {
    mutateAsync: postKnowledgeRepositoryThematicFieldData,
    isLoading: isPostKnowledgeRepositoryThematicFieldLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryThematicField(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });

  // Post knowledge repository documents
  const {
    mutateAsync: postKnowledgeRepositoryDocumentData,
    isLoading: isPostKnowledgeRepositoryDocumentLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryDoc(payloadData),
    onError: () => toast.error('Failed to attach documents. Try again'),
  });

  // Delete knowledge repository documents
  const {
    mutate: deleteKnowledgeRepositoryDocument,
    isError: isDeleteDocumentError,
    error: deleteDocumentError,
    isLoading: isDeleteDocumentLoading,
  } = useMutation({
    mutationFn: async () => {
      if (!documentToDelete) return;
      await deleteKnowledgeRepositoryDoc(documentToDelete);
    },
    onSuccess: () => {
      const documentList = getValues('documents');
      const updatedDocuments = documentList?.filter(
        document => document?.id !== documentToDelete,
      );
      setValue('documents', updatedDocuments);
      toast.success('Record deleted successfully');
      setValue('documentToDelete', null);
    },
  });

  // If edit, fetch previously added data
  const { isLoading: isKnowledgeRepoFetching } = useQuery({
    enabled: !!knowledgeRepoId,
    queryKey: ['knowledgerepo-form-data', isContextualInformation],
    queryFn: () =>
      getKnowledgeRepositoryById({
        id: knowledgeRepoId ? +knowledgeRepoId : '',
        is_contextual_indicator: isContextualInformation,
      }),
    onError: () =>
      toast.error('Failed to fetch knowledge repository form data'),
    select: responseData => responseData?.data?.results[0],
    onSuccess: data => {
      if (!data) return;

      const {
        programs,
        thematic_field: thematicField,
        province: provinceName,
        other_program_project,
      } = data;
      Object.keys(data)?.forEach((key: any) => {
        setValue(key, data[key]);
      });

      const setThematicFieldValues = thematicField?.reduce(
        (
          thematicData: Record<string, any>[],
          thematicItem: Record<string, any>,
        ) => {
          thematicData.push(thematicItem.thematic_field);
          return thematicData;
        },
        [],
      );

      const result = programs?.reduce(
        (acc: Record<string, any>, program: Record<string, any>) => {
          // Add the parent program key
          const parentId =
            program.knowledge_repository_program__associate_program__id;
          if (parentId) {
            acc[`parent_${parentId}`] = true;
          }

          // Add keys for each project in the program
          program.projects?.forEach((project: Record<string, any>) => {
            if (project.id) {
              acc[`child_${project.id}`] = true;
            }
          });

          return acc;
        },
        {}, // Initial accumulator is an empty object
      );

      if (isContextualInformation && provinceList && provinceName) {
        const selectedProvince = provinceList?.find(
          (province: Record<string, any>) => province.name === provinceName,
        )?.id;
        /* @ts-ignore */
        setValue('province', selectedProvince);
      }
      setValue('associate_program_project', result);
      setActualProgrammeProject(result);
      setBeforeProgramme(programs);
      setBeforeThematicFields(data.thematic_field);
      setBeforeThematicIds(setThematicFieldValues);
      setValue('other_program_project', other_program_project);
      setValue('thematic_field', setThematicFieldValues);
    },
  });

  const { isLoading: isKnowledgeRepoDocumentFetching } = useQuery({
    enabled: !!knowledgeRepoId,
    queryKey: ['knowledgerepo-document-data', isContextualInformation],
    queryFn: () => getKnowledgeRepositoryDocById(knowledgeRepoId || ''),
    onError: () =>
      toast.error('Failed to fetch knowledge repository documents'),
    select: data => {
      const response: knowledgeRepoDocumentResponseType[] = data?.data;
      const updatedDocumentResponse = response?.map(file => {
        return {
          id: file.id,
          file: {
            name: getFileNameFromURL(file.file),
          },
          previewURL: file.file,
        };
      });
      return updatedDocumentResponse;
    },
    onSuccess: data => {
      setValue('documents', data);
    },
  });

  // patch main knowledge repo data
  const patchKnowledgeRepo = async (formValues: knowledgeRepoFormType) => {
    if (isEmpty(formValues) || isDirty === false) return;

    const {
      documents,
      associate_program_project: progProject,
      thematic_field: thematicField,
      ...values
    } = formValues;

    const diffrenceProgProject = compareObjects(
      actualProgrammeProject,
      progProject,
    );

    const response =
      userProfile?.group_name === 'PEF User'
        ? await patchKnowledgeRepositoryData({
            ...values,
            is_contextual_indicator: true,
          })
        : isContextualInformation
          ? await patchKnowledgeRepositoryData({
              ...values,
              is_contextual_indicator: true,
            })
          : await patchKnowledgeRepositoryData(values);

    if (dirtyFields?.thematic_field) {
      const addPromises = thematicField?.map(async (fieldId: number) => {
        if (!beforeThematicFieldsIds?.includes(fieldId)) {
          await postKnowledgeRepositoryThematicFieldData({
            knowledge_repository: knowledgeRepoId,
            thematic_field: fieldId,
          });
        }
      });
      await Promise.all([addPromises]);

      const deletePromises = beforeThematicFieldsIds?.map(
        async (fieldId: number) => {
          if (!thematicField?.includes(fieldId)) {
            const idToDelete = beforeThematicFields.find(
              (id: any) => id.thematic_field === fieldId,
            )?.id;

            await deleteKnowledgeRepositoryThematicFieldData(idToDelete);
          }
        },
      );
      await Promise.all([deletePromises]);
    }

    if (!isEmpty(diffrenceProgProject)) {
      const patchProgrammeProject = Object.entries(diffrenceProgProject)?.map(
        async ([key, value]) => {
          if (key === 'parent_1010101010') return;
          if (key?.startsWith('parent_')) {
            if (!(key in actualProgrammeProject) && value === false) return;
            const programmeId = Number(key?.split('_')[1] || '');
            if (value) {
              await postKnowledgeRepositoryProgrammesData({
                knowledge_repository: knowledgeRepoId,
                associate_program: programmeId,
              });
            } else {
              const idToDelete = beforeProgramme?.find(
                (programme: Record<string, any>) =>
                  programmeId ===
                  programme.knowledge_repository_program__associate_program__id,
              )?.knowledge_repository_program__id;
              if (idToDelete) {
                await deleteProgrammeData(idToDelete);
              }
            }
          } else if (key?.startsWith('child_')) {
            if (!(key in actualProgrammeProject) && value === false) return;
            const projectId = Number(key?.split('_')[1] || '');
            if (value) {
              await postKnowledgeRepositoryProjectsData({
                knowledge_repository: knowledgeRepoId,
                project: projectId,
              });
            } else {
              const idToDeleteProject = beforeProgramme
                ?.map((program: Record<string, any>) => program?.projects)
                .flat()
                .find(
                  (project: Record<string, any>) => project?.id === projectId,
                )?.obj_id;

              if (idToDeleteProject) {
                await deletProjectData(idToDeleteProject);
              }
            }
          }
        },
      );

      await Promise.all(patchProgrammeProject);
    }

    if (!documents || (documents && documents?.length === 0)) return;
    const promises = documents?.map(async (document: Record<string, any>) => {
      if (document?.file instanceof File) {
        const documentFormData = {
          file: document?.file,
          knowledge_repository: response?.data?.id,
        };
        await postKnowledgeRepositoryDocumentData(
          prepareFormData(documentFormData),
        );
      }
    });

    await Promise.all(promises);
  };

  // post main knowledge repo data
  const postKnowledgeRepo = async (formValues: knowledgeRepoFormType) => {
    if (isEmpty(formValues)) return;
    const {
      documents,
      thematic_field: thematicfield,
      associate_program_project: programmeProjectFormValues,
      ...values
    } = formValues;

    const response =
      userProfile?.group_name === 'PEF User'
        ? await postKnowledgeRepositoryData({
            ...values,
            is_contextual_indicator: true,
          })
        : isContextualInformation
          ? await postKnowledgeRepositoryData({
              ...values,
              is_contextual_indicator: true,
            })
          : await postKnowledgeRepositoryData(values);

    if (!isEmpty(documents)) {
      const promises = documents?.map(async (document: Record<string, any>) => {
        const documentFormData = {
          file: document?.file,
          knowledge_repository: response?.data?.id,
        };
        await postKnowledgeRepositoryDocumentData(
          prepareFormData(documentFormData),
        );
      });
      await Promise.all(promises);
    }

    if (thematicfield) {
      const thematicFieldPromises = thematicfield?.map(async (field: any) => {
        await postKnowledgeRepositoryThematicFieldData({
          knowledge_repository: response?.data?.id,
          thematic_field: field,
        });
      });

      await Promise.all(thematicFieldPromises);
    }

    if (!isEmpty(programmeProjectFormValues)) {
      await Promise.all(
        Object.entries(programmeProjectFormValues)?.map(
          async ([key, value]) => {
            if (key === 'parent_1010101010') return;
            if (key?.startsWith('parent_')) {
              const programmeId = Number(key?.split('_')[1] || '');
              if (value) {
                await postKnowledgeRepositoryProgrammesData({
                  knowledge_repository: response?.data?.id,
                  associate_program: programmeId,
                });
              }
            }
            if (key?.startsWith('child_')) {
              const projectId = Number(key?.split('_')[1] || '');

              if (value) {
                await postKnowledgeRepositoryProjectsData({
                  knowledge_repository: response?.data?.id,
                  project: projectId,
                });
              }
            }
          },
        ),
      );
    }
  };

  const handleFormSubmit = async () => {
    try {
      const values = getValues();
      const editFields = getDirtyFieldValues();
      if (knowledgeRepoId) {
        await patchKnowledgeRepo(editFields);
      } else {
        await postKnowledgeRepo(values);
      }
      toast.success(
        knowledgeRepoId
          ? 'Knowledge repository updated successfully'
          : 'Knowledge repository added successfully ',
      );
      queryClient.invalidateQueries({
        queryKey: ['knowledge-repository-tabledata'],
      });
      onClose();
      reset();
    } catch (error) {
      toast.error('Error occured while saving!');
    }
  };

  const isDropdownOptionsLoading =
    isProvinceLoading ||
    isThematicFieldLoading ||
    (knowledgeRepoId &&
      (isKnowledgeRepoFetching || isKnowledgeRepoDocumentFetching)) ||
    isFileTypeLoading ||
    isProjectProgrammeLoading ||
    isReleaseTypeLoading;

  const isSaveBtnDisabled =
    isThematicFieldLoading ||
    isPostKnowledgeRepositoryProjectsLoading ||
    isPostKnowledgeRepositoryProgrammesLoading ||
    isPostKnowledgeRepositoryThematicFieldLoading ||
    isFileTypeLoading ||
    isReleaseTypeLoading ||
    isPostKnowledgeRepositoryDataLoading ||
    isProjectProgrammeLoading ||
    isPostKnowledgeRepositoryDocumentLoading ||
    (knowledgeRepoId &&
      (isKnowledgeRepoFetching || isKnowledgeRepoDocumentFetching)) ||
    isPatchKnowledgeRepositoryDataLoading;

  const props = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: { duration: 100 }, // Adjust duration as needed
    onRest: () => {
      if (!hasAnimated) {
        setHasAnimated(true); // Set it to true after the animation
      }
    },
  });

  const other_program_project = watch('other_program_project');
  const programmeProjectValues = watch('associate_program_project');

  useEffect(() => {
    if (other_program_project === '' || other_program_project === null) {
      setValue('associate_program_project', {
        ...programmeProjectValues,
        parent_1010101010: false,
      });
    } else {
      setValue('associate_program_project', {
        ...programmeProjectValues,
        parent_1010101010: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other_program_project]);

  useEffect(() => {
    if (programmeProjectValues.parent_1010101010 === false) {
      setValue('other_program_project', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeProjectValues]);

  useEffect(() => {
    setHasAnimated(false); // Reset before starting
  }, []);

  useEffect(() => {
    if (pathname?.includes('contextual')) {
      setActiveFormType('Contextual Information');
    }
  }, [pathname]);

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center sm:naxatw-max-w-[34.75rem] 2xl:naxatw-max-w-[40%]">
      <animated.div
        className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 "
        style={{
          transform: props.scale.to(scale => `scale(${scale})`),
        }}
      >
        <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
          <BreadCrumb
            heading={`Knowledge Repository ${knowledgeRepoId ? 'Edit' : 'Add'} Form`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>

        {userProfile.group_name === 'PEF User' ? (
          ''
        ) : pathname.includes('edit') ? null : (
          <SwitchTab
            options={knowledgeRepoFormTypeInformation || []}
            activeLabel={activeFormType || ''}
            wrapperClassName="!naxatw-cursor-pointer !naxatw-gap-[0rem] naxatw-mt-[1.25rem] naxatw-px-[1.5rem]"
            defaultBg={false}
            defaultBehaviour={false}
            className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
            titleClassName="naxatw-text-sm"
            onChange={label => {
              setActiveFormType(label);
              reset();
            }}
          />
        )}

        <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
          <div className="naxatw-w-full">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                <FlexColumn className="scrollbar  naxatw-h-[calc(100vh-23.8rem)]  naxatw-w-full naxatw-overflow-y-scroll naxatw-pr-2">
                  {isDropdownOptionsLoading ? (
                    <FormSkeleton numRows={7} className="!naxatw-w-full" />
                  ) : (
                    knowledgeRepoFormFields?.map(
                      (fieldRow: Record<string, any>, i) => (
                        <FlexRow key={i}>
                          {fieldRow?.map((field: FormFieldProps) => {
                            const { id } = field;
                            if (
                              field.id === 'other_program_project' &&
                              !programmeProjectValues?.parent_1010101010
                            ) {
                              return null;
                            }
                            return (
                              <FormControl
                                className="form-control naxatw-mt-5 naxatw-w-full naxatw-gap-[0.5rem]"
                                key={`${field.id}- ${field.name}`}
                              >
                                <InputLabel
                                  label={field.label}
                                  astric={field.required}
                                  id={field.id}
                                />
                                {getInputElement(
                                  {
                                    ...field,
                                    id: `${field.id}`,
                                  },
                                  /* @ts-ignore */
                                  formProps,
                                  knowledgeRepoDropdownOptions?.[id] || [],
                                )}
                                <>
                                  {
                                    /* @ts-ignore */
                                    formProps.errors[id] && (
                                      <ErrorMessage
                                        /* @ts-ignore */
                                        message={formProps.errors[id]?.message}
                                      />
                                    )
                                  }
                                </>
                              </FormControl>
                            );
                          })}
                        </FlexRow>
                      ),
                    )
                  )}
                </FlexColumn>
              </div>

              <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                <Button
                  size="sm"
                  variant="primary"
                  className="naxatw-px-4 naxatw-py-2"
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSaveBtnDisabled || isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
        {documentToDelete && (
          <Portal>
            <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-h-[100vh] naxatw-w-[100vw] naxatw-bg-black naxatw-opacity-30" />
            <DeleteConfirmationOverlay
              onClose={() => setValue('documentToDelete', null)}
              onDelete={() => deleteKnowledgeRepositoryDocument()}
              isError={isDeleteDocumentError}
              isLoading={isDeleteDocumentLoading}
              error={deleteDocumentError}
            />
          </Portal>
        )}
      </animated.div>
    </div>
  );
};

export default hasErrorBoundary(KnowledgeRepositoryForm);
