import React, { useState } from 'react';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { Input } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getMarkerCategoriesById,
  patchMarkerCategories,
  postMarkerCategories,
} from '@Services/markers';
import { FlexColumn } from '@Components/common/Layouts';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

type propType = {
  onClose: () => void;
};

const MarkerCategoriesForm = ({ onClose }: propType) => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const queryClient = useQueryClient();

  const [markerCategoryName, setMarkerCategoryName] = useState('');
  const [error, setError] = useState(false);

  // fetch marker-category to populate marker-category name if it is an update
  const { isFetching: isMarkerCategoryFetching } = useQuery({
    queryKey: ['marker-category-by-id', categoryId],
    enabled: !!categoryId,
    queryFn: () => getMarkerCategoriesById(categoryId || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      setMarkerCategoryName(data.name);
    },
  });

  const {
    mutateAsync: addMarkerCategoryData,
    isLoading: isAddMarkerCategoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postMarkerCategories(payloadData),
  });

  const {
    mutateAsync: updateMarkerCategoryData,
    isLoading: isUpdateMarkerCategoryDataLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      patchMarkerCategories(payload.id, payload.payload),
  });

  const saveMarkerCategory = async () => {
    if (!markerCategoryName?.trim()) {
      setError(true);
      return;
    }

    const payload = {
      name: markerCategoryName?.trim(),
    };

    if (!categoryId) {
      await addMarkerCategoryData(payload);
    } else {
      await updateMarkerCategoryData({ id: categoryId, payload });
    }

    queryClient.invalidateQueries({
      queryKey: ['marker-categories-tabledata'],
    });
    toast.success(
      categoryId
        ? 'Marker Category updated successfully'
        : 'Marker Category added successfully',
    );
    navigate('/data-bank/markers/markers-categories');
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading="Markers / Marker Category"
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="naxatw-h-[19.5rem] naxatw-w-full">
        <FlexColumn className="naxatw-h-full naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-px-6 naxatw-pt-5">
          {isMarkerCategoryFetching ? (
            <FormSkeleton className="naxatw-w-full" />
          ) : (
            <>
              <div className="naxatw-flex-1">
                <FlexColumn className="naxatw-w-full naxatw-gap-[0.469rem]">
                  <InputLabel
                    id="marker-category-name"
                    label="Name"
                    // tooltipMessage="Marker Category Name"
                    astric
                  />
                  <Input
                    type="text"
                    placeholder="Marker Category"
                    className="naxatw-w-full"
                    value={markerCategoryName}
                    onChange={e => {
                      setMarkerCategoryName(e.target.value);
                      if (error) setError(false);
                    }}
                  />
                  {error && (
                    <p className="naxatw-pt-1 naxatw-text-sm naxatw-text-red-500">
                      Marker Category Name is Required
                    </p>
                  )}
                </FlexColumn>
              </div>

              <div className="naxatw-px-6 naxatw-py-5 naxatw-text-center">
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  className="!naxatw-mt-10 naxatw-w-fit naxatw-px-4"
                  onClick={() => saveMarkerCategory()}
                  isLoading={
                    isAddMarkerCategoryDataLoading ||
                    isUpdateMarkerCategoryDataLoading
                  }
                  disabled={
                    isAddMarkerCategoryDataLoading ||
                    isUpdateMarkerCategoryDataLoading ||
                    isMarkerCategoryFetching
                  }
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default hasErrorBoundary(MarkerCategoriesForm);
