import { authenticated, api } from '.';

export const getVideoTutorial = () => {
  return authenticated(api).get('/video-manual/');
};
export const getVideoTutorialById = (id: string | number) => {
  return authenticated(api).get(`/video-manual/${id}/`);
};
export const postVideoTutorial = (data: any) => {
  return authenticated(api).post('/video-manual/', data);
};

export const patchVideoTutorial = (payload: Record<string, any>) => {
  const { id } = payload;
  return authenticated(api).patch(`/video-manual/${id}/`, payload);
};

export const deleteVideoTutorial = (id: any) => {
  return authenticated(api).delete(`/video-manual/${id}/`);
};

export const getUserManual = () => {
  return authenticated(api).get('/user-manual/');
};
export const getUserManualById = (id: string | number) => {
  return authenticated(api).get(`/user-manual/${id}/`);
};
export const postUserManual = (data: any) => {
  return authenticated(api).post('/user-manual/', data);
};

export const patchUserManual = (payload: Record<string, any>) => {
  const { id } = payload;
  return authenticated(api).patch(`/user-manual/${id}/`, payload);
};

export const deleteUserManual = (id: any) => {
  return authenticated(api).delete(`/user-manual/${id}/`);
};

export const getFAQ = (params: Record<string, any>) => {
  return authenticated(api).get('/faq/', { params });
};
export const getFAQById = (id: string | number) => {
  return authenticated(api).get(`/faq/${id}/`);
};
export const postFAQ = (data: any) => {
  return authenticated(api).post('/faq/', data);
};

export const patchFAQ = (payload: Record<string, any>) => {
  const { id } = payload;
  return authenticated(api).patch(`/faq/${id}/`, payload);
};

export const deleteFAQ = (id: any) => {
  return authenticated(api).delete(`/faq/${id}/`);
};
