/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useTypedSelector } from '@Store/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { deleteIndicatorById, getIndicators } from '@Services/indicators';

const IndicatorsTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [iDToDelete, setIDToDelete] = useState('');

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.indicators,
  );

  const {
    mutate: deleteIndicatorData,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteIndicatorById(iDToDelete),
    onSuccess: () => {
      toast.success('Record deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['indicators-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'S.N.',
      accessorKey: '',
      cell: ({ cell }: { cell: any }) => cell.row.index + 1,
    },
    {
      header: 'Indicator',
      accessorKey: 'indicator',
    },
    {
      header: 'Indicator Definition',
      accessorKey: 'indicator_definition',
    },
    {
      header: 'Measurement Unit',
      accessorKey: 'measurement_unit',
    },
    // {
    //   header: 'Assign to Programme',
    //   accessorKey: 'program',
    //   cell: ({ row }: any) => {
    //     return <span>{row?.original?.program?.toString() || '-'}</span>;
    //   },
    // },
    // {
    //   header: 'Assign to Component',
    //   accessorKey: 'pucomponentblished_date',
    //   cell: ({ row }: any) => {
    //     return <span>{row?.original?.component?.toString() || '-'}</span>;
    //   },
    // },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() => {
                navigate(`/data-bank/indicators/edit/${cellId}`);
              }}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];

  return (
    <div className="naxatw-h-[calc(100%-7rem)] naxatw-w-full lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="indicators-tabledata"
        queryFn={getIndicators}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteIndicatorData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(IndicatorsTable);
