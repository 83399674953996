import { commonSlice } from '../slices/common';

export const {
  setCommonState,
  toggleModal,
  setModalContent,
  togglePromptDialog,
  setPromptDialogContent,
  setCollapseSidebar,
  setAdminSearchState,
} = commonSlice.actions;
