import BottomLeft from './BottomLeft';
import BottomRight from './BottomRight';
import BottomTop from './BottomTop';

const BottomSection = () => {
  return (
    <>
      <div className="naxatw-w-full naxatw-rounded-xl naxatw-bg-white naxatw-shadow-sm">
        <BottomTop />
        <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-[#EAECF0]" />
        <div className="naxatw-flex naxatw-flex-col naxatw-items-center naxatw-gap-10 naxatw-self-stretch naxatw-py-[0.75rem] naxatw-pb-5 naxatw-pr-5 lg:naxatw-flex-row lg:naxatw-gap-20 lg:naxatw-pb-3">
          <BottomLeft />
          <BottomRight />
        </div>
      </div>
    </>
  );
};

export default BottomSection;
