import search from '@Assets/icons/search.svg';
import { FC } from 'react';
import Search from '../Search';

interface FilterSearchCategoryProps {
  category?: string;
  value?: string;
  onChange?: () => void;
}

const FilterSearchCategory: FC<FilterSearchCategoryProps> = ({
  category,
  value,
  onChange,
}) => {
  return (
    <div className="naxatw-mt-2 naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-3 naxatw-p-1">
      <img src={search} alt="search-btn" className="naxatw-h-3 naxatw-w-3" />
      <Search placeholder={`${category}`} value={value} onChange={onChange} />
    </div>
  );
};

export default FilterSearchCategory;
