export const viewByOptions = [
  { label: 'province' },
  { label: 'district' },
  { label: 'municipality' },
];

export const comparisonSwitchOptions = [
  { label: 'Province' },
  { label: 'Municipality' },
];

export const contextualIndicatorsOptions = [
  { id: 1, value: 'absentee_abroad', label: 'Absentee Abroad %' },
  { id: 2, value: 'avg_household', label: 'Average Household Size' },
  { id: 3, value: 'female', label: 'Female Population %' },
  { id: 4, value: 'female_household', label: 'Female Headed Household %' },
  { id: 7, value: 'literacy_rate', label: 'Literacy Rate' },
  { id: 8, value: 'male', label: 'Male Population %' },
  { id: 9, value: 'population_density', label: 'Population Density' },
  {
    id: 10,
    value: 'prop_females_u25_married_u20',
    label: 'Female under 25 who married aged <=20',
  },
  {
    id: 11,
    value: 'ggn_ranking',
    label: 'GGN Ranking',
  },
  {
    id: 12,
    value: 'lisp_ranking',
    label: 'LISP Ranking',
  },
  {
    id: 13,
    value: 'rain_ranking',
    label: 'RAIN Ranking',
  },
  {
    id: 14,
    value: 'sahakaria_ranking',
    label: 'Sahakaria Ranking',
  },
  {
    id: 15,
    value: 'samartha_ranking',
    label: 'Samartha Ranking',
  },

  {
    id: 16,
    value: 'sjp_ranking',
    label: 'SJP Ranking',
  },
  {
    id: 17,
    value: 'overall_count',
    label: 'Overall Top 10 Count',
  },
];

export const comparisonByProvinceOptions = [
  { id: 1, value: 'Koshi', label: 'Koshi' },
  { id: 2, value: 'Madhesh', label: 'Madhesh' },
  { id: 3, value: 'Bagmati', label: 'Bagmati' },
  { id: 4, value: 'Gandaki', label: 'Gandaki' },
  { id: 5, value: 'Lumbini', label: 'Lumbini' },
  { id: 6, value: 'Karnali', label: 'Karnali' },
  { id: 7, value: 'Sudurpashchim', label: 'Sudurpashchim' },
];

export const lumbiniData = [
  { value: 70, name: '42' },
  { value: 60, name: '68%' },
  { value: 50, name: '78.1%' },
  { value: 40, name: '24.4%' },
  { value: 30, name: '27% ' },
];
export const bagmatiData = [
  { value: 70, name: '52' },
  { value: 60, name: '65%' },
  { value: 50, name: '76.1%' },
  { value: 40, name: '44.4%' },
  { value: 30, name: '47%' },
];
export const labelData = [
  { id: 1, name: 'Palikas' },
  { id: 2, name: 'Economically Activ...' },
  { id: 3, name: 'Literacy Rate' },
  { id: 4, name: 'Poverty Headcount' },
  { id: 5, name: 'Absentee Aboard' },
];

export const indicatorsColumnForProvince = [
  {
    header: 'S.N.',
    accessorKey: '',
    cell: ({ cell }: { cell: any }) => cell.row.index + 1,
  },
  { header: 'Province', accessorKey: 'province__name' },
  { header: 'Absentee Abroad', accessorKey: 'absentee_abroad' },
  { header: 'Avg Household', accessorKey: 'avg_household' },
  { header: 'Female population', accessorKey: 'female' },
  { header: 'Female Household', accessorKey: 'female_household' },
  { header: 'Literacy Rate', accessorKey: 'literacy_rate' },
  { header: 'Male population', accessorKey: 'male' },
  { header: 'Population Density', accessorKey: 'population_density' },
  {
    header: 'Females under 25 who married aged <= 20',
    accessorKey: 'prop_females_u25_married_u20',
  },
  {
    header: 'GGN Ranking',
    accessorKey: 'ggn_ranking',
  },
  {
    header: 'LISP Ranking',
    accessorKey: 'lisp_ranking',
  },
  {
    header: 'RAIN Ranking',
    accessorKey: 'rain_ranking',
  },
  {
    header: 'Sahakaria Ranking',
    accessorKey: 'sahakaria_ranking',
  },
  {
    header: 'Samartha Ranking',
    accessorKey: 'samartha_ranking',
  },

  {
    header: 'SJP Ranking',
    accessorKey: 'sjp_ranking',
  },
  {
    header: 'Overall Top 10 Count',
    accessorKey: 'overall_count',
  },
];

export const indicatorsColumnForMunicipality = [
  {
    header: 'S.N.',
    accessorKey: '',
    cell: ({ cell }: { cell: any }) => cell.row.index + 1,
  },
  { header: 'Municipality', accessorKey: 'municipality__name' },
  { header: 'Absentee Abroad', accessorKey: 'absentee_abroad' },
  { header: 'Avg Household', accessorKey: 'avg_household' },
  { header: 'Female population', accessorKey: 'female' },
  { header: 'Female Household', accessorKey: 'female_household' },
  { header: 'Literacy Rate', accessorKey: 'literacy_rate' },
  { header: 'Male population', accessorKey: 'male' },
  { header: 'Population Density', accessorKey: 'population_density' },
  {
    header: 'Females under 25 who married aged <= 20',
    accessorKey: 'prop_females_u25_married_u20',
  },
  {
    header: 'GGN Ranking',
    accessorKey: 'ggn_ranking',
  },
  {
    header: 'LISP Ranking',
    accessorKey: 'lisp_ranking',
  },
  {
    header: 'RAIN Ranking',
    accessorKey: 'rain_ranking',
  },
  {
    header: 'Sahakaria Ranking',
    accessorKey: 'sahakaria_ranking',
  },
  {
    header: 'Samartha Ranking',
    accessorKey: 'samartha_ranking',
  },

  {
    header: 'SJP Ranking',
    accessorKey: 'sjp_ranking',
  },
  {
    header: 'Overall Top 10 Count',
    accessorKey: 'overall_count',
  },
];

export const indicatorsChardData = {
  indicator: 'Number of Palikas with local disaster management plan',
  definition:
    'Palikas that developed local disaster management plan with support from',
  measurementUnit: 'Number',
  disaggregationUnit: [
    {
      unit: '0 to 10',
      value: 40,
    },
    {
      unit: '20 to 30',
      value: 100,
    },
  ],
};

export const indicatorsWithNonDecimalValues = [
  'ggn_ranking',
  'lisp_ranking',
  'rain_ranking',
  'sahakaria_ranking',
  'samartha_ranking',
  'sjp_ranking',
  'overall_count',
];
