interface IAlertProps {
  message: string | undefined;
  success?: boolean;
  error?: boolean;
}

const Alert = ({ message, success, error }: IAlertProps) => {
  return (
    <>
      {error && (
        <div
          className="naxatw-w-full  naxatw-rounded-lg naxatw-bg-red-100 naxatw-p-3 naxatw-text-sm naxatw-text-red-700"
          role="alert"
        >
          <span className="font-medium">Error Occured! &nbsp;</span> {message}
        </div>
      )}
      {success && (
        <div
          className="naxatw-w-full  naxatw-rounded-lg naxatw-bg-blue-100 naxatw-p-3  naxatw-text-sm naxatw-text-green-700"
          role="alert"
        >
          <span className="font-medium">Success! &nbsp;</span> {message}
        </div>
      )}
    </>
  );
};

export default Alert;
