import { useState } from 'react';

import { useTypedDispatch } from '@Store/hooks';
import DropDown from '@Components/common/DropDown';
import ToolTip from '@Components/RadixComponents/ToolTip';

type ComparisonDropDownProps = {
  title: string;
  options: any[];
  className?: string;
  message?: string;
  multiple?: boolean;
  hasLeftIcon?: boolean;
  leftIconName?: string;
  lengthOfOptions?: number;
  checkBox?: boolean;
  placeHolder?: string;
  value?: string | string[];
  onChange?: any;
  dispatchAction?: any;
  isLoading?: boolean;
};

const LabeledDropDown = ({
  title,
  options,
  className,
  message,
  multiple,
  hasLeftIcon,
  leftIconName,
  lengthOfOptions,
  checkBox,
  placeHolder,
  onChange,
  value,
  dispatchAction,
  isLoading = false,
}: ComparisonDropDownProps) => {
  const dispatch = useTypedDispatch();
  const [selectedValue, setSelectedValue] = useState<string | string[]>('');
  if (!onChange) {
    // eslint-disable-next-line no-param-reassign
    onChange = (newValue: string | string[]) => {
      setSelectedValue(newValue);
      if (dispatchAction) {
        dispatch(dispatchAction(newValue));
      }
    };
  }

  return (
    <>
      <div className="naxatw-w-full">
        <div className="naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-items-start naxatw-gap-1">
          <div className="naxatw-flex naxatw-items-center naxatw-gap-1">
            <p className="naxatw-text-sm naxatw-font-medium naxatw-text-matt-100">
              {title}
            </p>
            <ToolTip
              name="info"
              message={message || 'Default Message'}
              className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-matt-200"
            />
          </div>
          <DropDown
            options={isLoading ? [] : options}
            className={`naxatw-items-start naxatw-self-stretch ${className}`}
            multiple={multiple}
            onChange={onChange}
            value={value || selectedValue}
            hasLeftIcon={hasLeftIcon}
            leftIconName={leftIconName}
            lengthOfOptions={lengthOfOptions}
            checkBox={checkBox}
            placeholder={placeHolder}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default LabeledDropDown;
