import ProvincialProfile from '@Components/ProvincialProfile';

const ProvincePage = () => {
  return (
    <>
      <div className="naxatw-w-full naxatw-overflow-x-hidden naxatw-bg-[#F4F7FE] naxatw-px-6 naxatw-pt-4 lg:naxatw-overflow-y-hidden">
        <ProvincialProfile />
      </div>
    </>
  );
};

export default ProvincePage;
