/* eslint-disable react/no-unstable-nested-components */
import AdminHeader from '@Components/common/AdminHeader';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useSpring, animated } from '@react-spring/web';
import React, { useState } from 'react';
import DataTable from '@Components/common/DataTable';
import { deleteFAQ, getFAQ } from '@Services/tutorials';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import TutorialsHeaderSwitchTab from '../TutorialsHeader';
import FAQRegistrationForm from './FAQRegistrationForm';

const FAQComponent = () => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string>('');
  const [idToEdit, setIdToEdit] = useState<string>('');

  const queryClient = useQueryClient();

  const faqDataColumns = [
    {
      header: 'QUESTION',
      accessorKey: 'title',
    },
  ];
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  const {
    mutate: deleteFAQData,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: () => deleteFAQ(idToDelete),
    onSuccess: () => {
      toast.success('FAQ deleted successfully');
      queryClient.invalidateQueries(['faq-table-data']);
      setOpenDeleteConfirmation(false);
    },
    onError: () => {
      toast.error('Failed to FAQ');
    },
  });

  return (
    <>
      <div className="naxatw-space-y-6">
        <AdminHeader
          title="Tutorials"
          onAddNewClick={() => {
            setShowRegistrationForm(true);
          }}
          hasBulkUpload={false}
          needSearchBar={false}
          registerButtonText="Add"
        />
        <TutorialsHeaderSwitchTab />
        <animated.div
          style={{ ...springs }}
          className="naxatw-h-[calc(100vh-18rem)] naxatw-w-full naxatw-overflow-hidden lg:naxatw-h-[calc(100vh-15.6rem)]"
        >
          <DataTable
            columns={[
              ...faqDataColumns,
              {
                header: 'ANSWER',
                accessorKey: 'description',
                cell: ({ row }: any) => {
                  return (
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: row?.original?.description,
                      }}
                      className="editor-content"
                    />
                  );
                },
              },
              {
                header: '',
                accessorKey: 'icon',
                // eslint-disable-next-line react/no-unstable-nested-components
                cell: ({ row }: any) => {
                  const cellId = row?.original?.id;
                  return (
                    <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-mt-2 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
                      <ToolTip
                        name="edit"
                        message="Edit"
                        className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
                        iconClick={() => {
                          setShowRegistrationForm(true);
                          setIdToEdit(cellId);
                        }}
                      />
                      <ToolTip
                        name="delete"
                        message="Delete"
                        className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
                        iconClick={() => {
                          setOpenDeleteConfirmation(true);
                          setIdToDelete(cellId);
                        }}
                      />
                    </FlexRow>
                  );
                },
              },
            ]}
            queryFn={getFAQ}
            queryKey="faq-table-data"
            searchInput=""
            isPaginated
            initialState={{
              paginationState: {
                pageIndex: 0,
                pageSize: 25,
              },
            }}
          />
        </animated.div>
      </div>
      {showRegistrationForm && (
        <Portal>
          <FAQRegistrationForm
            onClose={() => setShowRegistrationForm(false)}
            id={idToEdit}
          />
        </Portal>
      )}
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteFAQData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </>
  );
};

export default FAQComponent;
