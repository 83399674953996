import React from 'react';

type statusType = 'success' | 'pending' | 'error' | 'info';
type statusChipsPropType = {
  status: statusType;
  label: string;
};

const statusChipStyle = (status: statusType) => {
  switch (status) {
    case 'success':
      return `naxatw-text-[#067647] naxatw-bg-[#ECFDF3] naxatw-border-[#80D89F]`;
    case 'pending':
      return `naxatw-text-[#CF9600] naxatw-bg-[#FFF7E4] naxatw-border-[#F6D480]`;
    case 'error':
      return `naxatw-text-[#C61231] naxatw-bg-[#FFE9EC] naxatw-border-[#E38796]`;
    case 'info':
      return `naxatw-text-secondary-500 naxatw-bg-[#e6f0fc] naxatw-border-secondary-500`;
    default:
      return `naxatw-text-[#067647] naxatw-bg-[#ECFDF3] naxatw-border-[#80D89F]`;
  }
};

const StatusChips = ({ status, label }: statusChipsPropType) => {
  return (
    <div
      className={`${statusChipStyle(
        status,
      )} naxatw-w-fit naxatw-rounded-2xl naxatw-border-[1px] naxatw-px-2 naxatw-py-[2px] naxatw-text-[0.75rem] naxatw-capitalize naxatw-leading-5`}
    >
      {label}
    </div>
  );
};

export default StatusChips;
