import React, { ReactElement } from 'react';
import { useTypedSelector } from '@Store/hooks';
import NoAccessComponent from '@Components/common/NoAccessComponent';

interface CheckPermissionRouteProps {
  children: ReactElement;
  permission: string;
}

const CheckPermissionRoute: React.FC<CheckPermissionRouteProps> = ({
  children,
  permission,
}) => {
  const allPermissions = useTypedSelector(
    state => state.common.userGroupPermission,
  );
  const hasPermission = allPermissions?.includes(permission);
  const userProfile = useTypedSelector(state => state.common.userProfile);

  if (userProfile?.group_name === 'System Admin') {
    return children;
  }

  // checks admin only route
  if (permission === 'Admin-Only') {
    return <NoAccessComponent />;
  }

  if (hasPermission) {
    return children;
  }

  return <NoAccessComponent />;
};

export default CheckPermissionRoute;
