import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from '@Services/authentication';
import { useForm } from 'react-hook-form';
import InfoDialog from '@Components/common/InfoDialog';
import { FlexColumn } from '@Components/common/Layouts';
import InputLabel from '@Components/common/InputLabel';
import { FormControl, Input } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import { toast } from 'react-toastify';
import Icon from '@Components/common/Icon';

const initialState = {
  new_password: '',
  confirm_password: '',
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const { uid, token } = useParams();

  const { mutate, isError, error, isLoading } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      toast.success('Password reset successful.');
      navigate('/login');
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  const onSubmit = (data: any) => {
    if (data?.new_password !== data?.confirm_password) {
      setError('confirm_password', {
        message: 'Password and confirm password does not match',
      });
      return;
    }
    mutate({ ...data, token, uid });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="naxatw-flex naxatw-flex-col naxatw-gap-10"
    >
      <FlexColumn className="naxatw-gap-3">
        <h6 className="naxatw-text-xl naxatw-font-medium naxatw-text-black">
          Reset Password
        </h6>
        <p className="naxatw-body-sm">Enter new password</p>
      </FlexColumn>
      {isError ? (
        <InfoDialog status="error">
          {(error as Error).message || 'Something is not right.'}
        </InfoDialog>
      ) : null}
      <FlexColumn className="naxatw-gap-5">
        <FormControl>
          <InputLabel label="Password" />
          <div className="naxatw-relative">
            <Input
              id="new_password"
              placeholder="Password"
              type={newPassword ? 'text' : 'password'}
              className="naxatw-mt-2"
              {...register('new_password', { required: true })}
            />
            <Icon
              name={newPassword ? 'visibility' : 'visibility_off'}
              onClick={() => setNewPassword(!newPassword)}
              className="naxatw-absolute naxatw-right-4 naxatw-top-[calc(50%-6px)]  naxatw-select-none naxatw-text-xl"
            />
          </div>
        </FormControl>
        <FormControl>
          <InputLabel label="Confirm Password" />
          <div className="naxatw-relative">
            <Input
              id="confirm-password"
              placeholder="Confirm Password"
              type={confirmNewPassword ? 'text' : 'password'}
              className="naxatw-mt-2"
              {...register('confirm_password', {
                required: true,
              })}
            />
            <Icon
              name={newPassword ? 'visibility' : 'visibility_off'}
              onClick={() => setConfirmNewPassword(!confirmNewPassword)}
              className="naxatw-absolute naxatw-right-4 naxatw-top-[calc(50%-6px)]  naxatw-select-none naxatw-text-xl"
            />
          </div>
          {errors?.confirm_password && (
            <span className="naxatw-mt-2 naxatw-text-sm naxatw-text-red-500">
              {errors?.confirm_password?.message}
            </span>
          )}
        </FormControl>
      </FlexColumn>
      <FlexColumn className="naxatw-gap-3">
        <Button type="submit" isLoading={isLoading} disabled={isLoading}>
          Reset Password
        </Button>
        <Button
          type="button"
          variant="link"
          className="naxatw-mx-auto naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
          onClick={() => {
            navigate('/login');
          }}
        >
          Back to Login
        </Button>
      </FlexColumn>
    </form>
  );
};

export default ResetPassword;
