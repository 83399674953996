import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Tab = {
  selectedTab: Record<string, any>;
};
const initialState: Tab = {
  selectedTab: {
    programme: 'Province',
    component: 'Province',
  },
};

export const programmeDetails = createSlice({
  name: 'portfolioResultsSwitchTab',
  initialState,
  reducers: {
    setKeyStakeHoldersTabProgramme: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab.programme = action.payload;
    },
    setKeyStakeHoldersTabComponent: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab.component = action.payload;
    },
  },
});

export default programmeDetails.reducer;
