import { FC } from 'react';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import avatar from '@Assets/images/avatar-images.svg';
import { format, parseISO } from 'date-fns';

interface IActivityLogsProps {
  message?: string;
  date__date?: any;
  user__username?: string;
  user__Profile__role__name?: string;
}

const ActivityLogCard: FC<IActivityLogsProps> = ({
  message,
  date__date: date,
  user__username: username,
  user__Profile__role__name: role,
}) => {
  return (
    <div className="activity-log-card naxatw-mb-2 naxatw-flex naxatw-items-center naxatw-gap-5 naxatw-rounded-xl naxatw-border naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-p-3 last:naxatw-mb-0">
      <div className="naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-gap-5">
        {/* profile-img */}
        <div className="profile-img-name naxatw-flex naxatw-items-center naxatw-gap-3 md:naxatw-min-w-[10rem]">
          <div className="img-container naxatw-h-10 naxatw-w-10 naxatw-rounded-[2.5rem] naxatw-border naxatw-border-[#D7D7D7]">
            <img
              src={avatar}
              alt="profile"
              className="naxatw-h-full naxatw-w-full"
            />
          </div>
          <p className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-tracking-[-0.0175rem] naxatw-text-grey-800">
            {username || '-'}
          </p>
        </div>

        {/* username */}
        <p className="naxatw-text-[0.875rem] naxatw-leading-5 naxatw-text-grey-800 md:naxatw-min-w-[10rem]">
          {role || '-'}
        </p>

        {/* message */}
        <p className="naxatw-text-[0.875rem] naxatw-leading-5 naxatw-text-grey-800">
          {message || '-'}
        </p>
      </div>

      {/* date */}
      <p className="naxatw-text-[0.875rem] naxatw-leading-5 naxatw-text-grey-800">
        {format(parseISO(date), 'dd MMM, yyyy') || '-'}
      </p>
    </div>
  );
};

export default hasErrorBoundary(ActivityLogCard);
