export default function switchTile(tile: string) {
  if (!tile) return 'province_tile';
  switch (tile) {
    case 'province':
      return 'province_tile';
    case 'municipality':
      return 'municipality_tile';
    case 'district':
      return 'district_tile';
    default:
      return 'province_tile';
  }
}
