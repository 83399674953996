import { api } from '.';

export const signInUser = (data: any) => api.post('/user/sign-in/', data);

export const logoutUser = () => api.post('/user/logout/');

export const forgotPassword = (data: any) =>
  api.post('/user/forgot-password/', data);

export const getRandomTriviaQuestion = () =>
  api.get('/random-trivia-question/');

export const checkCorrectAnswer = (params: any) => {
  const { selectedOption, questionId } = params;
  return api.get(`/check-answer/?answer=${selectedOption}&id=${questionId}`);
};

export const resetPassword = (data: any) =>
  api.post('/user/reset-password/', data);

export const setCrfCookie = () => api.get('/user/set-csrf-cookie/');
