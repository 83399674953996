// import { getColorRangesByValueList } from '@Utils/getLegendValues';
import getRangeValueWithColorCode, {
  convertToInteger,
  getRangeValueWithColorCodeDecimalSupport,
} from '@Utils/choropleth';
import { pointStyles, roadStyles } from './map';

export const viewByOptionsSectorsCard = [
  { label: 'Project' },
  { label: 'Programme' },
];
export const viewByOptionsCardHeader = [
  { label: 'Government Stakeholders' },
  { label: 'Stakeholders Type' },
];

export const governmentStakeHoldersCardHeader = [
  { label: 'Project' },
  { label: 'Programme' },
];

export const temporaryLegendColors = [
  { min: -1, max: 0, color: '#FFFFFF' },
  { min: 0, max: 5, color: '#E7F2FF' },
  { min: 5, max: 10, color: '#B2C8E6' },
  { min: 10, max: 15, color: '#7F9FCE' },
  { min: 20, max: 25, color: '#08519C' },
];

export const viewByOptions = [
  { label: 'province' },
  { label: 'district' },
  { label: 'municipality' },
];

export const getExpression = (
  data: Record<string, any>[] | string,
  selectedId?: any,
  selectedFillColor?: string,
  countKey: string = 'count',
  idMatchingToVT: string = 'id',
  rangeWithDecimalSupport: Boolean = false,
) => {
  const rangeValueWithColor = rangeWithDecimalSupport
    ? getRangeValueWithColorCodeDecimalSupport(data, countKey)
    : getRangeValueWithColorCode(data, countKey);
  const expression = ['match', ['get', 'id']];
  if (Array.isArray(data)) {
    if (selectedId) expression.push(selectedId, selectedFillColor || '#5DB8B2');
    if (!data?.length) return '#FFFFFF';
    data?.forEach(eachData => {
      if (
        expression?.includes(eachData?.[idMatchingToVT]) ||
        !eachData?.[idMatchingToVT]
      )
        return;
      expression.push(eachData?.[idMatchingToVT]);
      const count = convertToInteger(eachData?.[countKey]);
      // @ts-ignore
      const range = rangeValueWithColor?.find(
        // @ts-ignore
        item => item?.min <= count && item?.max >= count,
      );
      if (range?.color) expression.push(range?.color);
      else expression.push('#FFFFFF');
    });
  }
  expression.push('#FFFFFF');
  return expression;
};

export const dashboardTabOptions = [
  { label: 'map' },
  { label: 'table' },
  { label: 'chart' },
];

export const knowledgeLibrarytabOptions = [
  {
    id: 1,
    label: 'Programmatic Information',
    path: '/knowledge-library',
    activePaths: ['/knowledge-library'],
  },
  {
    id: 2,
    label: 'Contextual Information',
    path: '/knowledge-library/contextual',
    activePaths: ['/knowledge-library/contextual'],
  },
];

export const mapGovernmentStakeHolderColors: Record<string, any> = {
  'Government Stakeholder': '#D64C63',
  'Provincial Government Stakeholder': '#41588F',
  'Local Government Stakeholder': '#41C570',
};
export const mapGovernmentStakeHoldersTitle: Record<string, any> = {
  'Government Stakeholder': 'Federal Government',
  'Provincial Government Stakeholder': 'Provincial Government',
  'Local Government Stakeholder': 'Local Government',
};

export const staticLayerStyle = {
  'Road Network': {
    style: {
      ...roadStyles,
      paint: {
        ...roadStyles?.paint,
        'line-color': '#fff000',
      },
    },
    geometry_type: 'LineString',
  },
  'River Network': {
    style: {
      ...roadStyles,
      paint: {
        ...roadStyles?.paint,
        'line-color': '#000FFF',
      },
    },
    geometry_type: 'LineString',
  },
  Airport: {
    style: {
      ...pointStyles,
      paint: { ...pointStyles?.paint, 'circle-color': '#2B2C1D' },
    },
    geometry_type: 'Point',
  },
  'Major Cities': {
    style: {
      ...pointStyles,
      paint: { ...pointStyles?.paint, 'circle-color': '#E34234' },
    },
    geometry_type: 'Point',
  },
  'Province Capital': {
    style: {
      ...pointStyles,
      paint: { ...pointStyles?.paint, 'circle-color': '#4F7942' },
    },
    geometry_type: 'Point',
  },
  'Government Offices': {
    style: {
      ...pointStyles,
      paint: { ...pointStyles?.paint, 'circle-color': '#41C570' },
    },
    geometry_type: 'Point',
  },
};

export const mappedStatus: Record<string, any> = {
  Ongoing: 'info',
  Pipeline: 'pending',
  Completed: 'success',
};

export const budgetExpenditureChartHeader = [
  { label: 'Expenditure Type' },
  { label: 'Budget Type' },
];

export const mapBudgetExpenditureColors: Record<string, string> = {
  total_rdel_expenditure: '#D64C63',
  total_cdel_expenditure: '#41588F',
  total_rdel_icf_expenditure: '#41C570',
  total_cdel_icf_expenditure: '#719ED7',
  total_on_budget_on_treasury: '#D64C63',
  total_on_budget_off_treasury: '#41588F',
  total_off_budget_on_treasury: '#41C570',
  total_off_budget_off_treasury: '#719ED7',
};

export const mapBudgetExpenditureTitle: Record<string, string> = {
  total_rdel_expenditure: 'RDEL',
  total_cdel_expenditure: 'CDEL',
  total_rdel_icf_expenditure: 'RDEL-ICF',
  total_cdel_icf_expenditure: 'CDEL-ICF',
  total_on_budget_on_treasury: 'On Budget On Treasury',
  total_on_budget_off_treasury: 'On Budget Off Treasury',
  total_off_budget_on_treasury: 'Off Budget On Treasury',
  total_off_budget_off_treasury: 'Off Budget Off Treasury',
};
