import { cn } from '@Utils/index';
import { HTMLAttributes } from 'react';

interface IErrorMessageProps extends HTMLAttributes<HTMLDivElement> {
  message?: string;
}
export default function ErrorLabel({
  message = '',
  className,
}: IErrorMessageProps) {
  return (
    <p
      className={cn(
        `naxatw-rounded-md naxatw-bg-red-400 naxatw-px-3 naxatw-py-2 naxatw-text-sm naxatw-font-normal naxatw-text-white`,
        className,
      )}
    >
      {message}
    </p>
  );
}
