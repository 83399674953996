import * as React from 'react';
import { CalendarIcon } from '@radix-ui/react-icons';
// import { format } from 'date-fns';
import { cn } from '@Utils/index';
import { Button } from '@Components/RadixComponents/Button';
import { Calendar } from '@Components/RadixComponents/Calendar';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@Components/RadixComponents/Popover';
import Icon from '@Components/common/Icon';

interface IDatePickerProps {
  date: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (date: Date) => void;
  disabledDays?: string;
  placeHolder?: string;
}

// eslint-disable-next-line import/prefer-default-export
export function DatePicker({
  date,
  onChange,
  disabledDays,
  placeHolder = 'Select Date',
}: IDatePickerProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="dropDown"
          size="drop-lg"
          className={cn(
            'naxatw-group naxatw-flex naxatw-w-full naxatw-items-center  naxatw-justify-between naxatw-gap-2 naxatw-font-normal naxatw-duration-200 hover:naxatw-border-secondary-500',
            !date && 'naxatw-text-muted-foreground',
          )}
        >
          <div className="naxatw-flex naxatw-items-center naxatw-justify-start">
            <CalendarIcon className="naxatw-mr-2 naxatw-h-4 naxatw-w-4 naxatw-duration-200 group-hover:naxatw-text-secondary-500" />
            {date || (
              <span className="naxatw-text-[#667085]">{placeHolder}</span>
            )}
          </div>
          <Icon
            name="expand_more"
            className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 group-hover:naxatw-text-secondary-500"
          />
          {/* {date ? format(date, 'PPP') : <span>Pick a date</span>} */}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="naxatw-max-w-[17.3rem] naxatw-bg-white naxatw-p-0"
        align="start"
      >
        <Calendar
          mode="single"
          selected={new Date(date)}
          defaultMonth={date || new Date()}
          disabled={{ before: new Date(`${disabledDays}`) }}
          // eslint-disable-next-line no-shadow
          onSelect={(date: Date) => {
            setIsOpen(false);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            // Pad the month and day with leading zeros if necessary and construct the formatted date string
            const formattedDateString = `${year}-${String(month).padStart(
              2,
              '0',
            )}-${String(day).padStart(2, '0')}`;
            if (onChange) {
              // @ts-ignore
              onChange(formattedDateString);
            }
          }}
          initialFocus
          className="naxatw-bg-white"
        />
      </PopoverContent>
    </Popover>
  );
}
