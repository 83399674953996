import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ActivityLogsContent from './ActivityLogsContent';

const ActivityLogs = () => {
  return (
    <div className="activity-logs-wrapper">
      <ActivityLogsContent />
    </div>
  );
};

export default hasErrorBoundary(ActivityLogs);
