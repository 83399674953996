import AdminHeader from '@Components/common/AdminHeader';
import { useSpring, animated } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import { useLocation, useNavigate } from 'react-router-dom';
import MapLayersForm from './MapLayersForm';
import MapLayersTable from './MapLayersTable';

const MapLayers = () => {
  const navigate = useNavigate();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <>
      <animated.div
        style={{ ...springs }}
        className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
      >
        <AdminHeader
          title="Map Layers"
          onAddNewClick={() => {
            navigate('/data-bank/map-layers/add');
          }}
          registerButtonText="Add"
        />
        <MapLayersTable />
        {toggleForm && (
          <Portal>
            <MapLayersForm onClose={() => navigate('/data-bank/map-layers')} />
          </Portal>
        )}
      </animated.div>
    </>
  );
};

export default MapLayers;
