/* eslint-disable react/no-array-index-key */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Button } from '@Components/RadixComponents/Button';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FieldValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FeedbackFormValidationSchema } from '@Validations/FeedbackForm';
import { feedbackFormInputFeilds } from '@Constants/FormConstants/feedbackForm';
import groupFormElements from '@Utils/index';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { getFeedbackTypeOptions, postFeedback } from '@Services/feedback';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';

type propType = {
  onClose: () => void;
};

const FeedbackForm = ({ onClose }: propType) => {
  const queryClient = useQueryClient();
  const initialFeedbackValues: FieldValues = {
    name: '',
    email: '',
    feedback_type: '',
    attachment: '',
    message: '',
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: initialFeedbackValues,
    resolver: zodResolver(FeedbackFormValidationSchema),
  });
  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
  };
  const groupedFormFeilds = groupFormElements(feedbackFormInputFeilds);
  const fileAttachment = watch('attachment');

  const {
    data: feedbackTypeDropdownOptions,
    isLoading: isDropdownOptionsLoading,
  } = useQuery({
    queryKey: ['feedbacktype-dropdown'],
    queryFn: () => getFeedbackTypeOptions(),
    select: res => {
      const dropdownOptions = res?.data?.map((option: any) => {
        return { id: option.name, label: option.name, value: option.id };
      });
      return dropdownOptions;
    },
  });
  const { mutate: feedbackMutation } = useMutation({
    mutationFn: (payload: Record<string, any>) => postFeedback(payload),
    onSuccess: () => {
      toast.success('Feedback submitted successfully');
      queryClient.invalidateQueries({ queryKey: ['feedback-tabledata'] });
      onClose();
    },
    onError: (error: Record<string, any>) => {
      const errorMessage = error?.response?.data?.message;
      toast.error(
        errorMessage || 'Could not submit feedback. Please try again.',
      );
    },
  });
  function handleFeedbackSubmission(feedbackFormData: Record<string, any>) {
    const { name } = feedbackFormData;
    const binaryAttachment = fileAttachment?.[0]?.file;
    feedbackMutation({
      name: name || 'Anynomous',
      ...feedbackFormData,
      attachment: binaryAttachment,
    });
  }

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb heading="Add Feedback" overlayStatus={() => onClose()} />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>

      {isDropdownOptionsLoading ? (
        <div className="naxatw-mx-auto naxatw-w-[80%]">
          <FormSkeleton numRows={5} className="naxatw-w-full" />
        </div>
      ) : (
        <form
          className="scrollbar naxatw-relative naxatw-flex naxatw-h-[35rem] naxatw-w-full naxatw-flex-col naxatw-overflow-auto naxatw-px-6"
          onSubmit={handleSubmit(handleFeedbackSubmission)}
        >
          <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-5 naxatw-pt-5">
            {groupedFormFeilds?.map((fieldRow: Record<string, any>, i) => (
              <FlexRow key={i}>
                {fieldRow?.map((field: FormFieldProps) => {
                  const { id } = field;
                  return (
                    <FormControl
                      className="form-control naxatw-w-full naxatw-gap-1  "
                      key={`${field.id}- ${field.name}`}
                    >
                      <InputLabel
                        label={field.label}
                        astric={field.required}
                        id={field.id}
                      />
                      {getInputElement(
                        {
                          ...field,
                          id: `${field.id}`,
                        },
                        /* @ts-ignore */
                        formProps,
                        feedbackTypeDropdownOptions || [],
                      )}
                      <>
                        {
                          /* @ts-ignore */
                          formProps.errors[id] && (
                            <ErrorMessage
                              /* @ts-ignore */
                              message={formProps.errors[id]?.message}
                            />
                          )
                        }
                      </>
                    </FormControl>
                  );
                })}
              </FlexRow>
            ))}
          </FlexColumn>
          <div className="naxatw-px-6 naxatw-py-5 naxatw-text-center">
            <Button
              size="normal"
              type="submit"
              variant="primary"
              className="naxatw-w-fit naxatw-px-4"
              onClick={() => {}}
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default hasErrorBoundary(FeedbackForm);
