/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  comparisonByProvinceOptions,
  contextualIndicatorsOptions,
} from '@Constants/exploreByIndicators';
import { setFetchData } from '@Store/actions/portfolioProfiles';
import { useQuery } from '@tanstack/react-query';
import { getComparisonData } from '@Services/portfolioProfile';
import isEmpty from '@Utils/isEmpty';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useEffect, useState } from 'react';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import IndicatorsChartComponent from './ComparisonChart';

const ComparisonByContextualIndicators = () => {
  const dispatch = useTypedDispatch();
  const {
    selectedProvinceOptions,
    selectedComparisonIndicatorsOptions,
    fetchData,
    compareBy,
    selectedMunicipalityData,
  } = useTypedSelector(state => state.portfolioProfiles);

  // const [indicatorsValue, setIndicatorsValue] = useState<string[]>([]);

  const { data: comparisonData, isLoading: comparisonDataIsLoading } = useQuery(
    {
      queryKey: [
        'compare-contextual-indicator',
        selectedComparisonIndicatorsOptions,
        compareBy,
        selectedProvinceOptions,
        selectedMunicipalityData,
      ],
      queryFn: async () => {
        // setComparisonDataIsLoading(true); // Set loading to true before fetching
        const result = await getComparisonData({
          indicator: selectedComparisonIndicatorsOptions
            .map(indicator => indicator.value)
            .join(','),
          by: compareBy,
          ...(compareBy === 'Province'
            ? {
                province: selectedProvinceOptions
                  .map(province => province.id)
                  .join(','),
              }
            : {
                municipality: selectedMunicipalityData
                  .map(municipality => municipality.id)
                  .join(','),
              }),
        });

        // setComparisonDataIsLoading(false); // Set loading to false after fetching
        return result;
      },
      onSuccess: () => {
        dispatch(setFetchData());
      },
      select: res => res.data,
      enabled:
        compareBy === 'Province'
          ? fetchData && selectedProvinceOptions?.length > 0
          : fetchData && selectedMunicipalityData?.length > 0,
    },
  );

  function getComparisonChart(value: any) {
    switch (value) {
      case 'Province':
        return (
          <IndicatorsChartComponent
            comparisonData={comparisonData}
            selectedOptions={selectedProvinceOptions}
          />
        );
      case 'Municipality':
        return (
          <IndicatorsChartComponent
            comparisonData={comparisonData}
            selectedOptions={selectedMunicipalityData}
          />
        );
      default:
        return null;
    }
  }

  return (
    <>
      {comparisonDataIsLoading && fetchData ? (
        <Skeleton className="naxatw-h-[15rem] naxatw-w-11/12" />
      ) : (
        !isEmpty(comparisonData) && (
          // <>
          //   <div className="naxatw-flex naxatw-w-11/12 naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-py-5 naxatw-shadow-sm">
          //     <div className="naxatw-flex naxatw-min-w-[25%] naxatw-flex-col naxatw-gap-3">
          //       <div className="naxatw-h-[4.5rem] naxatw-w-full naxatw-border-b naxatw-border-primary-200" />
          //       {(compareBy === 'Province'
          //         ? selectedProvinceOptions
          //         : selectedMunicipalityData
          //       )?.map((province: any, provinceIndex: any) => (
          //         <div
          //           className={`naxatw-h-[4rem] naxatw-w-full naxatw-border-primary-200 ${provinceIndex !== (compareBy === 'Province' ? selectedProvinceOptions.length - 1 : selectedMunicipalityData.length - 1) && 'naxatw-border-b'}`}
          //           key={province.id}
          //         >
          //           <p className="naxatw-p-[0.62rem] naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-normal naxatw-tracking-[-0.0175rem] naxatw-text-matt-200">
          //             {compareBy === 'Municipality'
          //               ? province.label
          //               : `${province.value} Province`}
          //             {/* {province.value} Province */}
          //           </p>
          //         </div>
          //       ))}
          //     </div>
          //     <div className="scrollbar naxatw-flex naxatw-w-[75%] naxatw-overflow-x-auto">
          //       {selectedComparisonIndicatorsOptions?.map(indicator => (
          //         <div
          //           className="naxatw-flex naxatw-min-w-[30%] naxatw-flex-col naxatw-gap-3 xl:naxatw-w-full xl:naxatw-min-w-[15%]"
          //           key={indicator}
          //         >
          //           <div className="naxatw-h-[4.5rem] naxatw-w-full naxatw-border-b naxatw-border-primary-200 naxatw-px-2 naxatw-py-3">
          //             <p className="naxatw-text-[0.75rem] naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
          //               {indicator.label}
          //             </p>
          //           </div>
          //           {compareBy === 'Province' &&
          //             selectedProvinceOptions?.map(
          //               (province, provinceIndex) => {
          //                 // gets the province id from the comparison data
          //                 const provinceId = comparisonData?.findIndex(
          //                   (data: any) => data.province__id === province.id,
          //                 );
          //                 // gets the values of the selected indicator
          //                 const values = comparisonData.map(
          //                   (value: any) => value[indicator.value],
          //                 );
          //                 // sorts the values in descending order
          //                 values.sort((a: number, b: number) => b - a);
          //                 const indicatorValue =
          //                   provinceId !== -1 &&
          //                   comparisonData[provinceId][indicator.value];

          //                 return (
          //                   <div
          //                     className={`naxatw-h-[4rem] naxatw-w-full ${provinceIndex !== selectedProvinceOptions.length - 1 && 'naxatw-border-b'} naxatw-border-primary-200 naxatw-px-2`}
          //                     key={indicator}
          //                   >
          //                     <div
          //                       className={` naxatw-rounded-s-[0.25rem]
          //                  ${getBackgroundColor(provinceIndex)} naxatw-p-[0.62rem] naxatw-text-[0.75rem] naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-white`}
          //                       //  sets the width of the indicator value based on the values
          //                       style={{
          //                         width:
          //                           indicatorValue === values[0]
          //                             ? widthValue[0]
          //                             : indicatorValue === values[1]
          //                               ? widthValue[1]
          //                               : widthValue[2],
          //                       }}
          //                     >
          //                       {(provinceId !== -1 &&
          //                         comparisonData[provinceId][
          //                           indicator.value
          //                         ]) ||
          //                         '-'}
          //                     </div>
          //                   </div>
          //                 );
          //               },
          //             )}
          //           {compareBy === 'Municipality' &&
          //             selectedMunicipalityData?.map(
          //               (municipality: any, municipalityIndex: any) => {
          //                 // gets the province id from the comparison data
          //                 const municipalityId = comparisonData?.findIndex(
          //                   (data: any) =>
          //                     data.municipality__id === municipality.id,
          //                 );
          //                 // gets the values of the selected indicator
          //                 const values = comparisonData?.map(
          //                   (value: any) => value[indicator.value],
          //                 );
          //                 // sorts the values in descending order
          //                 values.sort((a: number, b: number) => b - a);
          //                 const indicatorValue =
          //                   municipalityId !== -1 &&
          //                   comparisonData[municipalityId][indicator.value];

          //                 return (
          //                   <div
          //                     className={`naxatw-h-[4rem] naxatw-w-full ${municipalityIndex !== selectedMunicipalityData.length - 1 && 'naxatw-border-b'} naxatw-border-primary-200 naxatw-px-2`}
          //                     key={indicator}
          //                   >
          //                     <div
          //                       className={` naxatw-rounded-s-[0.25rem]
          //                  ${getBackgroundColor(municipalityIndex)} naxatw-p-[0.62rem] naxatw-text-[0.75rem] naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-white`}
          //                       //  sets the width of the indicator value based on the values
          //                       style={{
          //                         width:
          //                           indicatorValue === values[0]
          //                             ? widthValue[0]
          //                             : indicatorValue === values[1]
          //                               ? widthValue[1]
          //                               : widthValue[2],
          //                       }}
          //                     >
          //                       {(municipalityId !== -1 &&
          //                         comparisonData[municipalityIndex][
          //                           indicator.value
          //                         ]) ||
          //                         '-'}
          //                     </div>
          //                   </div>
          //                 );
          //               },
          //             )}
          //         </div>
          //       ))}
          //     </div>
          //   </div>
          // </>
          // <div className="naxatw-flex naxatw-w-11/12 naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-py-5 naxatw-shadow-sm">
          <>{getComparisonChart(compareBy)}</>
          // </div>
        )
      )}
    </>
  );
};

export default hasErrorBoundary(ComparisonByContextualIndicators);
