/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';
import {
  choroplethColorRange,
  pointStyles,
  polygonStyles,
  roadStyles,
} from '@Constants/map';
import { PieChartDataItem } from '@Components/common/Charts/types';

/**
 *
 * @param obj
 * @returns
 */
export function hasBinaryData(obj: Record<string, any>): boolean {
  if (typeof obj !== 'object') {
    return false;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (
        value instanceof Blob ||
        value instanceof File ||
        value instanceof ArrayBuffer
      ) {
        return true;
      }
      if (typeof value === 'object' && hasBinaryData(value)) {
        return true;
      }
    }
  }

  return false;
}

/**
 *
 * @param obj
 * @returns
 */
export function convertJsonToFormData(obj: Record<string, any>): FormData {
  const formData = new FormData();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value[i]);
        }
      } else {
        formData.append(key, value);
      }
    }
  }
  return formData;
}

/**
 *
 * @param obj1
 * @param obj2
 * @returns
 */

export function objectsAreEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 == null || obj2 == null) {
    return false;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (const key of obj1Keys) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    const value1 = obj1[key];
    const value2 = obj2[key];
    if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const exceptionWords = ['of', 'for', 'the'];
export function getInitialsFromString(stringName: string) {
  const words = stringName?.split(' ');
  const initialsArray = words
    .filter(word => !exceptionWords.includes(word))
    .map(word => word[0]);
  return initialsArray.join('');
}

export default function groupFormElements(data: Record<string, any>) {
  const result: Record<string, any>[][] = [];
  const groupIndices: Record<string, any> = {};
  data?.forEach((item: Record<string, any>) => {
    if (item?.group) {
      if (!(item?.group in groupIndices)) {
        // If group does not exist, add it and track its index in result
        result.push([item]);
        groupIndices[item?.group] = result.length - 1; // Store the index of the group
      } else {
        // Group exists, push item into the existing group
        result[groupIndices[item?.group]].push(item);
      }
    } else {
      // No group, each item in its own array
      result.push([item]);
    }
  });
  return result;
}

export function convertFileUrlToFileArray(url: Record<string, any>) {
  const fileArray = url.split('/');
  const fileName = fileArray[fileArray.length - 1];
  return [
    {
      id: uuidv4(),
      file: { name: fileName },
      previewURL: url,
    },
  ];
}

export function getFileNameFromURL(url: string) {
  const fileArray = url.split('/');
  const fileName = fileArray[fileArray.length - 1];
  return fileName;
}

// this function compare between two objects and return the keys of objects whose value are different.

export function compareObjects(objectBeforePatch: any, objectAfterPatch: any) {
  if (!objectBeforePatch || !objectAfterPatch) return {};
  const changedKeys: Record<string, any> = {};
  const allKeys = new Set([
    ...Object.keys(objectBeforePatch),
    ...Object.keys(objectAfterPatch),
  ]);
  allKeys.forEach(key => {
    if (objectBeforePatch[key] !== objectAfterPatch[key]) {
      changedKeys[key] = objectAfterPatch[key];
    }
  });
  return changedKeys;
}

export const convertStringToBoolean = (str: string) => {
  switch (str.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

export function getTruncateValue(value: string, letterLimit: number) {
  if (value?.length > letterLimit) {
    return `${value.slice(0, letterLimit)}...`;
  }
  return value;
}

// export function convertToCurrencySystem(labelValue: number) {
//   // Nine Zeroes for Billions
//   return Math.abs(Number(labelValue)) >= 1.0e9
//     ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(1)} B`
//     : // Six Zeroes for Millions
//     Math.abs(Number(labelValue)) >= 1.0e6
//     ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(1)} M`
//     : // Three Zeroes for Thousands
//     Math.abs(Number(labelValue)) >= 1.0e3
//     ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(1)} K`
//     : Math.abs(Number(labelValue));
// }

export function convertToCurrencySystem(labelValue: number) {
  const result = Math.abs(Number(labelValue));
  if (result >= 1.0e9) {
    // Nine Zeroes for Billions
    return `${(result / 1.0e9).toFixed(1)} B`;
  }
  if (result >= 1.0e6) {
    // Six Zeroes for Millions
    return `${(result / 1.0e6).toFixed(1)} M`;
  }
  if (result >= 1.0e3) {
    // Three Zeroes for Thousands
    return `${(result / 1.0e3).toFixed(1)} K`;
  }
  return result;
}

/**
 * This TypeScript function removes an object from an array at a specified index and returns a new
 * array.
 * @param {T[]} array - An array of elements of type T.
 * @param {number} index - The index parameter is a number that represents the position of the object
 * to be removed from the array. It should be a non-negative integer that is less than the length of
 * the array.
 * @returns a new array with the object at the specified index removed. If the index is out of bounds,
 * the function returns the original array.
 */
export function removeObjectAtIndex<T>(array: T[], index: number): T[] {
  if (index < 0 || index >= array.length) {
    // Index is out of bounds, return the original array
    return array;
  }

  // Create a new array with the object at the specified index removed
  const newArray = [...array.slice(0, index), ...array.slice(index + 1)];

  return newArray;
}

export const prepareDataForDropdown = (
  data: Record<string, any>[],
  labelKey: string,
) => {
  const updatedData = data?.map(datax => {
    return {
      id: datax.id,
      label: datax[labelKey],
      value: datax[labelKey],
    };
  });
  return updatedData;
};

export const generateYearList = (
  startyear: number,
): { id: string; name: string; value: string; label: string }[] => {
  const years = [];
  const startYear = startyear;
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= startYear; year--) {
    years.push({
      id: year.toString(),
      name: year.toString(),
      value: year.toString(),
      label: year.toString(),
    });
  }
  return years;
};

// function which return percentage for each element in array
export const calculatePercentage = (arr: []) => {
  const basePercentage = Math.floor(100 / arr.length);
  const percentages = arr.map((_, index) => {
    if (index === arr.length - 1) {
      return basePercentage + (100 - basePercentage * arr.length);
    }
    return basePercentage;
  });
  return percentages;
};

// return true if the sum of the array elements is 100
export const isSum100 = (obj: any) => {
  // Get all values from the object
  const values = Object.values(obj);

  // Use reduce to compute the sum
  const totalSum = values.reduce((accumulator: any, currentValue: any) => {
    // Only add numeric values
    return accumulator + (Number(currentValue) || 0);
  }, 0);

  // Return whether the total sum equals 100
  return totalSum === 100;
};

export function getValueById(
  nameList: Record<string, any>[],
  id: number,
  key: string = 'name',
) {
  return nameList.find(itm => itm.id === id)?.[key] || '';
}

export function getValueByKey(
  nameList: Record<string, any>[],
  matchingValue: string | number = '',
  matchingKey: string = 'id',
  key: string = 'name',
) {
  return (
    nameList?.find(itm => itm?.[matchingKey] === matchingValue)?.[key] ||
    matchingValue ||
    ''
  );
}

export function getMaxAndMinValues(arr: any[], key: string) {
  if (!arr || arr?.length === 0) return { min: 0, max: 0 };
  const values = arr?.map(itm => itm[key]);
  return { min: Math.min(...values), max: Math.max(...values) };
}

export function roundToNearest(value: number, nearest: number) {
  return Math.round(value / nearest) * nearest;
}

export function splitRangeIntoEqualRanges(
  min: number,
  max: number,
  numRanges: number,
) {
  if (!max || max === -Infinity || !numRanges) return [];
  let rangeSize = Math.ceil((max - min + 1) / numRanges);
  const ranges = [];
  if (max < 15) {
    for (let i = 0; i < numRanges; i++) {
      const rangeStart = min + i * 5;
      const rangeEnd = rangeStart + 5;
      ranges.push({ rangeStart, rangeEnd });
    }
    return ranges;
  }
  const range = Math.ceil(max / numRanges);
  if (range < 50 && range > 10) {
    rangeSize = roundToNearest(range, 20);
  }
  if (range < 75 && range > 50) {
    rangeSize = roundToNearest(range, 50);
  }
  if (range < 100 && range > 75) {
    rangeSize = roundToNearest(range, 100);
  }
  if (range > 100 && range < 300) {
    rangeSize = roundToNearest(range, 200);
  }
  if (range > 300 && range < 1000) {
    rangeSize = roundToNearest(range, 500);
  }
  if (range > 1000 && range < 10000) {
    rangeSize = roundToNearest(range, 500);
  }
  if (range > 10000 && range < 100000) {
    rangeSize = roundToNearest(range, 5000);
  }
  for (let i = 0; i < numRanges; i++) {
    const rangeStart = min + i * rangeSize;
    const rangeEnd = rangeStart + rangeSize;
    ranges.push({ rangeStart, rangeEnd });
  }
  return ranges;
}

export const getLegendValues = (
  rangeList: Record<string, any>,
  colorCodes: any[],
) => {
  return rangeList?.map((itm: Record<string, any>, index: number) => ({
    min: Number(itm?.rangeStart || 0),
    max: Number(itm?.rangeEnd || 0),
    color: colorCodes[index],
  }));
};

export function debounce(callback: any, wait: number) {
  let timeoutId: any = null;
  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args);
    }, wait);
  };
}

export const getColorRangesByValueList = (
  data: Record<string, any>[],
  keyOfValue: string,
  colorRange: string[] = choroplethColorRange,
) => {
  const { max } = getMaxAndMinValues(data, keyOfValue);
  // const nearestValue = findAboveNearestMultipleOfFiveAndGivenNumber(max, 5);
  const ranges = splitRangeIntoEqualRanges(0, max, 5);
  const legendValues = getLegendValues(ranges, colorRange);
  return legendValues;
};

export const getColorCodeByValue = (
  ranges: Record<string, any>[],
  value: any,
) => {
  if (ranges[ranges.length - 1].max < value) {
    return ranges[ranges.length - 1]?.color;
  }
  const range = ranges?.find(
    (itm: any) => itm.min <= value && itm.max >= value,
  );
  return range?.color;
};

export const getExpression = (data: Record<string, any>[], dataKey: string) => {
  const colorRanges = getColorRangesByValueList(data, dataKey);
  const fillColorExpression: any = [];
  data?.forEach((item: any) => {
    const valueId = item.id;
    const value = item?.[dataKey];
    const expression = [
      ['==', ['get', 'id'], valueId],
      getColorCodeByValue(colorRanges, value),
    ];
    fillColorExpression.push(...expression);
  });
  // this is for default color and for when we get no data
  // eslint-disable-next-line no-unused-expressions
  fillColorExpression.length === 0
    ? fillColorExpression.push(['==', ['get', 'id'], 0], '#fff', '#ffffff')
    : fillColorExpression.push('#ffffff');

  return { expression: ['case', ...fillColorExpression], colorRanges };
};

export const getTypeWiseExpression = (
  data: Record<string, any>[],
  type: string,
) => {
  return getExpression(data, type);
};
export function splitArrayByKey(
  arr: Record<string, any>[],
  key: string,
): [Record<string, any>[], Record<string, any>[]] {
  const withKeyId: Record<string, any>[] = [];
  const withoutKeyId: Record<string, any>[] = [];

  for (const item of arr) {
    if (String(item.id)?.includes(key)) {
      withKeyId.push(item);
    } else {
      withoutKeyId.push(item);
    }
  }

  return [withKeyId, withoutKeyId];
}

/**
 * This TypeScript function returns the object with the highest value property from an array of
 * DataItem objects.
 * @param {DataItem[]} data - An array of objects of type `DataItem`. Each `DataItem` object has a
 * `value` property that is a number.
 * @returns a single `DataItem` object that has the maximum `value` property among all the objects in
 * the `data` array. If the `data` array is empty, the function returns `null`.
 */
export function getObjectWithMaxValue(
  data: PieChartDataItem[],
): PieChartDataItem | null {
  if (data.length === 0) {
    return null;
  }

  let maxObject: PieChartDataItem = data[0];

  for (let i = 1; i < data.length; i++) {
    if (data[i].value > maxObject.value) {
      maxObject = data[i];
    }
  }
  return maxObject;
}

export function removeEmptyFields(obj: Record<string, any>) {
  // Create a new object to store the cleaned properties
  const cleanedObj: any = {};

  // Iterate over the object's entries
  for (const [key, value] of Object.entries(obj)) {
    // Check if the value is not null, undefined, or an empty string
    if (value !== null && value !== undefined && value !== '') {
      cleanedObj[key] = value;
    }
  }

  return cleanedObj;
}

export const removeNullUndefinedFields = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value != null && value !== ''),
  );

export function checkExceed(
  totalAmount: any,
  value1: any,
  value2: any,
  value3: any,
  value4: any,
): boolean {
  const toNumber = (value: any) => {
    const number = Number(value);
    // eslint-disable-next-line no-restricted-globals
    return isNaN(number) || value === '' ? 0 : number;
  };

  const values = [value1, value2, value3, value4]
    .map(value => toNumber(value))
    .reduce((sum, current) => sum + current, 0); // Sum up all values

  const total = toNumber(totalAmount);

  return total < values;
}

export const replaceUnderscores = (key: string) => {
  return (
    key
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\bprogram\b/g, 'programmes') // Replace "program" with "programmes"
      .replace(/\bcomponent\b/g, 'projects') // Replace "component" with "projects"
      .replace(/\bsector\b/g, 'sectors') // Replace "component" with "programme"
      // .replace(/\bpartner\b/g, 'partner')
      .replace(/\bstakeholder\b/g, 'stakeholders') // Replace "component" with "programme"
      .replace(/\b\w/g, char => char.toUpperCase())
  );
};

// Function to format numbers with commas for display
export const formatNumber = (value: any) => {
  if (value === undefined || value === null || value === '') return '';
  const num = parseFloat(value?.toString().replace(/,/g, ''));
  if (Number.isNaN(num)) return '';
  return num.toLocaleString('en-US');
};

// remove commas from number
export const parseFormattedNumber = (value: any): number => {
  if (typeof value !== 'string') return value;

  // Remove commas and attempt to convert to a number
  const numericValue = parseFloat(value.replace(/,/g, ''));

  // Return 0 if the conversion results in NaN
  return Number.isNaN(numericValue) ? 0 : numericValue;
};

export const getStyleByGeomType = (geometryType: string, defaultStyle: any) => {
  return geometryType === 'LineString'
    ? {
        ...roadStyles,
        paint: {
          ...roadStyles?.paint,
          'line-color': defaultStyle?.['line-color'] || `#0664D2`,
        },
        layout: {
          'text-field': ['step', ['zoom'], '', 7, ['get', 'name']], // show empty string by default if the zoom level is greater or equal to 7 show name received on properties
        },
      }
    : geometryType === 'Point'
      ? {
          ...pointStyles,
          paint: {
            ...pointStyles?.paint,
            'circle-color': defaultStyle?.['circle-color'] || `#0664D2`,
          },
          layout: {
            'text-field': ['step', ['zoom'], '', 7, ['get', 'name']],
          },
        }
      : geometryType === 'Polygon'
        ? {
            ...polygonStyles,
            paint: {
              ...polygonStyles?.paint,
              'fill-color': defaultStyle?.['fill-color'] || `#0664D2`,
            },
            layout: {
              'text-field': ['step', ['zoom'], '', 7, ['get', 'name']],
            },
          }
        : { ...roadStyles };
};

export function generatePermissionTemplateArray(key: string) {
  const permissions = ['add', 'change', 'delete', 'view'];
  return permissions.map(permission => `Can ${permission} ${key}`);
}

export function checkKeyInPermssion(permissionList: string[], key: string) {
  if (!permissionList) return false;

  const permissionArray = generatePermissionTemplateArray(key);
  return permissionArray.some(
    permission => permissionList.indexOf(permission) !== -1,
  );
}

/**
 * This function checks if all the arraylistItem in the array are of type number or not.
 * @param {arrList[]} array - An array of any items
 * @returns returns true if all the arraylistItems matches the condition or else false.
 */

export function isTypeNumber(arrList: any) {
  return arrList.every((list: any) => typeof list === 'number');
}

export function getDisplayedRowCount(data: any): number {
  let allData: any[] = [];
  data?.forEach((element: Record<string, any>) => {
    allData = [...(element?.results || []), ...allData];
  });
  return allData.length;
}
