import ProgrammeDetails from '@Components/ProgrammeDetails';

const ProgrammeDetailsPage = () => {
  return (
    <div className="naxatw-w-full naxatw-overflow-x-hidden naxatw-bg-white naxatw-pt-4">
      <ProgrammeDetails />
    </div>
  );
};

export default ProgrammeDetailsPage;
