import CheckBoxSkeleton from '@Components/common/FormComponent/CheckBoxSkeleton';
import { FormControl } from '@Components/common/FormUI';
import { FlexColumn } from '@Components/common/Layouts';
import Checkbox from '@Components/common/FormUI/CheckBox';
import { getIndicatorList } from '@Services/components';
import { useQuery } from '@tanstack/react-query';
import { ComponentFormValidationSchemaProps } from '@Validations/ComponentForm';
import { Controller, useFormContext } from 'react-hook-form';

const Indicator = () => {
  const { control } = useFormContext<ComponentFormValidationSchemaProps>();

  const { data: indicatorsList, isFetching } = useQuery({
    queryKey: ['indicators'],
    queryFn: () => getIndicatorList(),
    select: list =>
      list?.data?.map((option: Record<string, any>) => ({
        id: option.id,
        label: option.indicator,
        value: option.id,
      })),
  });

  return (
    <FlexColumn className="naxatw-w-full naxatw-space-y-5 ">
      {isFetching ? (
        <CheckBoxSkeleton />
      ) : (
        <>
          <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
            {indicatorsList?.map((list: Record<string, any>) => {
              return (
                <Controller
                  key={list.index}
                  control={control}
                  name={`indicators.${list.id}`}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      value={value}
                      onChange={onChange}
                      label={list.label}
                      id={list.id}
                      checked={value}
                    />
                  )}
                />
              );
            })}
          </FormControl>
        </>
      )}
    </FlexColumn>
  );
};

export default Indicator;
