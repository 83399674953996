import LoginComponent from '@Components/Login';
// import { useTypedSelector } from '@Store/hooks';
// import Curtain from '@Components/Curtain';
// import { motion } from 'framer-motion';

const Login = () => {
  // const openCurtain = useTypedSelector(state => state.curtain.openCurtain);

  return (
    <>
      <div className="naxatw-overflow-hidden naxatw-p-0 lg:naxatw-h-[100vh]">
        {/* {openCurtain ? (
          <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center">
            <Curtain />
          </div>
        ) : ( */}
        <LoginComponent />
        {/* )} */}
      </div>
    </>
  );
};

export default Login;
