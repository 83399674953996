export default function findHighestCount(data: any): number {
  let highest = 0;
  data.forEach((item: any) => {
    const newCount = item.count || 0;
    if (Number(newCount) > highest) {
      highest = Number(newCount);
    }
  });
  return highest;
}
