/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { HtmlHTMLAttributes, useRef } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import Modal from '@Components/common/Modal';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn } from '@Components/common/Layouts';
import getChartOfType from '../utils/getChartOfType';
import getChartFillOfType from '../utils/getChartFillOfType';
import { IChartHeaderProps } from '../ChartHeader';
import { ChartTypes, ILegendProps } from '../types';
import { ChartFills } from '../constants';
import NoChartDataComponent from '../NoChartDataComponent';
import { IResankyChartProps } from '../SankeyChart';

interface ICustomChartContainerProps<T>
  extends HtmlHTMLAttributes<HTMLDivElement>,
    Omit<IChartHeaderProps, 'downloadComponentRef'> {
  chartTitle: string;
  type: ChartTypes;
  scrollable?: boolean;
  fillWithType?: boolean;
  showFullScreen: boolean;
  onClose: () => void;
  sankeyChartData?: IResankyChartProps;
  hoverTitle?: string;
}

function FullChart<T>({
  legend,
  xLabel,
  yLabel,
  data,
  type,
  chartTitle,
  scrollable = false,
  fillWithType = false,
  fill,
  showFullScreen,
  onClose,
  sankeyChartData = {
    nodeColors: [],
  },
  chartId,
  showPound,
  hoverTitle,
}: ICustomChartContainerProps<T>) {
  const componentRef = useRef(null);
  const chart = getChartOfType(type);
  const fills = fill || (fillWithType ? getChartFillOfType(type) : ChartFills);

  return (
    <Portal>
      <Modal
        show={showFullScreen}
        className="naxatw-h-[85vh] naxatw-w-[80vw] naxatw-max-w-[60rem]"
        modalWrapperStyles="naxatw-h-[calc(100%-76px)]"
        subtitle=""
        title=""
        onClose={onClose}
      >
        <FlexColumn className="naxatw-h-full naxatw-w-full">
          <h1 className="naxatw-absolute naxatw-top-6 naxatw-text-xl naxatw-font-bold naxatw-leading-6">
            {chartTitle}
          </h1>
          {data?.length === 0 ? (
            <div className="naxatw-col-span-12 naxatw-h-fit">
              <NoChartDataComponent />
            </div>
          ) : (
            <>
              {yLabel && !(type === 'donut') ? (
                <div
                  className={`y-label naxatw-absolute ${
                    type === 'verticalBar'
                      ? 'naxatw-left-[0.3rem]'
                      : 'naxatw-left-8'
                  } naxatw-top-0 naxatw-col-span-1 naxatw-flex naxatw-h-full naxatw-w-12 naxatw-items-center naxatw-justify-end`}
                >
                  <p className="naxatw-origin-center -naxatw-rotate-90 naxatw-whitespace-nowrap naxatw-text-xs naxatw-font-medium naxatw-text-matt-100">
                    {yLabel}
                  </p>
                </div>
              ) : null}
              <div
                className={`card naxatw-h-full   ${
                  // eslint-disable-next-line no-nested-ternary
                  type === 'donut'
                    ? 'naxatw-col-span-12 naxatw-flex  naxatw-h-full naxatw-items-center sm:naxatw-col-span-6 lg:naxatw-col-span-6'
                    : yLabel
                      ? 'naxatw-col-span-12'
                      : 'naxatw-col-span-12 naxatw-h-full naxatw-overflow-y-hidden'
                } ${
                  scrollable ? 'scrollbar naxatw-overflow-auto' : ''
                } ${type === 'bar' ? 'naxatw-px-8' : 'naxatw-px-3'} `}
              >
                {chart &&
                  chart({
                    data,
                    fills,
                    scrollable,
                    sankeyChartData,
                    chartId,
                    showPound,
                    hoverTitle,
                    showFullScreen,
                  })}
              </div>
              {xLabel && !(type === 'donut') ? (
                <div className="x-label naxatw-col-span-12 naxatw-mt-4 naxatw-flex naxatw-items-center naxatw-justify-center naxatw-pb-3">
                  <p className="naxatw-mr-2 naxatw-text-[12px] naxatw-font-[500] naxatw-text-[#475467]">
                    {xLabel}
                  </p>
                </div>
              ) : null}
              {legend && (
                <div
                  className={`legend ${
                    type === 'donut'
                      ? 'naxatw-col-span-12 naxatw-flex naxatw-items-center naxatw-justify-start  sm:naxatw-col-span-6 lg:naxatw-col-span-6'
                      : 'naxatw-col-span-11 naxatw-col-start-1 naxatw-col-end-13 naxatw-mb-5'
                  } `}
                >
                  {legend({
                    data,
                    type,
                    fills,
                    chartId,
                    showPound,
                  })}
                </div>
              )}
            </>
          )}
        </FlexColumn>
      </Modal>
    </Portal>
  );
}

export default hasErrorBoundary(FullChart);
