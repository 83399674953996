/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const SignupFormValidationSchema = z
  .object({
    first_name: z.string().min(1, 'Name is required'),
    last_name: z.string().min(1, 'Name is required'),
    email: z.string().email('Invalid email address'),
    username: z
      .string()
      .min(5, { message: 'Min 5 characters' })
      .max(20, { message: 'Max 20 characters' }),

    // role: z.number().min(1, 'Role is Required'),
    password: z
      .string()
      .min(8, 'Min 8 characters required')
      .regex(/[a-zA-Z]/, 'At least one alphabet required')
      .regex(/[0-9]/, 'At least one Number required')
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        'At least one Special character required',
      ),
    confirmPassword: z.string().min(1, 'Confirm Password Required'),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });
