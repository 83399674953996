import React from 'react';

type addMoreButtonType = {
  onClick: () => void;
  btnText: string;
  textClassname?: string;
};

const AddMoreButton = ({
  onClick,
  btnText,
  textClassname,
}: addMoreButtonType) => {
  return (
    <button
      className="naxatw-group naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-gap-1 naxatw-rounded-lg naxatw-border-[1px] naxatw-border-dashed naxatw-border-[#D0D5DD] naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-font-medium naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-border-secondary-500 hover:naxatw-bg-primary-100"
      type="button"
      tabIndex={0}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      <p
        className={`naxatw-text-[0.875rem] group-hover:naxatw-text-matt-100 ${textClassname}`}
      >
        + {btnText}
      </p>
    </button>
  );
};

export default AddMoreButton;
