import { api, authenticated } from '.';

export const getFeedbackList = () => {
  return authenticated(api).get('/feedback/');
};
export const postFeedback = (payload: Record<string, any>) => {
  return authenticated(api).post('/feedback/', payload);
};
export const getFeedbackTypeOptions = () => {
  return authenticated(api).get('/choice/?ordering=name&type=feedback_type');
};
