/* eslint-disable no-nested-ternary */
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { v4 as uuidv4 } from 'uuid';
import {
  getProvincialPrioritiesGoalsData,
  getProvincialPrioritiesHeaderData,
} from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import './index.css';
import isEmpty from '@Utils/isEmpty';

const ProvincialProritiesTable = () => {
  const { provinceId } = useParams();
  const {
    data: provincialPrioritiesHeaderData,
    isLoading: provincialPrioritiesHeaderDataIsLoading,
  } = useQuery({
    queryKey: ['provincial-priorities-header-data'],
    queryFn: async () =>
      getProvincialPrioritiesHeaderData({
        province: provinceId,
      }),
    select: res => res.data,
  });
  const {
    data: provincialPrioritiesGoalsData,
    isLoading: provincialPrioritiesGoalsDataIsLoading,
  } = useQuery({
    queryKey: ['provincial-priorities-goals-data'],
    queryFn: async () =>
      getProvincialPrioritiesGoalsData({
        province: provinceId,
      }),
    select: res => res.data,
  });

  const headerIdArray = provincialPrioritiesHeaderData?.map(
    (objId: any) => objId.id,
  );

  return (
    <>
      {provincialPrioritiesHeaderDataIsLoading ||
      provincialPrioritiesGoalsDataIsLoading ? (
        <Skeleton className="naxatw-h-[15rem] naxatw-w-full" />
      ) : !isEmpty(provincialPrioritiesHeaderData) ||
        !isEmpty(provincialPrioritiesGoalsData) ? (
        <div className="naxatw-w-full">
          <div className="naxatw-flex">
            <div className="naxatw-flex naxatw-w-[40%] naxatw-flex-grow naxatw-flex-col">
              <div className="naxatw-flex naxatw-h-[4rem] naxatw-w-full naxatw-min-w-[15rem] naxatw-items-center naxatw-self-stretch naxatw-border-[0.5px] naxatw-px-6 naxatw-py-5">
                <p className="naxatw-text-[1.125rem] naxatw-font-bold">
                  Provincial Priorities
                </p>
              </div>
              <div className="naxatw-flex naxatw-flex-col">
                {provincialPrioritiesGoalsData?.map((details: any) => {
                  return (
                    <div
                      className="naxatw-flex naxatw-min-h-[5rem] naxatw-w-full naxatw-min-w-[15rem] naxatw-items-center naxatw-self-stretch naxatw-border-[0.5px] naxatw-px-6 naxatw-py-5"
                      key={details.id}
                    >
                      <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5">
                        {details.goal}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="scrollbar naxatw-flex naxatw-flex-col naxatw-overflow-x-auto">
              <div className="naxatw-flex">
                {provincialPrioritiesHeaderData?.map((header: any) => {
                  return (
                    <div
                      key={header.id}
                      className="naxatw-flex naxatw-h-[4rem] naxatw-w-[10%] naxatw-min-w-[5.5rem] naxatw-items-center naxatw-justify-center naxatw-border-[0.5px] naxatw-p-[0.625rem]"
                    >
                      <p className="naxatw-text-[0.75rem] naxatw-font-bold">
                        {header.abbreviation}
                      </p>
                    </div>
                  );
                })}
              </div>
              {provincialPrioritiesGoalsData?.map((details: any) => {
                return (
                  <div className="naxatw-flex" key={details.id}>
                    {headerIdArray.map((checkedId: any) => {
                      return (
                        <div
                          className={`naxatw-flex naxatw-min-h-[5rem] naxatw-w-[10%] naxatw-min-w-[5.5rem] naxatw-items-center naxatw-justify-center naxatw-border-[0.5px] naxatw-p-[0.625rem] 
                            ${
                              details.program_ids.includes(checkedId)
                                ? 'naxatw-bg-[#E8F7F6]'
                                : 'naxatw-bg-transparent'
                            }`}
                          key={uuidv4()}
                        >
                          <Icon
                            type="material-icons"
                            name={
                              details.program_ids.includes(checkedId)
                                ? 'check_circle'
                                : ''
                            }
                            className="naxatw-text-[2.5rem] naxatw-text-[#45C1B9]"
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <NoChartDataComponent
          className="naxatw-py-8"
          message="Provincial Priorities are not yet available."
        />
      )}
    </>
  );
};
export default hasErrorBoundary(ProvincialProritiesTable);
