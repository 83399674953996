import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Store/hooks';
import { useSearchParams } from 'react-router-dom';
import Icon from '@Components/common/Icon';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Arrow,
} from '@radix-ui/react-tooltip';
import { clearFilterState } from '@Store/actions/dataBankForms';

const ReportFilterButton = () => {
  const dispatch = useDispatch();
  const [filterParams, setFilterParmas] = useSearchParams();
  const isRpeortFilterActive = filterParams.get('filter') === 'true';

  const filterFields = useTypedSelector(
    state => state.databankforms?.report?.filterParams,
  );

  return (
    <div className="report-filter-btn-container">
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger className="naxatw-w-full">
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              className="filter-btn naxatw-relative naxatw-cursor-pointer"
              onClick={() => {
                setFilterParmas({ filter: String(!isRpeortFilterActive) });
                if (isRpeortFilterActive) {
                  dispatch(clearFilterState({}));
                }
              }}
            >
              <div
                className={`inner naxatw-rounded-lg naxatw-border naxatw-border-solid naxatw-border-[#D0D5DD] ${isRpeortFilterActive ? 'naxatw-bg-secondary-500' : 'naxatw-bg-white'}  naxatw-p-2 naxatw-shadow-xs`}
              >
                <Icon
                  name="filter_alt"
                  className={`${isRpeortFilterActive ? 'naxatw-text-white' : 'naxatw-text-matt-100'}`}
                />
                {isRpeortFilterActive && (
                  <span className="naxatw-absolute naxatw-right-[-8px] naxatw-top-[-12px] naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-items-center naxatw-justify-center naxatw-rounded-[6.25rem] naxatw-border-[3px] naxatw-border-solid naxatw-border-[#EAECF0] naxatw-bg-secondary-500 naxatw-p-[0.0625rem] naxatw-text-xs naxatw-font-bold naxatw-leading-4 naxatw-text-white">
                    {filterFields
                      ? Object.values(filterFields).filter(
                          value =>
                            value &&
                            (Array.isArray(value) ? value.length : true),
                        ).length
                      : 0}
                  </span>
                )}
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent side="bottom" className="!naxatw-z-[99]">
            <div className="message naxatw-z-[99999999999] naxatw-rounded-sm naxatw-bg-secondary-500 naxatw-px-3 naxatw-py-1 naxatw-text-xs naxatw-font-medium naxatw-text-white">
              {isRpeortFilterActive ? 'Clear Filter' : 'Apply Filter'}
            </div>
            <Arrow
              className="TooltipArrow naxatw-rounded"
              style={{ fill: '#417EC9' }}
            />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default ReportFilterButton;
