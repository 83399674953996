/* eslint-disable camelcase */
import formatDate from '@Utils/formatDate';
import {
  getProgrammeProgressDataByProgrammeId,
  getProgrammeProgressDataByComponentId,
} from '@Services/provincialProfile';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';

const StatusBar = () => {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');
  const { data: programmeProgressData, isLoading: programmeProgressIsLoading } =
    useQuery({
      queryKey: ['programme-progress-data', programmeId],
      queryFn: async () =>
        getProgrammeProgressDataByProgrammeId({ program: programmeId }),
      // enabled: !!provinceId || !!searchedText,
      select: res => res.data,
    });
  // eslint-disable-next-line no-unused-vars
  const { data: componentProgressData, isLoading: componentProgressIsLoading } =
    useQuery({
      queryKey: ['programme-progress-data', programmeId, componentId],
      queryFn: async () =>
        getProgrammeProgressDataByComponentId({
          program: programmeId,
          project: componentId,
        }),
      enabled: !!componentId,
      select: res => res.data,
    });

  const { start_date, end_date, progress_percentage } = !componentId
    ? programmeProgressData || {}
    : componentProgressData || {};
  const formatStartDate = formatDate(start_date);
  const formatEndDate = formatDate(end_date);

  const todayDate = new Date().toISOString().split('T')[0];
  const todayFormattedDate = formatDate(todayDate);

  return (
    <>
      {programmeProgressIsLoading ? (
        <Skeleton className="naxatw-h-[10rem] naxatw-w-full" />
      ) : (
        <div className="naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-pt-7 naxatw-shadow-sm">
          <div className="naxatw-flex naxatw-justify-between naxatw-self-stretch naxatw-px-10 naxatw-py-3">
            <div className="naxatw naxatw-flex naxatw-flex-col naxatw-items-start naxatw-justify-center naxatw-gap-1">
              <p className="naxatw-text-[0.875rem] naxatw-text-matt-100">
                Start Date
              </p>
              <p className="naxatw-text-base naxatw-font-medium naxatw-leading-normal naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                {formatStartDate}
              </p>
            </div>

            <div className="naxatw-w-[85%] naxatw-p-[0.625rem] ">
              <div className="naxatw-relative naxatw-h-[1.25rem] naxatw-w-full naxatw-rounded-2xl naxatw-bg-[#EFEFEF]">
                <div
                  style={{
                    width: `${progress_percentage < 0 ? 0 : progress_percentage || 0}%`,
                  }}
                  className={`naxatw-relative naxatw-h-[1.25rem] ${progress_percentage === 100 ? 'naxatw-rounded-2xl' : 'naxatw-rounded-s-2xl'} naxatw-bg-[#08519C]`}
                >
                  <div className="naxatw-absolute naxatw-bottom-0 naxatw-right-0 naxatw-h-[3.188rem] naxatw-w-[1px] naxatw-bg-[#667085]" />
                </div>
                <div
                  className="naxatw-absolute naxatw-bottom-[2.125rem] naxatw-flex naxatw-w-full naxatw-items-center naxatw-gap-2 naxatw-border-red-500"
                  style={{
                    left: `${progress_percentage < 1 ? 0 : progress_percentage}%`,
                  }}
                >
                  <p className="naxatw-ml-1 naxatw-text-[0.875rem] naxatw-leading-[1.25rem] naxatw-text-matt-100">
                    {todayFormattedDate}
                  </p>
                  <p className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#08519C]">
                    {progress_percentage < 0 ? 0 : progress_percentage}%
                  </p>
                </div>
              </div>
            </div>
            <div className="naxatw naxatw-flex naxatw-flex-col naxatw-items-end naxatw-justify-center naxatw-gap-1">
              <p className="naxatw-text-[0.875rem] naxatw-text-matt-100">
                End Date
              </p>
              <p className="naxatw-text-base naxatw-font-medium naxatw-leading-normal naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                {formatEndDate}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StatusBar;
