/* eslint-disable no-nested-ternary */
import Skeleton from '@Components/RadixComponents/Skeleton';

interface ISearchListProps {
  isLoading?: Boolean;
  dataList?: any[];
  onDataClick?: any;
  dataKey?: string;
}

const SearchList = ({
  isLoading,
  dataList,
  onDataClick,
  dataKey = 'id',
}: ISearchListProps) => {
  return (
    <div className=" naxatw-absolute naxatw-top-12 naxatw-flex  naxatw-max-h-[22rem] naxatw-w-full naxatw-flex-col naxatw-overflow-y-scroll naxatw-bg-white naxatw-p-2 naxatw-py-1 naxatw-shadow-lg">
      {isLoading ? (
        <div className=" naxatw-flex naxatw-flex-col naxatw-gap-1 naxatw-border-b naxatw-p-2">
          <Skeleton className="naxatw-h-8 naxatw-w-full" />
          <Skeleton className="naxatw-h-8 naxatw-w-full" />
          <Skeleton className="naxatw-h-8 naxatw-w-full" />
        </div>
      ) : dataList?.length === 0 ? (
        <div className="naxatw-p-2 naxatw-text-center naxatw-text-sm naxatw-font-semibold naxatw-text-[#475467]">
          No data found
        </div>
      ) : (
        dataList?.map((data: any) => (
          <div
            role="presentation"
            className="naxatw-cursor-pointer naxatw-border-b naxatw-border-[#EAECF0] naxatw-p-2 naxatw-text-sm naxatw-font-semibold naxatw-text-[#475467] hover:naxatw-bg-gray-100"
            key={data?.[dataKey]}
            onClick={() => onDataClick(data)}
          >
            {data?.[dataKey]}
          </div>
        ))
      )}
    </div>
  );
};

export default SearchList;
