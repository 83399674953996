/* eslint-disable no-unused-vars */
import { useState } from 'react';
import SearchBar from '@Components/common/SearchBar';
import { useSpring, animated } from '@react-spring/web';
import { useQuery } from '@tanstack/react-query';
import { downloadTableData } from '@Services/provincialProfile';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import ExploreHeader from '../common/ExploreHeaders';
import ProgrammeTable from './Table';

const ExploreByProgramme = () => {
  const [isDownload, setIsDownload] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  // download table
  const { refetch } = useQuery({
    queryKey: ['programme-details-table-data'],
    queryFn: async () =>
      downloadTableData({
        is_export: true,
      }),
    enabled: isDownload,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `tableData.csv`;
      a.click();
    },
  });

  const [searchProgramme, setSearchProgramme] = useDebouncedInput({
    ms: 600,
    init: searchTerm,
    onChange: debouncedEvent => setSearchTerm(debouncedEvent.target.value),
  });

  return (
    <>
      <div className="naxatw-m-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-7">
        <div className="naxatw-mx-auto naxatw-w-11/12">
          <div className="naxatw-mb-5 naxatw-flex naxatw-w-full naxatw-flex-wrap naxatw-justify-between">
            <ExploreHeader />
            <div className="naxatw-flex naxatw-items-center naxatw-gap-3 min-[320px]:naxatw-my-4 min-[320px]:naxatw-w-full min-[992px]:naxatw-my-0 min-[992px]:naxatw-w-1/5">
              <SearchBar
                value={searchProgramme}
                onChange={setSearchProgramme}
              />

              {/* <ToolTip
                name="download"
                message="Bulk Download"
                className="naxatw-rounded-lg naxatw-border naxatw-border-[#D0D5DD] naxatw-bg-white naxatw-p-2 !naxatw-text-2xl naxatw-text-matt-100"
                messageStyle="naxatw-font-normal"
                iconClick={() => {
                  setIsDownload(true);
                  refetch();
                  setIsDownload(false);
                }}
              /> */}
            </div>
          </div>
          <animated.div style={{ ...springs }} className="naxatw-w-full">
            <div className="naxatw-w-full naxatw-justify-start">
              <ProgrammeTable
                searchTerm={searchProgramme}
                className="naxatw-w-[90rem] lg:naxatw-w-full"
              />
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default ExploreByProgramme;
