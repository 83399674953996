/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import PortalTemplate from '@Components/common/Layouts/Portal';
import Dropdown from '@Components/common/DropDown';
import Skeleton from '@Components/RadixComponents/Skeleton';
import {
  deleteWorkingArea,
  getDistrict,
  getGroupedDistrict,
  getGroupedMunicipality,
  getLevelOfIntervention,
  getMunicipality,
  getProvince,
  getWorkingAreas,
} from '@Services/portfolioReporting';
import { useMutation, useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import InputLabel from '@Components/common/InputLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import ErrorMessage from '@Components/common/ErrorMessage';
import { useParams } from 'react-router-dom';
import isEmpty from '@Utils/isEmpty';
import Checkbox from '@Components/common/FormUI/CheckBox';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { IDropDownData } from '@Constants/interface';
import Switch from '@Components/RadixComponents/Switch';
import { DatePicker } from '@Components/common/FormUI';
import { useTypedDispatch } from '@Store/hooks';
import { setWorkingAreasValidation } from '@Store/actions/componentFormValidation';
import GroupedComboBox from '@Components/common/GroupedComboBox';
// const workingAreasProvince = ['Madhesh', 'Lumbini', 'Karnali'];

const newFormValue = {
  id: 1,
  district__name: null,
  district: null,
  municipality__name: null,
  municipality: null,
  province__name: null,
  province: null,
  budget: undefined,
  area: null,
  postData: true,
  isDelete: false,
};

function generateLocationName(location: Record<string, any>) {
  if (location?.municipality__name) {
    return `${location.province__name} / ${location.district__name} / ${location.municipality__name}`;
  }
  return `${location.province__name}`;
}

const WorkingArea = () => {
  const {
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const { portfolioID } = useParams();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const todayDate = new Date().toISOString().split('T')[0].split('-').join('/');

  const dispatch = useTypedDispatch();

  // palika level
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [reverseSelection, setReverseSelection] = useState(false);

  const [provinceList, setProvinceList] = useState<IDropDownData[]>([]);
  const [municipalityList, setMunicipalityList] = useState<IDropDownData[]>([]);
  const [districtList, setDistrictList] = useState<IDropDownData[]>([]);
  const [groupedDistrictList, setGroupedDistrictList] = useState<
    IDropDownData[]
  >([]);
  const [groupedMunicipalityList, setGroupedMunicipalityList] = useState<
    IDropDownData[]
  >([]);

  const [selectedLocation, setSelectedLocation] = useState<Record<
    string,
    any
  > | null>(null);
  const [formData, setFormData] = useState<Record<string, any>[]>([]);

  const formValues: {
    interventionLevel: {
      'National Level': boolean | undefined;
      'Sub-National Level': boolean | undefined;
    };
    workingAreas: Record<string, any>[];
  } = watch('workingAreas');
  // update formvalues working area with local form data
  useEffect(() => {
    setValue('workingAreas.workingAreas', formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const {
    data: levelOfInterventionData,
    isLoading: levelOfInterventionLoading,
  } = useQuery({
    queryKey: ['level-of-intervention'],
    queryFn: () => getLevelOfIntervention(),
    select: res => {
      const response = res?.data;
      return response?.map((loi: Record<string, any>) => ({
        id: loi?.name,
        label: loi?.name,
        name: loi?.name,
      }));
    },
  });

  const { isLoading: isWorkingAreasLoading } = useQuery({
    enabled: !!levelOfInterventionData,
    queryKey: ['get-working-areas'],
    queryFn: () => getWorkingAreas({ project_data: portfolioID }),
    onSuccess: response => {
      const responseFormData = response.data.map(
        (area: Record<string, any>) => {
          return area.municipality || area.district || area.province
            ? {
                ...area,
                isActive: !area.end_date,
                area: 'Sub-National Level',
              }
            : {
                ...area,
                isActive: !area.end_date,
                area: 'National Level',
              };
        },
      );
      setFormData(responseFormData);

      const hasPalikaLvl = responseFormData.some(
        (area: Record<string, any>) => area.area === 'Sub-National Level',
      );
      const hasFederalLvl = responseFormData.some(
        (area: Record<string, any>) => area.area === 'National Level',
      );
      // const hasProvinceLvl = responseFormData.some(
      //   (area: Record<string, any>) => area.area === 'Province Level',
      // );
      if (hasPalikaLvl) {
        setValue(
          `workingAreas.interventionLevel.${['Sub-National Level']}`,
          true,
        );
      }
      if (hasFederalLvl) {
        setValue(`workingAreas.interventionLevel.${['National Level']}`, true);
      }
      // if (hasProvinceLvl) {
      //   setValue(`workingAreas.interventionLevel.${['Province Level']}`, true);
      // }
    },
  });

  const { isLoading: provinceListLoading } = useQuery({
    queryKey: ['province-list'],
    queryFn: () => getProvince(),
    select: response =>
      response?.data
        // ?.filter((provinceData: Record<string, any>) =>
        //   workingAreasProvince.includes(provinceData.name),
        // )
        .map((provincex: Record<string, any>) => ({
          ...provincex,
          label: provincex?.name,
        })),
    onSuccess: data => {
      setProvinceList(data);
    },
  });

  useQuery({
    queryKey: ['grouped-district-list', province],
    queryFn: () => getGroupedDistrict({ province }),
    // enabled: !!province,
    select: response => {
      const modifiedDistrictData = (response as any)?.data?.map(
        (districtx: Record<string, any>) => {
          const {
            district: destructuredDistrict,
            name,
            id,
            ...restData
          } = districtx;
          return {
            subCategories: destructuredDistrict,
            label: districtx.name, // Use the modified label
            id,
          };
        },
      );
      return modifiedDistrictData;
    },
    onSuccess: data => {
      setGroupedDistrictList(data);
    },
  });
  useQuery({
    queryKey: ['district-list', province],
    queryFn: () => getDistrict({ province }),
    // enabled: !!province,
    select: response =>
      response?.data?.map((districtx: Record<string, any>) => ({
        ...districtx,
        label: districtx?.name,
      })),
    onSuccess: data => {
      setDistrictList(data);
    },
  });

  useQuery({
    queryKey: ['municipality-list', district],
    queryFn: () => getMunicipality({ district }),
    // enabled: !!district,
    select: response =>
      response?.data?.map((municipalityx: Record<string, any>) => ({
        ...municipalityx,
        label: municipalityx?.name,
      })),
    onSuccess: data => {
      setMunicipalityList(data);
    },
  });

  useQuery({
    queryKey: ['grouped-municipality-list-data', district, province],
    queryFn: async () => getGroupedMunicipality({ province, district }),
    select: response => {
      const modifiedResponseData = (response as any)?.data?.map(
        (municipalityx: Record<string, any>) => {
          const {
            municipality: destructredMunicipality,
            name,
            id,
            ...restData
          } = municipalityx;
          return {
            subCategories: destructredMunicipality,
            label: municipalityx.name, // Use the modified label
            id,
          };
        },
      );
      return modifiedResponseData;
    },
    // enabled: !!district,
    onSuccess: data => {
      setGroupedMunicipalityList(data);
    },
  });

  const handleLocationAdd = (e: any) => {
    e.preventDefault();
    const selectedMunicipalities = municipality ? municipality?.split(',') : [];

    const municipalityLevelData = selectedMunicipalities?.map(mun => {
      const munDetail = municipalityList?.find(
        (munObj: Record<string, any>) => munObj?.id === +mun,
      );

      const disDetail = districtList?.find(
        (distObj: Record<string, any>) =>
          munDetail?.district === +distObj?.code,
      );

      const provDetail = provinceList?.find(
        (provObj: Record<string, any>) =>
          munDetail?.province === +provObj?.code,
      );

      // return location containing province, district and municipality
      return {
        ...newFormValue,
        id: `new-${uuidv4()}`,
        area: 'Sub-National Level',
        province: provDetail?.id,
        province__name: provDetail?.name,
        district: disDetail?.id,
        district__name: disDetail?.name,
        municipality: munDetail?.id,
        municipality__name: munDetail?.name,
        isActive: true,
        start_date: todayDate.split('/').join('-'),
        end_date: todayDate.split('/').join('-'),
      };
    });

    const provinceLevelData: Record<string, any>[] = [];
    if (!municipalityLevelData?.length && !reverseSelection) {
      province?.split(',')?.map(prov => {
        if (
          !municipalityLevelData?.some(munData => munData.province === +prov)
        ) {
          const provinceLocation = {
            ...newFormValue,
            id: `new-${uuidv4()}`,
            area: 'Sub-National Level',
            province: +prov,
            province__name:
              provinceList?.find(
                (provincex: Record<string, any>) => provincex?.id === +prov,
              )?.label || null,
            isActive: true,
            start_date: todayDate.split('/').join('-'),
            end_date: todayDate.split('/').join('-'),
          };
          provinceLevelData?.push(provinceLocation);
        }
      });
    }

    const locationDataToAdd = [
      ...municipalityLevelData,
      ...provinceLevelData,
    ]?.filter(
      locationData =>
        locationData?.area === 'Sub-National Level' &&
        !formData?.some(
          data =>
            data?.municipality === locationData?.municipality &&
            data?.province === locationData?.province &&
            data?.district === locationData?.district,
        ),
    );
    setFormData(prev => [...prev, ...locationDataToAdd]);

    setProvince('');
    setDistrict('');
    setMunicipality('');
  };
  const {
    mutateAsync: deleteLocation,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: (id: number) => deleteWorkingArea(id),
    onError: () => {
      toast.error('Error Occured! Try again');
    },
  });

  const handleDelete = async (location: Record<string, any>) => {
    if (location.postData) {
      setFormData(
        formData.filter(formLocation => formLocation.id !== location.id),
      );
      setConfirmDelete(false);
    } else {
      await deleteLocation(location.id);
      setFormData(
        formData.filter(formLocation => formLocation.id !== location.id),
      );
      setConfirmDelete(false);
      if (location?.area !== 'National Level') {
        toast.success('Working Areas Deleted Succesfully');
      }
    }
  };

  // handle National Level delete on toggle checkbox
  useEffect(() => {
    // if National Level is selecetd & no National Level location present, add federal area
    if (
      formValues?.interventionLevel?.['National Level'] === true &&
      !formValues?.workingAreas?.some(area => area?.area === 'National Level')
    ) {
      setFormData(prev => [
        ...prev,
        { ...newFormValue, id: `new-${uuidv4()}`, area: 'National Level' },
      ]);
    }
    // if National Level is unselected & National Level location present, delete federal area
    else if (
      formValues?.interventionLevel?.['National Level'] !== true &&
      formValues?.workingAreas?.some(area => area?.area === 'National Level')
    ) {
      const federalLocation = formValues?.workingAreas?.find(
        area => area?.area === 'National Level',
      );
      if (federalLocation) {
        handleDelete(federalLocation);
      }
    }
  }, [formValues?.interventionLevel?.['National Level']]);

  // handle sub-national level delete on toggle checkbox
  useEffect(() => {
    if (
      formValues?.workingAreas?.some(
        area => area?.area === 'Sub-National Level',
      )
    ) {
      if (formValues?.interventionLevel?.['Sub-National Level'] !== true) {
        const newFormData = formData?.map(area => {
          if (area?.area === 'Sub-National Level') {
            return { ...area, isDelete: true };
          }
          return area;
        });
        setFormData(newFormData);
      } else if (
        formValues?.interventionLevel?.['Sub-National Level'] === true
      ) {
        const newFormData = formData?.map(area => {
          if (area?.area === 'Sub-National Level') {
            return { ...area, isDelete: false };
          }
          return area;
        });
        setFormData(newFormData);
      }
    }
  }, [formValues?.interventionLevel?.['Sub-National Level']]);

  // clear related municipalites on district unselect, clear districts on province unselect
  useEffect(() => {
    if (!provinceList || !districtList) return;
    // clear districts on province unselect
    // get district details from district list based on selected province
    if (!reverseSelection) {
      const disDetails =
        districtList?.filter((dis: Record<string, any>) =>
          province?.split(',')?.map(Number)?.includes(dis?.province),
        ) || [];

      // filter selected district where only only store district, which are present on the  disDetails
      const filteredDis = district
        ? district
            ?.split(',')
            ?.map(Number)
            ?.filter(dis => {
              return !!disDetails?.some(
                (disx: Record<string, any>) => disx?.code === dis,
              );
            })
        : [];

      setDistrict(filteredDis?.length > 0 ? filteredDis?.join(',') : '');

      if (!provinceList || !districtList || !municipalityList) return;

      // clear municipality on district unselect
      // get municipality details from municipality list based on selected district
      const munsDetails =
        municipalityList?.filter((mun: Record<string, any>) =>
          district?.split(',')?.map(Number)?.includes(mun?.district),
        ) || [];

      // filter selected municipality where only only store municipality, which are present on the municipalityDetails
      const filteredMun = municipality
        ? municipality
            ?.split(',')
            ?.map(Number)
            ?.filter(mun => {
              return !!munsDetails?.some(
                (munx: Record<string, any>) => munx?.code === mun,
              );
            })
        : [];

      setMunicipality(filteredMun?.length > 0 ? filteredMun?.join(',') : '');
    }
  }, [province, district, provinceList, districtList, municipalityList]);

  // useEffect(() => {
  //   if (!province) {
  //     setDistrictList([]);
  //   }
  //   // if (!district) {
  //   //   setMunicipalityList([]);
  //   // }
  // }, [province, district]);

  useEffect(() => {
    if (errors.workingAreas && !isEmpty(formData)) {
      clearErrors('workingAreas');
    }
  }, [clearErrors, errors, formData]);

  const toggleActiveStatus = (location: Record<string, any>) => {
    const updatedFormData = formData?.map(locationx => {
      if (locationx?.id === location?.id) {
        return {
          ...locationx,
          isActive: locationx?.isActive ? !locationx?.isActive : true,
        };
      }
      return locationx;
    });
    setFormData(updatedFormData);
  };

  useEffect(() => {
    dispatch(
      setWorkingAreasValidation(
        province.length !== 0 || municipality.length !== 0,
      ),
    );
  }, [province]);

  return (
    <>
      {levelOfInterventionLoading ||
      isWorkingAreasLoading ||
      provinceListLoading ? (
        <div>
          <div>
            <FormSkeleton numRows={2} />
            <FlexColumn className="naxatw-gap-2">
              <Skeleton className="naxatw-h-10 naxatw-w-full" />
              <Skeleton className="naxatw-h-10 naxatw-w-full" />
              <Skeleton className="naxatw-h-10 naxatw-w-full" />
            </FlexColumn>
          </div>
        </div>
      ) : (
        <FlexColumn className="naxatw-gap-5">
          {confirmDelete && (
            <PortalTemplate>
              <DeleteConfirmationOverlay
                onClose={() => {
                  setConfirmDelete(false);
                  setSelectedLocation(null);
                }}
                onDelete={() => {
                  if (selectedLocation) {
                    handleDelete(selectedLocation);
                  }
                }}
                isError={isError}
                isLoading={isLoading}
                error={error}
              />
            </PortalTemplate>
          )}

          <FlexColumn className="naxatw-space-y-2">
            <InputLabel label="Working Area" />
            {levelOfInterventionData?.map(
              (locationType: Record<string, any>, index: number) => (
                <Controller
                  key={`${locationType.index}-${index}`}
                  control={control}
                  name={`workingAreas.interventionLevel.${locationType.id}`}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      value={value}
                      onChange={onChange}
                      label={locationType.label}
                      id={locationType.id}
                      checked={value}
                    />
                  )}
                />
              ),
            )}
            {errors?.workingAreas && (
              <ErrorMessage
                // @ts-ignore
                message={errors?.workingAreas?.message}
              />
            )}
          </FlexColumn>

          {/* SUB-NATIONAL LEVEL */}
          {formValues?.interventionLevel?.['Sub-National Level'] && (
            <FlexColumn className="naxatw-gap-y-3">
              <p className="naxatw-body-btn">Sub-National Level</p>
              <FlexRow className="naxatw-items-end naxatw-gap-2">
                <div className="naxatw-grid naxatw-flex-1 naxatw-grid-cols-1 naxatw-gap-3 sm:naxatw-grid-cols-3">
                  {/* Province */}
                  <FlexColumn className="naxatw-space-y-2">
                    <InputLabel label="Province" />
                    <Dropdown
                      options={provinceList || []}
                      // @ts-ignore
                      value={province ? province?.split(',')?.map(Number) : []}
                      onChange={value => {
                        if (!district && !municipality) {
                          setReverseSelection(false);
                        }
                        if (reverseSelection) {
                          setDistrict('');
                          setMunicipality('');
                        }
                        setProvince(value?.join());
                      }}
                      multiple
                      checkBox
                    />
                  </FlexColumn>
                  {/* District */}
                  <FlexColumn className="naxatw-space-y-2">
                    <InputLabel label="District" />
                    <GroupedComboBox
                      options={groupedDistrictList || []}
                      // @ts-ignore
                      value={district ? district?.split(',')?.map(Number) : []}
                      onChange={(value: any) => {
                        setDistrict(value?.join());
                      }}
                      multiple
                      checkBox
                      showSelectAllBtn
                      handleSelectAll={() => {
                        if (province || municipality) {
                          const districtValues = groupedDistrictList.map(
                            category =>
                              category.subCategories.map(
                                (districtx: any) => districtx.id,
                              ),
                          );
                          setDistrict(districtValues.flat().join(','));
                        }
                      }}
                      handleClearAll={() => setDistrict('')}
                      selectAllBtnDisabled={!(province || municipality)}
                    />
                  </FlexColumn>
                  {/* Municipality */}
                  <FlexColumn className="naxatw-space-y-2">
                    <InputLabel label="Municipality" />
                    <GroupedComboBox
                      options={groupedMunicipalityList || []}
                      // @ts-ignore
                      value={
                        municipality
                          ? municipality?.split(',')?.map(Number)
                          : []
                      }
                      onChange={(value: any) => {
                        if (!province && !district) {
                          setReverseSelection(true);
                        }
                        setMunicipality(value?.join());
                        const selectedMunicipalitiesData =
                          municipalityList.filter((municiplaitiesData: any) =>
                            value.includes(municiplaitiesData.id),
                          );

                        if (reverseSelection || (!province && !district)) {
                          const selectedDistrictFromMunicipality =
                            selectedMunicipalitiesData?.map(d => d.district);
                          const selectedDistrictData = Array.from(
                            new Set(selectedDistrictFromMunicipality),
                          );
                          setDistrict(selectedDistrictData.join(','));
                          const selectedProvinceFromMunicipality =
                            selectedMunicipalitiesData?.map(d => d.province);
                          const selectedProvinceData = Array.from(
                            new Set(selectedProvinceFromMunicipality),
                          );
                          setProvince(selectedProvinceData.join(','));
                        }
                      }}
                      multiple
                      checkBox
                      showSelectAllBtn
                      handleSelectAll={() => {
                        const municiplaityValues = groupedMunicipalityList.map(
                          category =>
                            category.subCategories.map(
                              (municipalityx: any) => municipalityx.id,
                            ),
                        );
                        setMunicipality(municiplaityValues.join(','));
                      }}
                      handleClearAll={() => setMunicipality('')}
                    />
                  </FlexColumn>
                </div>
              </FlexRow>
              <button
                type="button"
                disabled={
                  !!((province && district && !municipality) || !province)
                }
                onClick={e => {
                  handleLocationAdd(e);
                  setReverseSelection(false);
                }}
                className={`naxatw-mx-auto naxatw-w-fit !naxatw-min-w-8 naxatw-rounded-lg naxatw-border naxatw-border-[#417EC9] naxatw-px-4 naxatw-py-2 naxatw-text-[0.875rem] naxatw-font-bold 
                   naxatw-text-[#417EC9] hover:naxatw-bg-[#a4cdff2f] disabled:naxatw-border-gray-500 disabled:naxatw-text-gray-500 disabled:hover:naxatw-bg-transparent ${!!(province && district && !municipality) || !province ? 'naxatw-cursor-not-allowed' : 'naxatw-cursor-pointer'} `}
              >
                Save Working Areas
              </button>
              <FlexColumn className="naxatw-space-y-2">
                <div className="naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-3">
                  {formData?.filter(
                    location =>
                      !location.is_deleted &&
                      location.area === 'Sub-National Level',
                  )?.length > 0 ? (
                    <FlexColumn className="naxatw-gap-3">
                      {formData
                        ?.filter(
                          location =>
                            !location.is_deleted &&
                            location.area === 'Sub-National Level',
                        )
                        ?.map((location, i) => {
                          return (
                            <div key={i}>
                              <div
                                key={location.id}
                                className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-x-3"
                              >
                                <FlexRow className="naxatw-items-center naxatw-gap-2">
                                  <span className="naxatw-text-xs naxatw-font-medium">
                                    {generateLocationName(location)}
                                  </span>
                                </FlexRow>
                                <FlexRow className="naxatw-gap-4">
                                  <Switch
                                    checked={location?.isActive}
                                    onClick={() => toggleActiveStatus(location)}
                                  />
                                  <Icon
                                    name="close"
                                    onClick={() => {
                                      setSelectedLocation(location);
                                      setConfirmDelete(true);
                                    }}
                                    className="!naxatw-text-base naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
                                  />
                                </FlexRow>
                              </div>
                              <FlexRow className="naxatw-w-[75%] naxatw-items-center naxatw-gap-2">
                                <DatePicker
                                  date={
                                    location?.start_date?.includes('T')
                                      ? location?.start_date.split('T')[0]
                                      : location?.start_date
                                  }
                                  onChange={value => {
                                    setFormData(
                                      formData.map(data => {
                                        const { start_date, ...values } = data;
                                        if (data.id === location.id) {
                                          return {
                                            ...values,
                                            start_date: value,
                                          };
                                        }
                                        return data;
                                      }),
                                    );
                                  }}
                                />
                                {!location?.isActive && (
                                  <>
                                    <p>to</p>
                                    <DatePicker
                                      date={
                                        location?.end_date?.includes('T')
                                          ? location?.end_date.split('T')[0]
                                          : location?.end_date
                                      }
                                      onChange={value => {
                                        setFormData(
                                          formData.map(data => {
                                            const { end_date, ...values } =
                                              data;
                                            if (data.id === location.id) {
                                              return {
                                                ...values,
                                                end_date: value,
                                              };
                                            }
                                            return data;
                                          }),
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </FlexRow>
                            </div>
                          );
                        })}
                    </FlexColumn>
                  ) : (
                    <FlexRow className="naxatw-h-full naxatw-items-center naxatw-justify-center">
                      <p className="naxatw-py-3 naxatw-text-sm naxatw-font-medium naxatw-text-gray-400">
                        No Working Areas Selected
                      </p>
                    </FlexRow>
                  )}
                </div>
              </FlexColumn>
            </FlexColumn>
          )}
        </FlexColumn>
      )}
    </>
  );
};

export default WorkingArea;
