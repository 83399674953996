/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { MapMouseEvent } from 'maplibre-gl';
import removeObjectKeys from '@Utils/removeObjectKeys';
import { Popup } from 'maplibre-gl';
import { IVectorTileLayer } from '../types';

const popup = new Popup({
  closeButton: false,
  closeOnClick: false,
});

export default function VectorTileLayer({
  map,
  id,
  url,
  isMapLoaded,
  layerOptions,
  visibleOnMap = true,
  interactions = [],
  onFeatureSelect,
  onMapClick = () => {},
  showLabel,
  showOutline = false,
  refreshLayer = false,
  showNamePopup = false,
  onHover = undefined,
}: IVectorTileLayer) {
  const sourceId = useMemo(() => id.toString(), [id]);
  const hasInteractions = useRef(false);

  useEffect(() => {
    hasInteractions.current = !!interactions.length;
  }, [interactions]);

  const addSourceFn = useCallback(
    (mapx: any) => {
      if (mapx.getSource(sourceId)) return;
      mapx.addSource(sourceId, {
        type: 'vector',
        tiles: [url],
      });
    },
    [sourceId, url],
  );

  const addLayerFn = useCallback(
    (mapx: any) => {
      if (mapx?.getLayer(sourceId)) return;

      mapx.addLayer({
        id: sourceId,
        type: 'fill',
        source: sourceId,
        'source-layer': 'default',
        layout: {},
        ...removeObjectKeys(layerOptions || {}, ['layout']),
      });
    },
    [sourceId],
  );

  // add source and layer to the map
  useEffect(() => {
    if (!map || !isMapLoaded || !visibleOnMap) return () => {};
    addSourceFn(map);
    addLayerFn(map);
    return () => {
      if (map.getLayer(sourceId)) {
        map.removeLayer(sourceId);
        if (map.getLayer(`${sourceId}-label`)) {
          map.removeLayer(`${sourceId}-label`);
        }
        if (map.getLayer(`${sourceId}-outline`)) {
          map.removeLayer(`${sourceId}-outline`);
        }
        map.removeSource(sourceId);
      }
    };
  }, [isMapLoaded, map, url, sourceId, addSourceFn, addLayerFn, visibleOnMap]);

  // change cursor to pointer on feature hover
  useEffect(() => {
    if (!map) return () => {};
    function onMouseOver() {
      if (!map || !hasInteractions.current) return;
      map.getCanvas().style.cursor = 'pointer';
    }
    function onMouseLeave() {
      if (!map || !hasInteractions.current) return;
      map.getCanvas().style.cursor = '';
    }
    map.on('mouseover', sourceId, onMouseOver);
    map.on('mouseleave', sourceId, onMouseLeave);
    // remove event handlers on unmount
    return () => {
      map.off('mouseover', sourceId, onMouseOver);
      map.off('mouseleave', sourceId, onMouseLeave);
    };
  }, [map, sourceId]);

  useEffect(() => {
    if (!map) return () => {};
    function onMouseMove(event: any) {
      if (!map || !hasInteractions.current) return;
      map.getCanvas().style.cursor = 'pointer';
      const { features } = event;
      if (!features?.length) return;
      const { properties, layer } = features[0];
      if (onHover) {
        onHover({ ...properties, layer: layer?.id, isHovered: true });
      }
    }
    const hoverFn = (e: any) => {
      if (e?.features.length > 0) {
        // hoveredFeatureId.current = e.features[0].properties.id;
        // debounceEvent(
        onMouseMove(e);
        // );
      }
    };

    function onMouseOut() {
      if (!map || !hasInteractions.current) return;
      if (onHover) {
        onHover({ isHovered: false });
      }
    }
    map.on('mousemove', sourceId, hoverFn);
    map.on('mouseout', sourceId, onMouseOut);
    // remove event handlers on unmount
    return () => {
      map.off('mousemove', onMouseMove);
      map.off('mouseout', onMouseOut);
    };
  }, [map, sourceId, onHover]);

  // add select interaction & return properties on feature select
  useEffect(() => {
    if (!map || !interactions.includes('select')) return () => {};
    function handleSelectInteraction(event: MapMouseEvent) {
      if (!map) return;
      map.getCanvas().style.cursor = 'pointer';
      // @ts-ignore
      const { features } = event;
      if (!features?.length) return;
      const { properties, layer } = features[0];
      onFeatureSelect?.({ ...properties, layer: layer?.id });
    }
    map.on('click', sourceId, handleSelectInteraction);
    return () => map.off('click', sourceId, handleSelectInteraction);
  }, [map, interactions, sourceId, onFeatureSelect]);

  // return if the clicked part has feature or not
  useEffect(() => {
    if (!map || !interactions.includes('select')) return () => {};
    function handleMapClick(event: maplibregl.MapMouseEvent) {
      const { point } = event;
      const { lngLat } = event;

      const features = map?.queryRenderedFeatures(point);

      let clickResult;

      if (features && features.length > 0) {
        // A feature was clicked
        const clickedFeature = features[0];
        const { properties, layer } = clickedFeature;
        clickResult = {
          hasFeature: true,
          properties,
          layer: layer.id,
          lngLat,
        };
      } else {
        // No feature was clicked (base map click)
        clickResult = {
          hasFeature: false,
          lngLat,
        };
      }

      onMapClick?.(clickResult);
    }

    map.on('click', handleMapClick);

    return () => map.off('click', handleMapClick);
  }, [interactions, map, onMapClick]);

  // add label to layer
  useEffect(() => {
    if (!map || !isMapLoaded) return () => {};
    const labelSourceId = `${sourceId}-label`;
    map.setGlyphs(
      'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
    );
    if (visibleOnMap && showLabel) {
      map.addLayer({
        id: labelSourceId,
        type: 'symbol',
        source: sourceId,
        'source-layer': 'default',
        layout: {
          // @ts-ignore
          ...(layerOptions?.layout || {}),
        },
        paint: {
          'text-color': '#000000',
          'text-halo-color': '#ffffff',
          'text-halo-width': 1,
        },
      });
    }
    return () => {
      if (map.getLayer(labelSourceId)) {
        map.removeLayer(labelSourceId);
      }
    };
  }, [map, isMapLoaded, visibleOnMap, sourceId, showLabel]); // eslint-disable-line

  // add outline to layer
  useEffect(() => {
    if (!map || !isMapLoaded || !visibleOnMap || !showOutline) return () => {};
    const outlineLayerId = `${sourceId}-outline`;
    if (map.getLayer(outlineLayerId)) return () => {};
    map.addLayer({
      id: outlineLayerId,
      type: 'line',
      source: sourceId,
      'source-layer': 'default',
      layout: {},
      paint: {
        'line-color': '#808080',
        'line-width': 0.6,
      },
    });
    return () => {
      if (map.getLayer(outlineLayerId)) {
        map.removeLayer(outlineLayerId);
      }
    };
  }, [
    map,
    isMapLoaded,
    visibleOnMap,
    sourceId,
    showOutline,
    url,
    refreshLayer,
  ]);

  useEffect(() => {
    const labelSourceId = `${sourceId}-label`;
    // const outlineLayerId = `${sourceId}-outline`;
    if (!refreshLayer || !map || !map.getLayer(sourceId)) return;
    if (map.getLayer(labelSourceId)) {
      map.removeLayer(labelSourceId);
    }
    // if (map.getLayer(outlineLayerId)) {
    //   map.removeLayer(outlineLayerId);
    // }
    map.removeLayer(sourceId);
    map.removeSource(sourceId);
    addSourceFn(map);
    addLayerFn(map);
  }, [map, refreshLayer, sourceId, addSourceFn, addLayerFn]);

  // display popup on road hover
  useEffect(() => {
    if (!map || !showNamePopup) return () => {};
    const handleMouseEnter = (e: any) => {
      map.getCanvas().style.cursor = 'pointer';

      if (e.features.length > 0) {
        const { properties } = e.features[0];

        let popupContent = '<div>';
        if (properties.name) {
          popupContent += `<strong>${properties.name}</strong><div style="margin-bottom: 5px;"></div>`;
        }
        if (properties.district_name) {
          popupContent += `${properties.district_name}<br>`; // Normal text
        }
        if (properties.province_name) {
          popupContent += `${properties.province_name}`;
        }
        popupContent += '</div>';
        popup.setLngLat(e.lngLat).setHTML(popupContent).addTo(map);
      }
    };
    const handleMouseLeave = () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    };
    map.on('mousemove', sourceId, handleMouseEnter);
    map.on('mouseleave', sourceId, handleMouseLeave);
    return () => {
      map.off('mousemove', sourceId, handleMouseEnter);
      map.off('mouseleave', sourceId, handleMouseLeave);
    };
  }, [map, showNamePopup, sourceId]);

  return null;
}
