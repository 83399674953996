import { z } from 'zod';

export const KnowledgeRepositoryValidationSchema = z
  .object({
    title: z.string().trim().min(1, 'Title is Required'),
    thematic_field: z
      .union([z.array(z.number()), z.array(z.object({}))])
      .optional()
      .nullable(),
    file_type: z.string().trim().min(1, 'File type is Required'),
    associate_program_project: z.record(z.any()).optional(),
    published_date: z.string().trim().min(1, 'Published date is Required'),
    document_link: z.string().trim().min(0).nullable().optional(),
    documents: z.any().array().min(0).optional(),
    other_program_project: z.string().trim().optional(),
  })
  .refine(
    data =>
      !data.associate_program_project ||
      !data.associate_program_project.parent_1010101010 ||
      (data.other_program_project &&
        data.other_program_project.trim().length > 0),
    {
      message: 'Other Programme Name is required',
      path: ['other_program_project'],
    },
  )
  .refine(
    data =>
      data.document_link || (data.documents && data.documents?.length > 0),
    {
      message: 'Either links or documents is Required',
      path: ['documents'],
    },
  );

export const KnowledgeRepositoryConceptualValidationSchema = z
  .object({
    title: z.string().trim().min(1, 'Title is Required'),
    thematic_field: z
      .union([z.array(z.number()), z.array(z.object({}))])
      .optional()
      .nullable(),
    file_type: z.string().trim().min(1, 'File type is Required'),
    associate_program_project: z.record(z.any()).optional(),
    province: z.number({ message: 'Province is Required' }),
    published_date: z.string().trim().min(1, 'Published date is Required'),
    document_link: z.string().trim().min(0).nullable().optional(),
    documents: z.any().array().min(0).optional(),
    other_program_project: z.string().trim().optional(),
  })
  .refine(
    data =>
      !data.associate_program_project ||
      !data.associate_program_project.parent_1010101010 ||
      (data.other_program_project &&
        data.other_program_project.trim().length > 0),
    {
      message: 'Other Programme Name  is required',
      path: ['other_program_project'], // This will mark the 'other' field as invalid
    },
  )
  .refine(
    data =>
      data.document_link || (data.documents && data.documents?.length > 0),
    {
      message: 'Either links or documents is Required',
      path: ['documents'],
    },
  );

export type KnowledgeRepositorySchemaProps = z.infer<
  typeof KnowledgeRepositoryValidationSchema & {
    [key: string]: any;
  }
>;
