import React, { useState } from 'react';
import { useTypedDispatch } from '@Store/hooks';
import Icon from '@Components/common/Icon';
import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import {
  setCheckBox,
  setCheckBoxProgrammeDetails,
  setCheckBoxProvincialProfile,
} from '@Store/actions/adminPdfAction';

interface ICheckBoxProps {
  name: string;
  title: string;
  id: string;
  pdfType?: string;
}

const CheckBoxComponent = ({ name, title, id, pdfType }: ICheckBoxProps) => {
  const [checked, setChecked] = useState(true);
  const dispatch = useTypedDispatch();

  function handleCheck(key: string) {
    setChecked(!checked);
    // if (pdfType === 'report') {
    //   dispatch(setCheckBox({ key, value: !checked }));
    // } else {
    //   dispatch(setCheckBoxProvincialProfile({ key, value: !checked }));
    // }
    switch (pdfType) {
      case 'report':
        dispatch(setCheckBox({ key, value: !checked }));
        break;
      case 'provincialProfile':
        dispatch(setCheckBoxProvincialProfile({ key, value: !checked }));
        break;
      case 'programmeDetail':
        dispatch(setCheckBoxProgrammeDetails({ key, value: !checked }));
        break;
      default:
        dispatch(setCheckBoxProgrammeDetails({ key, value: !checked }));
    }
  }
  return (
    <Button
      className={`naxatw-relative naxatw-flex naxatw-h-[8.625rem] naxatw-flex-[1_0_0] naxatw-flex-col naxatw-items-center naxatw-justify-center naxatw-gap-5 naxatw-rounded-lg naxatw-border naxatw-py-3 naxatw-ease-in-out hover:naxatw-border-secondary-500 ${checked ? 'naxatw-border-secondary-500' : ''} naxatw-bg-white naxatw-drop-shadow-sm`}
      onClick={() => handleCheck(id)}
      variant="link"
    >
      <Icon
        name={checked ? 'check_box' : 'check_box_outline_blank'}
        className={`naxatw-absolute naxatw-right-2 naxatw-top-2 naxatw-text-2xl ${checked ? 'naxatw-text-secondary-500' : 'naxatw-text-matt-200'}`}
      />
      <FlexColumn className="naxatw-items-center naxatw-justify-center naxatw-gap-2 naxatw-self-stretch">
        <Icon
          name={name}
          className="naxatw-text-2xl naxatw-leading-6 naxatw-text-primary-700"
        />
        <p className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-normal naxatw-tracking-[-0.0175rem] naxatw-text-matt-200">
          {title}
        </p>
      </FlexColumn>
    </Button>
  );
};

export default CheckBoxComponent;
