const colors = [
  '#D64C63',
  '#A26599',
  '#41588F',
  '#41C570',
  '#719ED7',
  '#D54345',
  '#077bad',
  '#6b07ad',
  '#ad0770',
  '#ad6b07',
  '#ad0707',
  '#07ad6b',
  '#07adad',
  '#6bad07',
  '#ad5407',
];

export default colors;
