import CheckBoxSkeleton from '@Components/common/FormComponent/CheckBoxSkeleton';
import { FormControl } from '@Components/common/FormUI';
import { FlexColumn } from '@Components/common/Layouts';
import Checkbox from '@Components/common/FormUI/CheckBox';
import { getCampaignGoals } from '@Services/components';
import { useQuery } from '@tanstack/react-query';
import { ComponentFormValidationSchemaProps } from '@Validations/ComponentForm';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import Searchbar from '@Components/common/SearchBar';

const CampaignGoals = () => {
  const [searchCampaignGoals, setSearchCampaignGoals] = useState('');
  const { control } = useFormContext<ComponentFormValidationSchemaProps>();

  // fetches campaign list
  const { data: campaignList, isFetching } = useQuery({
    queryKey: ['campaign-goals', searchCampaignGoals],
    queryFn: () =>
      getCampaignGoals({
        search: searchCampaignGoals,
      }),
    select: list =>
      list?.data?.map((option: Record<string, any>) => ({
        id: option.id,
        label: option.name,
        value: option.id,
      })),
  });

  const [valueCampaignGoals, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchCampaignGoals,
    onChange: debouncedEvent =>
      setSearchCampaignGoals(debouncedEvent.target.value),
  });

  return (
    <div className="campaign-goals-container">
      <div className="naxatw-px-6 naxatw-pb-2">
        <Searchbar
          placeholder="Search Campaign Goals"
          value={valueCampaignGoals}
          onChange={handleChange}
          required={false}
        />
      </div>
      <div className="scrollbar naxatw-h-[calc(100vh-520px)] naxatw-w-full naxatw-overflow-y-auto naxatw-px-6 lg:naxatw-h-[calc(100vh-350px)]">
        <FlexColumn className="naxatw-w-full naxatw-space-y-5 ">
          {isFetching ? (
            <CheckBoxSkeleton />
          ) : (
            <>
              <FormControl className="naxatw-w-full ">
                {campaignList?.map((list: Record<string, any>) => {
                  return (
                    <div key={list.id} className="naxatw-px-3 naxatw-py-2">
                      <Controller
                        control={control}
                        name={`campaign_goals.${list.id}`}
                        render={({ field: { value, onChange } }) => (
                          <Checkbox
                            value={value}
                            onChange={onChange}
                            label={list.label}
                            id={list.id}
                            checked={value}
                          />
                        )}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default CampaignGoals;
