import { IDropDownData } from '@Constants/interface';
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export type knowledgeRepositoryState = {
  dropdownOptions: { [key: string]: IDropDownData[] };
  publicFilterOptions: Record<string, any>;
  publicSearchText: string;
  publicSelectedDate: Date | null;
};

const initialState: knowledgeRepositoryState = {
  dropdownOptions: {
    release_type: [],
    associate_program_project: [],
    thematic_field: [],
    file_type: [],
  },
  publicFilterOptions: {
    file_type: null,
    release_type: null,
    thematic_field: null,
    associate_program_project: null,
  },
  publicSearchText: '',
  publicSelectedDate: null,
};

const setDropdownOptionsList: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    dropdownOptions: {
      ...state.dropdownOptions,
      [key]: value,
    },
  };
};
const setPublicFilterOptions: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  return {
    ...state,
    publicFilterOptions: {
      ...state.publicFilterOptions,
      ...action.payload,
    },
  };
};

const setPublicSearchText: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<string>
> = (state, action) => {
  return {
    ...state,
    publicSearchText: action.payload,
  };
};

const setPublicSelectedDate: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<Date | null>
> = (state, action) => {
  return {
    ...state,
    publicSelectedDate: action.payload,
  };
};

const knowledgeRepositorySlice = createSlice({
  name: 'knowledgeRepository',
  initialState,
  reducers: {
    setDropdownOptionsList,
    setPublicFilterOptions,
    setPublicSearchText,
    setPublicSelectedDate,
  },
});

export { knowledgeRepositorySlice };

export default knowledgeRepositorySlice.reducer;
