import Icon from '@Components/common/Icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IExploreTuturialCardProps {
  iconName: string;
  title: string;
  description: string;
  link: string;
}
const ExploreTutorialCard = ({
  iconName,
  title,
  description,
  link,
}: IExploreTuturialCardProps) => {
  const navigate = useNavigate();
  return (
    <div
      tabIndex={0}
      role="button"
      className="explore-card"
      onClick={() => navigate(link)}
      onKeyDown={() => {}}
    >
      <div className="naxatw-flex naxatw-h-full naxatw-cursor-pointer naxatw-flex-col naxatw-gap-6 naxatw-rounded-lg naxatw-bg-white naxatw-px-[0.69rem] naxatw-py-[2.19rem] naxatw-pb-[3.03rem] naxatw-shadow-sm hover:naxatw-shadow-lg">
        <div className="naxatw-mx-auto naxatw-flex naxatw-h-[4.4rem] naxatw-w-[4.4rem] naxatw-items-center naxatw-justify-center naxatw-rounded-[4.652rem] naxatw-bg-primary-100">
          <Icon
            name={iconName}
            className="naxatw-text-[#833177] hover:naxatw-text-[#16B1A7]"
          />
        </div>
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.88rem]">
          <h5 className="naxatw-text-center naxatw-text-[1.5rem] naxatw-font-medium naxatw-tracking-[-0.045rem] naxatw-text-[#06367D]">
            {title}
          </h5>
          <p className="naxatw-mx-auto naxatw-max-w-[14.375rem] naxatw-text-center naxatw-text-[0.875rem] naxatw-leading-5 naxatw-text-matt-200">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExploreTutorialCard;
