import Skeleton from '@Components/RadixComponents/Skeleton';
import { v4 as uuidv4 } from 'uuid';

interface IFormSkeletonProps {
  numRows?: number;
  className?: string;
}

const FormSkeleton = ({ numRows = 1, className }: IFormSkeletonProps) => {
  const skeletonRows = Array.from({ length: numRows });
  return (
    <>
      <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-max-w-full naxatw-flex-col naxatw-rounded-lg  naxatw-py-5">
        <div className="naxatw-w-full naxatw-space-y-4">
          {skeletonRows.map(() => (
            <div key={uuidv4()} className="naxatw-space-y-2">
              <Skeleton className="naxatw-h-4 naxatw-w-[15%] naxatw-border naxatw-border-gray-200" />

              <Skeleton
                className={`naxatw-h-10 naxatw-w-[80%] naxatw-border naxatw-border-gray-200 ${className}`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FormSkeleton;
