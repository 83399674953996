/* eslint-disable import/prefer-default-export */
export const datePickerStyles = {
  months: 'naxatw-flex naxatw-relative naxatw-justify-start',
  day: 'naxatw-rounded-md hover:naxatw-bg-[#eee] naxatw-overflow-hidden naxatw-text-matt-100  naxatw-p-2 naxatw-text-[14px] naxatw-text-center naxatw-w-full naxatw-cursor-pointer',
  weekday: '!naxatw-text-[14px] naxatw-mb-2',
  day_button:
    'naxatw-w-full naxatw-px-[2px] naxatw-font-normal disabled:naxatw-text-grey-500 ',
  nav: 'naxatw-space-x-1 naxatw-flex naxatw-items-center naxatw-right-[-8px] naxatw-top-[4px]',
  chevron: 'naxatw-fill-matt-100 naxatw-h-[15px] naxatw-w-[15px]',
  dropdown: 'naxatw-text-[14px] naxatw-font-semibold naxatw-text-matt-100',
  today:
    'hover:!naxatw-text-matt-100 naxatw-text-red-500 naxatw-font-bold naxatw-rounded-lg ',
  button_previous: 'naxatw-absolute naxatw-top-[6px] naxatw-left-[0]',
  button_next: 'naxatw-absolute naxatw-top-[6px] naxatw-right-[0]',
  dropdowns:
    'naxatw-flex naxatw-align-center naxatw-gap-4 naxatw-justify-center !naxatw-accent-matt-100',
};
