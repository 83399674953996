/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import { useState } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import ReportPdfPopUp from '@Components/common/PdfDesign';
import SelectionBox from '@Components/common/PdfDesign/SelectionBox';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { reportCheckList } from '@Constants/pdfGeneration';
import { getReportList } from '@Services/report';
import { resetCheckBox } from '@Store/actions/adminPdfAction';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import ReportDataTable from '../ReportDataTable/ReportDataTable';

const ReportTable = () => {
  const [projectId, setProjectId] = useState('');
  const [open, setOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);

  const dispatch = useTypedDispatch();

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.report,
  );

  const filterFields = useTypedSelector(
    state => state.databankforms?.report?.filterParams,
  );

  const areaFilterField = useTypedSelector(
    state => state.databankforms?.report?.area,
  );

  const report = useTypedSelector(state => state.adminPdfSlice.report);

  function handleClose() {
    setOpen(false);
  }
  function handlePdfReview() {
    setPdfOpen(true);
  }

  function handlePdfPreviewClose() {
    setPdfOpen(false);
    dispatch(resetCheckBox());
  }

  const columns = [
    { header: 'PROJECT', accessorKey: 'project' },
    { header: 'PROGRAMME', accessorKey: 'program' },
    { header: 'FIRST TIER PARTNER', accessorKey: 'first_tier_partner' },
    { header: 'SECTOR', accessorKey: 'sector' },
    {
      header: 'MARKER',
      accessorKey: 'marker',
      size: 400,
      cell: (info: any) => info.getValue(),
    },
    { header: 'STAKEHOLDER', accessorKey: 'stakeholder' },
    { header: 'WORKING AREA', accessorKey: 'working_area' },
    { header: 'START DATE', accessorKey: 'start_date' },
    { header: 'END DATE', accessorKey: 'end_date' },
  ];

  return (
    <>
      {open && (
        <Portal>
          <SelectionBox
            handleClose={handleClose}
            handlePdfPreview={handlePdfReview}
            componentId={projectId}
            checkBoxData={reportCheckList}
          />
        </Portal>
      )}
      {pdfOpen && (
        <Portal>
          <ReportPdfPopUp
            handleClose={handlePdfPreviewClose}
            compoenentId={projectId}
            checkList={report}
          />
        </Portal>
      )}
      <div className="naxatw-h-[calc(100%-9.8rem)] sm:naxatw-h-[calc(100%-7rem)] lg:naxatw-h-[calc(100%-4.5rem)]">
        <ReportDataTable
          columns={[
            {
              header: 'Report',
              accessorKey: 'pdfIcon',
              // eslint-disable-next-line no-unused-vars
              cell: ({ row }) => {
                return (
                  <div className="naxatw-mr-auto naxatw-py-4">
                    <ToolTip
                      name="picture_as_pdf"
                      type="material-icons"
                      className="naxatw-rounded-full naxatw-pr-4 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
                      message="Project Details"
                      preventDefault
                      iconClick={() => {
                        setOpen(true);
                        setProjectId(row?.original?.project_id);
                      }}
                    />
                  </div>
                );
              },
            },
            ...columns,
          ]}
          queryKey="programmes11"
          queryFn={getReportList}
          queryFnParams={{
            ...filterFields,
            area: areaFilterField,
          }}
          searchInput={searchTerm || ''}
        />
      </div>
    </>
  );
};

export default ReportTable;
