/* eslint-disable no-nested-ternary */
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { motion } from 'framer-motion';

import { useSpring, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '@Store/hooks';
import { getUserManual } from '@Services/tutorials';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColumnDocumentCards } from './DocumentViewer/DocumentCards';

const UserManual = () => {
  // const dispatch = useTypedDispatch();
  const [isDocumentViewerVisible, setIsDocumentViewerVisible] = useState(false);
  const navigate = useNavigate();

  const { currentDocument } = useTypedSelector(state => state.tutorialsSlice);
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const documentColumnCardVariants = {
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1, // Slower animation
        staggerChildren: 0.2, // Delay for each child to create row-to-column effect
        delayChildren: 0.1, // Adds a slight delay before the first child animates
      },
    },
    hidden: {
      opacity: 0,
      y: '-100%',
      transition: { duration: 1 },
    },
  };

  const columnChildVariants = {
    show: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: { duration: 0.25 },
    },
    hidden: {
      opacity: 0,
      x: '50%',
      y: '-50%',
      transition: { duration: 0.25 },
    },
  };

  useEffect(() => {
    if (currentDocument.file) {
      setIsDocumentViewerVisible(true);
    }
  }, [currentDocument]);

  const { data: userManualData, isLoading: userManualDataIsLoading } = useQuery(
    {
      queryKey: ['user-manual-data'],
      queryFn: () => getUserManual(),
      select: res => res.data,
    },
  );

  return (
    <>
      {userManualData?.length === 0 ? (
        <NoChartDataComponent />
      ) : (
        <animated.div style={{ ...springs }}>
          <div className="naxatw-m-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-7">
            <div className="naxatw-mx-auto naxatw-w-11/12">
              <FlexColumn className="naxatw-items-start naxatw-gap-4">
                <FlexRow className="naxatw-items-center naxatw-gap-2">
                  <ToolTip
                    name="arrow_back"
                    message="Go Back"
                    className="naxatw-flex naxatw-items-center naxatw-text-[1.5rem] naxatw-text-primary-700"
                    iconClick={() => navigate('/dvs-tutorial')}
                  />
                  <p className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-text-primary-700">
                    User Manual
                  </p>
                </FlexRow>
                {userManualDataIsLoading ? (
                  <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-5">
                    <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                    <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                    <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                    <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                    <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                    <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                    <Skeleton className="naxatw-h-[12rem] naxatw-w-[22rem]" />
                  </div>
                ) : (
                  <motion.div
                    variants={documentColumnCardVariants}
                    initial="hidden"
                    animate={!isDocumentViewerVisible ? 'show' : 'hidden'}
                  >
                    <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-5 md:naxatw-grid-cols-2 lg:naxatw-grid-cols-3 xl:naxatw-grid-cols-4">
                      {userManualData?.map((document: any) => (
                        <motion.div
                          variants={columnChildVariants}
                          key={document.id}
                        >
                          <ColumnDocumentCards
                            key={document.id}
                            {...document}
                            onClick={() => window.open(document.file, '_blank')}
                          />
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </FlexColumn>
            </div>
          </div>
        </animated.div>
      )}
    </>
  );
};

export default UserManual;
