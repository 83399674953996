import { FormFieldProps } from '@Constants/interface/FormInterface';

/* eslint-disable import/prefer-default-export */
export const ProgramFormFields: FormFieldProps[] = [
  {
    label: 'Programme Code',
    inputType: 'number',
    type: 'input',
    placeholder: 'Enter Code',
    id: 'code',
    name: 'code',
    isVisible: true,
    required: true,
  },
  {
    label: 'Programme Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Name',
    id: 'name',
    name: 'name',
    isVisible: true,
    required: true,
  },
  {
    label: 'Programme Abbreviation',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Programme Abbreviation',
    id: 'abbreviation',
    name: 'abbreviation',
    isVisible: true,
  },

  {
    label: 'Type of Programme',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'program_type',
    name: 'program_type',
    choose: 'value',
    isVisible: true,
    required: true,
    enableSearchbar: false,
  },
  {
    label: 'Programme Start Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'start_date',
    name: 'start_date',
    group: 'group-1',
    isVisible: true,
    required: true,
  },
  {
    label: 'Programme End Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'end_date',
    name: 'end_date',
    group: 'group-1',
    disabledDays: '',
    isVisible: true,
    required: true,
  },
  {
    label:
      'Is this Programme a successor of any previous programme / project ?',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'is_successor',
    name: 'is_successor',
    choose: 'value',
    isVisible: true,
    required: true,
    enableSearchbar: false,
  },
  {
    label: 'Name of Previous Programme / Project',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'previous_programs',
    name: 'previous_programs',
    choose: 'value',
    isVisible: true,
    required: true,
    multiple: true,
    checkBox: true,
  },
  {
    label: 'Specify Other Programme/Project',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Previous Programme',
    id: 'other_previous_program',
    name: 'other_previous_program',
    isVisible: false,
    required: true,
  },
];

export const isSuccessorDropdown = [
  { id: 1, label: 'Yes', value: 'true' },
  { id: 2, label: 'No', value: 'false' },
];

export const otherOption = { label: 'Other', value: 'other-programs' };
