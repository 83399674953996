/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
  LabelList,
  Label,
  TooltipProps,
} from 'recharts';
import findHighestCount from '@Utils/findHighestCountOfComponents';
import generateArray from '@Utils/getArrayFromLength';
import transformData from '@Components/common/Charts/utils/transformDataForStackedBarchart';
import colors from '@Constants/stackedBarChartData';
import { IChartProps } from '../types';

const CustomizedLabel = (props: any) => {
  const { x, y, payload } = props;
  return (
    <Text
      x={x}
      y={y + 3}
      style={{
        fontSize: '12px',
        fill: 'rgb(71, 84, 103)',
        fontWeight: 500,
        userSelect: 'none',
      }}
      color="#475467"
      textAnchor="middle"
      dominantBaseline="hanging"
    >
      {payload?.value.length > 7
        ? `${payload?.value.substring(0, 5)}...`
        : payload?.value}
    </Text>
  );
};
const truncateLabel = (label: string) => {
  if (label.length > 7) {
    return `${label.substring(0, 5)}...`; // Truncate if more than 5 characters
  }
  return label;
};
let tooltip: any;
let fill = '';
const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (!active || !tooltip || !payload) return null;
  const { payload: data } = payload[0];
  const toolTipData = data[tooltip];
  if (active)
    return (
      <div
        className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2  naxatw-px-3 naxatw-py-2 naxatw-text-sm"
        style={{
          backgroundColor: fill,
          border: '1px solid #ccc',
          color: 'white',
        }}
      >
        <p className="naxatw-text-sm naxatw-font-semibold">{`Programme: ${data.fullName}`}</p>{' '}
        {/* Access fullName or other properties from data */}
        <p>Project: {toolTipData}</p>
      </div>
    );
  return null;
};
export default function StackedBarChart({
  data,
  scrollable = false,
  barSize = 110,
  yAxisLabel = '',
  xAxisLabel = '',
  width = '150%',
}: IChartProps) {
  const transData = transformData(data);

  // highest count is extracted from the transformed data
  const highestCount = findHighestCount(transData);
  // array of length highest count is generated
  const highestCountArray = generateArray(highestCount);

  return (
    <ResponsiveContainer
      width={scrollable && width ? width : '100%'}
      minHeight={200}
      maxHeight={530}
    >
      <BarChart
        width={500}
        height={300}
        data={transData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 35,
        }}
        stackOffset="none"
      >
        <CartesianGrid vertical={false} stroke="#EBEBEB" strokeWidth={0.7} />
        <XAxis
          dataKey="name"
          tickLine={false}
          tick={<CustomizedLabel />}
          interval={0}
          style={{
            strokeWidth: '0.5px',
            stroke: '#BDBDBD',
          }}
          label={{
            value: xAxisLabel,
            offset: -30,
            position: 'insideBottom',
            style: {
              textAnchor: 'middle',
              fill: '#475467',
              fontSize: '12px',
              fontWeight: 500,
            },
          }}
        />
        <YAxis
          yAxisId="left"
          style={{
            strokeWidth: '0.5px',
            fontSize: '12px',
            fill: 'rgb(71, 84, 103)',
            fontWeight: 500,
          }}
          dx={-15}
          stroke="#BDBDBD"
          tickLine={false}
          tick={false}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'insideLeft',
            style: {
              textAnchor: 'middle',
              fill: 'rgb(71, 84, 103)',
              fontSize: '12px',
              fontWeight: 500,
            },
          }}
          domain={[0, highestCount + 1]}
          ticks={[0, ...highestCountArray, highestCount + 1]}
        />
        {/* <Legend /> */}
        {/* the highest count array is mapped so that the bar with highest stack is rendered */}
        {/* recharts doesnt throw errror in case data not found, so the highest count is mapped */}
        {highestCountArray.reverse().map((item, index) => {
          return (
            <React.Fragment key={item}>
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey={`count${item}`}
                stackId="a"
                fill={colors[item - 1]}
                yAxisId="left"
                barSize={barSize}
                radius={item === 1 ? [4, 4, 0, 0] : [0, 0, 0, 0]}
                // eslint-disable-next-line no-return-assign
                onMouseOver={() => {
                  tooltip = `fullName${item}`;
                  fill = colors[item - 1];
                }}
              >
                <LabelList
                  dataKey={`name${item}`}
                  position="center"
                  fill="#fff"
                  width={15}
                  formatter={truncateLabel}
                  className="naxatw-text-sm naxatw-font-semibold"
                />
              </Bar>
            </React.Fragment>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}
