import { useSpring, animated } from '@react-spring/web';
import Map from './Map';

const MapSection = () => {
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  return (
    <animated.div
      style={{ ...springs }}
      className="map-section naxatw-relative naxatw-h-full naxatw-w-full"
    >
      <div className="naxatw-h-full naxatw-w-full naxatw-overflow-hidden naxatw-rounded-[0.84rem] naxatw-border naxatw-border-solid naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
        <Map />
      </div>
    </animated.div>
  );
};

export default MapSection;
