/* eslint-disable no-unused-vars */
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import Icon from '@Components/common/Icon';
import { FlexColumn } from '@Components/common/Layouts';
import IconButton from '@Components/common/IconButton';
import { Button } from '@Components/RadixComponents/Button';
import CheckBoxComponent from './CheckBox';

interface ISelectionBoxProps {
  handleClose: () => void;
  componentId?: string;
  handlePdfPreview: () => void;
  checkBoxData: any[];
  pdfType?: string;
}
const SelectionBox = ({
  handleClose,
  componentId,
  handlePdfPreview,
  checkBoxData,
  pdfType = 'report',
}: ISelectionBoxProps) => {
  function handleReportGeneration() {
    handlePdfPreview();
    handleClose();
  }

  return (
    <>
      <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-z-[111] naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem]">
        <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
          <BreadCrumb heading="Report Generation" overlayStatus={() => {}} />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={handleClose}
          />
        </div>
        <FlexColumn className="naxatw-w-full naxatw-items-start naxatw-gap-5 naxatw-px-7 naxatw-pt-5">
          <p className="naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-100">
            Please select the topic that need to be in the report
          </p>
          <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-3">
            {checkBoxData.map((list, index) => (
              <CheckBoxComponent
                name={list.name}
                title={list.title}
                key={list.name}
                id={list.id}
                pdfType={pdfType}
              />
            ))}
          </div>
          <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-py-5">
            <Button
              className="naxatw-w-fit"
              onClick={() => handleReportGeneration()}
            >
              Generate Report
            </Button>
          </div>
        </FlexColumn>
      </div>
    </>
  );
};

export default SelectionBox;
