function NoAccessComponent() {
  return (
    <section className="no-permission-section naxatw-p-4">
      <p className="naxatw-text-xl naxatw-text-matt-100">
        You do not have permission to access this content.
      </p>
    </section>
  );
}

export default NoAccessComponent;
