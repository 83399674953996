import { useTypedSelector } from '@Store/hooks';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import ChartDescription from '../ChartDescription';

interface IChartProps {
  chart: Record<string, any>;
  chartDescriptionData: Record<string, any>;
}

const Chart = ({ chart, chartDescriptionData }: IChartProps) => {
  const showFilterColumn = useTypedSelector(
    state => state.mainDashboard.showFilterColumn,
  );

  return (
    <div
      className={`chart-wrapper naxatw-grid naxatw-h-[calc(100%-4px)] naxatw-w-full naxatw-grid-rows-[auto_1fr] naxatw-overflow-hidden  naxatw-rounded-xl naxatw-border naxatw-border-solid naxatw-border-primary-200 naxatw-bg-white lg:naxatw-grid-rows-[auto]    ${showFilterColumn ? 'lg:naxatw-grid-cols-[14rem_1fr]' : 'lg:naxatw-grid-cols-[16rem_1fr]'}`}
    >
      <ChartDescription data={chartDescriptionData?.[chart.descriptionKey]} />
      <ChartRenderer data={chart} key={chart.id} />
    </div>
  );
};

export default Chart;
