import React from 'react';
import { DayPicker } from 'react-day-picker';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

/**
 * This is a TypeScript React component that renders a calendar using DayPicker library with
 * customizable styling.
 * @param {any}  - - `selected`: the currently selected date(s)
 * @returns A React functional component named "Calendar" is being returned. It takes in several props
 * including "selected", "className", " ", "onSelect", and "props". It renders a DayPicker
 * component with various classNames and props passed down as well.
 */

function Calendar({
  selected,
  className,
  classNames,
  onSelect,
  disabledDays,
  customStyles,
  ...props
}: any) {
  const datePickerStyles = {
    months: 'naxatw-flex naxatw-relative naxatw-justify-center',
    month: 'naxatw-space-y-4 !naxatw-ml-0 naxatw-w-full',
    weekdays: 'naxatw-flex naxatw-justify-around naxatw-w-full',
    caption_label: 'naxatw-hidden',
    nav: 'naxatw-space-x-1 naxatw-flex naxatw-items-center naxatw-absolute naxatw-right-[10px] naxatw-top-[2px]',
    month_grid:
      'naxatw-w-full naxatw-p-2 naxatw-bg-white naxatw-rounded-lg naxatw-mt-4',
    day: 'naxatw-rounded-lg hover:naxatw-bg-primary-600 hover:naxatw-text-white naxatw-overflow-hidden  naxatw-p-1 naxatw-text-[16px] naxatw-text-center naxatw-w-full naxatw-cursor-pointer',
    day_button:
      'naxatw-w-full naxatw-px-[4px] naxatw-py-[2px] naxatw-font-semibold disabled:naxatw-text-grey-500 ',
    today:
      'naxatw-bg-white naxatw-text-red-500 naxatw-font-bold naxatw-rounded-lg hover:naxatw-bg-primary-600 hover:naxatw-text-white',
    selected:
      'naxatw-rounded-lg !naxatw-bg-primary-600 !naxatw-text-white !naxatw-bg-clip-content',
    outside:
      'naxatw-text-gray-400 !naxatw-font-light naxatw-pointer-events-none naxatw-opacity-0',
    week: '!naxatw-bg-transparent naxatw-flex',
    dropdowns:
      'naxatw-flex naxatw-align-center naxatw-gap-4 !naxatw-accent-matt-100',
    dropdown: 'naxatw-text-sm naxatw-font-semibold naxatw-text-matt-100',
    chevron: 'naxatw-fill-matt-100 naxatw-h-[20px] naxatw-w-[20px]',
  };

  return (
    <DayPicker
      mode="single"
      selected={selected}
      captionLayout="dropdown"
      fromYear={new Date().getFullYear() - 13}
      toYear={new Date().getFullYear() + 11}
      onSelect={onSelect}
      className="naxatw-w-full naxatw-rounded-lg  !naxatw-bg-white"
      classNames={{ ...datePickerStyles, ...classNames }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
