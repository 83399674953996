import { api, authenticated } from '.';

export const getSectorById = (id: string) => {
  return authenticated(api).get(`/sector/${id}/`);
};

export const getSubSectorList = (params: Record<string, any>) => {
  return authenticated(api).get('/sector/', { params });
};

export const getSubSectorById = (id: string) => {
  return authenticated(api).get(`/sector/${id}/`);
};

export const postSubSector = (payload: Record<string, any>) => {
  return authenticated(api).post(`/sector/`, payload);
};

export const patchSubSector = (id: string, payload: Record<string, any>) => {
  return authenticated(api).patch(`/sector/${id}/`, payload);
};

export const deleteSubSector = (id: string) => {
  return authenticated(api).delete(`/sector/${id}/`);
};

export const getSectorDropdownList = () => {
  return authenticated(api).get('/sector-group-dropdown/');
};
