import React from 'react';
import LoginSection from './LoginSection';
import MobileQuestion from './MobileQuestion';

const LoginComponent = () => {
  return (
    <div className="naxatw-grid lg:naxatw-grid-cols-2">
      <LoginSection />
      <MobileQuestion />
    </div>
  );
};

export default LoginComponent;
