/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useInfiniteQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useIntersectionObserver from '@Hooks/useIntersectionObserver';
import { useTypedSelector } from '@Store/hooks';
import { getPublicKnowledgeRepositoryData } from '@Services/publicKnowledgeRepository';
import Skeleton from '@Components/RadixComponents/Skeleton';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import KnowledgeCard from './KnowledgeCard';

const CardSection = ({ activeTab }: { activeTab: string }) => {
  const [isIntersecting, _, viewRef] = useIntersectionObserver();

  const searchText = useTypedSelector(
    state => state.knowledgeRepository.publicSearchText,
  );
  const selectedDate = useTypedSelector(
    state => state.knowledgeRepository.publicSelectedDate,
  );
  const selectedFilterOptions = useTypedSelector(
    state => state.knowledgeRepository.publicFilterOptions,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const fileTypeParams = useTypedSelector(
    state => state.mainDashboard.filterParams.fileType,
  );

  const {
    data: cardData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    isError,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'public-knowledge-repository-card-data',
      searchText,
      selectedDate,
      selectedFilterOptions,
      activeTab,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      startDate,
      endDate,
      fileTypeParams,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getPublicKnowledgeRepositoryData({
        page: pageParam,
        page_size: 5,
        search: searchText,
        published_date: selectedDate,
        is_contextual_indicator: !!activeTab?.includes('contextual'),
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        ...selectedFilterOptions,
        file_type: fileTypeParams ? fileTypeParams?.join(',') : ' ',
      });
      return res?.data;
    },
    getNextPageParam: lastPage => {
      return lastPage?.next_page ?? undefined;
    },
    select: response => {
      return response?.pages.reduce((acc, page) => {
        return [...acc, ...(page.results || [])];
      }, []);
    },
  });

  if (isError) {
    toast.error('Something went wrong');
  }

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, fetchNextPage, hasNextPage]);

  const knowledgeRepoData: any = cardData || [];

  return (
    <div className="naxatw-w-full naxatw-rounded-lg naxatw-bg-white lg:naxatw-h-[calc(100vh-15.15rem)]">
      {isLoading ? (
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-1 naxatw-bg-white naxatw-px-3 naxatw-py-2">
          {Array.from({ length: 3 }).map(() => (
            <Skeleton key={uuid()} className="naxatw-h-[14rem] naxatw-w-full" />
          ))}
        </div>
      ) : (
        <div className="naxatw-h-full">
          {isEmpty(knowledgeRepoData) ? (
            <div className="no-chart naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center ">
              <NoChartDataComponent />
            </div>
          ) : (
            <div className="scrollbar naxatw-h-full naxatw-overflow-x-auto  ">
              {knowledgeRepoData?.map((repository: Record<string, any>) => (
                <KnowledgeCard key={repository?.id} repository={repository} />
              ))}
              {/* view ref */}
              <div
                ref={viewRef}
                className="naxatw-pointer-events-none naxatw-h-0 naxatw-opacity-0"
              >
                viewRef
              </div>
              {isFetchingNextPage && (
                <Skeleton className="naxatw-h-[14rem] naxatw-w-full" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default hasErrorBoundary(CardSection);
