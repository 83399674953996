import { getColorRangesByValueList } from '@Utils/getLegendValues';

import lumbini from '@Assets/images/lumbini.png';
import madhesh from '@Assets/images/madesh.png';
import karnali from '@Assets/images/karnali.png';

export const exploreByGeographyCardsData = [
  {
    provinceId: 5,
    link: '/explore-by/geography/5',
    img: lumbini,
    heading: 'Lumbini Province',
    body: '8 programmes with 13 projects across 12 sectors, Collaborating with 40 first-tier partners, Allocated budget: £3.3 million',
  },
  {
    provinceId: 2,
    link: '/explore-by/geography/2',
    img: madhesh,
    heading: 'Madhesh Province',
    body: '8 programmes with 13 projects across 12 sectors, Collaborating with 40 first-tier partners, Allocated budget: £3.3 million',
  },
  {
    provinceId: 6,
    link: '/explore-by/geography/6',
    img: karnali,
    heading: 'Karnali Province',
    body: '8 programmes with 13 projects across 12 sectors, Collaborating with 40 first-tier partners, Allocated budget: £3.3 million',
  },
];

export const headerOptions = [
  {
    id: 1,
    title: 'Explore by Province',
    value: 'geography',
  },
  {
    id: 2,
    title: 'Explore by Programme',
    value: 'programmes',
  },
  {
    id: 3,
    title: 'Explore by Contextual Indicators',
    value: 'indicators',
  },
];

export function getChoropethForCount(
  data: Record<string, any>[] | string,
  selectedId: Array<number> | null,
  legendKey?: string,
) {
  if (!Array.isArray(data)) return '#E7F2FF';
  const choroPethFillColor: any = ['match', ['get', 'id']];
  const budgetMapping = getColorRangesByValueList(data, 'budget');
  if (Array.isArray(data)) {
    if (selectedId) {
      choroPethFillColor.push(...selectedId);
    }
    if (data.length > 0) {
      data?.forEach((district: Record<string, any>) => {
        if (choroPethFillColor.includes(district?.id) || !district?.id) return;
        choroPethFillColor.push(district?.id);
        let fillColor = null;
        budgetMapping.forEach((colorRange: any) => {
          const count = legendKey ? district[legendKey] : district?.count;
          const { min, max, color } = colorRange;
          if (count > min && count <= max) {
            fillColor = color;
          }
        });
        if (fillColor) choroPethFillColor.push(fillColor);
        else choroPethFillColor.push('#E7F2FF');
      });
    }
  }
  choroPethFillColor.push('#E7F2FF');
  return choroPethFillColor;
}

export const provincialPrioritiesTableData = {
  header: [
    {
      header: 'NIB',
      accessorKey: 'nib',
    },
    {
      header: 'GGN',
      accessorKey: 'ggn',
    },
    {
      header: 'LISP',
      accessorKey: 'lisp',
    },
    {
      header: 'Sahakarya',
      accessorKey: 'Sahakarya',
    },
    {
      header: 'Samartha',
      accessorKey: 'Samartha',
    },
    {
      header: 'AIIN',
      accessorKey: 'aiin',
    },
  ],
  detailsData: [
    {
      programme:
        'To increase production and productivity in the fields of agriculture, industry and tourism – creating jobs and fostering entrepreneurship',
      nib: true,
      ggn: true,
      lisp: true,
      Sahakarya: false,
      Samartha: false,
      aiin: false,
    },
    {
      programme:
        'To achieve high and rapid economic growth directed by dense interconnectivity and infrastructural development at the grassroots level',
      nib: false,
      ggn: false,
      lisp: true,
      Sahakarya: false,
      Samartha: false,
      aiin: false,
    },
    {
      programme:
        'To ensure universal access to education and health, and to create a healthy and skilled human capital',
      nib: false,
      ggn: false,
      lisp: false,
      Sahakarya: false,
      Samartha: true,
      aiin: false,
    },
    {
      programme:
        'To ensure accountability and good governance in the flow of services and dimensions of development through the use of the latest and advanced technology',
      nib: false,
      ggn: false,
      lisp: false,
      Sahakarya: false,
      Samartha: false,
      aiin: true,
    },
    {
      programme:
        'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      nib: true,
      ggn: true,
      lisp: true,
      Sahakarya: false,
      Samartha: false,
      aiin: false,
    },
  ],
};

export const columns = [
  {
    header: 'COMPONENT',
    accessorKey: 'component',
  },
  {
    header: 'FIRST TIER PARTNER',
    accessorKey: 'first_tier_partner',
  },
  {
    header: 'SECTORS',
    accessorKey: 'sectors',
  },
  {
    header: 'ACTIVE IN',
    accessorKey: 'active_in',
  },
];

export const programmeDetailsTableData = [
  {
    id: 1,
    title: ' Skills for Employment Programme (SEP)',
    columns,
    link: '/programme-details',
    demoData: [
      {
        component: '1.1 SAHS',
        first_tier_partner: 'The Asia Foundation',
        sectors: 'Health, Research',
        active_in: 'FG, PG, LG',
      },
      {
        component: '1.2 RNHSSIP',
        first_tier_partner: 'The Asia Foundation',
        sectors: 'Health, Research',
        active_in: 'LG',
      },
    ],
  },
  {
    id: 2,
    title: ' Climate Smart Development For Nepal',
    columns,
    link: 'programme/programme-details',
    demoData: [
      {
        component: '2.1 SAHS',
        first_tier_partner: 'The Asia Foundation',
        sectors: 'Health, Research',
        active_in: 'PG, LG',
      },
      {
        component: '2.2 RNHSSIP',
        first_tier_partner: 'The Asia Foundation',
        sectors: 'Health, Research',
        active_in: 'FG',
      },
    ],
  },
  {
    id: 3,
    title: ' Accelerating Investment and Infrastructure in Nepal (AiiN)',
    columns,
    link: '/programme-details',
    demoData: [
      {
        component: '3.1 SAHS',
        first_tier_partner: 'The Asia Foundation',
        sectors: 'Health, Research',
        active_in: 'PG',
      },
      {
        component: '3.2 RNHSSIP',
        first_tier_partner: 'The Asia Foundation',
        sectors: 'Health, Research',
        active_in: 'FG, PG',
      },
    ],
  },
];

export const descriptionData = {
  description: {
    name: 'Description',
    value: 'description',
  },
  data: [
    {
      name: 'Capital',
      data: 'Deukhuri',
      value: 'capital',
    },
    {
      name: 'Provincial Assembly',
      data: 'Unicameral (87 seats)',
      value: 'assembly',
    },
    {
      name: 'Chief Minister',
      data: 'Jokh Bahadur Mahara',
      value: 'incumbent',
    },
    {
      name: 'Principal Secretary',
      data: 'Mahadev Panta',
      value: 'secretary',
    },
    {
      name: 'Speaker',
      data: 'Niranjan Ghimire',
      value: 'spokeperson',
    },
    {
      name: 'No. of Ministries',
      data: '8',
      value: 'no_of_ministries',
    },
  ],
};

export const provincialProfileData = [
  { characteristic: 'No. of Palikas', value: 'no_of_palika', dataType: '' },
  { characteristic: 'No. of Districts', value: 'no_of_district', dataType: '' },
  {
    characteristic: 'Average Household Size',
    value: 'avg_household',
    dataType: '',
  },
  {
    characteristic: 'Population Density',
    value: 'population_density',
    dataType: '/km²',
  },
  // { characteristic: 'HDI', value: 'hdi', dataType: '' },
  { characteristic: 'Male', value: 'male', dataType: '%' },
  { characteristic: 'Female', value: 'female', dataType: '%' },
  // { characteristic: 'GDP Growth Rate', value: 'gdp_growth', dataType: '%' },
  // {
  //   characteristic: 'Poverty Headcount Rate',
  //   value: 'poverty_count',
  //   dataType: '%',
  // },
  {
    characteristic: 'Absentee Abroad',
    value: 'absentee_abroad',
    dataType: '%',
  },
  { characteristic: 'Literacy Rate', value: 'literacy_rate', dataType: '%' },
  {
    characteristic: 'Female Headed Household',
    value: 'female_household',
    dataType: '%',
  },
  {
    characteristic: 'Economically Active Population',
    value: 'economically_active',
    dataType: '%',
  },
  { characteristic: 'Disability', value: 'disability', dataType: '%' },
];

export const municipalityData = [
  {
    characteristic: 'Average Household Size',
    value: 'avg_household',
    dataType: '',
  },
  {
    characteristic: 'Population Density',
    value: 'population_density',
    dataType: '/km²',
  },
  { characteristic: 'Male', value: 'male', dataType: '%' },
  { characteristic: 'Female', value: 'female', dataType: '%' },
  // { characteristic: 'GDP Growth Rate', value: 'gdp_growth', dataType: '%' },
  // {
  //   characteristic: 'Poverty Headcount Rate',
  //   value: 'poverty_count',
  //   dataType: '%',
  // },
  {
    characteristic: 'Absentee Abroad',
    value: 'absentee_abroad',
    dataType: '%',
  },
  { characteristic: 'Literacy Rate', value: 'literacy_rate', dataType: '%' },
  {
    characteristic: 'Female Headed Household',
    value: 'female_household',
    dataType: '%',
  },
  {
    characteristic: 'Economically Active Population',
    value: 'economically_active',
    dataType: '%',
  },
  { characteristic: 'Disability', value: 'disability', dataType: '%' },
];

export const nepalDescriptionData = {
  no_of_palika: 753,
  no_of_district: 77,
  avg_household: 4.37,
  population_density: 216, // Assuming '216/km2' as just '216'
  hdi: 0.601,
  male: 48.98,
  female: 51.02,
  gdp_growth: 5.6,
  poverty_count: 20.3,
  absentee_abroad: 23.4,
  literacy_rate: 76.3,
  female_household: 31.55,
  economically_active: 65.5,
  disability: 2.2,
};

// sankey chart data

export const keyDPsData = {
  nodes: [
    { name: 'Environment Protection' },
    { name: 'Humanitarian Assistance' },
    { name: 'Socail Services and Infrastructure' },
    { name: 'Education' },
    { name: 'Government and State Building' },
    { name: 'Abt Britain Ltd' },
    { name: 'Action Against Hunger UK' },
    { name: 'Action Aid International' },
    { name: 'Adventist Dev & Relief Agency (ADRA)' },
    { name: 'Asian Development Bank' },
    { name: 'British Council Nepal' },
  ],
  links: [
    { source: 0, target: 5, value: 5 },
    { source: 0, target: 7, value: 5 },
    { source: 1, target: 5, value: 5 },
    { source: 2, target: 6, value: 5 },
    { source: 3, target: 10, value: 5 },
    { source: 3, target: 8, value: 5 },
    { source: 4, target: 6, value: 5 },
    { source: 4, target: 8, value: 5 },
    // { source: 9, target: 9, value: 5},
  ],
};

export const keySkateHoldersData = {
  nodes: [
    { name: 'PLGSP' },
    { name: 'SFP' },
    { name: 'CSDN' },
    { name: 'NHSP III' },
    { name: 'SDRN' },
    { name: 'Ministry of Defence' },
    { name: 'Ministry of Foreign Affairs' },
    { name: 'Ministry of Home Affairs' },
    { name: 'Ministry of Finance' },
    { name: 'Ministry of Urban Development' },
    { name: 'Ministry of Culture and Tourism' },
  ],
  links: [
    { source: 0, target: 5, value: 5 },
    { source: 0, target: 7, value: 5 },
    { source: 1, target: 5, value: 5 },
    { source: 2, target: 6, value: 5 },
    { source: 3, target: 10, value: 5 },
    { source: 3, target: 8, value: 5 },
    { source: 4, target: 6, value: 5 },
    { source: 4, target: 8, value: 5 },
  ],
};

// accordion data of explore by geography page

export const AccordianData = [
  {
    title: 'Nepal Health Sector Programme (NHSP III)',
    component: 'Social Accountability in the health sector',
  },
  {
    title: 'Skills for Employment Implementation Programme',
    component: 'Social Accountability in the health sector',
  },
  {
    title: 'Nepal Renewable Energy Programme',
    component: 'Social Accountability in the health sector',
  },
  {
    title: 'Accelerating Investment and Infrastructure in Nepal (AIIN)',
    component: 'Social Accountability in the health sector',
  },
  {
    title: 'Nepal Health Sector Programme III',
    component: 'Social Accountability in the health sector',
  },
  {
    title: 'Evidence for Development',
    component: 'Social Accountability in the health sector',
  },
];

export const mapPopulationByProvince: Record<string, any> = {
  'Lumbini Province': '17.6%',
  'Madhesh Province': '21.0%',
  'Karnali Province': '5.8%',
};

export const mapWikikpediaByProvince: Record<string, any> = {
  'Lumbini Province': 'https://en.wikipedia.org/wiki/Lumbini_Province',
  'Madhesh Province': 'https://en.wikipedia.org/wiki/Madhesh_Province',
  'Karnali Province': 'https://en.wikipedia.org/wiki/Karnali_Province',
};
