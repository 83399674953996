/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-vars */
import {
  comparisonByProvinceOptions,
  contextualIndicatorsOptions,
} from '@Constants/exploreByIndicators';
import LabeledDropDown from '@Components/common/LabeledDropdown';
import Icon from '@Components/common/Icon';
import { Button } from '@Components/RadixComponents/Button';
import {
  setSelectedProvinceOptions,
  setComparisonIndicatorsOptions,
  // removeComparisonIndicatorsOptions,
  // removeSelectedProvinceOptions,
  // setSelectedProvince,
  // setSelectedMunicipality,
  // setSelectedDistrictOption,
  setFetchData,
  // removeSelectedMunicipalityOptions,
  // removeSelectedProvince,
  // removeSelectedDistrict,
  emptyAllData,
  setSelectedMunicipalityData,
} from '@Store/actions/portfolioProfiles';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { getDistrict, getMunicipality } from '@Services/portfolioReporting';
import { useEffect, useState } from 'react';
import { set } from 'date-fns';
// import IndicatorsBox from './IndicatorsBox';

const ComparisonBox = () => {
  const [formattedMunicipalityData, setFormattedMunicipalityData] =
    useState<any>([]);
  const [formattedDistrictData, setFormattedDistrictData] = useState<any>([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState<any>([]);
  const [selectedProvince, setSelectedProvince] = useState<any>([]);
  const [indicatorValues, setIndicatorValues] = useState<any>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>([]);
  const dispatch = useTypedDispatch();
  const {
    selectedProvinceOptions,
    selectedComparisonIndicatorsOptions,
    compareBy,
    // selectedDistrict,
    // selectedMunicipalityOptions,
    selectedMunicipalityData,
    // fetchData,
  } = useTypedSelector(state => state.portfolioProfiles);
  const { data: districtOptions, isLoading: districtOptionsIsLoading } =
    useQuery({
      queryKey: ['districtOptions', selectedProvince],
      queryFn: async () => {
        const response = await getDistrict({
          province: selectedProvince.join(','),
        });
        return response?.data;
      },
      select: res => res,
      enabled: !!selectedProvince,
    });
  const { data: municipalityOptions, isLoading: municipalityOptionsIsLoading } =
    useQuery({
      queryKey: ['municipalityOptions', selectedProvince, selectedDistrict],
      queryFn: () =>
        getMunicipality({
          // province: selectedProvince,
          district: selectedDistrict.join(','),
        }),

      select: res => res.data,
      enabled: !!selectedDistrict,
    });
  useEffect(() => {
    setFormattedDistrictData(
      districtOptions?.map((district: any) => ({
        id: district.id,
        value: district.name,
        label: district.name,
      })),
    );
  }, [districtOptions]);
  useEffect(() => {
    setFormattedMunicipalityData(
      municipalityOptions?.map((municipality: any) => ({
        id: municipality.id,
        value: municipality.name,
        label: municipality.name,
      })),
    );
  }, [municipalityOptions]);

  useEffect(() => {
    setSelectedProvince([]);
    dispatch(setSelectedMunicipalityData([]));
    dispatch(setSelectedProvinceOptions([]));
    dispatch(setComparisonIndicatorsOptions([]));
    setIndicatorValues([]);
  }, [compareBy]);

  function clearAllDataSelection() {
    setSelectedMunicipality([]);
    setSelectedProvince([]);
    setIndicatorValues([]);
    setSelectedDistrict([]);
  }

  useEffect(() => {
    const provinceData = comparisonByProvinceOptions?.filter((province: any) =>
      selectedProvince.includes(province.id),
    );
    dispatch(setSelectedProvinceOptions(provinceData));
    setSelectedDistrict([]);
    setSelectedMunicipality([]);
  }, [selectedProvince, dispatch]);
  useEffect(() => {
    const municipalityData = formattedMunicipalityData?.filter(
      (municipality: any) => selectedMunicipality.includes(municipality.id),
    );
    dispatch(setSelectedMunicipalityData(municipalityData));
  }, [selectedMunicipality, dispatch]);

  useEffect(() => {
    const selectedIndicators = contextualIndicatorsOptions.filter(indicator =>
      indicatorValues.includes(indicator.id),
    );
    dispatch(setComparisonIndicatorsOptions(selectedIndicators));
  }, [indicatorValues, dispatch]);

  useEffect(() => {
    setSelectedMunicipality([]);
  }, [selectedDistrict]);

  return (
    <>
      <div className="naxatw-px-[1.5rem] naxatw-py-[1.25rem]">
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-4">
          {compareBy === 'Province' && (
            <>
              <LabeledDropDown
                options={comparisonByProvinceOptions}
                title="Province"
                className="naxatw-w-full"
                message="Select Minimum 2 Provinces to Compare"
                multiple
                lengthOfOptions={3}
                onChange={(newValue: any) => setSelectedProvince(newValue)}
                checkBox
                // dispatchAction={setSelectedProvinceOptions}
                value={selectedProvince}
              />
              <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-1">
                {selectedProvinceOptions?.map((province: any) => (
                  <div
                    className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-rounded-[1.25rem] naxatw-border naxatw-border-matt-200 naxatw-px-2 naxatw-py-1 naxatw-text-xs naxatw-text-matt-200"
                    key={province}
                  >
                    <p>{province.label}</p>
                    <Icon
                      name="close"
                      className="naxatw-ml-1 naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-text-xs naxatw-text-matt-100"
                      onClick={() =>
                        setSelectedProvince(
                          selectedProvince.filter(
                            (provinceId: any) => provinceId !== province.id,
                          ),
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {compareBy === 'Municipality' && (
            <>
              <LabeledDropDown
                options={comparisonByProvinceOptions}
                title="Province"
                className="naxatw-w-full"
                message="Select Province"
                onChange={(newValue: string) => {
                  setSelectedProvince(newValue);
                }}
                value={selectedProvince}
                multiple
                checkBox
              />
              <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-1">
                {selectedProvinceOptions?.map((province: any) => (
                  <div
                    className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-rounded-[1.25rem] naxatw-border naxatw-border-matt-200 naxatw-px-2 naxatw-py-1 naxatw-text-xs naxatw-text-matt-200"
                    key={province}
                  >
                    <p>{province.label}</p>
                    <Icon
                      name="close"
                      className="naxatw-ml-1 naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-text-xs naxatw-text-matt-100"
                      onClick={() =>
                        setSelectedProvince(
                          selectedProvince.filter(
                            (provinceId: any) => provinceId !== province.id,
                          ),
                        )
                      }
                    />
                  </div>
                ))}
              </div>
              <LabeledDropDown
                options={formattedDistrictData || []}
                title="District"
                className="naxatw-w-full"
                message="Select District"
                onChange={(newValue: string) => {
                  setSelectedDistrict(newValue);
                }}
                value={selectedDistrict}
                isLoading={districtOptionsIsLoading}
                multiple
                checkBox
              />
              <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-1">
                {selectedDistrict?.map((district: any) => {
                  const districtData = districtOptions.find(
                    (item: any) => item.id === district,
                  );

                  return (
                    <div
                      className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-rounded-[1.25rem] naxatw-border naxatw-border-matt-200 naxatw-px-2 naxatw-py-1 naxatw-text-xs naxatw-text-matt-200"
                      key={district}
                    >
                      <p>{districtData?.name}</p>
                      <Icon
                        name="close"
                        className="naxatw-ml-1 naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-text-xs naxatw-text-matt-100"
                        onClick={() =>
                          setSelectedDistrict(
                            selectedDistrict.filter(
                              (districtId: any) => districtId !== district,
                            ),
                          )
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <LabeledDropDown
                options={
                  municipalityOptionsIsLoading
                    ? []
                    : formattedMunicipalityData || []
                }
                title="Municipality"
                className="naxatw-w-full"
                message="Select Municipality"
                onChange={(newValue: any) => setSelectedMunicipality(newValue)}
                multiple
                checkBox
                lengthOfOptions={3}
                value={selectedMunicipality}
                isLoading={municipalityOptionsIsLoading}
              />
              <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-1">
                {selectedMunicipalityData?.map((municipality: any) => (
                  <div
                    className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-rounded-[1.25rem] naxatw-border naxatw-border-matt-200 naxatw-px-2 naxatw-py-1 naxatw-text-xs naxatw-text-matt-200"
                    key={municipality}
                  >
                    <p className="naxatw-w-full naxatw-whitespace-nowrap naxatw-text-nowrap">
                      {municipality.label}
                    </p>
                    <Icon
                      name="close"
                      className="naxatw-ml-1 naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-text-xs naxatw-text-matt-100"
                      onClick={() =>
                        setSelectedMunicipality(
                          selectedMunicipality.filter(
                            (municipalityId: any) =>
                              municipalityId !== municipality.id,
                          ),
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          <LabeledDropDown
            options={contextualIndicatorsOptions}
            title="Contextual Indicators"
            className="naxatw-w-full"
            message="Select Minimum 1 Indicator to Compare"
            multiple
            onChange={(newValue: any) => setIndicatorValues(newValue)}
            lengthOfOptions={5}
            checkBox
            // dispatchAction={setComparisonIndicatorsOptions}
            value={indicatorValues}
          />
          <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-1">
            {selectedComparisonIndicatorsOptions?.map((indicator: any) => (
              <div
                className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-rounded-[1.25rem] naxatw-border naxatw-border-matt-200 naxatw-px-2 naxatw-py-1 naxatw-text-xs naxatw-text-matt-200"
                key={indicator}
              >
                <p>{indicator.label}</p>
                <Icon
                  name="close"
                  className="naxatw-ml-1 naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-text-xs naxatw-text-matt-100"
                  onClick={() =>
                    setIndicatorValues(
                      indicatorValues.filter(
                        (indicatorId: any) => indicatorId !== indicator.id,
                      ),
                    )
                  }
                />
              </div>
            ))}
          </div>
          <div className="naxatw-full naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-[1rem] lg:naxatw-mt-10">
            <div
              role="button"
              className="naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-gap-1 naxatw-text-[#417EC9]"
              onClick={clearAllDataSelection}
            >
              <Icon
                name="restart_alt"
                className="naxatw-flex naxatw-items-center"
              />
              <p className="naxatw-text-sm naxatw-font-medium">Clear All</p>
            </div>
            <Button
              variant="primary"
              className="naxatw-rounded-lg naxatw-px-4 naxatw-py-2 naxatw-text-sm"
              onClick={() => dispatch(setFetchData())}
              // disabled={
              //   compareBy === 'Province'
              //     ? selectedProvinceOptions.length < 2 ||
              //       indicatorValues.length < 1
              //     : selectedMunicipalityData.length < 2 ||
              //       indicatorValues.length < 1
              // }
            >
              Compare
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonBox;
