import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@Components/RadixComponents/Button';
import { FormControl, Input } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import {
  getSectorById,
  patchSubSector,
  postSubSector,
} from '@Services/sectors';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import { FlexColumn } from '@Components/common/Layouts';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

type propType = {
  onClose: () => void;
};

const SectorForm = ({ onClose }: propType) => {
  const navigate = useNavigate();
  const { sectorId } = useParams();
  const queryClient = useQueryClient();

  const [sectorName, setSectorName] = useState('');
  const [sectorCode, setSectorCode] = useState('');
  const [sectorCodeErrMsg, setSectorCodeErrMsg] = useState('');
  const [error, setError] = useState({
    sectorError: false,
    sectorCodeError: false,
  });

  // fetch sector to populate sector name if it is an update
  const { isFetching: isSectorFetching } = useQuery({
    queryKey: ['sector-by-id', sectorId],
    enabled: !!sectorId,
    queryFn: () => getSectorById(sectorId || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      if (!data) return;
      setSectorName(data.name);
      setSectorCode(data.code);
    },
  });

  // post sector
  const { mutateAsync: addSectorData, isLoading: isAddSectorDataLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postSubSector(payloadData),
      onError: (err: any) => {
        const caughedError = err?.response?.data?.message;
        toast.error(caughedError || 'Could not add Sector. Please try again.');
      },
    });

  // patch sector
  const {
    mutateAsync: updateSectorData,
    isLoading: isUpdateSectorDataLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      patchSubSector(payload.id, payload.payload),
    onError: (err: any) => {
      const caughedError = err?.response?.data?.message;
      toast.error(caughedError || 'Could not add Sector. Please try again.');
    },
  });

  // handle submit sector
  const saveSector = async () => {
    if (!sectorName?.trim()) {
      setError(prev => ({ ...prev, sectorError: true }));
      return;
    }

    if (!sectorCode?.trim()) {
      setSectorCodeErrMsg('Code is Required');
      setError(prev => ({ ...prev, sectorCodeError: true }));
      return;
    }

    if (!/^\d+$/.test(sectorCode.trim())) {
      setError(prev => ({ ...prev, sectorCodeError: true }));
      setSectorCodeErrMsg('Code must be number only');
      return;
    }

    const payload = {
      name: sectorName?.trim(),
      code: sectorCode?.trim(),
    };

    if (!sectorId) {
      await addSectorData(payload);
    } else {
      await updateSectorData({ id: sectorId, payload });
    }

    queryClient.invalidateQueries({ queryKey: ['sector-tabledata'] });
    toast.success(
      sectorId ? 'Sector updated successfully' : 'Sector added successfully',
    );
    navigate('/data-bank/sectors/sector');
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading={`Sector / ${sectorId ? 'Edit' : 'Add'}`}
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>

      <div className="naxatw-h-[19.5rem] naxatw-w-full">
        <FlexColumn className="naxatw-relative naxatw-h-full naxatw-w-full naxatw-px-6">
          {isSectorFetching ? (
            <FormSkeleton className="!naxatw-w-full" numRows={2} />
          ) : (
            <>
              <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-5 naxatw-pt-5">
                <FormControl>
                  <InputLabel
                    id="sector-name"
                    label="Sector Name"
                    astric
                    className="naxatw-mb-2"
                  />
                  <Input
                    type="text"
                    placeholder="Sector"
                    className="!naxatw-w-full"
                    value={sectorName}
                    onChange={e => {
                      setSectorName(e.target.value);
                      if (error)
                        setError(prev => ({ ...prev, sectorError: false }));
                    }}
                  />
                  {error.sectorError && (
                    <p className="naxatw-pt-1 naxatw-text-sm naxatw-text-red-500">
                      Sector Name is Required
                    </p>
                  )}
                </FormControl>
                <FormControl>
                  <InputLabel
                    id="sector-code"
                    label="Code"
                    astric
                    className="naxatw-mb-2"
                  />
                  <Input
                    type="text"
                    placeholder="Code"
                    className="!naxatw-w-full"
                    value={sectorCode}
                    onChange={e => {
                      setSectorCode(e.target.value);
                      if (error)
                        setError(prev => ({ ...prev, sectorCodeError: false }));
                    }}
                  />
                  {error.sectorCodeError && (
                    <p className="naxatw-pt-1 naxatw-text-sm naxatw-text-red-500">
                      {sectorCodeErrMsg}
                    </p>
                  )}
                </FormControl>
              </FlexColumn>
              <div className="naxatw-px-6 naxatw-py-5 naxatw-text-center">
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  className="naxatw-w-fit naxatw-px-4"
                  onClick={() => saveSector()}
                  isLoading={
                    isAddSectorDataLoading || isUpdateSectorDataLoading
                  }
                  disabled={
                    isAddSectorDataLoading ||
                    isUpdateSectorDataLoading ||
                    isSectorFetching
                  }
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default hasErrorBoundary(SectorForm);
