import { FlexColumn } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { v4 as uuidv4 } from 'uuid';

const RoleSkeleton = ({ numRows = 6, numItems = 4 }) => {
  const skeletonRows = Array.from({ length: numRows });
  const skeletonItems = Array.from({ length: numItems });

  return (
    <div className="naxatw-grid naxatw-h-full naxatw-grid-cols-2 naxatw-gap-5">
      {skeletonRows.map(() => {
        return (
          <div key={uuidv4()}>
            <div className="text-sk naxatw-mb-2">
              <Skeleton className="naxatw-h-5 naxatw-w-40 !naxatw-bg-gray-100" />
            </div>
            <FlexColumn className="card-sk naxatw-rounded-lg">
              <Skeleton className="naxatw-min-h-[150px] naxatw-w-full !naxatw-bg-gray-200 naxatw-p-3">
                {skeletonItems.map(() => {
                  return (
                    <div
                      className="naxatw-mb-3 naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-2"
                      key={uuidv4()}
                    >
                      <Skeleton className="naxatw-h-4 naxatw-w-4 !naxatw-bg-gray-100" />
                      <Skeleton className="naxatw-h-5 naxatw-w-40 naxatw-flex-1 !naxatw-bg-gray-100" />
                    </div>
                  );
                })}
              </Skeleton>
            </FlexColumn>
          </div>
        );
      })}
    </div>
  );
};

export default RoleSkeleton;
