/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React from 'react';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { FormProvider, useForm } from 'react-hook-form';
import FormTooltip from '@Components/common/FormComponent/FormTooltip';
import {
  defaultIndicatorsFormValues,
  indicatorsFormFields,
  tooltipInfo,
  indicatorFormStateType,
  defualtDisaggregation,
} from '@Constants/FormConstants/indicatorsFormConstants';
import groupFormElements from '@Utils/index';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import {
  deleteIndicatorDisaggregationById,
  getComponentDropdown,
  getIndicatorById,
  getIndicatorDisaggregationById,
  // getIndicatorProgramComponent,
  getMeasurementUnit,
  getProgramDropdown,
  patchIndicator,
  patchIndicatorDisaggregation,
  postIndicator,
  postIndicatorDisaggregation,
  postIndicatorProgramComponent,
} from '@Services/indicators';
import InputIterator from '@Components/common/FormUI/InputIterator.tsx';
import { Button } from '@Components/RadixComponents/Button';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from '@Utils/isEmpty';
import { toast } from 'react-toastify';
import { IndicatorsFormValidationSchema } from '@Validations/IndicatorForm';
import { zodResolver } from '@hookform/resolvers/zod';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import AssignComponentProgram from './AssignComponentProgram';

type propType = {
  onClose: () => void;
};

const IndicatorsForm = ({ onClose }: propType) => {
  const { indicatorId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const indicatorFormFields = groupFormElements(indicatorsFormFields);

  const formMethods = useForm({
    mode: 'onChange',
    resolver: zodResolver(IndicatorsFormValidationSchema),
    defaultValues: defaultIndicatorsFormValues,
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { dirtyFields, errors },
    watch,
  } = formMethods;

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
  };

  const selectedProgram = watch('programme');

  const getDirtyFieldValues = () => {
    const formValues = getValues();
    const dirtyValues: any = {};
    if (formValues?.indicator && dirtyFields?.indicator) {
      dirtyValues.indicator = {};
      Object.keys(formValues.indicator).forEach((key: string) => {
        // @ts-ignore
        if (dirtyFields?.indicator?.[key as keyof typeof dirtyFields]) {
          dirtyValues.indicator[key] =
            // @ts-ignore
            formValues?.indicator?.[key as keyof typeof dirtyFields];
        }
      });
    }
    if (dirtyFields.disaggregations) {
      dirtyValues.disaggregations = formValues?.disaggregations.filter(
        (_, index: number) => dirtyFields?.disaggregations?.[index],
      );
    }
    if (dirtyFields.programComponents) {
      dirtyValues.programComponents = formValues.programComponents;
    }

    return dirtyValues;
  };

  // program & component dropdown list fetch
  const { data: measurementUnitList, isLoading: measurementUnitListLoading } =
    useQuery({
      queryKey: ['measurement-unit-list'],
      queryFn: () => getMeasurementUnit(),
      select: response =>
        response?.data?.map((unit: Record<string, any>) => ({
          ...unit,
          value: unit?.name,
          label: unit?.name,
        })),
    });
  const { data: programList, isLoading: isProgramListLoading } = useQuery({
    queryKey: ['program-list'],
    queryFn: () => getProgramDropdown(),
    select: response =>
      response?.data?.map((program: Record<string, any>) => ({
        ...program,
        label: program?.name,
      })),
  });
  const { data: filteredComponentList } = useQuery({
    enabled: !!selectedProgram,
    queryKey: ['filtered-component-list', selectedProgram],
    queryFn: () => getComponentDropdown({ program: selectedProgram }),
    select: response =>
      response?.data?.map((component: Record<string, any>) => ({
        ...component,
        label: component?.name,
      })),
  });
  const { data: componentList, isLoading: isComponentListLoading } = useQuery({
    queryKey: ['component-list'],
    queryFn: () => getComponentDropdown({}),
    select: response =>
      response?.data?.map((component: Record<string, any>) => ({
        ...component,
        label: component?.name,
      })),
  });

  // previous form data fields
  const { isLoading: isIndicatorLoading } = useQuery({
    enabled: !!indicatorId,
    queryKey: ['indicator-list', indicatorId],
    queryFn: () => getIndicatorById(indicatorId || ''),
    select: data => data?.data,
    onError: () => toast.error('Failed to fetch indicator. Try again'),
    onSuccess: data => {
      setValue('indicator', {
        indicator: data?.indicator,
        indicator_definition: data?.indicator_definition,
        measurement_unit: data?.measurement_unit,
      });
    },
  });
  const { isLoading: isDisaggregationIndicatorLoading } = useQuery({
    enabled: !!indicatorId,
    queryKey: ['indicator-disaggregation-list', indicatorId],
    queryFn: () => getIndicatorDisaggregationById(indicatorId || ''),
    select: data => data?.data,
    onError: () =>
      toast.error('Failed to fetch indicator disaggregations. Try again'),
    onSuccess: data => {
      if (data?.length > 0) {
        setValue('disaggregations', data);
      } else {
        setValue('disaggregations', [defualtDisaggregation]);
      }
    },
  });

  // const { isLoading: isProgramComponentIndicatorLoading } = useQuery({
  //   enabled: !!indicatorId,
  //   queryKey: ['indicator-program-component-list', indicatorId],
  //   queryFn: () => getIndicatorProgramComponent(indicatorId || ''),
  //   select: data => data?.data,
  //   onError: () => toast.error('Failed to fetch data. Try again'),
  //   onSuccess: data => {
  //     setValue('programComponents', data);
  //   },
  // });

  // post form data mutation function
  const { mutateAsync: postIndicators, isLoading: isPostIndicatorLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postIndicator(payloadData),
      onError: () => toast.error('Failed to post indicator. Try again'),
    });
  const {
    mutateAsync: postIndicatorDisaggregations,
    isLoading: isPostIndicatorDisaggregationsLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postIndicatorDisaggregation(payloadData),
    onError: () =>
      toast.error('Failed to post indicator disaggregations. Try again'),
  });
  const {
    mutateAsync: postIndicatorProgramComponents,
    isLoading: isPostIndicatorComponentsLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postIndicatorProgramComponent(payloadData),
    onError: () => toast.error('Failed to post data. Try again'),
  });

  // patch form data mutation function
  const { mutateAsync: patchIndicators, isLoading: isPatchIndicatorLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        patchIndicator(indicatorId || '', payloadData),
      onError: () =>
        toast.error('Failed to post indicator disaggregations. Try again'),
    });
  const {
    mutateAsync: patchIndicatorDisaggregations,
    isLoading: isPatchIndicatorDisaggregationsLoading,
  } = useMutation({
    mutationFn: ({
      id,
      payload,
    }: {
      id: string;
      payload: Record<string, any>;
    }) => patchIndicatorDisaggregation(id, payload),
    onError: () =>
      toast.error('Failed to post indicator disaggregations. Try again'),
  });

  // post indicators form
  const postIndicatorsForm = async (formValues: indicatorFormStateType) => {
    if (isEmpty(formValues)) return;
    const { indicator, disaggregations, programComponents } = formValues;

    // post indicator
    const indicatorResponse = await postIndicators(indicator);
    const currIndicatorId = indicatorResponse?.data?.id;

    // post indicator disaggregations with indicator id
    const postIndicatorDisaggregationsPromises = disaggregations?.map(
      async (disaggregation: Record<string, any>) => {
        await postIndicatorDisaggregations({
          indicator: currIndicatorId,
          disaggregation_unit: disaggregation?.disaggregation_unit,
        });
      },
    );
    await Promise.all(postIndicatorDisaggregationsPromises);

    // post indicator program components with indicator id
    const postIndicatorProgramComponentsPromises = programComponents?.map(
      async (programComponent: Record<string, any>) => {
        await postIndicatorProgramComponents({
          indicator: currIndicatorId,
          component: programComponent?.component,
          program: programComponent?.program,
        });
      },
    );
    await Promise.all(postIndicatorProgramComponentsPromises);
  };

  // patch indicators form
  const patchIndicatorsForm = async (formValues: indicatorFormStateType) => {
    if (isEmpty(formValues)) return;
    const { indicator, disaggregations, programComponents } = formValues;

    if (indicator) {
      const patchIndicatorPromise = await patchIndicators(indicator);
    }

    if (disaggregations) {
      const postPatchIndicatorDisaggregationsPromises = disaggregations?.map(
        async (disaggregation: Record<string, any>) => {
          // if indicator is already present patch else post
          if (disaggregation?.id) {
            await patchIndicatorDisaggregations({
              id: disaggregation?.id,
              payload: {
                indicator: indicatorId,
                disaggregation_unit: disaggregation?.disaggregation_unit,
              },
            });
          } else {
            await postIndicatorDisaggregations({
              indicator: indicatorId,
              disaggregation_unit: disaggregation?.disaggregation_unit,
            });
          }
        },
      );
      await Promise.all(postPatchIndicatorDisaggregationsPromises);
    }

    // if (programComponents) {
    //   const postIndicatorProgramComponentsPromises = programComponents?.map(
    //     async (programComponent: Record<string, any>) => {
    //       if (!programComponent?.id) {
    //         await postIndicatorProgramComponents({
    //           indicator: indicatorId,
    //           component: programComponent?.component,
    //           program: programComponent?.program,
    //         });
    //       }
    //     },
    //   );
    //   await Promise.all(postIndicatorProgramComponentsPromises);
    // }
  };

  const handleFormSubmit = async () => {
    try {
      const values = getValues();

      const editFields = getDirtyFieldValues();

      if (indicatorId) {
        await patchIndicatorsForm(editFields);
      } else {
        await postIndicatorsForm(values);
      }

      toast.success(
        indicatorId
          ? 'Indicators updated successfully'
          : 'Indicators added successfully ',
      );
      queryClient.invalidateQueries({
        queryKey: ['indicators-tabledata'],
      });
      navigate('/data-bank/indicators');
      onClose();
      reset();
    } catch (error) {
      toast.error('Error occured while saving!');
    }
  };

  const isFormLoading =
    (!indicatorId && (isProgramListLoading || measurementUnitListLoading)) ||
    (indicatorId &&
      (measurementUnitListLoading ||
        isProgramListLoading ||
        isComponentListLoading ||
        isIndicatorLoading ||
        isDisaggregationIndicatorLoading));

  const isButtonLoading =
    isPostIndicatorLoading ||
    isPostIndicatorDisaggregationsLoading ||
    isPostIndicatorComponentsLoading ||
    isPatchIndicatorLoading ||
    isPatchIndicatorDisaggregationsLoading;

  return (
    <div
      className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-max-w-[80%] naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem] xl:naxatw-w-[40%]"
      style={{ transform: 'translate(-50%, calc(-50% + 49px))' }}
    >
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading="Indicators / Form"
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="naxatw-flex naxatw-w-full naxatw-gap-3">
        <div className="naxatw-w-full naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
          {isFormLoading ? (
            <FormSkeleton numRows={4} />
          ) : (
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FlexColumn className="indicator-form scrollbar naxatw-min-h-[26rem] naxatw-w-full naxatw-space-y-5 naxatw-overflow-y-scroll md:naxatw-pr-2.5 lg:naxatw-h-[calc(100vh-24rem)]">
                  {indicatorFormFields?.map(
                    (fieldRow: Record<string, any>, i) => (
                      <FlexRow key={i}>
                        {fieldRow?.map((field: FormFieldProps) => {
                          const { id } = field;
                          return (
                            <FormControl
                              className="naxatw-w-full naxatw-gap-[0.5rem] "
                              key={`${field.id}- ${field.name}`}
                            >
                              <InputLabel
                                label={field.label}
                                // tooltipMessage={field.label}
                                astric={field.required}
                                id={field.id}
                              />
                              {getInputElement(
                                {
                                  ...field,
                                  id: `indicator.${field.id}`,
                                },
                                /* @ts-ignore */
                                formProps,
                                measurementUnitList || [],
                              )}
                              <>
                                {
                                  /* @ts-ignore */
                                  formProps?.errors?.indicator?.[id] && (
                                    <ErrorMessage
                                      /* @ts-ignore */
                                      message={
                                        /* @ts-ignore */
                                        formProps?.errors?.indicator?.[id]
                                          ?.message
                                      }
                                    />
                                  )
                                }
                              </>
                            </FormControl>
                          );
                        })}
                      </FlexRow>
                    ),
                  )}
                  <InputIterator
                    title="Disaggregation Unit"
                    fieldArrayName="disaggregations"
                    fieldKey="disaggregation_unit"
                    defaultField={defualtDisaggregation}
                    deleteMutationFn={deleteIndicatorDisaggregationById}
                    errors={formProps?.errors?.disaggregations}
                    hasPopupOverlay
                  />
                  {/* <AssignComponentProgram
                    programList={programList}
                    componentList={componentList}
                    filteredComponentList={filteredComponentList}
                  /> */}
                </FlexColumn>
                <div className="naxatw-flex naxatw-justify-center naxatw-py-5">
                  <Button
                    size="sm"
                    variant="primary"
                    className="naxatw-px-4 naxatw-py-2"
                    type="submit"
                    disabled={isButtonLoading}
                    isLoading={isButtonLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(IndicatorsForm);
