interface ISearchProps {
  placeholder?: string;
  value?: string;
  onChange?: () => void;
}

const Search = ({ placeholder, value, onChange }: ISearchProps) => {
  return (
    <div className="search wrapper naxatw-wrapper naxatw-w-full">
      <input
        type="search"
        value={value}
        onChange={onChange}
        className="naxatw-relative naxatw-w-full naxatw-border-b naxatw-border-[#EAECF0] naxatw-pb-1 naxatw-text-[0.875rem] naxatw-font-medium focus:naxatw-outline-none"
        placeholder={placeholder}
      />
    </div>
  );
};

export default Search;
