import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { comparisonSwitchOptions as options } from '@Constants/exploreByIndicators';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  setCompareByTab,
  emptyAllData,
} from '@Store/actions/portfolioProfiles';

const ComparisonTabSwitcher = () => {
  const dispatch = useTypedDispatch();
  const { compareBy } = useTypedSelector(state => state.portfolioProfiles);

  function emptyComparisonDataFromStore(value: string) {
    dispatch(setCompareByTab(value));
    dispatch(emptyAllData());
  }

  return (
    <>
      <div className="naxatw-flex naxatw-w-full naxatw-items-start naxatw-gap-[0.84rem] naxatw-self-stretch naxatw-px-6 naxatw-py-3">
        <SwitchTab
          title="Compare By"
          options={options}
          activeLabel={compareBy}
          defaultBg={false}
          defaultBehaviour={false}
          onChange={value => emptyComparisonDataFromStore(value)}
          className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
          titleClassName="naxatw-text-sm"
        />
      </div>
    </>
  );
};

export default ComparisonTabSwitcher;
