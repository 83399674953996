import StatusChips from '@Components/common/StatusChips';
import { useSearchParams } from 'react-router-dom';
import { convertToCurrencySystem } from '@Utils/index';
import { mappedStatus } from '@Constants/mainDashboard';

interface SubHeaderProps {
  status: string;
  id: number;
  componentCount?: number;
  sectorCount?: number;
  allocatedBudget?: number;
}

const SubHeader = ({
  status,
  id,
  componentCount,
  sectorCount,
  allocatedBudget,
}: SubHeaderProps) => {
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');
  return (
    <>
      <div className="naxatw-z-10 naxatw-flex naxatw-w-full naxatw-py-3">
        <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between">
          <div className="naxatw-flex naxatw-items-center naxatw-gap-5">
            <h5 className="naxatw-text-base naxatw-font-medium naxatw-text-[#484848]">
              Code: {id}
            </h5>
            <StatusChips status={mappedStatus[`${status}`]} label={status} />

            <div className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-px-3">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="27"
                viewBox="0 0 2 27"
                fill="none"
              >
                <path d="M1 0V27" stroke="black" />
              </svg> */}
              {!componentId ? (
                <p className="naxatw-text-base naxatw-font-semibold naxatw-leading-normal naxatw-text-[#0B2E62]">
                  {componentCount || 0} Projects &nbsp;
                  <span className="naxatw-text-sm naxatw-font-medium naxatw-text-[#667085]">
                    across &nbsp;
                  </span>
                  {sectorCount || 0} Sectors
                </p>
              ) : (
                <p className="naxatw-text-base naxatw-font-semibold naxatw-leading-normal naxatw-text-[#0B2E62]">
                  {sectorCount || 0} Sectors
                </p>
              )}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="27"
                viewBox="0 0 2 27"
                fill="none"
              >
                <path d="M1 0V27" stroke="black" />
              </svg>

              <p className="naxatw-text-base naxatw-font-semibold naxatw-leading-normal naxatw-text-[#0B2E62]">
                <span className="naxatw-text-sm naxatw-font-medium naxatw-text-[#667085]">
                  Allocated Budget &nbsp;
                </span>
                &#163; {convertToCurrencySystem(allocatedBudget || 0)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
