import { useState } from 'react';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import { getPartners, deletePartner } from '@Services/partner';
import { useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTypedSelector } from '@Store/hooks';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
// import { deletePartner } from '@Services/partner';

const PartnersTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [partnerIDToDelete, setPartnerIDToDelete] = useState<string>('');

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.partners,
  );

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const {
    mutate: deletePartners,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deletePartner(partnerIDToDelete),
    onSuccess: () => {
      toast.success('Delete Success');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({ queryKey: ['partner-tabledata'] });
    },
  });

  const columns = [
    {
      header: 'FIRST TIER PARTNER NAME',
      accessorKey: 'name',
    },
    {
      header: 'TYPE OF FIRST TIER PARTNER',
      accessorKey: 'organization_type__name',
    },
    {
      header: 'ADDRESS',
      accessorKey: 'address',
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;

        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            {userGroupPermission?.includes('Can change organization') ? (
              <ToolTip
                name="edit"
                message="Edit"
                className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
                iconClick={() => navigate(`/data-bank/partners/edit/${cellId}`)}
              />
            ) : null}
            {userGroupPermission?.includes('Can delete organization') ? (
              <ToolTip
                name="delete"
                message="Delete"
                className="naxatw-text-icon-md  naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
                iconClick={() => {
                  setOpenDeleteConfirmation(prev => !prev);
                  setPartnerIDToDelete(cellId);
                }}
              />
            ) : null}
          </FlexRow>
        );
      },
    },
  ];
  return (
    <div className="naxatw-h-[calc(100%-7rem)] naxatw-w-full lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="partner-tabledata"
        queryFn={getPartners}
        // queryFnParams={() => getPartners}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
        showDataCount
        dataCountCategory="Partners"
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deletePartners()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(PartnersTable);
