interface IHorizontalLabelProps {
  width: number;
  value: string;
  label: string;
  flip?: boolean;
  isDefault?: boolean;
}

export default function HorizontalBarLabel({
  width,
  value,
  label,
  isDefault = true,
  // to flip the label, so  that when chart is flipped, label flips to 360 degree

  flip = false,
}: IHorizontalLabelProps) {
  return (
    <>
      <div className="bar naxatw-group naxatw-relative naxatw-h-8 naxatw-w-full">
        <div
          className="fill naxatw-h-full naxatw-rounded-r-lg naxatw-bg-[#B7E7E4] naxatw-bg-opacity-50
           naxatw-transition-all naxatw-duration-500 naxatw-ease-in-out group-hover:naxatw-border "
          style={{
            width: `${width}%`,
          }}
        />
        <div className="content naxatw-absolute  naxatw-top-1/2 -naxatw-translate-y-1/2 naxatw-translate-x-2">
          <div className="cover naxatw-flex naxatw-h-full naxatw-items-center naxatw-justify-center naxatw-gap-4">
            <p
              className="value naxatw-text-sm naxatw-font-bold naxatw-text-primary-700"
              style={flip ? { transform: 'rotateY(-180deg)' } : {}}
            >
              {isDefault ? value : ''}
            </p>
            <p
              className="label naxatw-mb-1 naxatw-line-clamp-1 naxatw-text-sm naxatw-text-grey-800 "
              style={flip ? { transform: 'rotateY(-180deg)' } : {}}
            >
              {label}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
