/* eslint-disable react/no-danger */
import { useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Popup } from 'maplibre-gl';
import type { MapMouseEvent } from 'maplibre-gl';
import { Button } from '@Components/RadixComponents/Button';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { IAsyncPopup } from '../types';

const popup = new Popup({
  closeOnClick: false,
  closeButton: false,
});

interface IPopupUIComponent {
  isLoading: boolean;
  popupUI: any;
  properties: Record<string, any>;
  title: string;
  hideButton: boolean;
}

function PopupUIComponent({
  isLoading,
  popupUI,
  properties,
  title,
  hideButton,
}: IPopupUIComponent) {
  const popupHTML = useMemo(
    () => renderToString(popupUI(properties)),
    [popupUI, properties],
  );
  return (
    <div className="naxatw-relative naxatw-w-[17.5rem] naxatw-bg-white naxatw-px-3">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-py-2">
        {isLoading ? (
          <Skeleton className="naxatw-my-3 naxatw-h-4 naxatw-w-1/2 naxatw-rounded-md naxatw-bg-grey-100 naxatw-shadow-sm" />
        ) : (
          <p className="naxatw-btn-text naxatw-text-primary-400">{title}</p>
        )}
        <span
          id="popup-close-button"
          className="naxatw-absolute naxatw-right-3 naxatw-top-1 naxatw-cursor-pointer naxatw-rounded-full naxatw-text-grey-600 hover:naxatw-bg-green-100"
        >
          <i className="material-symbols-outlined">close</i>
        </span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: popupHTML }} />
      {!isLoading && !hideButton && (
        <div className="naxatw-p-3">
          <Button variant="link" size="sm" id="popup-button">
            View More
          </Button>
        </div>
      )}
    </div>
  );
}

export default function AsyncPopup({
  map,
  fetchPopupData,
  popupUI,
  title,
  handleBtnClick,
  isLoading = false,
  onClose,
  hideButton,
  // eslint-disable-next-line no-unused-vars
  showPopup = (_clickedFeature: Record<string, any>) => true,
}: IAsyncPopup) {
  const [properties, setProperties] = useState<Record<string, any> | null>(
    null,
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (!map) return;
    function displayPopup(e: MapMouseEvent): void {
      if (!map) return;
      const features = map.queryRenderedFeatures(e.point);
      const clickedFeature = features?.[0];

      // in case of popup rendering conditionally
      if (!showPopup(clickedFeature)) return;
      if (!clickedFeature) return;
      setProperties({
        ...clickedFeature.properties,
        layer: clickedFeature.source,
      });
      popup.setLngLat(e.lngLat);
    }
    map.on('click', displayPopup);
  }, [map, showPopup]);

  useEffect(() => {
    if (!map || !properties) return;
    fetchPopupData?.(properties);
  }, [map, properties]); // eslint-disable-line

  useEffect(() => {
    if (!map || !properties || !popupUI) return;
    const htmlString = renderToString(
      <PopupUIComponent
        isLoading={isLoading}
        popupUI={popupUI}
        properties={properties}
        title={title as string}
        hideButton={hideButton as boolean}
      />,
    );
    popup.setHTML(htmlString).addTo(map);
    setIsPopupOpen(true);
  }, [
    handleBtnClick,
    hideButton,
    isLoading,
    map,
    onClose,
    popupUI,
    properties,
    title,
  ]);

  useEffect(() => {
    const closeBtn = document.getElementById('popup-close-button');
    const popupBtn = document.getElementById('popup-button');

    const handleCloseBtnClick = () => {
      popup.remove();
      onClose?.();
      setProperties(null);
    };

    const handlePopupBtnClick = () => {
      if (!properties) return;
      handleBtnClick?.(properties);
    };

    closeBtn?.addEventListener('click', handleCloseBtnClick);
    popupBtn?.addEventListener('click', handlePopupBtnClick);

    return () => {
      closeBtn?.removeEventListener('click', handleCloseBtnClick);
      popupBtn?.removeEventListener('click', handlePopupBtnClick);
    };
  }, [isPopupOpen, onClose, properties, handleBtnClick]);

  if (!properties) return <div />;
  return null;
}
