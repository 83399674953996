interface ISectorsBoxDataProps {
  markerName: string;
  marker: string[];
  className?: string;
}
const MarkersCardBox = ({
  markerName,
  marker,
  className,
}: ISectorsBoxDataProps) => {
  return (
    <div className="naxatw-w-full naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-5 naxatw-shadow-sm">
      <div className="naxatw-flex naxatw-gap-5 ">
        <div
          className={`naxatw-flex naxatw-items-center naxatw-justify-center ${className}`}
        >
          <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-matt-200">
            {markerName}
          </p>
        </div>
        <div className="naxatw-w-[1px] naxatw-bg-[#CFE0F1]" />
        <ul className="naxatw-flex naxatw-list-inside naxatw-list-disc naxatw-flex-col naxatw-items-start naxatw-justify-center naxatw-gap-1">
          {marker?.map(sectorData => (
            <li
              className="naxatw-text-[0.875rem] naxatw-text-[#0B2E62]"
              key={sectorData}
            >
              {sectorData}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MarkersCardBox;
