/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import ToolTip from '@Components/RadixComponents/ToolTip';
import { MapInstanceType } from '@Components/common/MapLibreComponents/types';
import { FlexRow } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';

interface IMapToolsProps {
  map?: MapInstanceType | null;
}

const MapTools = ({ map }: IMapToolsProps) => {
  return (
    <FlexRow className="actions map-tools naxatw-absolute naxatw-bottom-5 naxatw-right-6 naxatw-z-10 naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-solid naxatw-border-grey-600 naxatw-shadow-[0px_2px_8px_0px_rgba(16,24,40,0.08)]">
      <div
        title="Zoom Out"
        className="zoom-out  naxatw-cursor-pointer naxatw-border-r naxatw-border-solid naxatw-border-r-grey-600 naxatw-bg-white naxatw-p-1  hover:naxatw-bg-grey-200"
        onClick={() => {
          map?.zoomOut();
        }}
      >
        <Icon name="remove" className="naxatw-text-[#212121]" />
      </div>
      <div
        title="Zoom In"
        className="zoom-in   naxatw-cursor-pointer naxatw-border-b naxatw-border-solid naxatw-border-b-grey-600 naxatw-bg-white naxatw-p-1  hover:naxatw-bg-grey-200"
        onClick={() => {
          map?.zoomIn();
        }}
      >
        <Icon name="add" className="naxatw-text-[#212121]" />
      </div>
    </FlexRow>
  );
};

export default MapTools;
