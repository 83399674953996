import Skeleton from '@Components/RadixComponents/Skeleton';

const ActivityLogCardSkeleton = () => {
  return (
    <div className="naxatw-mb-2 naxatw-flex  naxatw-w-full  naxatw-cursor-pointer naxatw-items-center naxatw-justify-between naxatw-rounded-xl naxatw-border naxatw-border-gray-300 naxatw-bg-white naxatw-p-3 hover:naxatw-bg-gray-50">
      <div className="naxatw-flex naxatw-w-full naxatw-flex-wrap naxatw-items-start naxatw-gap-3 lg:naxatw-flex-nowrap">
        <div className="naxatw-flex naxatw-h-fit naxatw-w-full naxatw-min-w-[200px] naxatw-flex-col naxatw-gap-1">
          <Skeleton className="naxatw-button naxatw-line-clamp-2 naxatw-h-8 naxatw-font-bold naxatw-text-gray-900" />
        </div>
      </div>
    </div>
  );
};

export default ActivityLogCardSkeleton;
