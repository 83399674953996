/* eslint-disable no-unused-vars */
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  nepalDescriptionData,
  provincialProfileData as provincialProfileDataColumns,
  municipalityData as municipalityDataColumns,
} from '@Constants/exploreByGeography';
import { getProvincialSummary, municipalSummary } from '@Services/goals';
import { parseFormattedNumber } from '@Utils/index';
import Skeleton from '@Components/RadixComponents/Skeleton';
import {
  getMunicipalityListByCode,
  getProvinceData,
} from '@Services/portfolioProfile';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import DescriptionCard from './DescriptionCard';
import CharacteristicsColumn from './Characteristics';
import CountData from './CountData';

export default function Description({
  activeProvinceName,
}: {
  activeProvinceName: Record<string, any>;
}) {
  const { provinceId } = useParams();

  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');

  const { data: provinceData, isLoading: provinceDataIsLoading } = useQuery({
    queryKey: ['province-data-name', provinceId],
    queryFn: async () => getProvincialSummary(provinceId),
    select: (res: any) => res.data[0],
    enabled: !!provinceId,
  });

  const { data: municipalityData, isLoading: isLoadingMunicipality } = useQuery(
    {
      queryKey: ['municipality-data', provinceId, municipalityCode],
      queryFn: async () => getMunicipalityListByCode(municipalityCode || ''),
      select: (res: any) => res.data,
      enabled: !!municipalityCode,
    },
  );

  const { data: municipalitySummary } = useQuery({
    queryKey: ['municipality-data-summary', provinceId, municipalityData],
    queryFn: async () =>
      municipalSummary(provinceId, municipalityData?.cbs_code || ''),
    select: res => res.data,
    enabled: !!(provinceId || municipalityData),
  });

  const { data: provinceName, isLoading: provinceNameIsLoading } = useQuery({
    queryKey: ['province-name', provinceId],
    queryFn: async () => getProvinceData(provinceId),
    select: (res: any) => res.data.name,
    enabled: !!provinceId,
  });

  return (
    <div className=" naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-xl naxatw-border-[1px] naxatw-border-[#EAECF0] naxatw-bg-white">
      {!municipalityCode && (
        <p className=" naxatw-px-6 naxatw-py-5 naxatw-text-[18px] naxatw-font-bold naxatw-text-[#475467]">
          Description
        </p>
      )}
      <div
        className={`naxatw-flex naxatw-flex-col  naxatw-px-6 ${municipalityCode ? 'naxatw-gap-1' : 'naxatw-gap-10 '} naxatw-pb-10 naxatw-pt-3`}
      >
        {!municipalityCode && (
          <div className="naxatw-border-t-[1px] naxatw-py-3  naxatw-text-[#4A4A4A]">
            <DescriptionCard
              cardHeadersData={provinceData || []}
              isLoading={provinceDataIsLoading}
              activeProvinceName={activeProvinceName || {}}
            />
          </div>
        )}
        <div
          className={`naxatw-flex naxatw-w-full naxatw-flex-col naxatw-items-center naxatw-justify-center naxatw-gap-3 ${municipalityCode ? '' : 'naxatw-border-b-[0.5px] naxatw-border-t-[0.5px]'}  naxatw-border-[#C0C7DA] naxatw-px-[1.6rem] naxatw-py-3`}
        >
          <p className="naxatw-text-center naxatw-text-base naxatw-font-bold naxatw-uppercase naxatw-leading-normal naxatw-tracking-[-0.01rem] naxatw-text-[#475467]">
            Key Context Indicators
          </p>
        </div>
        {!municipalityCode && (
          <div className="naxatw-flex naxatw-flex-col naxatw-items-center naxatw-gap-1">
            <p className="naxatw-text-sm naxatw-uppercase naxatw-leading-normal">
              Total Population
            </p>
            <p className=" naxatw-text-4xl naxatw-font-medium naxatw-leading-normal naxatw-text-[#06367D]">
              {provinceData?.total_population || ''}
            </p>
            <p className=" naxatw-text-sm naxatw-font-medium naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-[#06367D]">
              {(
                (parseFormattedNumber(provinceData?.total_population) /
                  29164578) *
                100
              ).toFixed(2)}{' '}
              % Share of National Population
            </p>
          </div>
        )}
      </div>
      {provinceDataIsLoading || provinceNameIsLoading ? (
        <div className="naxatw-flex naxatw-justify-between naxatw-px-6">
          <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
          <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
          <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
        </div>
      ) : (
        <div className="naxatw-flex naxatw-items-start naxatw-self-stretch naxatw-px-6">
          <CharacteristicsColumn />
          <CountData
            data={
              municipalityCode
                ? municipalitySummary?.[0] || []
                : provinceData || []
            }
            // data={provinceData || []}
            title={` ${municipalityCode ? municipalityData?.name : provinceName}`}
            columns={
              municipalityCode
                ? municipalityDataColumns
                : provincialProfileDataColumns
            }
          />
          <CountData
            data={nepalDescriptionData}
            title="Nepal"
            columns={
              municipalityCode
                ? municipalityDataColumns
                : provincialProfileDataColumns
            }
          />
        </div>
      )}
    </div>
  );
}
