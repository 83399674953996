import { Link, useLocation } from 'react-router-dom';
import { tutorialsAdminHeaderSwitchTabData } from '@Constants/tutorial';

const TutorialsHeaderSwitchTab = () => {
  const location = useLocation();

  const selectedTab = location.pathname.split('/')[3];

  return (
    <>
      <div className="naxatw-flex naxatw-flex-col naxatw-justify-start naxatw-gap-x-10">
        <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
          {tutorialsAdminHeaderSwitchTabData.map(header => (
            <h5
              className={`naxatw-border-b-2 naxatw-text-[0.875rem] naxatw-duration-200 hover:naxatw-text-[#0B2E62] ${
                selectedTab === header.value.split('/')[1]
                  ? ' naxatw-border-[#0B2E62] naxatw-py-0 naxatw-text-[#0B2E62]'
                  : 'naxatw-border-transparent naxatw-text-gray-500'
              }`}
              key={header.id}
            >
              <Link
                to={`/data-bank/${header.value.toLocaleLowerCase()}`}
                className="naxatw-cursor-pointer naxatw-whitespace-nowrap naxatw-px-2 naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-normal"
              >
                {header.title}
              </Link>
            </h5>
          ))}
        </div>
      </div>
    </>
  );
};

export default TutorialsHeaderSwitchTab;
