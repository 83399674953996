/* eslint-disable jsx-a11y/media-has-caption */
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import userManualDefaultThumbnail from '@Assets/images/userManualDefaultThumbnail.png';

export interface IDocumentCardProps {
  title: string;
  description: string;
  file: string;
  onClick: () => void;
  thumbnail?: string;
}

export const RowDocumentCards = ({
  title,
  file,
  description,
  onClick,
}: IDocumentCardProps) => {
  const formattedDescription =
    description.length > 130 ? `${description.slice(0, 130)}...` : description;

  return (
    <>
      <FlexRow
        className="naxatw-relative naxatw-h-fit naxatw-w-full naxatw-cursor-pointer naxatw-items-start naxatw-justify-center naxatw-gap-4 naxatw-overflow-hidden naxatw-px-4"
        onClick={() => onClick()}
      >
        <div className="naxatw-absolute naxatw-top-0 naxatw-z-50 naxatw-h-[20rem] naxatw-w-full naxatw-bg-transparent" />
        <iframe
          src={file}
          title={title}
          className="naxatw-aspect-video naxatw-h-[7rem] naxatw-rounded-lg naxatw-border-0 naxatw-bg-[#2625253b] naxatw-object-cover naxatw-shadow-sm"
        />

        <FlexColumn className="naxatw-w-[60%] naxatw-items-start naxatw-gap-2">
          <p className="naxatw-text-base naxatw-font-semibold naxatw-text-primary-700">
            {title}
          </p>
          <p className="naxatw-text-wrap naxatw-text-sm naxatw-font-normal naxatw-leading-4">
            {formattedDescription}
          </p>
        </FlexColumn>
      </FlexRow>
    </>
  );
};

export const ColumnDocumentCards = ({
  title,
  thumbnail,
  description,
  onClick,
}: IDocumentCardProps) => {
  return (
    <>
      <>
        <FlexColumn
          className="naxatw-max-h-[19rem] naxatw-min-h-[19rem] naxatw-w-full naxatw-flex-1 naxatw-cursor-pointer naxatw-items-start naxatw-justify-start naxatw-gap-4 naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-shadow-sm hover:naxatw-shadow-lg"
          onClick={() => onClick()}
        >
          <div className="naxatw-relative naxatw-h-fit naxatw-w-full">
            <div className="naxatw-absolute naxatw-top-0 naxatw-z-50 naxatw-h-[20rem] naxatw-w-full naxatw-bg-transparent" />

            <img
              src={thumbnail || userManualDefaultThumbnail}
              alt="Thumbnail"
              className="naxatw-aspect-square naxatw-h-[12rem] naxatw-w-full naxatw-bg-[#2625253b] naxatw-object-cover naxatw-shadow-sm "
            />
          </div>
          <FlexColumn className="naxatw-items-start naxatw-gap-2 naxatw-px-3 naxatw-pb-3 naxatw-pt-0">
            <p className="naxatw-text-base naxatw-font-semibold naxatw-text-primary-700">
              {title}
            </p>
            <p className="naxatw-text-wrap naxatw-text-sm naxatw-font-normal naxatw-leading-4">
              {description}
            </p>
          </FlexColumn>
        </FlexColumn>
      </>
    </>
  );
};
