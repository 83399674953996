import { api, authenticated } from '.';

// fetches province list
export const getProvinceList = () => {
  return authenticated(api).get('/province/');
};

//  post goals form data
export const postGoals = (data: Record<string, any>) => {
  return authenticated(api).post('/goal-location/', data, {
    headers: { 'Content-Type': 'application/json' },
  });
};

// post goals with programme data
export const postGoalsProgramme = (data: Record<string, any>) => {
  return authenticated(api).post('/goal-program/', data, {
    headers: { 'Content-Type': 'application/json' },
  });
};

// get programme dropdown
export const getProgrammeDropDown = () => {
  return authenticated(api).get('/program-dropdown/');
};

// get goals by province
export const getGoalsByProvinceId = (id: any) => {
  return authenticated(api).get(`/goal-location/?province=${id}`);
};

// del program
export const deleteGoalProgram = (id: any) =>
  authenticated(api).delete(`/goal-program/${id}/`);

// get all goals list
export const getGoalsList = (params: Record<string, any>) => {
  return authenticated(api).get('/goal-location/', { params });
};

// del goal location
export const deleteGoalLocation = (id: any) =>
  authenticated(api).delete(`/goal-location/${id}/`);

// get provincial summary
export const getProvincialSummary = (id: any) =>
  authenticated(api).get(`/get-federal-data/?province_id=${id}`);

export const municipalSummary = (provinceId: any, municipalityId: any) =>
  authenticated(api).get(
    `/get-federal-data/?municipality_code=${municipalityId}`,
  );

export const patchComponentEstimatedBudget = (payload: Record<string, any>) => {
  const id = payload?.id || '';
  return authenticated(api).patch(`/component-budget/${id}/`, payload);
};

// patch provincal summary
export const patchProvincialSummary = (payload: any) => {
  const id = payload?.id || '';
  return authenticated(api).patch(`/federal-info/${id}/`, payload);
};

// post provincial ministry
export const postProvinicalMinistry = (params: Record<string, any>) => {
  return authenticated(api).post('/unit-ministry/', params);
};

// post provincial ministry department
export const postProvincialDepartmentSummary = (
  params: Record<string, any>,
) => {
  return authenticated(api).post('/unit-ministry-department/', params);
};

// delete provincial ministry
export const deleteProvinicalMinistry = (id: any) => {
  return authenticated(api).delete(`/unit-ministry/${id}/`);
};

// patch provincial ministry
export const patchProvinicalMinistry = (payload: any) => {
  const { id, params } = payload;
  return authenticated(api).patch(`/unit-ministry/${id}/`, params);
};

// get provincal ministry list
export const getProvinicialMinstry = (payload: any) => {
  const { provinceId, type } = payload;
  return authenticated(api).get(
    `/unit-ministry/?province=${provinceId}&ministry_type=${type}`,
  );
};

// get federal ministry list
export const getFederalMinistry = () => {
  const payload = 'Federal Level';
  return authenticated(api).get(`/unit-ministry/?ministry_type=${payload}`);
};

// patch fiscal year
export const patchGoals = (payload: Record<string, any>) => {
  const { id, ...remainingPayload } = payload;
  return authenticated(api).patch(`/goal-location/${id}/`, remainingPayload, {
    headers: { 'Content-Type': 'application/json' },
  });
};
