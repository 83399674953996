type DataBankIconProps = {
  hover?: boolean;
};

const DataBank = ({ hover }: DataBankIconProps) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5234 29.7305H9.10938V30.9375H11.5234V29.7305Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M27.2148 29.7305H24.8008V30.9375H27.2148V29.7305Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M6.72655 16.4531H5.51953V24.9023H6.72655V16.4531Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M4.31249 16.4531H3.10547V24.9023H4.31249V16.4531Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M22.3169 28.6133C22.0833 28.049 22.1412 27.4413 22.4587 26.9495L21.5462 26.0369C21.0549 26.3544 20.4472 26.4117 19.8829 26.1788C19.3192 25.9452 18.93 25.4739 18.8068 24.9023H17.5159C17.3928 25.4739 17.0036 25.9458 16.4399 26.1788C15.8756 26.4123 15.2679 26.3538 14.7766 26.0369L13.8641 26.9495C14.1815 27.4407 14.2395 28.049 14.0059 28.6133C13.7724 29.177 13.3016 29.5657 12.7295 29.6888V30.9797C13.301 31.1028 13.7724 31.4921 14.0059 32.0552C14.2395 32.6194 14.1815 33.2272 13.8641 33.719L14.7766 34.6315C15.2679 34.3141 15.8762 34.2568 16.4399 34.4897C17.0036 34.7233 17.3928 35.1946 17.5159 35.7661H18.8068C18.93 35.1946 19.3192 34.7227 19.8829 34.4897C20.4472 34.2568 21.0549 34.3141 21.5462 34.6315L22.4587 33.719C22.1412 33.2278 22.0833 32.6194 22.3169 32.0552C22.5504 31.4915 23.0212 31.1028 23.5933 30.9797V29.6888C23.0218 29.5657 22.5504 29.1764 22.3169 28.6133ZM18.1617 32.748C16.8285 32.748 15.7476 31.6671 15.7476 30.3339C15.7476 29.0008 16.8285 27.9199 18.1617 27.9199C19.4948 27.9199 20.5757 29.0008 20.5757 30.3339C20.5757 31.6671 19.4948 32.748 18.1617 32.748Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M24.8007 22.4883H11.5234V26.7129H12.3943L14.7486 24.3592L15.3219 24.9337C15.5857 25.1968 15.891 25.0997 15.9786 25.0641C16.0661 25.0273 16.3515 24.88 16.3515 24.5058V23.6953H19.9726V24.5058C19.9726 24.88 20.258 25.0273 20.3456 25.0641C20.4337 25.1015 20.739 25.1974 21.0028 24.9331L21.5755 24.3592L23.9298 26.7129H24.8007V22.4883Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M0.660156 26.1094V34.5585H7.90289L7.90229 26.1094H0.660156ZM6.69526 33.3515H1.86718V32.1445H6.69526V33.3515ZM6.69526 30.9375H1.86718V29.7304H6.69526V30.9375ZM6.69526 28.5234H1.86718V27.3164H6.69526V28.5234Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M1.89129 15.2461H7.93364V14.0391H1.875L1.89129 15.2461Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M34.4805 14.0391H28.4219V15.2461H34.4648L34.4805 14.0391Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M1.26353 12.8316H1.68418L18.1618 1.84712L34.6395 12.8322H35.0921C35.4072 12.8322 35.6642 12.5757 35.6642 12.2601C35.6642 12.067 35.5677 11.8877 35.4059 11.7821L18.9265 0.984699C18.4805 0.6926 17.8752 0.6926 17.4298 0.984699L0.949709 11.7815C0.787968 11.8877 0.691406 12.0664 0.691406 12.2595C0.691406 12.5751 0.948502 12.8316 1.26353 12.8316Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M30.8359 16.4531H29.6289V24.9023H30.8359V16.4531Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M33.25 16.4531H32.043V24.9023H33.25V16.4531Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M26.0074 12.8311H32.4631L18.1617 3.29688L3.86035 12.8311H10.3161C10.3161 12.1655 10.8575 11.6241 11.5231 11.6241H24.8004C25.466 11.6241 26.0074 12.1655 26.0074 12.8311ZM18.1617 8.60656C17.4949 8.60656 16.9547 8.06642 16.9547 7.39954C16.9547 6.73266 17.4949 6.19252 18.1617 6.19252C18.8286 6.19252 19.3688 6.73266 19.3688 7.39954C19.3688 8.06642 18.8286 8.60656 18.1617 8.60656Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M11.5234 21.2812H24.8007V17.6602H11.5234V21.2812ZM22.3866 18.8672H23.5937V20.0742H22.3866V18.8672ZM19.9726 18.8672H21.1796V20.0742H19.9726V18.8672ZM17.5585 18.8672H18.7656V20.0742H17.5585V18.8672Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M35.664 26.1094H28.4219V34.5585H35.6646L35.664 26.1094ZM34.457 33.3515H29.6289V32.1445H34.457V33.3515ZM34.457 30.9375H29.6289V29.7304H34.457V30.9375ZM34.457 28.5234H29.6289V27.3164H34.457V28.5234Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
      <path
        d="M24.8007 12.832H11.5234V16.4531H24.8007V12.832ZM18.7656 15.2461H17.5585V14.0391H18.7656V15.2461ZM21.1796 15.2461H19.9726V14.0391H21.1796V15.2461ZM23.5937 15.2461H22.3866V14.0391H23.5937V15.2461Z"
        fill={hover ? '#16B1A7' : '#833177'}
      />
    </svg>
  );
};

export default DataBank;
