import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Searchbar from '@Components/common/SearchBar';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { setPublicSearchText } from '@Store/actions/knowledgeRepository';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';

const HeaderSection = () => {
  const dispatch = useTypedDispatch();
  const searchText = useTypedSelector(
    state => state.knowledgeRepository.publicSearchText,
  );

  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchText,
    onChange: debouncedEvent => {
      dispatch(setPublicSearchText(debouncedEvent.target.value));
    },
  });

  return (
    <div className=" naxatw-grid naxatw-w-full naxatw-gap-5 lg:!naxatw-grid-cols-[3.3fr_1fr]">
      <h5 className=" naxatw-leading-[2.375rem] naxatw-tracking-[-0.03rem] naxatw-text-[#0B2E62]">
        Knowledge Library
      </h5>
      <Searchbar
        value={value}
        onChange={handleChange}
        className="!naxatw-w-full"
      />
    </div>
  );
};

export default hasErrorBoundary(HeaderSection);
