import ComparisonBox from './ComparisonTab';
import ComparisonTabSwitcher from './SwitchTab';

const BottomLeft = () => {
  return (
    <>
      <div className="naxatw-w-full lg:naxatw-max-w-[25rem]">
        <ComparisonTabSwitcher />
        <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-[#EAECF0]" />
        <ComparisonBox />
      </div>
    </>
  );
};

export default BottomLeft;
