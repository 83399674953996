export const mappedFilterKeys: Record<string, string> = {
  program: 'program',
  project: 'project',
  first_tier_partner: 'first_tier_partner',
  sector: 'sector',
  marker: 'marker',
  stakeholder: 'stakeholder',
  working_area: 'working_area',
  start_date: 'start_date',
  end_date: 'end_date',
};

export const mappedFilterParmas: Record<string, string> = {
  program: 'program',
  project: 'project',
  first_tier_partner: 'first_tier_partner',
  sector: 'sector',
  marker: 'marker',
  stakeholder: 'stakeholder_name',
  working_area: 'province',
  start_date: 'start_date',
  end_date: 'end_date',
};

export const mappedFilterFieldsKeys: Record<string, any> = {
  project: 'project__',
  program: 'program__',
  first_tier_partner: 'project__first_tier_partner__',
  sector: 'project__project_sector__sector__',
  marker: 'project__project_marker__marker__category__',
  working_area: 'project_data_working_area__province__',
};

export const singleFilterkeys = ['stakeholder', 'start_date', 'end_date'];

export const nationalLevel = { id: 'National Level', name: 'National Level' };

export const excludeFilterParams = ['pdfIcon'];
export const dateCategories = ['start_date', 'end_date'];
