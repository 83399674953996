/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import ErrorMessage from '@Components/common/ErrorMessage';
import { useSpring, animated } from '@react-spring/web';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { FormControl } from '@Components/common/FormUI';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import {
  mapLayersFormFields,
  dropDownOptions,
  mapLayersDefaultValues,
} from '@Constants/FormConstants/mapLayersFormConstants';
import {
  postMapLayers,
  getIndividualMapLayer,
  patchMapLayer,
  postLayerStyle,
  patchLayerStyle,
} from '@Services/mapLayers';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { zodResolver } from '@hookform/resolvers/zod';
import groupFormElements from '@Utils/index';
import { MapLayersValidationSchema } from '@Validations/MapLayers';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import isEmpty from '@Utils/isEmpty';

interface IMapLayersFormProps {
  onClose: () => void;
}

const MapLayersForm = ({ onClose }: IMapLayersFormProps) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const mapLayersFields = groupFormElements(mapLayersFormFields);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mapLayerId } = useParams();
  const {
    formState: { errors, dirtyFields },
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: mapLayersDefaultValues,
    resolver: zodResolver(MapLayersValidationSchema),
  });
  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
  };

  const styleId = watch('style_id');

  const { mutateAsync: mutateLayersStyle } = useMutation({
    mutationFn: (payloadData: any) => postLayerStyle(payloadData),
    onSuccess: () => {
      toast.success('Map layer added successfully!');
    },
  });

  const { mutateAsync: patchMapLayerStyle } = useMutation({
    mutationFn: (payloadData: any) => patchLayerStyle(payloadData),
    onSuccess: () => {
      toast.success('Map layer updated successfully!');
    },
  });

  const { mutateAsync: mutateMapLayers, isLoading: postMapLayersIsLoading } =
    useMutation({
      mutationFn: (payloadData: any) => postMapLayers(payloadData),
      onSuccess: data => {
        const allValues = getValues();
        const styleJsonByGeomType =
          allValues?.geometry_type === 'Point'
            ? { 'circle-color': allValues.fill_color }
            : allValues?.geometry_type === 'LineString'
              ? { 'line-color': allValues.fill_color }
              : { 'fill-color': allValues.fill_color };

        mutateLayersStyle({
          style_json: JSON.stringify(styleJsonByGeomType),
          layer: data.data.layer_id,
        });
      },
    });

  // get indiviudual map layer to populate on edit
  const { isFetching: isMapLayerDataFetching } = useQuery({
    queryKey: ['get-map-layer'],
    enabled: !!mapLayerId,
    queryFn: () => getIndividualMapLayer(mapLayerId || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      Object.keys(data)?.forEach((key: any) => {
        // to show default color on fill_color field
        if (key === 'style') {
          setValue(
            'fill_color',
            data[key]?.[
              data?.geometry_type === 'LineString'
                ? 'line-color'
                : data?.geometry_type === 'Point'
                  ? 'circle-color'
                  : 'fill-color'
            ],
          );
        }
        setValue(key, data[key]);
      });
    },
  });

  // Patch Map Layer
  const {
    mutateAsync: patchMapLayerData,
    isLoading: isPatchMapLayerDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchMapLayer(payloadData),

    onSuccess: () => {
      const allValues = getValues();
      const styleJsonByGeomType =
        allValues?.geometry_type === 'Point'
          ? { 'circle-color': allValues.fill_color }
          : allValues?.geometry_type === 'LineString'
            ? { 'line-color': allValues.fill_color }
            : { 'fill-color': allValues.fill_color };
      patchMapLayerStyle({
        style_json: JSON.stringify(styleJsonByGeomType),
        layer: mapLayerId,
        id: styleId,
      });
    },
    onError: () => toast.error('Failed to update Map Layer. Try again'),
  });

  // patch map Layers
  const patchMapLayerUpdatedData = async (formValues: Record<string, any>) => {
    if (isEmpty(formValues)) return;
    const { file_upload, ...rest } = formValues;
    if (file_upload) {
      const fileToPost = file_upload[0]?.file;
      await patchMapLayerData({
        file_upload: fileToPost,
        ...rest,
        id: mapLayerId,
      });
      return;
    }
    await patchMapLayerData({ ...rest, id: mapLayerId });
  };

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const postMapLayer = async (data: Record<string, any>) => {
    if (isEmpty(data)) return;
    await mutateMapLayers(data);
  };

  async function handleFormSubmit() {
    try {
      const allValues: Record<string, any> = getValues();
      const { file_upload, ...rest } = allValues;
      const editFields = getDirtyFieldValues();

      const dataToSubmit: any = {
        file_upload: file_upload[0]?.file,
        ...rest,
      };
      if (mapLayerId) {
        await patchMapLayerUpdatedData(editFields);
      } else {
        await postMapLayer(dataToSubmit);
      }
      queryClient.invalidateQueries({
        queryKey: ['map-layer-tabledata'],
      });
      navigate('/data-bank/map-layers');
      reset();
    } catch (error: any) {
      const caughtError = error?.response?.data?.message;
      toast.error(caughtError || 'Error occured while submitting!');
    }
  }

  const props = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: { duration: 100 }, // Adjust duration as needed
    onRest: () => {
      if (!hasAnimated) {
        setHasAnimated(true); // Set it to true after the animation
      }
    },
  });

  useEffect(() => {
    setHasAnimated(false); // Reset before starting
  }, []);

  return (
    <>
      <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-max-w-[80%] naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center">
        <animated.div
          className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem] xl:naxatw-w-[40%]"
          style={{
            transform: props.scale.to(scale => `scale(${scale})`),
          }}
        >
          <div className="naxatw-w-ful naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
            <BreadCrumb
              heading={`Map Layer ${mapLayerId ? 'Edit' : 'Registration'} Form`}
              overlayStatus={() => onClose()}
            />
            <IconButton
              name="close"
              className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
              iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
              onClick={() => onClose()}
            />
          </div>
          <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
            <div className="naxatw-w-full">
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                  <FlexColumn className="scrollbar  naxatw-h-[calc(100vh-23.8rem)]  naxatw-w-full  naxatw-space-y-5 naxatw-overflow-y-scroll naxatw-pr-2">
                    {isMapLayerDataFetching ? (
                      <FormSkeleton numRows={4} className="!naxatw-w-full" />
                    ) : (
                      mapLayersFields?.map(
                        (fieldRow: Record<string, any>, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <FlexRow key={i}>
                            {fieldRow?.map((field: FormFieldProps) => {
                              const { id } = field;
                              return (
                                <FormControl
                                  className="naxatw-w-full naxatw-gap-[0.5rem] "
                                  key={`${field.id}- ${field.name}`}
                                >
                                  <InputLabel
                                    label={field.label}
                                    astric={field.required}
                                    id={field.id}
                                  />
                                  {getInputElement(
                                    {
                                      ...field,
                                      id: `${field.id}`,
                                    },
                                    /* @ts-ignore */
                                    formProps,
                                    dropDownOptions[id] || [],
                                  )}
                                  <>
                                    {
                                      /* @ts-ignore */
                                      formProps?.errors[id] && (
                                        <ErrorMessage
                                          /* @ts-ignore */
                                          message={
                                            /* @ts-ignore */
                                            formProps?.errors[id]?.message
                                          }
                                        />
                                      )
                                    }
                                  </>
                                </FormControl>
                              );
                            })}
                          </FlexRow>
                        ),
                      )
                    )}
                  </FlexColumn>
                </div>

                <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                  <Button
                    size="sm"
                    variant="primary"
                    className="naxatw-px-4 naxatw-py-2"
                    type="submit"
                    isLoading={
                      postMapLayersIsLoading || isPatchMapLayerDataLoading
                    }
                    disabled={
                      postMapLayersIsLoading || isPatchMapLayerDataLoading
                    }
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </animated.div>
      </div>
    </>
  );
};

export default MapLayersForm;
