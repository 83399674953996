import { z } from 'zod';

export const ProgramFormValidationSchema = z
  .object({
    code: z
      .string()
      .trim()
      .length(6, 'Programme code is Required and must be upto 6 numbers'),
    name: z
      .string()
      .trim()
      .min(1, 'Programme Name is Required')
      .min(5, 'Name should be atleast 5 characters'),
    abbreviation: z
      .string()
      .trim()
      .optional()
      .refine(value => value === undefined || !value.includes(' '), {
        message: 'Abbreviation cannot contain space',
      }),
    program_type: z
      .string()
      .trim()
      .min(1, { message: 'Programme Type is Required' }),
    start_date: z.string().trim().min(1, { message: 'Start date is Required' }),
    end_date: z.string().trim().min(1, { message: 'End date is Required' }),
    is_successor: z.string().trim().min(1, { message: 'Value is Required' }),
    previous_programs: z
      .array(z.union([z.string(), z.number()]))
      .nullable()
      .optional(),
    other_previous_program: z.string().nullable().optional(),
  })
  .refine(
    data => {
      if (data.is_successor === 'true') {
        return data.previous_programs && data.previous_programs.length > 0;
      }
      return true;
    },
    {
      message: 'Previous programme / Project is Required',
      path: ['previous_programs'],
    },
  )
  .refine(
    data => {
      if (data.is_successor === 'true' && data.previous_programs) {
        const containsOtherProgram =
          data.previous_programs.includes('other-programs');

        return containsOtherProgram ? !!data.other_previous_program : true;
      }
      return true;
    },
    {
      message: 'Other programme / Project is Required',
      path: ['other_previous_program'],
    },
  )
  .refine(
    data => {
      if (data.start_date && data.end_date) {
        return data.start_date < data.end_date;
      }
      return true; // No error if one or both dates are missing
    },
    {
      message: 'End date must be after start date.',
      path: ['end_date'],
    },
  );

export type ProgramFormValidationSchemaProps = z.infer<
  typeof ProgramFormValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;
