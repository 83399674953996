/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import {
  BasicInfoValidationSchema,
  BasicInfoValidationSchemaProps,
} from './basicInfoValidation';

// Function to get the appropriate schema based on form type
export function getSchema() {
  return BasicInfoValidationSchema;
}

export type PartnerFormSchemaProps = BasicInfoValidationSchemaProps & {
  [key: string]: any; // Adding an index signature
};
