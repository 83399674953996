// import Icon from '@Components/common/Icon';

const BottomTop = () => {
  return (
    <>
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-px-[1.5rem] naxatw-py-[1.25rem]">
        <p className="naxatw-text-[1.125rem] naxatw-font-bold naxatw-text-matt-100">
          Compare Indicators
        </p>
        {/* <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
          <Icon name="open_in_full" />
          <Icon name="download" />
        </div> */}
      </div>
    </>
  );
};

export default BottomTop;
