/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IndividualStakeholderState {
  [key: string]: boolean; // Dynamic key-value pairs
}
const initialState = {
  disabled: false,
  subDisabled: {},
  stakeholderValidation: {} as IndividualStakeholderState,
  stakeholderValidationSubNational: [] as IndividualStakeholderState[],
  workingAreasValidation: false,
};

const componentFormValidationSlice = createSlice({
  name: 'componentFormValidation',
  initialState,
  reducers: {
    setDisabled: (state, action) => {
      state.disabled = action.payload;
    },
    setSubDisabled: (state, action) => {
      state.subDisabled = action.payload;
    },
    setStakeholderValidation: (state, action) => {
      state.stakeholderValidation = action.payload.reduce(
        (acc: any, obj: any) => {
          return { ...acc, ...obj };
        },
        {},
      );
    },
    setStakeholderValidationSubNational: (
      state,
      action: PayloadAction<any[][]>,
    ) => {
      // console.log(action.payload, 'subnational');

      state.stakeholderValidationSubNational = action.payload?.map(
        (stakeholderData: any) => {
          const newObject = stakeholderData.reduce((acc: any, curr: any) => {
            // Initialize the object with the id if it doesn't exist yet
            if (acc.id === undefined) {
              acc.id = curr.id;
            }

            // Add the key-value pairs from the current object, excluding the id
            Object.keys(curr).forEach(key => {
              if (key !== 'id') {
                acc[key] = curr[key];
              }
            });

            return acc;
          }, {});
          //  stakeholderData;
          return newObject;
        },
      );
    },
    setWorkingAreasValidation: (state, action) => {
      state.workingAreasValidation = action.payload;
    },
  },
});
export { componentFormValidationSlice };
export default componentFormValidationSlice.reducer;
