/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Icon from '@Components/common/Icon';
import { MultiStepType } from '@Constants/Types/type';

type MultiStepPropType = {
  page: number;
  setPage: (page: number) => void;
  data: MultiStepType[];
};

const MultiStep = ({ page, setPage, data }: MultiStepPropType) => {
  return (
    <div className="naxatw-flex naxatw-flex-row naxatw-flex-wrap naxatw-justify-center naxatw-gap-8 lg:naxatw-flex-col lg:naxatw-flex-nowrap lg:naxatw-gap-7">
      {data.map(pg => (
        <div
          key={pg.id}
          className={`naxatw-relative naxatw-flex naxatw-cursor-pointer naxatw-flex-col 
      naxatw-items-center naxatw-gap-1 lg:naxatw-flex-row lg:naxatw-gap-2`}
          // onClick={() => setPage(pg.value)}
        >
          {pg.value < page ? (
            <div
              className={`${
                page === pg.value
                  ? 'naxatw-border-primary-400 naxatw-text-primary-400'
                  : 'naxatw-text-[#757575]'
              }  naxatw-flex naxatw-h-[1.75rem] naxatw-min-h-[1.75rem] naxatw-w-[1.75rem] naxatw-min-w-[1.75rem] naxatw-items-center naxatw-justify-center naxatw-rounded-full naxatw-border-[1px] naxatw-bg-secondary-500`}
            >
              <Icon name="check" className="naxatw-pt-2 naxatw-text-white" />
            </div>
          ) : (
            <div
              className={`${
                page === pg.value
                  ? 'naxatw-border-secondary-500  naxatw-text-secondary-500'
                  : 'naxatw-text-matt-200'
              } naxatw-flex naxatw-h-[1.75rem] naxatw-min-h-[1.75rem] naxatw-w-[1.75rem] naxatw-min-w-[1.75rem] naxatw-items-center naxatw-justify-center naxatw-rounded-full naxatw-border-[1px] naxatw-font-bold`}
            >
              {pg.value}
            </div>
          )}
          <p
            className={`naxatw-text-body-md ${
              page === pg.value
                ? 'naxatw-text-secondary-500'
                : 'naxatw-text-matt-200'
            } naxatw-mt-1 naxatw-text-base naxatw-font-medium`}
          >
            {pg.text}
          </p>
          {/* {pageStatus.length !== pg.value && (
        <div className="naxatw-absolute naxatw-left-[70px] naxatw-top-5 naxatw-h-[1px] naxatw-w-[3.75rem] naxatw-bg-[#D7D7D7]" />
      )} */}
        </div>
      ))}
    </div>
  );
};

export default MultiStep;
