import { FlexColumn } from '@Components/common/Layouts';
import { useState } from 'react';
import {
  Sankey,
  Rectangle,
  Layer,
  ResponsiveContainer,
  TooltipProps,
  Tooltip,
} from 'recharts';

function SankeyNode({
  x,
  y,
  width = 8,
  height = 10,
  index,
  payload,
  containerWidth = 1250,
  nodeColors,
}: any) {
  const isOut = x + width + 6 > containerWidth;

  return (
    <>
      {/* Render the node */}
      <Layer key={`CustomNode${index}`}>
        <Rectangle
          x={x}
          y={y}
          width={width}
          height={height}
          fill={nodeColors[index % nodeColors.length]}
          fillOpacity="1"
          radius={4}
          isAnimationActive
        >
          <title className="naxatw-text-center">{payload.name}</title>
        </Rectangle>
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2}
          strokeOpacity="0.5"
          fontSize="14"
        >
          {payload.name.length > 20
            ? `${payload.name.slice(0, 20)}...`
            : payload.name}
        </text>
      </Layer>
    </>
  );
}

function SankeyLink({
  sourceX,
  targetX,
  sourceY,
  targetY,
  sourceControlX,
  targetControlX,
  linkWidth,
  index,
  colorGradients,
}: any) {
  const gradientID = `linkGradient${index}`;
  const [hovered, setHovered] = useState(false);

  return (
    <Layer key={`CustomLink${index}`}>
      <defs>
        <linearGradient id={gradientID}>
          <stop offset="100%" stopColor={colorGradients[index].source} />
          {/* <stop offset="80%" stopColor={colorGradients[index].target} /> */}
        </linearGradient>
      </defs>
      <path
        d={`
      M${sourceX},${sourceY + linkWidth / 2}
      C${sourceControlX},${sourceY + linkWidth / 2}
        ${targetControlX},${targetY + linkWidth / 2}
        ${targetX},${targetY + linkWidth / 2}
      L${targetX},${targetY - linkWidth / 2}
      C${targetControlX},${targetY - linkWidth / 2}
        ${sourceControlX},${sourceY - linkWidth / 2}
        ${sourceX},${sourceY - linkWidth / 2}
      
    `}
        style={{ fillOpacity: hovered ? 1 : 0.4 }}
        fill={`url(#${gradientID})`}
        opacity={0.4}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      />
    </Layer>
  );
}
const CustomTooltip = ({ active, payload }: TooltipProps<string, number>) => {
  if (active && payload && payload.length) {
    return (
      <FlexColumn className="naxatw-rounded-md naxatw-border naxatw-bg-white naxatw-p-2 naxatw-text-gray-800">
        <p className="naxatw-body-md !naxatw-font-normal">{payload[0].name}</p>
        {/* {'target' in payload[0].payload.payload && (
          <p className="naxatw-body-md !naxatw-font-normal">
            Link: {payload[0].payload?.payload?.target?.name}
          </p>
        )} */}
      </FlexColumn>
    );
  }

  return null;
};
export interface IResankyChartProps {
  nodeColors: Record<string, any>;
  title1?: string;
  title2?: string;
  title3?: string;
  scrollable?: boolean;
  fill?: string[];
  data?: any[];
}
interface ReSankeyProps {
  data: any;
  sankeyChartData: IResankyChartProps;
  height?: string;
}

export default function ReSankey({
  data,
  sankeyChartData,
  height = '22rem',
}: ReSankeyProps) {
  const { title1, title2, title3, nodeColors } = sankeyChartData;
  const numColors = nodeColors.length;

  const colorGradients = data.links.map((link: any) => {
    return {
      source: nodeColors[link.source % numColors],
      target: nodeColors[link.target % numColors],
    };
  });
  return (
    <div className="scrollbar naxatw-h-full naxatw-w-full naxatw-overflow-x-scroll">
      <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-min-w-[35rem] naxatw-flex-col naxatw-space-y-3">
        <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between">
          <span className="naxatw-body-md naxatw-font-bold naxatw-text-gray-800">
            {title1}
          </span>
          <span className="naxatw-body-md naxatw-mr-[115px] naxatw-font-bold naxatw-text-gray-800">
            {title2}
          </span>
          <span
            className={`naxatw-body-md naxatw-mr-[110px] naxatw-font-bold naxatw-text-gray-800 ${
              title3 ? 'naxatw-block' : 'naxatw-hidden'
            }`}
          >
            {title3}
          </span>
        </div>
        {/* <div className="naxatw-flex naxatw-items-center naxatw-justify-center w-full h-full"> */}
        <div className="naxatw-w-full naxatw-pb-5" style={{ height }}>
          <ResponsiveContainer width="100%" height="100%">
            <Sankey
              dataKey="source"
              width={960}
              height={600}
              data={data}
              nodeWidth={6}
              margin={{ top: 0, bottom: 0, right: 280, left: 0 }}
              node={<SankeyNode nodeColors={nodeColors} />}
              nodePadding={20}
              linkCurvature={0.61}
              iterations={60}
              // sort
              link={<SankeyLink colorGradients={colorGradients} data={data} />}
            >
              <Tooltip content={<CustomTooltip />} />
            </Sankey>
          </ResponsiveContainer>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
