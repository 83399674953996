const nodeColors = [
  '#41C570',
  '#719ED7',
  '#D64C63',
  '#EFBF41',
  '#A26599',
  '#41588F',
  '#D64C62',
  '#709ED6',
  '#A26499',
  '#EEBF41',
  '#41C570',
];

export { nodeColors };
