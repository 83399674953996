/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import isEmpty from '@Utils/isEmpty';
import { z } from 'zod';

export enum GoalFormTypeEnum {
  Goal = 'goal',
  AssignGoals = 'assignGoals',
}

export enum ProvincialMinstryTypeEnum {
  Ministries = 'ministries',
  Department = 'department',
}

export const GoalValidationSchema = z.object({
  fiscal_year_date: z
    .string()
    .trim()
    .min(1, { message: 'Fiscal Year is Empty' }),
  goals_lists: z.array(z.any()).superRefine((value, ctx) => {
    if (value.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Select at least one Priority.',
      });
    }
    if (value.length > 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Goals Should be not more than 5.',
      });
    }
  }),
});

export const AssignGoalsValidationSchema = z.object({
  programme_list: z.any().superRefine((value, ctx) => {
    value.forEach((item: Record<string, any>, index: number) => {
      if (isEmpty(item)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Programme is Empty',
          path: [index],
        });
      } else if (item === null || item === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Programme is Empty',
          path: [index],
        });
      }
    });
  }),
});

export type GoalValidationSchemaProps = z.infer<
  typeof GoalValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;

export type AssignGoalsValidationSchemaProps = z.infer<
  typeof AssignGoalsValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;

export type GoalsFormValidationSchemaProps = GoalValidationSchemaProps &
  AssignGoalsValidationSchemaProps & {
    [key: string]: any;
  };

// Function to get the appropriate schema based on form type
export function getGoalSchema(formType: 'goal' | 'assignGoals') {
  switch (formType) {
    case GoalFormTypeEnum.Goal:
      return GoalValidationSchema;
    case GoalFormTypeEnum.AssignGoals:
      return AssignGoalsValidationSchema;
    default:
      throw new Error('Unknown form type');
  }
}
