export const buildingStyles = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': '#0098ff',
    'fill-opacity': 1,
  },
};

export const defaultLayerStyle = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': '#FFFFFF',
    'fill-outline-color': '#808080',
    'fill-opacity': 0.7,
  },
};

export const DashboardLegendDropdownOptions = [
  {
    label: 'Programme Count',
    id: 1,
    value: 'program',
  },
  {
    label: 'Project Count',
    id: 2,
    value: 'project',
  },
  {
    label: 'Sector Count',
    id: 3,
    value: 'project_sector',
  },
];

export const provinceFillColor = [
  { name: 'Koshi', color: '#41C570' },
  { name: 'Madhesh', color: '#719ED7' },
  { name: 'Bagmati', color: '#D64C63' },
  { name: 'Gandaki', color: '#A26599' },
  { name: 'Lumbini', color: '#EFBF41' },
  { name: 'Karnali', color: '#86CAC5' },
  { name: 'Sudurpashchim', color: '#415885' },
];

export const fillByProvinceStyle = {
  paint: {
    'fill-color': [
      'case',
      ['==', ['get', 'id'], 1],
      '#41C570',
      ['==', ['get', 'id'], 2],
      '#719ED7',
      ['==', ['get', 'id'], 3],
      '#D64C63',
      ['==', ['get', 'id'], 4],
      '#A26599',
      ['==', ['get', 'id'], 5],
      '#EFBF41',
      ['==', ['get', 'id'], 6],
      '#86CAC5',
      ['==', ['get', 'id'], 7],
      '#415885',
      '#FFFFFF',
    ],
    'fill-outline-color': '#FFFFFF',
    'fill-opacity': 0.4,
  },
};

export const roadStyles = {
  type: 'line',
  layout: {},
  paint: {
    'line-color': '#0664D2',
    'line-width': 0.8,
  },
};

export const pointStyles = {
  type: 'circle',
  layout: {},
  paint: {
    'circle-color': '#0664D2',
    'circle-radius': 5,
  },
};

export const polygonStyles = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': '#0664D2',
    'fill-outline-color': '#FFFFFF',
    'fill-opacity': 0.7,
  },
};

export const defaultLayersData = [
  {
    id: 'building',
    endPoint: 'building-vector-tile',
    checked: true,
    styles: buildingStyles,
  },
  {
    id: 'road',
    endPoint: 'road-vector-tile',
    checked: true,
    styles: roadStyles,
  },
];

export interface ILayerState {
  id: string | number;
  endPoint: string;
  checked: boolean;
  styles: Record<string, any>;
}

const buildingColor = '#0077E4';
const roadColor = '#0077E4';
const gateColor = '#FF9525';

export const drawnBuildingStyle = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': buildingColor,
    'fill-opacity': 1,
  },
};

export const drawnRoadStyle = {
  type: 'line',
  layout: {},
  paint: {
    'line-color': roadColor,
    'line-width': 3,
  },
};

export const drawnGateLocationStyle = {
  type: 'circle',
  layout: {},
  paint: {
    'circle-radius': 7,
    'circle-color': gateColor,
  },
};

export const drawStyles = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': buildingColor,
      'fill-outline-color': buildingColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': buildingColor,
      'fill-outline-color': buildingColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': buildingColor,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': buildingColor,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': buildingColor,
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': buildingColor,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': buildingColor,
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': buildingColor,
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];

export function getChoropethForSelectedTiles({
  data = [],
  defaultColor = '#E7F2FF',
  selectedColor = '#5DB8B2',
  selectedIds = null,
}: {
  data: Record<string, any>[] | string;
  defaultColor: string;
  selectedColor: string;
  selectedIds: string[] | null;
}) {
  if (!Array.isArray(data)) return defaultColor;
  const choroPethFillColor: any = ['match', ['get', 'id']];
  if (selectedIds) {
    choroPethFillColor.push(...selectedIds);
  }
  if (data.length > 0) {
    data?.forEach((district: Record<string, any>) => {
      if (choroPethFillColor.includes(district?.id) || !district?.id) return;
      choroPethFillColor.push(district?.id);
      if (selectedColor) choroPethFillColor.push(selectedColor);
      else choroPethFillColor.push(defaultColor);
    });
  }
  choroPethFillColor.push(defaultColor);
  return choroPethFillColor;
}

export const choroplethColorRange = [
  '#E6F2FF',
  '#99C9FF',
  '#3295FF',
  '#0062CB',
  '#013167',
];

export const downloadMapDataOptions = [
  {
    id: 1,
    label: 'Download as CSV',
    type: 'csv',
  },
  {
    id: 2,
    label: 'Download as PNG',
    type: 'png',
  },
];
