/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getActivityLogs } from '@Services/activityLogs';
import useIntersectionObserver from '@Hooks/useIntersectionObserver';
import ActivityLogCardSkeleton from '@Components/common/Skeleton/LogSkeleton';
import { toast } from 'react-toastify';
import isEmpty from '@Utils/isEmpty';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useTypedSelector } from '@Store/hooks';
import ActivityLogCard from './ActivityLogCard';
import ActivityLogsHeader from '../ActivityLogsHeader';

const ActivityLogsFetching = () => {
  return (
    <div className="logs-skeleton">
      {Array.from({ length: 10 }, (_, index) => (
        <ActivityLogCardSkeleton key={index} />
      ))}
    </div>
  );
};

const NoActivityFound = () => {
  return (
    <div className="naxatw-rounded-xl naxatw-border naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-p-3 naxatw-text-center naxatw-text-[0.875rem] naxatw-text-matt-100">
      No Data Found
    </div>
  );
};

const ActivityLogsContent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { startDate, endDate, role, user } = useTypedSelector(
    state => state.acitivityLogsSlice.filterState,
  );

  const [searchLog, handleChangeLogs] = useDebouncedInput({
    ms: 400,
    init: searchTerm,
    onChange: debouncedEvent => setSearchTerm(debouncedEvent.target.value),
  });

  const [isIntersecting, _, viewRef] = useIntersectionObserver();

  const {
    data: activityLogsData,
    isLoading: isActivityLogsFetching,
    fetchNextPage,
    isFetchingNextPage,
    isError,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['get-activity-logs', searchLog, startDate, endDate, role, user],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getActivityLogs({
        page: pageParam,
        page_size: 15,
        search: searchLog,
        role,
        user,
        start_date: startDate,
        end_date: endDate,
      });
      return res?.data;
    },
    getNextPageParam: lastPage => {
      return lastPage?.next_page ?? undefined;
    },
    select: response => {
      return response?.pages.reduce((acc, page) => {
        return [...acc, ...(page.results || [])];
      }, []);
    },
  });

  if (isError) {
    toast.error('Something went wrong');
  }

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, fetchNextPage, hasNextPage]);

  const activitiesLogs: any = activityLogsData || [];

  return (
    <div className="acivity-logs-container">
      {/* header */}
      <ActivityLogsHeader
        activityCount={activityLogsData && activitiesLogs?.length}
        searchLogs={searchLog}
        setSearchLogs={handleChangeLogs}
      />

      {/* body */}
      <div className="activity-logs-content scrollbar naxatw-my-5 naxatw-h-[calc(100vh-219px)] naxatw-overflow-y-auto naxatw-pr-1">
        {isActivityLogsFetching ? (
          <ActivityLogsFetching />
        ) : (
          <div className="naxtw-h-full">
            {isEmpty(activityLogsData || []) ? (
              <NoActivityFound />
            ) : (
              activitiesLogs?.map((activity: Record<string, any>) => (
                <ActivityLogCard key={activity.id} {...activity} />
              ))
            )}
            {/* view ref */}
            <div
              ref={viewRef}
              className="naxatw-pointer-events-none naxatw-h-0 naxatw-opacity-0"
            >
              viewRef
            </div>
          </div>
        )}

        {isFetchingNextPage && <ActivityLogCardSkeleton />}
      </div>
    </div>
  );
};

export default hasErrorBoundary(ActivityLogsContent);
