/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import DropDown from '@Components/common/DropDown';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import {
  getChoiceData,
  getStakeholderData,
  getWorkingAreas,
} from '@Services/portfolioReporting';
import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from '@Components/RadixComponents/Skeleton';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import isEmpty from '@Utils/isEmpty';
import Checkbox from '@Components/common/FormUI/CheckBox';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { useDispatch } from 'react-redux';
import {
  setDisabled,
  setStakeholderValidation,
  setStakeholderValidationSubNational,
  setSubDisabled,
} from '@Store/actions/componentFormValidation';
import { ChevronDown } from 'lucide-react';
import GovernmentStakeholder from './GovernmentStakeholder';
// import PrivateStakeholder from './PrivateStakeholder';
// import OthersStakeholder from './OthersStakeholder';
import { mapProvincesWithStakeholders } from './stakeholderUtils';
import CommonStakeholder from './CommonStakeholder';

interface IStakeholderDataTypes {
  data: any[];
  type: string;
}
interface IStakeholderData {
  id: number;
  label: string;
  stakeholder: IStakeholderDataTypes[];
}

// import { useFormContext } from 'react-hook-form';
const federalLocationData = [
  { id: 1, label: 'Federal', value: 1, stakeholder: [] },
];

const excludeNationalLevelStakeholder = [
  'Local Government Stakeholder',
  'Provincial Government Stakeholder',
];

const excludeSubNationalLevelStakeholder = ['Government Stakeholder'];

function getStakeholderComponent(
  type: string,
  locationId: number,
  dataKey: string,
) {
  switch (type) {
    case 'Government Stakeholder':
      return (
        <GovernmentStakeholder
          locationId={locationId}
          dataKey={dataKey}
          formType={type}
        />
      );
    case 'Provincial Government Stakeholder':
      return (
        <GovernmentStakeholder
          locationId={locationId}
          dataKey={dataKey}
          formType={type}
        />
      );
    // case 'private':
    //   return <PrivateStakeholder locationId={locationId} dataKey={dataKey} />;
    // case 'others':
    //   return <OthersStakeholder locationId={locationId} dataKey={dataKey} />;
    default:
      return (
        <CommonStakeholder
          locationId={locationId}
          dataKey={dataKey}
          formType={type}
        />
      );
  }
}

function getTypeValue(
  formData: Record<string, any>,
  locationId: number,
  key: string,
) {
  const location = formData?.stakeholder?.[key]?.find(
    (form: Record<string, any>) => {
      return form.id === locationId;
    },
  );

  const filteredLocationStake = location?.stakeholder?.filter(
    (loc: Record<string, any>) => {
      return loc?.data?.some((stake: Record<string, any>) => !stake.isDelete);
    },
  );

  const typeValue = filteredLocationStake?.map(
    (stakeholder: Record<string, any>) => stakeholder.type,
  );
  return typeValue;
}

const StakeholdersForm = () => {
  const { portfolioID } = useParams();
  const {
    // formState: { errors },
    // register,
    // getValues,
    setValue,
    control,
    watch,
  } = useFormContext();
  const dispatch = useDispatch();
  const formValues = watch();
  const levelOfInterventionWatch = watch('stakeholder.level_of_intervention');
  const { stakeholder } = formValues;

  // function that checks if the stakeholder data is valid
  function checkGeneralValidation(federalData: IStakeholderData[]) {
    const hasGeneralStakeHolder = federalData?.map(
      (federalItem: IStakeholderData) => {
        return federalItem.stakeholder?.map((value: IStakeholderDataTypes) => {
          if (value.type !== 'Government Stakeholder') {
            const validation = value.data?.some((stakeholderData: any) =>
              stakeholderData?.isDelete
                ? false
                : 'other_stakeholder' in stakeholderData
                  ? stakeholderData.other_stakeholder === ''
                  : true,
            );
            return [value.type, validation];
          }
          return [value.type, false];
        });
      },
    );
    return hasGeneralStakeHolder;
  }

  // function that checks if the government stakeholder data is valid

  function checkGovernmentValidation(federalData: IStakeholderData[]) {
    const hasGovernmentStakeholder = federalData?.map((federalItem: any) =>
      federalItem?.stakeholder?.some((value: any) => {
        if (value.type === 'Government Stakeholder') {
          // Check if `unit_ministry` is an empty string or number 0
          return value.data?.some((ministry: any) =>
            !ministry?.isDelete
              ? ministry.unit_ministry === '' || ministry.unit_ministry === 0
              : false,
          );
        }
        return false;
      }),
    );
    return hasGovernmentStakeholder;
  }

  useEffect(() => {
    if (!isEmpty(stakeholder.federalData)) {
      const hasGeneralStakeHolder = checkGeneralValidation(
        stakeholder.federalData,
      )[0];
      const hasGovernmentStakeholderNational = checkGovernmentValidation(
        stakeholder.federalData,
      )[0];

      dispatch(setDisabled(hasGovernmentStakeholderNational));
      if (!isEmpty(hasGeneralStakeHolder && hasGeneralStakeHolder[0])) {
        dispatch(
          setStakeholderValidation(
            hasGeneralStakeHolder?.map((value: any) => {
              return {
                [value[0]]: value[1],
                // value: value[1],
              };
            }),
          ),
        );
      }
    }

    // Check if the sub-national stakeholder data is valid, and set the validation state
    if (!isEmpty(stakeholder.locationData)) {
      const hasGeneralStakeHolderSubNational = stakeholder?.locationData?.map(
        (federalItem: IStakeholderData) => {
          return federalItem.stakeholder?.map(
            (value: IStakeholderDataTypes) => {
              if (value.type !== 'Provincial Government Stakeholder') {
                const validation = value.data?.some((stakeholderData: any) =>
                  stakeholderData?.isDelete
                    ? false
                    : value.type === 'Local Government Stakeholder'
                      ? stakeholderData.local_government_stakeholder === ''
                      : // checks if the other_stakeholder field exists and if its empty
                        'other_stakeholder' in stakeholderData
                        ? stakeholderData.other_stakeholder === ''
                        : true,
                );
                return { [value.type]: validation, id: federalItem.id };
              }
              return { [value.type]: false, id: federalItem.id };
            },
          );
        },
      );
      const hasGovernmentStakeholderSubNational =
        stakeholder?.locationData?.reduce(
          (acc: Record<string, any>, federalItem: Record<string, any>) => {
            // Initialize the entry for this federalItem
            acc[federalItem.id] = false;

            // Check if there's any government stakeholder that meets the criteria
            const hasStakeholder = federalItem?.stakeholder?.some(
              (value: Record<string, any>) => {
                if (value.type === 'Provincial Government Stakeholder') {
                  return value.data?.some(
                    (ministry: any) =>
                      !ministry?.isDelete &&
                      (ministry.unit_ministry === '' ||
                        ministry.unit_ministry === 0),
                  );
                }
                return false; // No government stakeholder, so return false
              },
            );

            // If we found at least one valid ministry, set the value to true
            if (hasStakeholder) {
              acc[federalItem.id] = true;
            }

            return acc;
          },
          {},
        );
      dispatch(
        setStakeholderValidationSubNational(hasGeneralStakeHolderSubNational),
      );
      dispatch(setSubDisabled(hasGovernmentStakeholderSubNational));
    }
  }, [stakeholder, dispatch]);

  const { data: provinceData, isLoading: isWorkingAreasLoading } = useQuery({
    queryKey: ['get-working-areas'],
    queryFn: () => getWorkingAreas({ project_data: portfolioID }),
    select: res => {
      const workingArea = res?.data
        ?.map((area: Record<string, any>) => ({
          id: area.province,
          label: area.province__name,
          value: area.province,
        }))
        ?.filter((area: Record<string, any>) => area?.label);
      const mappedAreas = new Map<string, Record<string, any>>(
        workingArea?.map((area: Record<string, any>) => [area.id, area]),
      );

      const uniqueProvinceLocations = [...mappedAreas.values()];
      return uniqueProvinceLocations;
    },
  });

  const { data: stakeholderReportingData, isLoading: isStakeholderLoading } =
    useQuery({
      queryKey: ['get-stakeholder-reporting-data'],
      queryFn: () => getStakeholderData({ project_data: portfolioID }),
      select: res => res.data,
    });

  const { data: stakeholderTypeData, isLoading: isStakeholderTypeLoading } =
    useQuery({
      queryKey: ['get-stakeholder-type-choices'],
      queryFn: () => getChoiceData({ type: 'stakeholder_type' }),
      select: res =>
        res?.data?.map((stakeType: Record<string, any>) => ({
          id: stakeType.id,
          label: stakeType.name,
          value: stakeType.name,
        })),
    });

  // fetches interventionlevel list
  const {
    data: interventionLevelList,
    isFetching: isInterventionLevelFetching,
  } = useQuery({
    queryKey: ['intervention-level'],
    queryFn: () => getChoiceData({ type: 'level_of_intervention' }),
    select: list =>
      list?.data?.map((option: Record<string, any>) => ({
        id: option.name,
        label: option.name,
        value: option.name,
      })),
  });

  const handleChange = (
    selectedStake: Record<string, any>[],
    locationId: number,
    key: 'locationData' | 'federalData', // locationData means provincial data
  ) => {
    const selectedLocationData = stakeholder?.[key]?.find(
      (location: Record<string, any>) => location.id === locationId,
    );

    const filteredstakeholder = selectedLocationData?.stakeholder?.map(
      (stake: Record<string, any>) => {
        if (selectedStake?.includes(stake.type)) {
          return {
            ...stake,
            data: stake?.data?.map((dt: Record<string, any>) => ({
              ...dt,
              isDelete: false,
            })),
          };
        }
        return {
          ...stake,
          data: stake?.data?.map((dt: Record<string, any>) => ({
            ...dt,
            isDelete: true,
          })),
        };
      },
    );

    let modifiedData = {
      ...selectedLocationData,
      stakeholder: filteredstakeholder,
    };
    selectedStake?.forEach(stake => {
      if (
        !filteredstakeholder?.some((s: Record<string, any>) => s.type === stake)
      ) {
        modifiedData = {
          ...modifiedData,
          stakeholder: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...modifiedData?.stakeholder,
            {
              type: stake,
              data: [
                {
                  id: `new-${uuidv4()}`,
                  unit_ministry: '',
                  unit_ministry_department: '',
                  local_government_stakeholder: '',
                },
              ],
            },
          ],
        };
      }
    });

    const newLocationData = stakeholder?.[key]?.map(
      (location: Record<string, any>) => {
        return location.id === locationId ? modifiedData : location;
      },
    );
    const newStakeholderData = {
      ...stakeholder,
      [key]: newLocationData,
    };
    setValue('stakeholder', newStakeholderData);
  };

  useEffect(() => {
    if (!provinceData || !stakeholderReportingData) return;
    const levelOfIntervention = {
      'National Level': stakeholderReportingData.some(
        (stake: Record<string, any>) =>
          stake.level_of_intervention === 'National Level',
      ),
      'Sub-National Level': stakeholderReportingData.some(
        (stake: Record<string, any>) =>
          stake.level_of_intervention === 'Sub-National Level',
      ),
    };

    const locationData = mapProvincesWithStakeholders(
      provinceData,
      stakeholderReportingData,
      (stake, province) => stake.province === province?.id,
    );

    const federalData = mapProvincesWithStakeholders(
      federalLocationData,
      stakeholderReportingData,
      stake => stake.level_of_intervention === 'National Level',
    );

    const newStakeholderData = {
      level_of_intervention: levelOfIntervention,
      locationData,
      federalData,
    };
    setValue('stakeholder', newStakeholderData);
  }, [provinceData, setValue, stakeholderReportingData]);

  return (
    <FlexColumn className="naxatw-w-full naxatw-space-y-5">
      <FormControl className="naxatw-w-full naxatw-space-y-5">
        {isInterventionLevelFetching ||
        isWorkingAreasLoading ||
        isStakeholderLoading ||
        isStakeholderTypeLoading ? (
          <div>
            <FlexColumn className="naxatw-gap-4">
              <FormSkeleton numRows={1} />
              <Skeleton className="naxatw-h-80 naxatw-w-full" />
              <Skeleton className="naxatw-h-80 naxatw-w-full" />
            </FlexColumn>
          </div>
        ) : (
          <>
            <div className="naxatw-gap-[0.5rem] naxatw-space-y-3">
              <InputLabel label="Which stakeholders is the project working with?" />
              {interventionLevelList?.map((list: Record<string, any>) => {
                return (
                  <Controller
                    key={list.index}
                    control={control}
                    name={`stakeholder.level_of_intervention.${list.id}`}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        value={value}
                        onChange={onChange}
                        label={list.label}
                        id={list.id}
                        checked={value}
                      />
                    )}
                  />
                );
              })}
            </div>

            {/* National Level */}
            {levelOfInterventionWatch?.['National Level'] && (
              <div className="naxatw-rounded-md naxatw-border">
                <p className="naxatw-subtitle-md naxatw-p-3">National Level</p>
                <div className="naxatw-space-y-3 naxatw-divide-y-[1px] naxatw-px-1">
                  {federalLocationData?.map(province => (
                    <div
                      key={province.id}
                      className="budget-allocated-provinces naxatw-mb-4  naxatw-space-y-3 naxatw-overflow-hidden naxatw-px-5 naxatw-py-3"
                    >
                      <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
                        <InputLabel label="Stakeholder Type" />

                        <DropDown
                          placeholder="Choose Stakeholder Type"
                          options={
                            stakeholderTypeData.filter(
                              (stakeholderType: any) =>
                                !excludeNationalLevelStakeholder.includes(
                                  stakeholderType.value,
                                ),
                            ) || []
                          }
                          value={getTypeValue(
                            formValues,
                            province.id,
                            'federalData',
                          )}
                          onChange={value =>
                            handleChange(value, province.id, 'federalData')
                          }
                          choose="value"
                          multiple
                          checkBox
                        />
                      </FormControl>
                      {/* Stakeholders Map */}
                      {stakeholder?.federalData
                        ?.find(
                          (location: Record<string, any>) =>
                            location.id === province.id,
                        )
                        ?.stakeholder?.map((stake: Record<string, any>) => {
                          if (
                            stake?.data?.some(
                              (stakeData: Record<string, any>) =>
                                stakeData?.isDelete,
                            )
                          )
                            return;
                          return (
                            <Accordion
                              key={stake.type}
                              type="single"
                              collapsible
                              className="naxatw-w-full naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-2"
                            >
                              <AccordionItem
                                value="id"
                                key="key"
                                className="!naxatw-border-0"
                              >
                                <AccordionTrigger className="!naxatw-py-2 hover:!naxatw-no-underline">
                                  <p className="naxatw-subtitle-md naxatw-text-matt-100">
                                    {`${capitalizeFirstLetter(stake?.type)} ${stake?.type?.toLowerCase().includes('stakeholder') ? '' : 'Stakeholder'}`}
                                  </p>
                                  <ChevronDown className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200" />
                                </AccordionTrigger>
                                <AccordionContent>
                                  {getStakeholderComponent(
                                    stake.type,
                                    province.id,
                                    'federalData',
                                  )}
                                </AccordionContent>
                              </AccordionItem>
                            </Accordion>
                          );
                        })}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Sub-National Level */}
            {levelOfInterventionWatch?.['Sub-National Level'] && (
              <div className=" naxatw-rounded-md naxatw-border">
                <p className="naxatw-subtitle-md naxatw-p-3">
                  Sub-National Level
                </p>
                <div className="naxatw-space-y-3 naxatw-divide-y-[1px] naxatw-px-1">
                  {provinceData?.map(province => (
                    <div
                      key={province.id}
                      className="budget-allocated-provinces naxatw-mb-4  naxatw-space-y-3 naxatw-overflow-hidden  naxatw-px-5 naxatw-py-3"
                    >
                      <span>{province.label}</span>
                      <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
                        <InputLabel label="Stakeholder Type" />

                        <DropDown
                          placeholder="Choose Stakeholder Type"
                          options={
                            stakeholderTypeData.filter(
                              (stakeholderType: any) =>
                                !excludeSubNationalLevelStakeholder.includes(
                                  stakeholderType.value,
                                ),
                            ) || []
                          }
                          value={getTypeValue(
                            formValues,
                            province.id,
                            'locationData',
                          )}
                          onChange={value =>
                            handleChange(value, province.id, 'locationData')
                          }
                          choose="value"
                          multiple
                          checkBox
                        />
                      </FormControl>
                      {/* Stakeholders Map */}
                      {stakeholder?.locationData
                        ?.find(
                          (location: Record<string, any>) =>
                            location.id === province.id,
                        )
                        ?.stakeholder?.map(
                          (stake: Record<string, any>, index: number) => {
                            if (
                              stake?.data?.some(
                                (stakeData: Record<string, any>) =>
                                  stakeData?.isDelete,
                              )
                            )
                              return;
                            return (
                              <Accordion
                                key={stake.type}
                                type="single"
                                collapsible
                                defaultValue={
                                  index === 0 ? stake.type : undefined
                                }
                                className="naxatw-w-full naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-2"
                              >
                                <AccordionItem
                                  value={stake.type}
                                  key="key"
                                  className="!naxatw-border-0"
                                >
                                  <AccordionTrigger className="!naxatw-py-2 hover:!naxatw-no-underline">
                                    <p className="naxatw-subtitle-md naxatw-text-matt-100">
                                      {`${capitalizeFirstLetter(stake?.type)} ${stake?.type?.toLowerCase().includes('stakeholder') ? '' : 'Stakeholder'}`}
                                    </p>
                                    <ChevronDown className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200" />
                                  </AccordionTrigger>
                                  <AccordionContent>
                                    {getStakeholderComponent(
                                      stake.type,
                                      province.id,
                                      'locationData',
                                    )}
                                  </AccordionContent>
                                </AccordionItem>
                              </Accordion>
                            );
                          },
                        )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </FormControl>
    </FlexColumn>
  );
};

export default StakeholdersForm;
