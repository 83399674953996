/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import { deleteComponentByID, getComponentList } from '@Services/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import Portal from '@Components/common/Layouts/Portal';
import { useTypedSelector } from '@Store/hooks';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';

const ComponentsTable = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [componentID, setComponentID] = useState<string>('');
  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.projects,
  );

  const userProjects = useTypedSelector(
    state => state.common?.userProfile?.project,
  );

  const groupName = useTypedSelector(
    state => state.common.userProfile?.group_name,
  );

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const {
    mutate: deleteComponent,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteComponentByID(componentID),
    onSuccess: () => {
      toast.success('Delete Success');
      queryClient.invalidateQueries({ queryKey: ['components'] });
      setOpenDeleteConfirmation(prev => !prev);
    },
  });

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'PROGRAMME',
      accessorKey: 'program__name',
    },
    {
      header: 'PARTNER',
      accessorKey: 'first_tier_partner__name',
    },
    {
      header: 'ALLOCATED BUDGET (£)',
      accessorKey: 'project_budget__approved_budget',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => (
        <span>
          {row?.original?.project_budget__approved_budget?.toLocaleString(
            'en-us',
          ) || '-'}
        </span>
      ),
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => {
        const cellId = row?.original?.id || '';

        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-5">
            {/* <ToolTip
              name="open_in_new"
              message="Project Details"
              className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200"
              iconClick={() => {
                navigate(`/explore-by/programmes/${programmeId}/?componentId=${cellId}`);
              }}
            /> */}

            {userGroupPermission?.includes('Can change project') ? (
              <div className="naxatw-cursor-not-allowed">
                <ToolTip
                  name="edit"
                  message="Edit"
                  className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200"
                  iconClick={() => {
                    navigate(`/data-bank/project/edit/${cellId}`);
                  }}
                  triggerClassName={
                    groupName === 'System Admin'
                      ? ''
                      : userProjects && isEmpty(userProjects)
                        ? ''
                        : userProjects && !userProjects.includes(cellId)
                          ? '!naxatw-pointer-events-none'
                          : ''
                  }
                />
              </div>
            ) : null}
            {userGroupPermission?.includes('Can delete project') ? (
              <div className="naxatw-cursor-not-allowed">
                <ToolTip
                  name="delete"
                  message="Delete"
                  className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
                  iconClick={() => {
                    setOpenDeleteConfirmation(prev => !prev);

                    setComponentID(cellId);
                  }}
                  triggerClassName={
                    groupName === 'System Admin'
                      ? ''
                      : userProjects && isEmpty(userProjects)
                        ? ''
                        : userProjects && !userProjects.includes(cellId)
                          ? '!naxatw-pointer-events-none'
                          : ''
                  }
                />
              </div>
            ) : null}
          </FlexRow>
        );
      },
    },
  ];

  return (
    <div className="naxatw-h-[calc(100%-10rem)] naxatw-w-full lg:naxatw-h-[calc(100%-7.875rem)]">
      <DataTable
        columns={columns}
        queryKey="components"
        queryFn={getComponentList}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
        className="!naxatw-max-h-none"
        showDataCount
        dataCountCategory="Projects"
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteComponent()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(ComponentsTable);
