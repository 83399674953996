import { z } from 'zod';

export const MapLayersValidationSchema = z.object({
  name_en: z.string().trim().min(1, 'Layer Name is Required'),
  type_of_layer: z.string().trim().min(1, 'Layer Type is Required'),
  geometry_type: z.string().trim().min(1, 'Geometry Type is Required'),
  file_upload: z.union([
    z.array(z.any()).min(1, 'Map Layer is Required'), // Non-empty array
    z.string(), // Single string
  ]),
});

export type MapLayersValidationSchemaProps = z.infer<
  typeof MapLayersValidationSchema & {
    [key: string]: any;
  }
>;
