/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import {
  descriptionData,
  mapWikikpediaByProvince,
} from '@Constants/exploreByGeography';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useRef, useState } from 'react';
import useSeeMoreButton from '@Hooks/useSeeMoreButton';

interface IDescritptionCardProps {
  cardHeadersData: Record<string, any>;
  isLoading?: boolean;
  activeProvinceName?: Record<string, any>;
}

export default function DescriptionCard({
  cardHeadersData,
  isLoading,
  activeProvinceName,
}: IDescritptionCardProps) {
  const updatesRef = useRef(null);
  const getQueryParams = useRouteParamQuery();
  const [isSeeMoreToggled, setIsSeeMoreToggled] = useState(false);
  const { isOpen, setIsOpen, showSeeMoreBtn } = useSeeMoreButton(updatesRef);

  const getMunicipality = getQueryParams.get('municipality') || null;
  const wikipediaUrl = activeProvinceName
    ? mapWikikpediaByProvince[activeProvinceName?.name]
    : {};

  function sliceString(description: string) {
    const maxLength = 230;
    if (description.length <= maxLength) return description;

    // Check if there's a space before or at the maxLength
    const slicePoint = description?.lastIndexOf(' ', maxLength);

    // If a space exists, slice at that point; otherwise, slice at maxLength
    return slicePoint !== -1
      ? description?.slice(0, slicePoint)
      : description?.slice(0, maxLength);
  }
  return (
    <>
      {isLoading ? (
        <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-3">
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
        </div>
      ) : (
        <div className=" naxatw-w-full">
          {!getMunicipality && (
            <div className="naxatw-flex naxatw-flex-col naxatw-gap-8">
              <span className="naxatw-text-[14px] naxatw-text-[#484848]">
                <p className="naxatw-text-ellipsis naxatw-text-[14px] naxatw-text-[#484848]">
                  {cardHeadersData?.description?.length ? (
                    <span className="naxatw-text-[14px]">
                      {isSeeMoreToggled
                        ? cardHeadersData.description
                        : sliceString(cardHeadersData.description)}
                      <span
                        role="button"
                        className="naxatw-cursor-pointer naxatw-font-semibold naxatw-text-primary-700"
                        onClick={() => setIsSeeMoreToggled(!isSeeMoreToggled)}
                      >
                        {isSeeMoreToggled ? ' See Less...' : ' See More...'}
                      </span>
                    </span>
                  ) : (
                    'N/A'
                  )}
                </p>

                {isSeeMoreToggled && (
                  <>
                    <br />
                    <p
                      className="naxatw-cursor-pointer naxatw-text-sm naxatw-font-bold naxatw-leading-normal naxatw-text-[#06367D] naxatw-underline hover:naxatw-text-[#63589e]"
                      onClick={() => {
                        if (wikipediaUrl) {
                          window.open(
                            wikipediaUrl,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }
                      }}
                    >
                      More Information
                    </p>
                  </>
                )}
              </span>
              <div className="province-update">
                <p className="naxatw-mb-1 naxatw-text-[0.875rem] naxatw-font-bold naxatw-text-primary-600">
                  Updates
                </p>
                <div
                  className={`${isOpen ? '' : 'naxatw-line-clamp-3'} editor-content naxatw-text-[0.875rem] naxatw-leading-[1.25rem] naxatw-text-[#484848]`}
                  ref={updatesRef}
                  dangerouslySetInnerHTML={{
                    __html: cardHeadersData?.update || '-',
                  }}
                />
                {showSeeMoreBtn && (
                  <button
                    type="button"
                    className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F]"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    See {isOpen ? 'Less' : 'More'}
                  </button>
                )}
              </div>
              <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-y-8">
                {descriptionData?.data.map((cardHeaders: any) => (
                  <div
                    className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2"
                    key={cardHeaders.name}
                  >
                    <p className=" naxatw-text-sm naxatw-text-[#475467]">
                      {cardHeaders.name}
                    </p>
                    <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                      {`${cardHeadersData[cardHeaders.value] || 'N/A'}`}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {getMunicipality && (
            <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-y-8">
              <div className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2">
                <p className=" naxatw-text-sm naxatw-text-[#475467]">Mayor</p>
                <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                  Jokh Bahadur Mahar
                </p>
              </div>
              <div className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2">
                <p className=" naxatw-text-sm naxatw-text-[#475467]">
                  Deputy Mayor
                </p>
                <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                  Mahadev Pantha
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
