import Icon from '@Components/common/Icon';

interface MultiStepProps {
  id: number;
  title: string;
  position: number;
  isactive: boolean;
  onClick: () => void;
  iscompleted?: boolean;
}

const MultiStep = ({
  id,
  title,
  position,
  isactive,
  onClick,
  iscompleted,
}: MultiStepProps) => {
  return (
    <div
      className={`naxatw-flex naxatw-flex-col naxatw-items-center naxatw-justify-start naxatw-gap-2 lg:naxatw-flex-row ${
        isactive ? 'naxatw-border-primary-400' : 'naxatw-border-transparent'
      }`}
      key={id}
      tabIndex={0}
      role="button"
      onClick={() => onClick()}
      onKeyDown={() => {}}
    >
      {iscompleted ? (
        <div className="naxatw-flex naxatw-h-[1.75rem] naxatw-min-h-[1.75rem] naxatw-w-[1.75rem] naxatw-min-w-[1.75rem] naxatw-items-center naxatw-justify-center naxatw-rounded-full naxatw-border-[1px] naxatw-bg-secondary-500">
          <Icon name="check" className="naxatw-text-white" />
        </div>
      ) : (
        <div
          className={`naxatw-flex naxatw-h-[1.75rem] naxatw-min-h-[1.75rem] naxatw-w-[1.75rem] naxatw-min-w-[1.75rem] naxatw-items-center naxatw-justify-center naxatw-rounded-full naxatw-border naxatw-font-bold  ${
            isactive
              ? 'naxatw-border-secondary-500 naxatw-text-secondary-500'
              : 'naxatw-text-matt-200'
          } `}
        >
          {position}
        </div>
      )}
      <p
        className={`naxatw-text-body-md ${
          isactive ? 'naxatw-text-secondary-500' : 'naxatw-text-matt-200'
        } naxatw-mt-1 naxatw-text-[0.938rem] naxatw-font-medium`}
      >
        {title}
      </p>
    </div>
  );
};

export default MultiStep;
