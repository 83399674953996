/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { FlexRow } from '@Components/common/Layouts';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { setSelectedFeature } from '@Store/actions/provincialProfile';
import {
  getDistrictList,
  getMunicipalityList,
  getProvinceList,
} from '@Services/mainDashboard';
import { useTypedSelector } from '@Store/hooks';
import SearchWithToggle from '@Components/common/SearchBar/SearchWithToggle';
import SearchList from './SearchList';

const SearchFilter = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const activeViewBy = useTypedSelector(
    state => state.mainDashboard.map.activeViewBy,
  );
  const [showList, setShowList] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchedText,
    onChange: debouncedEvent => {
      setSearchedText(debouncedEvent.target.value);
    },
  });

  const { data: provinceList, isLoading: isProvinceFetching } = useQuery({
    queryKey: ['province-list', searchedText],
    queryFn: async () => getProvinceList({ search: searchedText }),
    select: (response: any) => response.data,
    enabled: activeViewBy === 'province',
  });

  const { data: districtList, isLoading: isDistrictFetching } = useQuery({
    queryKey: ['district-list', searchedText],
    queryFn: async () => getDistrictList({ search: searchedText }),
    select: (response: any) => response.data,
    enabled: activeViewBy === 'district',
  });

  const { data: municipalityList, isLoading: isMunicipalityFetching } =
    useQuery({
      queryKey: ['municipality-list', searchedText],
      queryFn: async () => getMunicipalityList({ search: searchedText }),
      select: (response: any) => response.data,
      enabled: activeViewBy === 'municipality',
    });

  const getSearchListData = (viewBy: string) => {
    let list = [];
    switch (viewBy) {
      case 'municipality':
        list = municipalityList;
        break;
      case 'district':
        list = districtList;
        break;
      case 'province':
        list = provinceList;
        break;
      default:
        list = [];
        break;
    }

    return list;
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowList(false);
      setSearchedText('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <FlexRow className="actions naxatw-absolute naxatw-left-96 naxatw-top-5 naxatw-z-10 naxatw-w-[180px]">
      <div className="naxatw-relative naxatw-w-full" ref={dropdownRef}>
        <SearchWithToggle
          value={value}
          onChange={handleChange}
          placeholder={`Search ${activeViewBy}`}
          onClick={() => setShowList(!showList)}
          onSearchBarClose={() => setShowList(false)}
        />
        {(showList || value) && (
          <SearchList
            dataList={getSearchListData(activeViewBy)}
            isLoading={
              activeViewBy === 'municipality'
                ? isMunicipalityFetching
                : activeViewBy === 'district'
                  ? isDistrictFetching
                  : activeViewBy === 'province'
                    ? isProvinceFetching
                    : false
            }
            dataKey="name"
            onDataClick={(data: any) => {
              dispatch(setSelectedFeature(data));
              setShowList(false);
            }}
          />
        )}
      </div>
    </FlexRow>
  );
};

export default SearchFilter;
