/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { portfolioReportingRegistrationFormFields } from '@Constants/FormConstants/componentUpdateConstants';
import groupFormElements, { generateYearList } from '@Utils/index';
import { useFormContext } from 'react-hook-form';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import {
  getPeriodOfReporting,
  getProgramDropdown,
  getComponentDropdown,
  getFirstTierPartner,
  getSinglePartnerReporting,
} from '@Services/portfolioReporting';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import FirstTierPartner from './FirstTierPartner';
import DownstreamPartner from './DownstreamPartner';

const RegistrationForm = () => {
  const navigate = useNavigate();
  const { portfolioID } = useParams();
  const groupedFormFields = groupFormElements(
    portfolioReportingRegistrationFormFields,
  );

  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    watch,
  } = useFormContext();

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
  };

  const formValues = watch();
  const componentValueWatch = watch('component');
  const programValueWatch = watch('program');

  const { data: periodOfReporting } = useQuery({
    queryKey: ['portfolio-registration'],
    queryFn: () => getPeriodOfReporting(),
    select: res => {
      const reportingPeriod = res?.data;
      const updatedData = reportingPeriod?.map(
        (period: Record<string, any>) => {
          return {
            id: period?.name,
            name: period?.name,
            label: period?.name,
          };
        },
      );
      return updatedData;
    },
  });

  // fetch individual partner reporting data
  const { isLoading: getPartnerReportingLoading } = useQuery({
    queryKey: ['get-partner-reporting'],
    enabled: !!portfolioID,
    queryFn: () => getSinglePartnerReporting(Number(portfolioID)),
    onSuccess: response => {
      const { year, reporting_period, program, project, downstream_partner } =
        response.data;
      const downstreamPartners = downstream_partner?.split('|');
      const downStreamValuesArray = downstreamPartners?.map(
        (partner: string, index: number) => ({ id: index + 1, value: partner }),
      );
      setValue('year', year);
      setValue('reporting_period', reporting_period);
      setValue('program', program);
      setValue('component', project);
      setValue('downstream_partner', downStreamValuesArray);
    },
    onError: (error: Record<string, any>) => {
      toast.error(error?.response?.data?.detail || 'Project Data not found');
      navigate('/data-bank/project-data');
    },
  });

  // fetch programme dropdown
  const { data: programDropDownData, isLoading: isProgramDropDownLoading } =
    useQuery({
      queryKey: ['programme-dropdown'],
      queryFn: () => getProgramDropdown(),
      select: res => {
        const programDropdown = res?.data;
        const updatedData = programDropdown?.map(
          (period: Record<string, any>) => {
            return {
              id: period?.id,
              name: period?.name,
              label: period?.name,
            };
          },
        );
        return updatedData;
      },
    });

  // fetch component dropdown
  const { data: componentDropDownData, isLoading: componentDropdownIsLoading } =
    useQuery({
      queryKey: ['component-dropdown', programValueWatch],
      queryFn: () => getComponentDropdown({ program: programValueWatch }),
      select: res => {
        const componentDropdown = res?.data;
        const updatedData = componentDropdown?.map(
          (period: Record<string, any>) => {
            return {
              id: period?.id,
              name: period?.name,
              label: period?.name,
            };
          },
        );
        return updatedData;
      },
    });

  // fetch first tier Partner
  const { isLoading: firstTierPartnerLoading } = useQuery({
    queryKey: ['first-tier-partner', formValues?.component],
    queryFn: () => getFirstTierPartner({ project: formValues.component }),
    select: res => {
      return res.data;
    },
    enabled: !!formValues.component,
    onSuccess: response => {
      setValue('first_tier_partner', response);
      setValue('partner', response.first_tier_partner__id);
    },
  });

  const isFormLoading =
    (portfolioID &&
      (getPartnerReportingLoading ||
        isProgramDropDownLoading ||
        componentDropdownIsLoading)) ||
    (!portfolioID && isProgramDropDownLoading);

  const dropdownOptions = {
    reporting_period: periodOfReporting,
    year: generateYearList(2000),
    program: programDropDownData,
    component: componentDropDownData,
  };

  const handleDownStreamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const selectedData = formValues.downstream_partner.find(
      (partner: Record<string, any>) => String(partner.id) === name,
    );
    const newChangedData = { ...selectedData, value };
    const newDownStreamPartners = formValues.downstream_partner.map(
      (partner: Record<string, any>) =>
        partner.id === selectedData.id ? newChangedData : partner,
    );
    setValue('downstream_partner', newDownStreamPartners, {
      shouldDirty: true,
    });
  };
  const handleAddDownstream = () => {
    const currentDownstreamPartners = formValues.downstream_partner;
    const newDownStreamPartners = [
      ...currentDownstreamPartners,
      { id: currentDownstreamPartners.length + 1, value: '' },
    ];
    setValue('downstream_partner', newDownStreamPartners, {
      shouldDirty: true,
    });
  };

  const handleDownstreamDelete = (id: number) => {
    const currentDownstreamPartners = formValues.downstream_partner;
    const newDownStreamPartners = currentDownstreamPartners.filter(
      (partner: Record<string, any>) => partner.id !== id,
    );
    setValue('downstream_partner', newDownStreamPartners, {
      shouldDirty: true,
    });
  };

  return (
    <>
      {isFormLoading ? (
        <FormSkeleton numRows={2} />
      ) : (
        <FlexColumn className="naxatw-w-full naxatw-gap-5 naxatw-pb-3">
          {groupedFormFields?.map((fieldRow: Record<string, any>, i) => (
            <FlexRow key={i}>
              {fieldRow?.map((field: FormFieldProps) => {
                const { id } = field;

                // eslint-disable-next-line no-nested-ternary
                return field.type === 'custom' &&
                  field.id === 'focal_person' ? (
                  componentValueWatch ? (
                    <FlexRow className="naxatw-w-full">
                      <FlexColumn className="naxatw-w-full naxatw-gap-[0.5rem] ">
                        <InputLabel label="First Tier Partner" />
                        <FirstTierPartner isLoading={firstTierPartnerLoading} />
                      </FlexColumn>
                    </FlexRow>
                  ) : null
                ) : field.id === 'downstream_partner' ? (
                  <FlexColumn className="naxatw-w-full naxatw-gap-3 naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-3">
                    <FlexRow className="naxatw-w-full">
                      {formValues.downstream_partner ? (
                        <DownstreamPartner
                          formValues={formValues}
                          handleAddDownstream={handleAddDownstream}
                          handleDownStreamChange={handleDownStreamChange}
                          handleDownstreamDelete={handleDownstreamDelete}
                        />
                      ) : null}
                    </FlexRow>
                  </FlexColumn>
                ) : (
                  <FlexRow
                    key={`${field.id}- ${field.name}`}
                    className="naxatw-w-full naxatw-items-end"
                  >
                    <FormControl
                      className="naxatw-w-full naxatw-gap-[0.5rem] "
                      key={`${field.id}- ${field.name}`}
                    >
                      <InputLabel
                        label={field.label}
                        astric={field.required}
                        id={field.id}
                      />
                      <div className="naxatw-cursor-not-allowed">
                        {getInputElement(
                          {
                            ...field,
                            // type: portfolioID ? 'input' : 'select',
                            disabled: !!portfolioID,
                            className: portfolioID
                              ? 'naxatw-bg-primary-100 hover:naxatw-border-0 naxatw-border-0'
                              : '',
                          },
                          formProps,
                          /* @ts-ignore */
                          dropdownOptions[id],
                        )}
                      </div>
                      <>
                        {
                          /* @ts-ignore */
                          formProps.errors[id] && (
                            <ErrorMessage
                              /* @ts-ignore */
                              message={formProps.errors[id]?.message}
                            />
                          )
                        }
                      </>
                    </FormControl>
                  </FlexRow>
                );
              })}
            </FlexRow>
          ))}
        </FlexColumn>
      )}
    </>
  );
};

export default RegistrationForm;
