import { FlexColumn } from '@Components/common/Layouts';
import NoDataImage from '@Assets/images/no-data.png';

interface INoChartDataComponent {
  className?: string;
  message?: string;
  messageStyles?: string;
}

export default function NoChartDataComponent({
  className,
  message,
  messageStyles,
}: INoChartDataComponent) {
  return (
    <div
      className={`${className} naxatw-flex naxatw-h-full naxatw-min-h-[150px] naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-rounded-lg`}
    >
      <FlexColumn className="naxatw-gap-3">
        <img
          src={NoDataImage}
          alt="No Data"
          height={100}
          width={100}
          className="naxatw-mx-auto naxatw-w-full"
        />
        <h6 className={`naxatw-text-center ${messageStyles}`}>
          {message || 'No Data Available'}
        </h6>
      </FlexColumn>
    </div>
  );
}
