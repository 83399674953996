import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import BekFootprint from '../ExploreIcons/bekFootprint';
import PortfolioProfile from '../ExploreIcons/portfolioProfile';
import KnowledgeLibrary from '../ExploreIcons/knowledgeLibrary';
import DataBank from '../ExploreIcons/dataBank';

interface IExploreCardProps {
  id: string;
  title: string;
  description: string;
  link?: string;
}

const ExploreCard = (card: IExploreCardProps) => {
  const { id, title, description } = card;
  const [hover, setHover] = useState(false);

  const getIcon = (componentId: string, componentHover: boolean) => {
    switch (componentId) {
      case 'main-dashboard':
        return <BekFootprint hover={componentHover} />;
      case 'data-bank':
        return <DataBank hover={componentHover} />;
      case 'portfolio-profiles':
        return <PortfolioProfile hover={componentHover} />;
      case 'knowledge-library':
        return <KnowledgeLibrary hover={componentHover} />;
      default:
        return '';
    }
  };

  const navigate = useNavigate();

  return (
    <div
      tabIndex={0}
      role="button"
      className="explore-card"
      onClick={() => (card.link ? `${navigate(card.link)}` : '')}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onKeyDown={() => {}}
    >
      <div className="naxatw-flex naxatw-h-full naxatw-cursor-pointer naxatw-flex-col naxatw-gap-6 naxatw-rounded-lg naxatw-bg-white naxatw-px-[0.69rem] naxatw-py-[2.19rem] naxatw-pb-[3.03rem] naxatw-shadow-xs hover:naxatw-shadow-lg">
        <div className="naxatw-mx-auto naxatw-flex naxatw-h-[4.4rem] naxatw-w-[4.4rem] naxatw-items-center naxatw-justify-center naxatw-rounded-[4.652rem] naxatw-bg-primary-100">
          {getIcon(id, hover)}
        </div>
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.88rem]">
          <h5 className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-tracking-[-0.045rem] naxatw-text-[#06367D]">
            {title}
          </h5>
          <p className="naxatw-mx-auto naxatw-max-w-[14.375rem] naxatw-text-[0.875rem] naxatw-leading-5 naxatw-text-matt-200">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExploreCard;
