export const convertToInteger = (value: string): number => {
  return typeof value === 'string'
    ? Number(value?.replace(/[, ]+/g, '').trim())
    : value;
};

function getMaxAndMinValues(arr: Record<string, any>[], key: string) {
  if (!arr || arr?.length === 0) return { min: 0, max: 0 };
  const values = arr?.map(itm => convertToInteger(itm?.[key]));
  return { min: Math.min(...values), max: Math.max(...values) };
}

const RoundingOffValue = 5; // to end with 5 or 0 as a last digit of max value on every range
const roundUpToMultipleOfRangeAnd5 = (max: number, range: number) => {
  return (
    Math.ceil(max / (range * RoundingOffValue)) * (range * RoundingOffValue)
  );
};

// returns ranges like 0-4, 5-10
const getRangeValues = (max: number, range: number) => {
  const rangeGap = max / range;
  const value = [];
  for (let i = 0; i < max; i += rangeGap) {
    value.push({ min: i + 1, max: i + rangeGap });
  }
  return value;
};

// returns ranges like 0-4, 4-10
// const getRangeValuesDecimalSupport = (max: number, range: number) => {
//   const rangeGap = max / range;
//   const value = [];
//   for (let i = 0; i < max; i += rangeGap) {
//     value.push({ min: i, max: i + rangeGap });
//   }
//   return value;
// };

const getRanges = (max: number, min: number, range: number) => {
  const startingMin = min === 0 ? 1 : min;
  const rangeGap = (max - startingMin) / range;
  const value = [];
  for (let i = startingMin; i < max; i += rangeGap) {
    value.push({ min: i?.toFixed(2), max: (i + rangeGap)?.toFixed(2) });
  }
  return value;
};

export const colorCodes = ['#B2C8E6', '#7F9FCE', '#08519C', '#073A6E'];

const rangesForMaxValue5 = [
  { min: 1, max: 1, color: colorCodes[0] },
  { min: 2, max: 2, color: colorCodes[1] },
  { min: 3, max: 3, color: colorCodes[2] },
  { min: 4, max: 6, color: colorCodes[3], hasGreaterThan: true },
];

const rangesForMaxValue10 = [
  { min: 1, max: 2, color: colorCodes[0] },
  { min: 3, max: 4, color: colorCodes[1] },
  { min: 5, max: 6, color: colorCodes[2] },
  { min: 7, max: 10, color: colorCodes[3], hasGreaterThan: true },
];

const rangesForMaxValue10DecimalSupport = [
  { min: 1, max: 2, color: colorCodes[0] },
  { min: 2, max: 4, color: colorCodes[1] },
  { min: 4, max: 6, color: colorCodes[2] },
  { min: 6, max: 10, color: colorCodes[3], hasGreaterThan: true },
];

const rangesForMaxValue15 = [
  { min: 1, max: 3, color: colorCodes[0] },
  { min: 4, max: 6, color: colorCodes[1] },
  { min: 7, max: 9, color: colorCodes[2] },
  { min: 10, max: 15, color: colorCodes[3], hasGreaterThan: true },
];

const rangesForMaxValue15DecimalSupport = [
  { min: 1, max: 4, color: colorCodes[0] },
  { min: 4, max: 8, color: colorCodes[1] },
  { min: 8, max: 12, color: colorCodes[2] },
  { min: 12, max: 15, color: colorCodes[3], hasGreaterThan: true },
];

const getRangeValueWithColorCode = (data: any, key: string) => {
  const numberOfRanges = 4;
  const { max } = getMaxAndMinValues(data, key);
  // return static range
  if (max <= 7) return rangesForMaxValue5;
  if (max <= 10) return rangesForMaxValue10;
  if (max <= 15) return rangesForMaxValue15;
  // returns nearest round-off value like 20, 40, 60
  const roundOffValue = roundUpToMultipleOfRangeAnd5(max, numberOfRanges);
  const ranges = getRangeValues(roundOffValue, numberOfRanges);
  return ranges?.map((range, index: number) => ({
    ...range,
    color: colorCodes[index],
  }));
};

export const getRangeValueWithColorCodeDecimalSupport = (
  data: any,
  key: string,
) => {
  const numberOfRanges = 4;
  const { max, min } = getMaxAndMinValues(data, key);

  // return static range
  if (min === 0 && max === 0) return [];
  if (max <= 10) return rangesForMaxValue10DecimalSupport;
  if (max <= 15) return rangesForMaxValue15DecimalSupport;
  // returns nearest round-off value like 20, 40, 60
  // const roundOffValue = roundUpToMultipleOfRangeAnd5(max, numberOfRanges);
  // const ranges = getRangeValuesDecimalSupport(roundOffValue, numberOfRanges);

  const rangesx = getRanges(max, min, numberOfRanges);

  // return ranges?.map((range, index: number) => ({
  //   ...range,
  //   color: colorCodes[index],
  // }));

  return rangesx?.map((range, index: number) => ({
    ...range,
    color: colorCodes[index],
  }));
};

export default getRangeValueWithColorCode;
