/* eslint-disable no-nested-ternary */
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { cn } from '@Utils/index';

const OverlayLayerLegend = ({ className }: { className?: string }) => {
  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );

  const checkedOverlayLayerList = overlayLayerList?.filter(
    layer => layer.checked,
  );

  return (
    <FlexColumn
      gap={3}
      className={cn(
        'actions naxatw-absolute naxatw-right-6 naxatw-top-[80px] naxatw-z-[9]  naxatw-w-[190px] naxatw-rounded-lg naxatw-border  naxatw-border-solid naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-px-3 naxatw-py-3 naxatw-shadow-[0px_2px_8px_0px_rgba(16,24,40,0.08)]',
        className,
      )}
    >
      <div className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-border-b">
        <h6 className=" naxatw-py-1 naxatw-text-sm">Overlay layers</h6>
        <ToolTip
          name="info"
          className="naxatw-text-sm"
          message="Zoom in to see the name of each feature"
        />
      </div>

      {checkedOverlayLayerList?.map(layer => (
        <FlexRow
          gap={2}
          className="naxatw-flex-nowrap naxatw-items-center naxatw-justify-start"
          key={layer?.id}
        >
          {layer?.geometry_type === 'LineString' ? (
            <div
              className="naxatw-h-0.5 naxatw-w-4"
              style={{
                backgroundColor:
                  layer?.vector_layer_style__style_json?.['line-color'] ||
                  `#0664D2`,
                outline: '1px solid #E6E6E6',
              }}
            />
          ) : layer?.geometry_type === 'Point' ? (
            <div
              className="naxatw-h-3 naxatw-w-3 naxatw-rounded-full"
              style={{
                backgroundColor:
                  layer?.vector_layer_style__style_json?.['circle-color'] ||
                  `#0664D2`,
                outline: '1px solid #E6E6E6',
              }}
            />
          ) : layer?.geometry_type === 'Polygon' ? (
            <div
              className="naxatw-h-3 naxatw-w-3 naxatw-shrink-0"
              style={{
                backgroundColor:
                  layer?.vector_layer_style__style_json?.['fill-color'] ||
                  `#0664D2`,
                outline: '1px solid #E6E6E6',
              }}
            />
          ) : (
            <></>
          )}
          <div className="naxatw-flex naxatw-grow naxatw-items-center naxatw-justify-start naxatw-gap-1">
            <p
              className={`naxatw-shrink-0 naxatw-text-[0.625rem] naxatw-font-medium naxatw-capitalize naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200 ${layer?.geometry_type !== 'LineString' ? 'naxatw-pl-2' : ''}`}
            >
              {layer.layer__name_en}
            </p>
            {layer?.source && (
              <span
                className="w- naxatw-line-clamp-1 naxatw-grow naxatw-text-[8px]"
                title={layer?.source}
              >
                ({layer?.source})
              </span>
            )}
          </div>
        </FlexRow>
      ))}
    </FlexColumn>
  );
};

export default OverlayLayerLegend;
