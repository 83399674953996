import { useParams } from 'react-router-dom';
import Tutorial from '@Components/Tutorial';
import Manual from '@Components/Tutorial/Manual';
import VideoTutorial from '@Components/Tutorial/VideoTutorial';

const TutorialPage = () => {
  const { tutorialType } = useParams();

  function getComponent() {
    switch (tutorialType) {
      case 'video-tutorial':
        return <VideoTutorial />;
      case 'manual':
        return <Manual />;
      default:
        return <Tutorial />;
    }
  }
  return (
    <div className="naxatw-h-full naxatw-bg-[#F4F7FE] md:naxatw-h-[calc(100vh-63px)] lg:naxatw-overflow-y-auto">
      {getComponent()}
    </div>
  );
};

export default TutorialPage;
