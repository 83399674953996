import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';

interface IIconHeaderProps {
  name: string;
  title: string;
  iconClassName?: string;
  titleClassName?: string;
  className?: string;
}

const IconHeader = ({
  name,
  title,
  iconClassName,
  titleClassName,
  className,
}: IIconHeaderProps) => {
  return (
    <FlexRow
      className={`${className} naxatw-flex naxatw-items-center naxatw-gap-1`}
    >
      <Icon
        name={name}
        className={`${iconClassName} naxatw-flex naxatw-items-center naxatw-text-base naxatw-text-primary-700 `}
      />
      <p
        className={`naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-primary-700 ${titleClassName}`}
      >
        {title}
      </p>
    </FlexRow>
  );
};

export default IconHeader;
