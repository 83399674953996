/* eslint-disable react/jsx-key */
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import { PartnerFormFields } from '@Constants/FormConstants/partnerFormConstants';
import groupFormElements from '@Utils/index';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { useFormContext } from 'react-hook-form';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ErrorMessage from '@Components/common/ErrorMessage';
import { BasicInfoValidationSchemaProps } from 'validations/PartnerForm/basicInfoValidation';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationType } from '@Services/partner';

const BasicInfoForm = () => {
  const groupedFormFields = groupFormElements(PartnerFormFields);

  const { data: organizationTypeDropDown } = useQuery({
    queryKey: ['organization_type'],
    queryFn: () => getOrganizationType(),
    select: data =>
      data?.data?.map((org: Record<string, any>) => {
        return { id: org?.id, label: org.name, value: org.id };
      }),
  });

  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
  } = useFormContext<BasicInfoValidationSchemaProps>();

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
  };

  return (
    <FlexColumn className="naxatw-w-full naxatw-space-y-5 ">
      {groupedFormFields?.map((fieldRow: Record<string, any>) => (
        <FlexRow>
          {fieldRow?.map((field: FormFieldProps) => {
            const { id } = field;
            return (
              <FormControl
                className="naxatw-w-full naxatw-gap-[0.5rem] "
                key={`${field.id}- ${field.name}`}
              >
                <InputLabel
                  label={field.label || ''}
                  astric={field.required}
                  id={field.id}
                />
                {getInputElement(
                  {
                    ...field,
                  },
                  /* @ts-ignore */
                  formProps,

                  organizationTypeDropDown || [],
                )}
                <>
                  {
                    /* @ts-ignore */
                    formProps.errors[id] && (
                      <ErrorMessage
                        /* @ts-ignore */
                        message={formProps.errors[id]?.message}
                      />
                    )
                  }
                </>
              </FormControl>
            );
          })}
        </FlexRow>
      ))}
    </FlexColumn>
  );
};

export default hasErrorBoundary(BasicInfoForm);
