/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import { Button } from '@Components/RadixComponents/Button';
import dvsLogo from '@Assets/images/dvs-logo-new.svg';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { convertToCurrencySystem } from '@Utils/index';
import html2pdf from 'html2pdf.js';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { IProvincialProfilePdfState } from '@Store/slices/adminPdfSlice';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import IconHeader from '@Components/common/PdfDesign/IconHeaders';
import Icon from '@Components/common/Icon';
import {
  getProvincialPrioritiesGoalsData,
  getProvincialProfileReport,
} from '@Services/provincialProfile';
import { useParams } from 'react-router-dom';
import { getProvinceData } from '@Services/portfolioProfile';
import lumbini from '@Assets/images/lumbini.png';
import useOverallCountData from '@Hooks/getOverallCountData';
import madhesh from '@Assets/images/madesh.png';
import karnali from '@Assets/images/karnali.png';
import DataTable from '@Components/common/DataTable';

type IReportPdfPopUpProps = {
  // display?: boolean;
  handleClose?: () => void;
  compoenentId?: string;
  checkList: IProvincialProfilePdfState;
};

const labels = [
  { id: 'program_count', title: 'Programme ' },
  { id: 'project_count', title: 'Projects' },
  { id: 'sector_count', title: 'Sector' },
  { id: 'allocated_budget', title: 'Allocated Budget' },
];

function transformStakeholders(data: any[]) {
  const result: any[] = [];

  data?.forEach(stakeholder => {
    stakeholder.stakeholders?.forEach((levelObj: any) => {
      Object?.keys(levelObj)?.forEach(level => {
        const content = levelObj[level]?.map((categoryObj: any) => {
          const category = Object?.keys(categoryObj)[0];
          const items = categoryObj[category];
          return { category, items };
        });

        result.push({ level, content });
      });
    });
  });

  return result;
}

function getProvinceImage(provinceId: string) {
  switch (provinceId) {
    case '5':
      return lumbini;
    case '2':
      return madhesh;
    case '6':
      return karnali;
    default:
      return '';
  }
}

const ProvincailProfilePdfPopUp = ({
  // display = false,
  compoenentId,
  handleClose,
  checkList,
}: IReportPdfPopUpProps) => {
  const { provinceId } = useParams();
  const [customIsLoading, setCustomIsLoading] = useState(false);
  const [stakeholderData, setStakeholderData] = useState<any[]>([]);
  const contentRef = useRef<HTMLDivElement>(null);

  const {
    top_partners_by_budget_allocated: partnerBudget,
    sectors,
    programme_details,
    provincial_priorities: provincialPriorities,
    programme_details: programmeDetails,
    working_palikas,
    stakeholders,
  } = checkList;

  const { data: provinceCountData, isLoading: provinceCountDataIsLoading } =
    useOverallCountData({ province: provinceId });

  const { data: reportListData, isLoading: reportDataIsLoading } = useQuery({
    queryKey: ['reportList', compoenentId],
    queryFn: async () => getProvincialProfileReport({ province: provinceId }),
    select: res => res.data[0],
  });

  const {
    data: provincialPrioritiesGoalsData,
    isLoading: provincialPrioritiesGoalsDataIsLoading,
  } = useQuery({
    queryKey: ['provincial-priorities-goals-data'],
    queryFn: async () =>
      getProvincialPrioritiesGoalsData({
        province: provinceId,
      }),
    select: res => res.data,
  });

  const { data: provinceData, isLoading: provinceDataLoading } = useQuery({
    queryKey: ['provinceData', provinceId],
    queryFn: async () => getProvinceData(provinceId),
    select: res => res.data,
  });

  const handleDownloadPdf = () => {
    const element = contentRef.current;
    if (element) {
      const opt = {
        margin: 0.25,
        filename: 'my-document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['css', 'avoid-all'], avoid: '.no-page-break' },
      };

      // Create PDF
      html2pdf().from(element).set(opt).save();
    }
  };

  const columns = [
    {
      header: 'FIRST TIER PARTNER',
      accessorKey: 'first_tier_partner',
    },
    {
      header: '',
      accessorKey: 'blankId',
    },
  ];

  const hasSubNationalLevel = stakeholderData.some(
    item => item.level === 'Sub-National Level',
  );

  useEffect(() => {
    setCustomIsLoading(true);
    if (reportDataIsLoading) return;
    setStakeholderData(
      reportListData ? transformStakeholders(reportListData.stakeholder) : [],
    );
    setCustomIsLoading(false);
  }, [reportListData, reportDataIsLoading]);

  return (
    <>
      <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-overflow-hidden">
        <div className="naxatw-relative naxatw-mx-auto naxatw-h-[93vh] naxatw-w-11/12 naxatw-overflow-hidden naxatw-rounded-2xl naxatw-border naxatw-bg-white naxatw-px-5 naxatw-shadow-sm lg:naxatw-w-3/5 xl:naxatw-w-2/5">
          {reportDataIsLoading ||
          customIsLoading ||
          provinceCountDataIsLoading ||
          provincialPrioritiesGoalsDataIsLoading ||
          provinceDataLoading ? (
            <Skeleton className="naxatw-mt-4 naxatw-h-[93vh] naxatw-w-full" />
          ) : (
            <div
              ref={contentRef}
              className="no-scrollbar no-page-break naxatw-flex naxatw-h-full naxatw-w-full naxatw-flex-col naxatw-gap-5 naxatw-overflow-y-auto"
            >
              <FlexColumn className="naxatw-w-full naxatw-gap-5 naxatw-pb-[6rem]">
                <div className="naxatw-py-2">
                  <img src={dvsLogo} alt="" />
                </div>
                <FlexColumn className="naxatw-gap-2">
                  <p className="naxatw-text-xl naxatw-font-medium naxatw-leading-normal naxatw-text-primary-700">
                    {provinceData?.name}
                  </p>
                </FlexColumn>
                <div className="naxatw-grid naxatw-grid-cols-10 naxatw-gap-[0.62rem]">
                  <FlexColumn className="naxatw-col-span-5 naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-shadow-sm">
                    {labels.map((data, index) => (
                      <FlexRow
                        className={`naxatw-items-center naxatw-gap-3 ${index % 2 !== 0 ? 'naxatw-bg-gray-100' : ''}  naxatw-px-2 naxatw-py-1`}
                        key={uuidv4()}
                      >
                        <p className="naxatw-min-w-[30%] naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-gray-500">
                          {data.title}
                        </p>
                        <p>:</p>
                        <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-matt-200">
                          {data.id === 'allocated_budget'
                            ? `£ ${convertToCurrencySystem(
                                provinceCountData[data.id],
                              )}`
                            : provinceCountData[data.id] || '-'}
                        </p>
                      </FlexRow>
                    ))}
                  </FlexColumn>
                  <div className="naxatw-col-span-5">
                    <img
                      src={getProvinceImage(provinceId || '')}
                      className="naxatw-aspect-square naxatw-max-h-[8rem] naxatw-w-full naxatw-object-contain"
                      alt="Province"
                    />
                  </div>
                </div>
                {provincialPriorities && (
                  <FlexColumn className="naxatw-gap-3">
                    <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-primary-700">
                      Provincial Priorities Alignment with BEK Programme
                    </p>
                    <FlexColumn className="naxatw-gap-2 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-py-2 naxatw-shadow-sm">
                      {provincialPrioritiesGoalsData?.map(
                        (goals: any, goalsIndex: number) => {
                          const isGoalsLastIndex =
                            goalsIndex ===
                            provincialPrioritiesGoalsData.length - 1;
                          return (
                            <React.Fragment key={goals.id}>
                              <FlexColumn className="naxatw-gap-2 naxatw-px-3 naxatw-py-2">
                                <p className="naxatw-text-[0.75rem] naxatw-text-matt-200">
                                  {goals.goal}
                                </p>
                                <FlexRow className="naxatw-gap-1">
                                  {goals?.program_abbreviation?.map(
                                    (program: any, index: number) => {
                                      const isLastIndex =
                                        index ===
                                        goals.program_abbreviation.length - 1;
                                      return (
                                        <React.Fragment key={program}>
                                          <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem]">
                                            {program}
                                          </p>
                                          {!isLastIndex && (
                                            <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300" />
                                          )}
                                        </React.Fragment>
                                      );
                                    },
                                  )}
                                </FlexRow>
                              </FlexColumn>
                              {!isGoalsLastIndex && (
                                <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-100" />
                              )}
                            </React.Fragment>
                          );
                        },
                      )}
                    </FlexColumn>
                  </FlexColumn>
                )}

                <FlexColumn className="naxatw-gap-8">
                  {programme_details && (
                    <FlexColumn className="naxatw-gap-3">
                      <IconHeader name="article" title="Programme Details" />

                      <div className="table-content ">
                        {reportListData?.program_details?.map(
                          (tableData: any, index: any) => {
                            return (
                              <div
                                className="naxatw-mb-2 naxatw-py-1 last:naxatw-mb-0"
                                key={tableData.program_id}
                              >
                                <FlexRow className="naxatw-mb-3 naxatw-justify-between">
                                  <p className="naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-[#0B2E62]">
                                    {index + 1}. {tableData.programs}
                                  </p>
                                </FlexRow>
                                <DataTable
                                  queryKey="programme details"
                                  queryFn={() => {}}
                                  columns={[
                                    {
                                      header: 'PROJECT',
                                      accessorKey: 'project',
                                      // eslint-disable-next-line react/no-unstable-nested-components
                                      cell: row => {
                                        return `${index + 1}.${Number(row?.row?.id) + 1}  ${row?.row?.original.project}`;
                                      },
                                    },
                                    ...columns,
                                  ]}
                                  className="!naxatw-border-[0]"
                                  demoData={tableData.data}
                                  isPaginated={false}
                                  searchInput=""
                                  tableStyle={{ tableLayout: 'fixed' }}
                                  needSorting={false}
                                  cellClassName="!naxatw-items-end"
                                />
                              </div>
                            );
                          },
                        )}
                      </div>
                    </FlexColumn>
                  )}
                  {/* -----------------sectors------------------------- */}
                  {/* {sectors && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-3">
                      <IconHeader name="category" title="Sector" />
                      <FlexColumn className="naxatw-w-full naxatw-gap-2">
                        {reportListData?.sector_budget?.map((sector: any) => (
                          <FlexRow
                            className="naxatw-relative naxatw-items-center naxatw-justify-between naxatw-border-neutral-200 naxatw-p-3"
                            key={sector.id}
                          >
                            <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                              {sector?.name}
                            </p>
                            <div className="naxatw-absolute naxatw-right-[20%] naxatw-h-6 naxatw-w-[2px] naxatw-bg-secondary-200" />
                            <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-matt-200">
                              &#163;{' '}
                              {convertToCurrencySystem(sector?.budget) || 'N/A'}
                            </p>
                          </FlexRow>
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )} */}

                  {/* ----------------top-tier-partners---------------- */}
                  {/* {partnerBudget && (
                    <FlexColumn className="naxatw-gap-3">
                      <IconHeader
                        name="currency_pound"
                        title="Top Partners by Budget Allocated"
                      />
                      <FlexColumn className="naxatw-gap-2 naxatw-rounded-lg naxatw-border naxatw-border-neutral-200 naxatw-px-3 naxatw-py-2 naxatw-shadow-sm">
                        {reportListData?.partner_budget?.map(
                          (data: any, index: number) => {
                            const isPartnerBudgetLastIndex =
                              reportListData?.partner_budget?.length === index;
                            return (
                              <FlexRow
                                className={`naxatw-justify-between naxatw-rounded-[0.25rem] ${reportListData?.partner_budget?.length === index ? 'naxatw-border-b' : ''} naxatw-border-b naxatw-border-neutral-200 naxatw-bg-white naxatw-p-2`}
                                key={data.id}
                              >
                                <p className="naxatw-min-w-[80%] naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-gray-500">
                                  {data.name}
                                </p>
                                <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-matt-200">
                                  &#163;{' '}
                                  {convertToCurrencySystem(data.budget) || '-'}
                                </p>
                              </FlexRow>
                            );
                          },
                        )}
                      </FlexColumn>
                    </FlexColumn>
                  )} */}
                  {/* ---------------working palikas ------------------ */}
                  {working_palikas && (
                    <FlexColumn className="naxatw-gap-2">
                      <IconHeader name="place" title="Working Areas" />
                      <FlexColumn className="naxatw-gap-2">
                        {reportListData?.municipality?.map((area: any) => (
                          <FlexRow
                            className="naxatw-block naxatw-items-center naxatw-justify-between naxatw-rounded-xl naxatw-border naxatw-p-3 naxatw-shadow-sm"
                            key={uuidv4()}
                          >
                            <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                              {area}
                            </p>
                          </FlexRow>
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                  {stakeholders && hasSubNationalLevel && (
                    <FlexColumn className="naxatw-gap-3">
                      <IconHeader name="groups" title="Stakeholders" />
                      <FlexColumn className="naxatw-gap-3">
                        {stakeholderData?.map((stakeholder: any) => {
                          if (stakeholder.level === 'National Level')
                            return null;
                          return (
                            <FlexColumn className="naxatw-gap-2">
                              <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-tracking-[0.03125rem] naxatw-text-primary-700">
                                {stakeholder?.level}
                              </p>
                              <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3 naxatw-shadow-sm">
                                {stakeholder?.content.map(
                                  (level: any, index: number) => {
                                    const isLastIndex =
                                      index === stakeholder.content.length - 1;
                                    return (
                                      <>
                                        <FlexColumn className="naxatw-gap-1">
                                          <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                            {level.category}
                                          </p>
                                          <FlexRow className="naxatw-gap-[0.62rem]">
                                            {level?.items?.map(
                                              (
                                                subStakeholders: any,
                                                subIndex: number,
                                              ) => {
                                                const isLastSubIndex =
                                                  subIndex ===
                                                  level.items.length - 1;
                                                return (
                                                  <FlexRow className="naxatw-gap-[0.62rem]">
                                                    <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                                      {subStakeholders}
                                                    </p>
                                                    <div
                                                      className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                                    />
                                                  </FlexRow>
                                                );
                                              },
                                            )}
                                          </FlexRow>
                                        </FlexColumn>
                                        <div
                                          className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                                        />
                                      </>
                                    );
                                  },
                                )}
                              </FlexColumn>
                            </FlexColumn>
                          );
                        })}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                </FlexColumn>
              </FlexColumn>
            </div>
          )}
          <div className="naxatw-absolute naxatw-bottom-0 naxatw-left-0 naxatw-z-50 naxatw-flex naxatw-h-[5rem] naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-gap-4 naxatw-bg-white naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
            <Button
              size="normal"
              type="button"
              variant="secondary"
              className="naxatw-w-fit naxatw-px-4"
              onClick={handleDownloadPdf}
            >
              <Icon
                name="download"
                className="naxatw-flex naxatw-items-center naxatw-text-[1.5rem]"
              />
              <p>Download Report</p>
            </Button>
            <Button
              variant="link"
              className="naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-secondary-500"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProvincailProfilePdfPopUp;
