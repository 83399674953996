/* eslint-disable no-unused-vars */

// eslint-disable-next-line no-shadow
export enum FormStepsEnum {
  stakeholders = 1,
  otherStakeholders = 2,
  outreachBudget = 3,
  indicator = 4,
  documents = 5,
}

export type portfolioDocumentType = {
  id: string;
  previewURL: string;
  file: File | string;
};

export type defaultOtherStakeholdersType = {
  id: string;
  organization: number | null;
  intervention_sector: number | null;
  province: number | null;
};

export type defaultPortfolioReportingValuesType = {
  portfolioRegistration: Record<string, any>;
  stakeholder: Record<string, any>;
  otherStakeholders: defaultOtherStakeholdersType[];
  outreachBudget: {
    budget: Record<string, any>;
    locations: Record<string, any>[];
    support_type: string;
  };
  indicator: Record<string, any>;
  documents: portfolioDocumentType[];
};

export const defaultOtherStakeholders: defaultOtherStakeholdersType = {
  id: '',
  organization: null,
  intervention_sector: null,
  province: null,
};

export const defaultPortfolioReportingValues: defaultPortfolioReportingValuesType =
  {
    portfolioRegistration: {},
    stakeholder: {
      id: '',
      downstream_partner: '',
      level_of_intervention: null,
      unit_ministry: '',
      unit_ministry_department: '',
    },
    otherStakeholders: [defaultOtherStakeholders],
    outreachBudget: { budget: {}, locations: [], support_type: '' },
    indicator: {},
    documents: [],
  };

export type uploadDocumentType = {
  name: string;
  thematic_field: number | null;
  file_type: string;
  associate_program: number | null;
  associate_component: number | null;
  release_type: string;
  published_date: string;
  summary: string;
  documents: Record<string, any>[];
  documentToDelete: number | null;
};

export type defaultPartnerReportingValuesType = {
  partnerRegistration: Record<string, any>;
  workingAreas: [];
  allocatedBudget: {
    provinceBudget: [];
    expensePeriod: {};
  };
  otherDPs: [];
  uploadDocument: uploadDocumentType;
};

export const defaultPartnerFocalPerson = {
  id: '',
  name: '',
  designation: '',
  email: '',
  phone: '',
};

export type defaultPartnerFocalPersonType = {
  id: string;
  name: string;
  designation: string;
  email: string;
  phone: string;
};

type defaultPartnerRegistrationType = {
  year: string;
  reporting_period: string;
  program: number | null;
  component: number | null;
  partner: number | null;
  focal_person: defaultPartnerFocalPersonType[];
  first_tier_partner: Record<string, any> | null;
  downstream_partner: Record<string, any>[];
  workingAreas: {
    interventionLevel: Record<string, any>;
    workingAreas: Record<string, any>[];
  };
  allocatedBudget: any;
  indicators: Record<string, any>[];
  otherDPs: Record<string, any>[];
  stakeholder: Record<string, any>;
  uploadDocument: Record<string, any>;
};

export const defaultPartnerRegistrationValues: defaultPartnerRegistrationType =
  {
    year: '',
    reporting_period: '',
    program: null,
    component: null,
    partner: null,
    focal_person: [defaultPartnerFocalPerson],
    first_tier_partner: null,
    workingAreas: { interventionLevel: {}, workingAreas: [] },
    allocatedBudget: {},
    indicators: [],
    downstream_partner: [{ id: 1, value: '' }],
    otherDPs: [],
    stakeholder: {},
    uploadDocument: {},
  };

export const defaultPartnerReportingValues: defaultPartnerReportingValuesType =
  {
    partnerRegistration: {},
    workingAreas: [],
    allocatedBudget: { provinceBudget: [], expensePeriod: {} },
    otherDPs: [],
    uploadDocument: {
      name: '',
      thematic_field: null,
      file_type: '',
      associate_program: null,
      associate_component: null,
      release_type: '',
      published_date: '',
      summary: '',
      documents: [],
      documentToDelete: null,
    },
  };

export const portfolioReportingRegistrationFormFields = [
  {
    label: 'Year of reporting',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'year',
    name: 'year',
    required: true,
  },
  {
    label: 'Period of reporting',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'reporting_period',
    name: 'reporting_period',
    required: true,
  },
  {
    label: 'Choose Programme',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'program',
    name: 'program',
    required: true,
  },
  {
    label: 'Choose Component',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'component',
    name: 'component',
    required: true,
  },
  {
    label: 'First Tier Partner',
    inputType: 'text',
    type: 'custom',
    placeholder: 'First Tier Partner',
    id: 'focal_person',
    name: 'focal_person',
  },
  {
    label: 'Downstream Partner/s',
    inputType: 'text',
    type: 'input',
    placeholder: 'If multiple partner, seperate partners with comma',
    id: 'downstream_partner',
    name: 'downstream_partner',
  },
];

type tooltipInfoType = {
  title: string;
  description: string;
};

export const tooltipInfo: tooltipInfoType[] = [
  {
    title: 'Select Supplier',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
  {
    title: 'Select Program',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
  {
    title: 'Select Component',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
];

export const portfolioFormTooltipInfo: tooltipInfoType[] = [
  {
    title: 'Reporting Line Ministry',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
  {
    title: 'Contact Name',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
  {
    title: 'Designation',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
  {
    title: 'Contact Name',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
  {
    title: 'Email',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
  {
    title: 'Remarks',
    description:
      '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”',
  },
];

type programComponentPartnerInfoType = {
  icon: string;
  title: string;
  value: string;
  route: string;
};

export const programComponentPartnerInfo: programComponentPartnerInfoType[] = [
  {
    icon: 'list_alt',
    title: 'Programme',
    value: 'Strengthening Disaster Resilence in Nepal',
    route: '/data-bank/programmes',
  },
  {
    icon: 'extension',
    title: 'Component',
    value: 'NFRI-Stock Piles',
    route: '/data-bank/components',
  },
  {
    icon: 'handshake',
    title: 'Partner',
    value: 'CARE NEPAL',
    route: '/data-bank/partners',
  },
];

// portfolio-reporting form fields
export const stakeholdersFormFields = [
  {
    label: 'Downstream Partner/s',
    inputType: 'text',
    type: 'input',
    placeholder: 'Partner',
    id: 'downstream_partner',
    name: 'downstream_partner',
  },
  {
    label: 'Level of Intervention',
    inputType: 'text',
    type: 'select',
    placeholder: 'Province Level',
    id: 'level_of_intervention',
    name: 'level_of_intervention',
  },
  {
    label: 'Province Ministry',
    inputType: 'text',
    type: 'input',
    placeholder: 'Label',
    id: 'unit_ministry',
    name: 'unit_ministry',
  },
  {
    label: 'Province Ministry Department',
    inputType: 'text',
    type: 'input',
    placeholder: 'Label',
    id: 'unit_ministry_department',
    name: 'unit_ministry_department',
  },
];

export const otherStakeholdersFormFields = [
  {
    label: 'Other Development Partner',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'organization',
    name: 'organization',
  },
  {
    label: 'Sector of Intervention by others Development Partners',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'intervention_sector',
    name: 'intervention_sector',
  },
  {
    label: 'Province other Development Partners are working in?',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'province',
    name: 'province',
  },
];

export const outreachBudgetFormFields = [
  {
    label: 'Type of Support',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'support_type',
    name: 'support_type',
  },
];

export const indicatorFormFields = [];

export const focalPersonFormFields = [
  {
    label: 'Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter the name of focal person',
    id: 'name',
    name: 'name',
    group: 'group1',
  },
  {
    label: 'Designation',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Designation',
    id: 'designation',
    name: 'designation',
    group: 'group1',
  },
  {
    label: 'Email',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter the Email',
    id: 'email',
    name: 'email',
    group: 'group2',
  },
  {
    label: 'Phone Number',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter the Phone Number',
    id: 'phone',
    name: 'phone',
    group: 'group2',
  },
];

export const uploadDocumentFormFields = [
  {
    label: 'Title',
    inputType: 'text',
    type: 'input',
    placeholder: 'Title',
    id: 'name',
    name: 'name',
    isVisible: true,
  },
  {
    label: 'Thematic Field',
    inputType: 'text',
    type: 'select',
    placeholder: 'Thematic Field',
    id: 'thematic_field',
    name: 'thematic_field',
  },
  {
    label: 'Type of File',
    inputType: 'text',
    type: 'select',
    placeholder: 'Type of File',
    id: 'file_type',
    name: 'file_type',
    choose: 'value',
  },
  {
    label: 'Type of Release',
    type: 'radio',
    id: 'release_type',
    name: 'release_type',
  },
  {
    label: 'Published or Finalised Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'published_date',
    name: 'published_date',
    disabledDays: '',
    isVisible: true,
  },
  {
    label: 'Summary',
    inputType: 'text',
    type: 'textArea',
    className: ' naxatw-min-h-[4.5rem]',
    placeholder: 'Summary of the file',
    id: 'summary',
    name: 'summary',
  },
  {
    label: 'Upload Documents',
    inputType: 'file',
    type: 'file-upload',
    placeholder: 'Please upload Documents ( Pdf file format )',
    id: 'documents',
    name: 'documents',
    fileAccept: '.pdf',
    multiple: true,
  },
];
