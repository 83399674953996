/* eslint-disable no-unused-vars */
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { Input } from '@Components/common/FormUI';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import TiptapEditor from '@Components/common/FormUI/TipTapEditor';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { getFAQById, patchFAQ, postFAQ } from '@Services/tutorials';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { faqFormValidationSchema } from '@Validations/Tutorials';
import { useEffect } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IFAQRegistrationForm {
  onClose: () => void;
  id?: string;
}

const FAQRegistrationForm = ({ onClose, id }: IFAQRegistrationForm) => {
  const queryClient = useQueryClient();
  const initialValues: FieldValues = {
    id: '',
    title: '',
    description: '',
    video: null,
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(faqFormValidationSchema),
  });

  const { mutate: postFAQdata, isLoading: faqIsUploading } = useMutation({
    mutationFn: (data: FieldValues) => postFAQ(data),
    onSuccess: () => {
      toast.success('FAQ added successfully');
      queryClient.invalidateQueries(['faq-table-data']);
      onClose();
    },
    onError: () => {
      toast.error('Failed to add FAQ');
    },
  });

  const { mutate: patchFAQdata, isLoading: faqIsPatching } = useMutation({
    mutationFn: (data: FieldValues) => patchFAQ(data),
    onSuccess: () => {
      toast.success('FAQ updated successfully');
      queryClient.invalidateQueries(['faq-table-data']);
      onClose();
    },
    onError: () => {
      toast.error('Failed to update FAQ');
    },
  });

  const { data: faqData, isLoading: isFAQdataLoading } = useQuery({
    queryKey: ['faq-data', id],
    queryFn: () => getFAQById(id || ''),
    select: res => res.data,
    enabled: !!id,
  });

  function handleFormSubmit(data: FieldValues) {
    if (id) {
      patchFAQdata({ ...data, id });
      return;
    }
    postFAQdata(data);
  }

  useEffect(() => {
    if (faqData) {
      reset(faqData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqData]);

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading={`Question Answer / ${id ? 'Edit' : 'Add'}`}
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>

      <div className="naxatw-w-full">
        <FlexColumn className="naxatw-relative naxatw-h-full naxatw-w-full naxatw-px-6">
          {id && isFAQdataLoading ? (
            <FormSkeleton className="!naxatw-w-full" />
          ) : (
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-pt-5">
                <InputLabel label="Title" astric />
                <Input
                  type="text"
                  placeholder="Title"
                  className="!naxatw-w-full"
                  {...register('title')}
                />
                <ErrorMessage
                  message={
                    typeof errors?.title?.message === 'string'
                      ? errors.title.message
                      : ''
                  }
                />
              </FlexColumn>
              <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-pt-5">
                <InputLabel label="Description" astric />
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TiptapEditor
                        bindvalue={value}
                        editable
                        onChange={onChange}
                        // isEditorEmpty={isEditorEmpty}
                      />
                    );
                  }}
                />
                {/* <Input
                  type="text"
                  placeholder="Description"
                  className="!naxatw-w-full"
                  {...register('description')}
                /> */}
                <ErrorMessage
                  message={
                    typeof errors?.description?.message === 'string'
                      ? errors.description.message
                      : ''
                  }
                />
              </FlexColumn>
              <div className="naxatw-px-6 naxatw-py-5 naxatw-text-center">
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  className="naxatw-w-fit naxatw-px-4"
                  isLoading={faqIsPatching || faqIsUploading}
                  disabled={faqIsPatching || faqIsUploading}
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default FAQRegistrationForm;
