import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICurrentVideoType {
  id: any;
  title: string;
  description: string;
  video: string;
}

interface ICurrentDocumentType {
  id: any;
  title: string;
  description: string;
  file: string;
}

interface TutorialState {
  currentVideo: ICurrentVideoType;
  currentDocument: ICurrentDocumentType;
}
const initialState = {
  currentVideo: {
    id: '',
    title: '',
    description: '',
    video: '',
  },
  currentDocument: {
    id: '',
    title: '',
    description: '',
    file: '',
  },
};

const setCurrentVideo: CaseReducer<
  TutorialState,
  PayloadAction<ICurrentVideoType>
> = (state, action) => ({
  ...state,
  currentVideo: action.payload,
});
const setCurrentDocument: CaseReducer<
  TutorialState,
  PayloadAction<ICurrentDocumentType>
> = (state, action) => ({
  ...state,
  currentDocument: action.payload,
});

const tutorialsSlice = createSlice({
  name: 'tutorials',
  initialState,
  reducers: { setCurrentVideo, setCurrentDocument },
});

export { tutorialsSlice };
export default tutorialsSlice.reducer;
