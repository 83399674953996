/* eslint-disable no-unused-vars */
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { Input } from '@Components/common/FormUI';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import FileUpload from '@Components/common/FormUI/Upload';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  getUserManualById,
  patchUserManual,
  postUserManual,
} from '@Services/tutorials';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { userManualFormValidationSchema } from '@Validations/Tutorials';
import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IUserManualRegistrationForm {
  onClose: () => void;
  id?: string;
}

const UserManualRegistrationForm = ({
  onClose,
  id,
}: IUserManualRegistrationForm) => {
  const queryClient = useQueryClient();
  const initialValues: FieldValues = {
    id: '',
    title: '',
    description: '',
    thumbnail: null,
  };

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(userManualFormValidationSchema),
  });

  const { mutate: postUserManualData, isLoading: userManualIsUploading } =
    useMutation({
      mutationFn: (data: FieldValues) => postUserManual(data),
      onSuccess: () => {
        toast.success('User manual added successfully');
        queryClient.invalidateQueries(['user-manual-table-data']);
        onClose();
      },
      onError: () => {
        toast.error('Failed to user manual');
      },
    });

  const { mutate: patchUserManualData, isLoading: userManualIsPatching } =
    useMutation({
      mutationFn: (data: FieldValues) => patchUserManual(data),
      onSuccess: () => {
        toast.success('TutUser manual Updated successfully');
        queryClient.invalidateQueries(['user-manual-table-data']);
        onClose();
      },
      onError: () => {
        toast.error('Failed to update user manual');
      },
    });

  const { data: userManualData, isLoading: isUserManualDataLoading } = useQuery(
    {
      queryKey: ['user-manual-data', id],
      queryFn: () => getUserManualById(id || ''),
      select: res => res.data,
      enabled: !!id,
    },
  );
  const thumbnail = watch('thumbnail');
  function handleFormSubmit(data: FieldValues) {
    const { file } = data;

    const documentBianry = file[0]?.file;
    const thumbnailBinary = thumbnail?.[0]?.file;
    if (id) {
      patchUserManualData({
        ...data,
        file: documentBianry,
        id,
        thumbnail: thumbnailBinary || '',
      });
      return;
    }
    postUserManualData({
      ...data,
      file: documentBianry,
      thumbnail: thumbnailBinary || '',
    });
  }
  const watchDescription = watch('description');
  useEffect(() => {
    if (userManualData) {
      reset(userManualData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManualData]);

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-full naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center sm:naxatw-w-[34.75rem]">
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 ">
        <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-px-7 naxatw-py-5 naxatw-shadow-light">
          <BreadCrumb
            heading={`User Manual / ${id ? 'Edit' : 'Add'}`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>
        <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
          <div className="naxatw-w-full">
            {id && isUserManualDataLoading ? (
              <div className="naxatw-mx-auto naxatw-w-11/12">
                <FormSkeleton numRows={6} className="naxatw-w-full" />
              </div>
            ) : (
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                  <FlexColumn className="scrollbar naxatw-h-[calc(100vh-20.8rem)] naxatw-w-full  naxatw-overflow-y-scroll naxatw-pr-2">
                    <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-pt-5">
                      <InputLabel label="Title" astric />
                      <Input
                        type="text"
                        placeholder="Title"
                        className="!naxatw-w-full"
                        {...register('title')}
                      />
                      <ErrorMessage
                        message={
                          typeof errors?.title?.message === 'string'
                            ? errors.title.message
                            : ''
                        }
                      />
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-pt-5">
                      <InputLabel
                        label="Description"
                        tooltipMessage="Add description upto 100 characters"
                      />
                      <textarea
                        placeholder="Description"
                        className="naxatw-input naxatw-h-[6rem] !naxatw-w-full naxatw-resize-none naxatw-text-sm"
                        value={watchDescription}
                        onChange={e => {
                          if (e.target.value.length <= 130) {
                            setValue('description', e.target.value); // Only set value if under limit
                          }
                        }}
                      />
                      <ErrorMessage
                        message={
                          typeof errors?.description?.message === 'string'
                            ? errors.description.message
                            : ''
                        }
                      />
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-pt-5">
                      <InputLabel label="Thumbnail" />
                      <FileUpload
                        name="thumbnail"
                        register={register}
                        setValue={setValue}
                        data={userManualData?.thumbnail}
                        multiple={false}
                        fileAccept="image/*"
                        placeholder="Please upload a image (jpeg, jpg, etc.)"
                        control={control}
                      />
                      <ErrorMessage
                        message={
                          typeof errors?.thumbnail?.message === 'string'
                            ? errors.thumbnail.message
                            : ''
                        }
                      />
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-pt-5">
                      <InputLabel label="Document" astric />
                      <FileUpload
                        name="file"
                        register={register}
                        setValue={setValue}
                        data={userManualData?.file}
                        multiple={false}
                        fileAccept=".pdf, .doc"
                        placeholder="Please upload a PDF or DOC file"
                        control={control}
                      />
                      <ErrorMessage
                        message={
                          typeof errors?.video?.message === 'string'
                            ? errors.video.message
                            : ''
                        }
                      />
                    </FlexColumn>
                  </FlexColumn>
                </div>
                <div className=" naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                  <Button
                    size="normal"
                    type="submit"
                    variant="primary"
                    className="naxatw-w-fit naxatw-px-4"
                    isLoading={userManualIsUploading || userManualIsPatching}
                    disabled={userManualIsPatching || userManualIsUploading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManualRegistrationForm;
