import { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  setActiveViewBy,
  setActiveViewAs,
  setSelectedIndicator,
} from '@Store/actions/exploreByIndicators';
import DropDown from '@Components/common/DropDown';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { contextualIndicatorsOptions } from '@Constants/exploreByIndicators';
import { FlexColumn } from '@Components/common/Layouts';
import { getProvinceList } from '@Services/common';

interface TopBoxProps {
  selectedProvince: number;
  setSelectedProvince: Dispatch<SetStateAction<number>>;
}

const viewByOptions = [
  { label: 'province' },
  // { label: 'district' },
  { label: 'municipality' },
];
const viewAsOptions = [{ label: 'Map' }, { label: 'Table' }];

const TopBox = ({ selectedProvince, setSelectedProvince }: TopBoxProps) => {
  const dispatch = useTypedDispatch();
  // const [selectedProvince, setSelectedProvince] = useState(1);
  const selectedIndicator = useTypedSelector(
    state => state.exploreByIndicators.selectedIndicator,
  );
  const activeViewAs = useTypedSelector(
    state => state.exploreByIndicators.map.activeViewAs,
  );
  const activeViewBy = useTypedSelector(
    state => state.exploreByIndicators.map.activeViewBy,
  );

  const { data: provinceList, isFetching: isProvinceFetching } = useQuery({
    queryFn: getProvinceList,
    select: (data: any) => data.data,
  });

  return (
    <>
      <div className="naxatw-flex naxatw-items-start naxatw-self-stretch naxatw-px-[1.685rem] naxatw-py-[1.40rem]">
        <div className="naxatw-flex naxatw-flex-[1_0_0] naxatw-items-start naxatw-justify-between naxatw-self-stretch naxatw-pl-9">
          {selectedIndicator || activeViewAs.toLocaleLowerCase() === 'table' ? (
            <SwitchTab
              title="View By"
              options={viewByOptions}
              activeLabel="province"
              defaultBg={false}
              defaultBehaviour={false}
              activeValue={activeViewBy}
              className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
              titleClassName="naxatw-text-sm"
              onChange={label => {
                dispatch(setActiveViewBy(label));
              }}
            />
          ) : (
            <div className="naxatw-h-1 naxatw-w-[240px] naxatw-opacity-0" />
          )}
          <FlexColumn className="naxatw-items-end naxatw-gap-y-3">
            <div className="naxatw-relative naxatw-flex naxatw-w-fit naxatw-items-center naxatw-gap-2">
              <p className="naxatw-whitespace-nowrap naxatw-text-sm">
                Contextual Indicators
              </p>
              <DropDown
                options={contextualIndicatorsOptions}
                title="Contextual Indicators"
                className="!naxatw-min-w-[250px] !naxatw-max-w-[250px]"
                onChange={value => {
                  dispatch(setSelectedIndicator(value));
                }}
                choose="value"
                value={selectedIndicator || ''}
              />
              {selectedIndicator && (
                <div
                  role="presentation"
                  className="naxatw-group naxatw-absolute -naxatw-right-[4.5rem] naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-justify-center"
                  onClick={() => dispatch(setSelectedIndicator(''))}
                >
                  <i className="material-icons-outlined naxatw-text-primary-700">
                    restart_alt
                  </i>
                  <p className="naxatw-text-base naxatw-font-medium naxatw-text-primary-700 group-hover:naxatw-underline ">
                    Reset
                  </p>
                </div>
              )}
            </div>
            {activeViewBy === 'municipality' && (
              <div className="naxatw-w-fit">
                <DropDown
                  options={
                    provinceList?.map((province: any) => ({
                      ...province,
                      label: province.name,
                    })) || []
                  }
                  placeholder="Select Province"
                  className="!naxatw-min-w-[250px]"
                  isLoading={isProvinceFetching}
                  value={selectedProvince}
                  onChange={value => setSelectedProvince(value)}
                />
              </div>
            )}
          </FlexColumn>
          <SwitchTab
            title="View As"
            options={viewAsOptions}
            activeLabel="Map"
            defaultBg={false}
            defaultBehaviour={false}
            activeValue={activeViewAs}
            className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
            titleClassName="naxatw-text-sm"
            onChange={label => {
              dispatch(setActiveViewAs(label));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TopBox;
