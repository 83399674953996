// eslint-disable-next-line no-unused-vars
import { useParams, useSearchParams } from 'react-router-dom';
import { getSectorWiseCountData } from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexRow } from '@Components/common/Layouts';
import IconHeader from '@Components/common/PdfDesign/IconHeaders';

// import SectorsBox from './SectorsBox';

const SectorsComponent = () => {
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');
  const { programmeId } = useParams();
  const { data: sectorsListData, isLoading: programmeDetailsIsLoading } =
    useQuery({
      queryKey: ['sectprs-data-by-component', programmeId, componentId],
      queryFn: async () =>
        getSectorWiseCountData({
          program: programmeId,
          project: componentId,
        }),
      select: res => res.data,
    });
  return (
    <>
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-5 naxatw-overflow-hidden">
        <IconHeader
          title="Sectors"
          name="category"
          titleClassName="naxatw-text-[1.125rem] naxatw-font-bold !naxatw-text-matt-100"
          iconClassName="!naxatw-text-2xl !naxatw-text-matt-100"
          className="naxatw-gap-2"
        />
        {programmeDetailsIsLoading ? (
          <Skeleton className="naxatw-h-[10rem] naxatw-w-full" />
        ) : (
          <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-x-3 naxatw-gap-y-5">
            {sectorsListData?.map((sectorData: any) => (
              <FlexRow
                className="naxatw-relative naxatw-items-center naxatw-justify-between naxatw-rounded-lg naxatw-border naxatw-p-3 naxatw-shadow-sm"
                key={sectorData.sector_id}
              >
                <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                  {sectorData.sector_name}
                </p>
              </FlexRow>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SectorsComponent;
