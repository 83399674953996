import * as React from 'react';

import { cn } from '@Utils/index';

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  ({ className, placeholder, type, ...rest }, ref) => {
    return (
      <input
        type={type}
        placeholder={placeholder}
        className={cn(
          `naxatw-input naxatw-body-sm`,
          // 'naxatw-ring-offset-background focus-visible:naxatw-ring-ring naxatw-flex naxatw-h-10 naxatw-w-full naxatw-rounded-md naxatw-border naxatw-border-gray-400 naxatw-bg-transparent naxatw-bg-white naxatw-p-3 naxatw-text-sm naxatw-transition-all naxatw-duration-200 file:naxatw-border-0 file:naxatw-bg-transparent file:naxatw-text-sm file:naxatw-font-medium placeholder:naxatw-text-gray-400 hover:naxatw-bg-gray-100 focus-visible:naxatw-outline-none focus-visible:naxatw-ring-2 focus-visible:naxatw-ring-offset-2 disabled:naxatw-cursor-not-allowed disabled:naxatw-opacity-50',
          className,
        )}
        ref={ref}
        {...rest}
      />
    );
  },
);
Input.displayName = 'Input';

export default Input;
