import SearchIcon from '@Assets/icons/searchIcon.svg';
import { useRef, useState } from 'react';

interface iSearchWithToggle {
  className?: string;
  placeholder?: string;
  value: string | number;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  onClick?: () => void;
  onSearchBarClose?: () => void;
}

const SearchWithToggle = ({
  className,
  placeholder = 'Search...',
  value,
  onChange,
  onClick,
  onSearchBarClose,
}: iSearchWithToggle) => {
  const inputRef = useRef<any>(null);
  const [activeSearch, setActiveSearch] = useState(false);

  const handleClearSearch = () => {
    onChange({ target: { value: '' } });
    inputRef?.current?.focus();
  };

  const handleSearchToggle = (e: any) => {
    e.stopPropagation();
    if (!activeSearch) {
      inputRef.current.focus();
    } else {
      onSearchBarClose?.();
    }
    setActiveSearch(prev => !prev);
  };
  return (
    <div className="naxatw-group naxatw-relative">
      <input
        ref={inputRef}
        type="text"
        className={`naxatw-block naxatw-h-[36px]  naxatw-rounded-2xl  naxatw-border
        naxatw-border-grey-600 naxatw-py-2 naxatw-pl-[34px]
        naxatw-text-[13px] naxatw-font-normal naxatw-text-gray-500 placeholder:naxatw-font-normal placeholder:naxatw-text-gray-500  
           focus:naxatw-border-[#484848] focus:naxatw-outline-none ${activeSearch ? 'naxatw-w-full naxatw-pr-[10px]' : 'naxatw-w-0 naxatw-pr-0 naxatw-opacity-0'} naxatw-transition-all naxatw-duration-300 naxatw-ease-in-out ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onClick={onClick}
      />
      {value && (
        <button
          type="button"
          className="naxatw-absolute naxatw-right-1 naxatw-top-2 naxatw-z-20 naxatw-flex naxatw-h-5 naxatw-w-5 naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-rounded-full hover:naxatw-bg-gray-200"
          onClick={handleClearSearch}
        >
          <span className="material-symbols-outlined naxatw-text-[16px] naxatw-text-grey-800">
            close
          </span>
        </button>
      )}
      <button
        type="button"
        className={`naxatw-absolute naxatw-top-1 naxatw-flex naxatw-items-center naxatw-justify-center naxatw-bg-[#f4f7fd] hover:naxatw-border ${activeSearch ? 'naxatw-left-0.5 naxatw-h-7 naxatw-w-7 naxatw-rounded-full' : 'naxatw-left-0 naxatw-top-1 naxatw-h-8 naxatw-w-8 naxatw-rounded-lg'}`}
        onClick={e => handleSearchToggle(e)}
      >
        <img
          src={SearchIcon}
          alt="Search Icon"
          className="naxatw-h-4 naxatw-w-4 !naxatw-text-matt-200 naxatw-transition-all naxatw-duration-300 naxatw-ease-in-out"
        />
      </button>
    </div>
  );
};

export default SearchWithToggle;
