/* eslint-disable no-unused-vars */
import React from 'react';
import DropDown from '@Components/common/DropDown';
import { FormControl, Input } from '@Components/common/FormUI';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexRow } from '@Components/common/Layouts';
import {
  getUnitMinistryDepartmentList,
  getUnitMinistryList,
} from '@Services/portfolioReporting';
import { useQuery } from '@tanstack/react-query';

interface IIterableGovernmentStakeholder {
  handleChange: (
    selectedValue: string | number,
    selectedId: number,
    key: string,
  ) => void;
  partner: Record<string, any>;
  developmentPartners: Record<string, any>[];
  setSelectedDP: any;
  setConfirmDelete: any;
  provinceId?: any;
  ministriesNames: Record<string, any>;
}

const IterableGovernmentStakeholder = ({
  handleChange,
  partner,
  developmentPartners,
  setSelectedDP,
  setConfirmDelete,
  ministriesNames,
  provinceId,
}: IIterableGovernmentStakeholder) => {
  const { data: federalMinistryList, isLoading: federalMinistryListIsLoading } =
    useQuery({
      queryKey: ['get-unit-ministry-list', 'federal-government'],
      queryFn: () =>
        getUnitMinistryList({
          ministry_type: 'Federal Level',
          stakeholder_type: 'Government Stakeholder',
        }),
      select: data => {
        const resp = data?.data;
        const updatedOptions = resp?.map((option: Record<string, any>) => ({
          ...option,
          id: option.id,
          label: option?.name,
          value: option?.id,
        }));
        return updatedOptions;
      },
      enabled: ministriesNames.ministryName === 'Federal Ministry',
    });

  const {
    data: provinceMinistryList,
    isLoading: provinceMinistryListIsLoading,
  } = useQuery({
    queryKey: ['get-unit-ministry-list', 'province-government'],
    queryFn: () =>
      getUnitMinistryList({
        province: provinceId,
        ministry_type: 'Province Level',
        stakeholder_type: 'Provincial Government Stakeholder',
      }),
    select: data => {
      const resp = data?.data;
      const updatedOptions = resp?.map((option: Record<string, any>) => ({
        ...option,
        id: option.id,
        label: option?.name,
        value: option?.id,
      }));
      return updatedOptions;
    },
    enabled: ministriesNames?.ministryName !== 'Federal Ministry',
  });

  const { data: ministryDepartmentList } = useQuery({
    queryKey: ['get-unit-ministry-department-choices', partner.unit_ministry],
    enabled: !!partner.unit_ministry,
    queryFn: () =>
      getUnitMinistryDepartmentList({
        ministry_type: 'Provincial',
        unit_ministry: partner.unit_ministry,
        stakeholder_type: 'government',
      }),
    select: data => {
      const resp = data?.data;
      const updatedOptions = resp?.map((option: Record<string, any>) => ({
        ...option,
        id: option.unit_ministry_department,
        label: option?.unit_ministry_department__name,
        value: option?.unit_ministry_department,
      }));
      return updatedOptions;
    },
  });

  return (
    <FlexRow key={partner.id} className="naxatw-items-end naxatw-gap-x-2">
      <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
        <InputLabel label={ministriesNames.ministryName} astric />

        <DropDown
          placeholder="Choose"
          options={
            ministriesNames?.ministryName === 'Federal Ministry'
              ? federalMinistryList
              : provinceMinistryList || []
          }
          value={partner.unit_ministry}
          onChange={selectedValue => {
            handleChange(selectedValue, partner.id, 'unit_ministry');
          }}
          choose="value"
          isLoading={
            ministriesNames?.ministryName === 'Federal Ministry'
              ? federalMinistryListIsLoading
              : provinceMinistryListIsLoading
          }
        />
      </FormControl>
      <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
        <InputLabel label={ministriesNames.ministryDepartmentName} />

        <Input
          type="text"
          placeholder="Stakeholder Name"
          value={partner.other_stakeholder}
          onChange={e =>
            handleChange(e.target.value, partner.id, 'other_stakeholder')
          }
        />
        {/* <DropDown
          placeholder="Choose"
          options={ministryDepartmentList || []}
          value={partner.unit_ministry_department}
          onChange={selectedValue =>
            handleChange(selectedValue, partner.id, 'unit_ministry_department')
          }
          choose="value"
        /> */}
      </FormControl>
      {developmentPartners.length > 1 && (
        <IconButton
          name="delete"
          iconClassName="naxatw-text-[#CED5DF] naxatw-duration-200 hover:!naxatw-text-red-600 naxatw-cursor-pointer"
          onClick={() => {
            setSelectedDP(partner);
            setConfirmDelete(true);
          }}
        />
      )}
    </FlexRow>
  );
};

export default IterableGovernmentStakeholder;
