import { z } from 'zod';

export const DailyTriviaValidationSchema = z.object({
  question: z.string().trim().min(1, 'Question is Required'),
  option_a: z.string().trim().min(1, 'Option A is Required'),
  option_b: z.string().trim().min(1, 'Option B is Required'),
  option_c: z.string().trim().min(1, 'Option C is Required'),
  correct_answer: z.string().trim().min(1, 'Correct Answer is Required'),
});

export type DailyTriviaSchemaProps = z.infer<
  typeof DailyTriviaValidationSchema & {
    [key: string]: any;
  }
>;
