import { api, authenticated } from '.';

export const getMarkerCategories = (params: Record<string, any>) => {
  return authenticated(api).get('/marker-category/', { params });
};

export const getMarkerCategoriesById = (id: string) => {
  return authenticated(api).get(`/marker-category/${id}/`);
};

export const postMarkerCategories = (payload: Record<string, any>) => {
  return authenticated(api).post(`/marker-category/`, payload);
};

export const patchMarkerCategories = (
  id: string,
  payload: Record<string, any>,
) => {
  return authenticated(api).patch(`/marker-category/${id}/`, payload);
};

export const deleteMarkerCategories = (id: string) => {
  return authenticated(api).delete(`/marker-category/${id}/`);
};

export const getSubMarkerValues = (params: Record<string, any>) => {
  return authenticated(api).get('/marker-value/', { params });
};

export const getMarkerValuesById = (id: string) => {
  return authenticated(api).get(`/marker-value/${id}/`);
};

export const postMarkerValues = (payload: Record<string, any>) => {
  return authenticated(api).post(`/marker-value/`, payload);
};

export const patchMarkerValues = (id: string, payload: Record<string, any>) => {
  return authenticated(api).patch(`/marker-value/${id}/`, payload);
};

export const deleteMarkerValues = (id: string) => {
  return authenticated(api).delete(`/marker-value/${id}/`);
};

export const getMarkerCategoriesDropdownList = () => {
  return authenticated(api).get('/marker-category-dropdown/');
};
