import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { cn } from '@Utils/index';
import React from 'react';

const Legend = ({ className }: { className?: string }) => {
  return (
    <FlexColumn
      gap={3}
      className={cn(
        'naxatw-absolute naxatw-bottom-5 naxatw-left-6 naxatw-z-10 naxatw-w-[175px] naxatw-rounded-lg naxatw-border naxatw-border-solid  naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-shadow-[0px_2px_8px_0px_rgba(16,24,40,0.08)]',
        className,
      )}
    >
      <FlexRow gap={2} className="naxatw-items-center">
        <div
          className="naxatw-h-6 naxatw-w-6"
          style={{
            backgroundColor: `#08519C`,
            outline: '1px solid #E6E6E6',
          }}
        />
        <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
          <>
            <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
              Working Area
            </p>
          </>
        </div>
      </FlexRow>
    </FlexColumn>
  );
};

export default Legend;
