/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { HtmlHTMLAttributes, useRef } from 'react';
import { cn } from '@Utils/index';
import RoundedContainer from '@Components/common/RoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import checkIfSannkeyDataIsEmpty from '@Utils/checkIfSankeyDataIsEmpty';
import getChartOfType from '../utils/getChartOfType';
import getChartFillOfType from '../utils/getChartFillOfType';
import { IChartHeaderProps, ISwitchTabDataProps } from '../ChartHeader';
import { ChartTypes, ILegendProps } from '../types';
import { ChartFills } from '../constants';
import NoChartDataComponent from '../NoChartDataComponent';
import { IResankyChartProps } from '../SankeyChart';

interface ICustomChartContainerProps<T>
  extends HtmlHTMLAttributes<HTMLDivElement>,
    Omit<IChartHeaderProps, 'downloadComponentRef'> {
  chartTitle: string;
  hasDownloadBtn?: boolean;
  header: (props: IChartHeaderProps) => JSX.Element;
  type: ChartTypes;
  scrollable?: boolean;
  fillWithType?: boolean;
  hasHeader?: boolean;
  hasFullScreen?: boolean;
  switchTabData?: ISwitchTabDataProps;
  sankeyChartData?: IResankyChartProps;
  title?: string;
  height?: string;
  currencyConvert?: boolean;
  xLabelClassname?: string;
  barSize?: number;
  needLegend?: boolean;
  subTitle?: string;
  chartId?: string;
  showPound?: boolean;
  hasDetails?: boolean;
  hoverTitle?: string;
  yLabelClassNames?: string;
  showYAxisIntOnly?: boolean;
}

const getClassNamesForChartItself = (chartType: string) => {
  switch (chartType) {
    case 'pieChart':
      return 'lg:naxatw-col-span-7';
    case 'sankeyChart':
      return 'naxatw-flex naxatw-justify-center naxatw-items-center naxatw-h-full !naxatw-pl-14';
    default:
      return 'naxatw-col-span-12';
  }
};

function ChartContainer<T>({
  header,
  legend,
  xLabel,
  yLabel,
  className,
  data,
  type,
  chartTitle,
  subTitle,
  hasDownloadBtn,
  scrollable = false,
  fillWithType = false,
  fill,
  hasHeader = true,
  hasFullScreen = true,
  switchTabData,
  title = '',
  sankeyChartData = {
    nodeColors: [],
  },
  height,
  currencyConvert,
  xLabelClassname,
  barSize,
  needLegend,
  chartId,
  showPound,
  hasDetails,
  hoverTitle,
  yLabelClassNames,
  showYAxisIntOnly,
}: ICustomChartContainerProps<T>) {
  const componentRef = useRef(null);
  const chart = getChartOfType(type);
  const fills = fill || (fillWithType ? getChartFillOfType(type) : ChartFills);

  return (
    <RoundedContainer
      ref={componentRef}
      className={cn(
        'chart-container naxatw-relative naxatw-order-1 naxatw-grid naxatw-h-full naxatw-w-full  naxatw-grid-cols-12 naxatw-border-none naxatw-bg-white md:naxatw-order-2',
        className,
      )}
    >
      {hasHeader && header && (
        <div className="head naxatw-col-span-12 naxatw-h-fit">
          <div className="cover">
            {header({
              chartTitle,
              hasDownloadBtn,
              downloadComponentRef: componentRef,
              legend,
              xLabel,
              yLabel,
              data,
              type,
              fill: fills,
              hasFullScreen,
              switchTabData,
              subTitle,
              chartId,
              showPound,
              hoverTitle,
            })}
          </div>
        </div>
      )}
      {data?.length === 0 ||
      (type === 'sankeyChart' && checkIfSannkeyDataIsEmpty(data)) ? (
        <div className="naxatw-col-span-12 naxatw-h-fit">
          <NoChartDataComponent />
        </div>
      ) : (
        <>
          {yLabel && !(type === 'donut') ? (
            <div
              className={`y-label naxatw-absolute ${
                type === 'verticalBar'
                  ? 'naxatw-left-[-0.3rem]'
                  : 'naxatw-left-0'
              } naxatw-top-0 naxatw-col-span-1 naxatw-flex naxatw-h-full naxatw-w-12 naxatw-items-center naxatw-justify-end`}
            >
              <p
                className={`naxatw-origin-center -naxatw-rotate-90 naxatw-whitespace-nowrap naxatw-text-xs naxatw-font-medium naxatw-text-matt-100 ${yLabelClassNames}`}
              >
                {yLabel}
              </p>
            </div>
          ) : null}
          <div
            className={`card ${getClassNamesForChartItself(type)} ${
              yLabel
                ? 'naxatw-col-span-12'
                : 'naxatw-col-span-12 naxatw-h-full naxatw-overflow-y-hidden'
            } ${
              scrollable ? 'scrollbar naxatw-overflow-auto' : ''
            } ${type === 'bar' ? 'naxatw-px-8' : 'naxatw-px-3'}  ${type === 'treeMap' ? 'naxatw-mt-[-1rem] !naxatw-flex !naxatw-items-center !naxatw-justify-center' : ''}`}
          >
            {chart &&
              chart({
                data,
                fills,
                scrollable,
                sankeyChartData,
                height,
                currencyConvert,
                barSize,
                needLegend,
                chartId,
                showPound,
                hasDetails,
                hoverTitle,
                showYAxisIntOnly,
                // title,
              })}
          </div>
          {xLabel && !(type === 'donut') ? (
            <div className="x-label naxatw-col-span-12 naxatw-flex naxatw-items-center naxatw-justify-center naxatw-pb-3">
              <p
                className={`${xLabelClassname} naxatw-text-[12px] naxatw-font-[500] naxatw-text-[#475467]`}
              >
                {xLabel}
              </p>
            </div>
          ) : null}
          {legend && (
            <>
              {legend({
                data,
                type,
                fills,
                chartId,
                showPound,
                hasDetails,
              })}
            </>
          )}
        </>
      )}
    </RoundedContainer>
  );
}

export default hasErrorBoundary(ChartContainer);
