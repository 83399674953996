export type subSectorFormState = {
  name: string;
  sector_group: string;
};

export const validateForm = (formState: subSectorFormState) => {
  const error = {
    name: false,
    sector_group: false,
  };
  if (!formState.name) {
    error.name = true;
  }
  if (!formState.sector_group) {
    error.sector_group = true;
  }
  return error;
};
