/* eslint-disable no-unneeded-ternary */
/* eslint-disable consistent-return */
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import MapTools from '@Components/common/MapTools';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  getBbox,
  getFederalWiseCount,
  getMunicipalityListByCode,
} from '@Services/portfolioProfile';
import { useTypedSelector } from '@Store/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DashboardLegendDropdownOptions,
  defaultLayerStyle,
} from '@Constants/map';
import { getExpression } from '@Constants/mainDashboard';
import { getOverlayLayerList } from '@Services/mainDashboard';
import {
  setMainDashboardStates,
  toggleOverlayPopup,
} from '@Store/actions/mainDashboard';
import LegendWithFIlter from '@Components/common/MapLibreComponents/LegendWithFIlter';
import { getStyleByGeomType } from '@Utils/index';
import {
  setProvincialProfileStates,
  setSelectedFeature,
} from '@Store/actions/provincialProfile';
import OverlayLayerLegend from '@Components/common/MapLibreComponents/OverlayLayerLegend';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import MapAdditionalTools from '@Components/MainDashboard/MapSection/MapAdditionalTools';
import NoDataAvailable from '@Components/MainDashboard/MapSection/Map/NodataAvailable';
import MapFilter from '../FilterSection';
import MapPopup from '../MapPopup';

const BASEURL = process.env.API_URL_V1;

interface MapProps {
  lat: number;
  lon: number;
  zoom?: number;
}
const Map = ({ lat, lon, zoom }: MapProps) => {
  const mapRef = useRef(null);
  const [showNoDataAvailable, setShowNoDataAvailable] = useState(false);
  const [showMapPopup, setShowMapPopup] = useState(false);
  const getQueryParams = useRouteParamQuery();
  const dispatch = useDispatch();
  const { provinceId } = useParams();
  const municipalityCode = getQueryParams.get('municipality');
  const queryClient = useQueryClient();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedmunicipalityId = Number(searchParams.get('municipality'));

  const [hoveredFeature, setHoveredFeature] = useState<Record<
    string,
    any
  > | null>(null);

  const selectedMunicipality = useTypedSelector(
    state => state.provincialProfile.selectedFeature,
  );
  const activeBaseLayer = useTypedSelector(
    state => state.provincialProfile.activeBaseLayer,
  );
  const activeFilterOption = useTypedSelector(
    state => state.mainDashboard.map.activeFilterOption,
  );
  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );

  // fetch layers and set them to state
  useQuery({
    queryKey: ['overlay-layers'],
    queryFn: getOverlayLayerList,
    select: res => res.data.data,
    onSuccess: res => {
      const modifiedResponse = res?.map((layer: Record<string, any>) => ({
        ...layer,
        checked: false,
      }));
      dispatch(setMainDashboardStates({ overlayLayerList: modifiedResponse }));
    },
    // enabled: overlayLayerList === null,
  });

  const { data: countMaping } = useQuery({
    queryKey: ['province-count-mapping', activeFilterOption],
    queryFn: async () =>
      getFederalWiseCount({
        federal_type: 'municipality',
        by: activeFilterOption,
        province: provinceId,
      }),
    enabled: !!provinceId,
    select: res => res.data,
  });

  const { data: zoomToExtent, isFetching: isLoading } = useQuery({
    queryKey: ['municipal-zoom-to-extent', selectedMunicipality?.id],
    queryFn: async () =>
      getBbox({
        municipality: selectedMunicipality?.id,
      }),
    enabled: !!selectedMunicipality?.id,
    select: res => res.data?.extent,
  });
  const { data: provinceZoomToExtent, isLoading: isProvinceExtendLoading } =
    useQuery({
      queryKey: ['province-zoom-to-extent', provinceId],
      queryFn: async () =>
        getBbox({
          province: provinceId,
        }),
      enabled: !!provinceId,
      select: res => res.data?.extent,
    });

  const {
    data: municipalityData,
    // isLoading: isMunicipalityDataLoading
  } = useQuery({
    queryKey: ['getMunicipality', municipalityCode],
    queryFn: async () =>
      getMunicipalityListByCode(municipalityCode || '').then(res => res.data),
    enabled: !!municipalityCode,
  });

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      center: [lon || 87.2720188783414, lat || 27.2101109726257],
      minZoom: zoom || 7,
      maxZoom: 19,
      preserveDrawingBuffer: true,
      maxBounds: [
        [73.916016, 23.785345],
        [94.669189, 32.879587],
      ],
    },
  });

  // code block to change the fill and outline on hover
  useEffect(() => {
    if (!map || !countMaping || !isMapLoaded) return;
    const fillData = getExpression(
      countMaping,
      selectedMunicipality?.id ? selectedMunicipality?.id : hoveredFeature?.id,
      selectedMunicipality?.id ? '#5DB8B2' : '#08519C',
    );

    if (!map.getLayer(`provinceTile-municipality-${provinceId}`)) return;
    map.setPaintProperty(
      `provinceTile-municipality-${provinceId}`,
      'fill-color',
      fillData,
    );

    // to set the outline to green of the map when hovered
    if (!map.getLayer(`provinceTile-municipality-${provinceId}-outline`))
      return;

    if (hoveredFeature?.isHovered) {
      map.setPaintProperty(
        `provinceTile-municipality-${provinceId}-outline`,
        'line-color',
        ['match', ['get', 'id'], hoveredFeature?.id, '#DE0A26', '#808080'],
      );
      map.setPaintProperty(
        `provinceTile-municipality-${provinceId}-outline`,
        'line-opacity',
        ['match', ['get', 'id'], hoveredFeature?.id, 1.5, 0.1],
      );
    } else {
      map.setPaintProperty(
        `provinceTile-municipality-${provinceId}-outline`,
        'line-color',
        '#808080',
      );
    }
  }, [
    map,
    provinceId,
    hoveredFeature,
    countMaping,
    selectedMunicipality,
    municipalityCode,
    isMapLoaded,
  ]);

  useEffect(() => {
    if (!map || !selectedMunicipality) return;
    if (!selectedMunicipality?.id) {
      if (isProvinceExtendLoading) return;
      map.fitBounds(provinceZoomToExtent);
      dispatch(setSelectedFeature(null));
    } else {
      if (isLoading) return;
      map.fitBounds(zoomToExtent, {
        padding: { top: 100, bottom: 60, left: 30, right: 30 },
      });
    }
  }, [
    selectedMunicipality,
    map,
    isLoading,
    zoomToExtent,
    provinceId,
    provinceZoomToExtent,
    isProvinceExtendLoading,
    dispatch,
  ]);

  // zoom and center tho selected municipality
  useEffect(() => {
    if (!map || !provinceId || !provinceZoomToExtent) return;
    if (
      municipalityCode &&
      municipalityData?.centroid_lat &&
      municipalityData?.centroid_lon
    ) {
      map.flyTo({
        center: [
          municipalityData?.centroid_lon,
          municipalityData?.centroid_lat,
        ],
        zoom: 9,
      });
      return;
    }
    map.fitBounds(provinceZoomToExtent);
    map.flyTo({
      zoom: 7,
      center: [lon || 87.2720188783414, lat || 27.2101109726257],
    });
  }, [
    map,
    provinceId,
    provinceZoomToExtent,
    municipalityCode,
    municipalityData?.centroid_lat,
    municipalityData?.centroid_lon,
    lat,
    lon,
  ]);

  useEffect(
    () => () => {
      dispatch(setSelectedFeature(null));
      queryClient.invalidateQueries({
        queryKey: ['overlay-layers'],
      });
      dispatch(toggleOverlayPopup(false));
    },
    [dispatch, queryClient],
  );

  const isOverlayLayerApplied = useMemo(
    () => overlayLayerList?.some(layer => layer.checked),
    [overlayLayerList],
  );

  // show data not available if data is empty
  useEffect(() => {
    if (!map || !isMapLoaded || isLoading || !countMaping) return () => {};
    if (countMaping?.length === 0) {
      setShowNoDataAvailable(true);
    } else {
      setShowNoDataAvailable(false);
    }
    const resetNoDataAvailable = setTimeout(() => {
      setShowNoDataAvailable(false);
    }, 2000);

    return () => {
      return clearTimeout(resetNoDataAvailable);
    };
  }, [map, isMapLoaded, isLoading, countMaping]);

  // to reset the map when clicked outside the feature
  const handleMapClick = (clickInfo: Record<string, any>) => {
    if (clickInfo.hasFeature) return;
    dispatch(setSelectedFeature(null));
    map?.fitBounds(provinceZoomToExtent, {
      padding: { top: 100, bottom: 60, left: 30, right: 30 },
    });
  };

  return (
    <div style={{ width: '100%', height: '100%' }} ref={mapRef}>
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        map={map}
        isMapLoaded={isMapLoaded}
      >
        <MapAdditionalTools
          mapRef={mapRef}
          isFetching={isLoading}
          sourceIdToLoad={`provinceTile-municipality-${provinceId}`}
          extent={provinceZoomToExtent}
        />
        <MapFilter />
        <BaseLayerSwitcher
          activeLayer={activeBaseLayer}
          onBaseLayerChange={layerId =>
            dispatch(setProvincialProfileStates({ activeBaseLayer: layerId }))
          }
        />

        {overlayLayerList &&
          overlayLayerList?.map(layer => (
            <VectorTileLayer
              key={layer.id}
              id={`vectorTile-${layer.layer__name_en}`}
              isMapLoaded={isMapLoaded}
              url={`${BASEURL}/feature_collection_tile/{z}/{x}/{y}.pbf?feature=${layer.id}&province=${provinceId}`}
              layerOptions={getStyleByGeomType(
                layer?.geometry_type,
                layer?.vector_layer_style__style_json,
              )}
              visibleOnMap={layer.checked}
              showLabel
            />
          ))}

        {provinceId && (
          <VectorTileLayer
            id={`provinceTile-municipality-${provinceId}`}
            url={`${BASEURL}/municipality_tile/{z}/{x}/{y}.pbf?province=${provinceId}`}
            layerOptions={defaultLayerStyle}
            onFeatureSelect={properties => {
              dispatch(
                setProvincialProfileStates({ selectedFeature: properties }),
              );
              setShowMapPopup(true);
            }}
            interactions={['select']}
            onHover={properties => {
              if (selectedMunicipality?.id)
                return setHoveredFeature({ isHovered: false });
              if (!properties?.isHovered) return setHoveredFeature(null);
              if (properties?.id && properties?.id !== hoveredFeature?.id) {
                return setHoveredFeature(properties);
              }
            }}
            showNamePopup
            showOutline
            onMapClick={handleMapClick}
          />
        )}

        {/* --------------- map legend ---------------  */}

        <LegendWithFIlter
          data={countMaping}
          datakey="count"
          selectedOption={activeFilterOption || 'program'}
          filterOptions={DashboardLegendDropdownOptions}
        />

        {isOverlayLayerApplied && (
          <OverlayLayerLegend className="!naxatw-top-5" />
        )}

        {showMapPopup &&
          selectedMunicipality?.id !== selectedmunicipalityId && (
            <MapPopup
              hoveredMunicipality={
                selectedMunicipality ? selectedMunicipality : hoveredFeature
              }
              selectedKey="municipality"
              className=" naxatw-top-[15%]"
              visible={showMapPopup}
            />
          )}

        {showNoDataAvailable && <NoDataAvailable />}

        {/* --------------- map tools ---------------  */}
        <MapTools map={map} />
      </MapContainer>
    </div>
  );
};

export default hasErrorBoundary(Map);
