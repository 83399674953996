import { useLocation } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import generateRoutes from '@Routes/generateRoutes';
import adminDashboardRoutes from '@Routes/adminDashboardRoutes';
import Sidebar from '@Components/AdminPanel/Sidebar';

export default function AdminPanel() {
  const { pathname } = useLocation();
  const collapseSidebar = useTypedSelector(
    state => state.common.collapseSidebar,
  );

  return (
    <div
      className={`naxatw-flex ${pathname?.includes('/data-bank/provincial-profile') ? 'naxatw-min-h-[calc(100vh-63px)] ' : 'naxatw-h-[calc(100vh-63px)]'} naxatw-overflow-x-hidden naxatw-overflow-y-hidden naxatw-bg-[#F4F7FE]`}
    >
      <Sidebar />
      <div
        className={`forms-wrapper naxatw-relative naxatw-w-[100vw] naxatw-px-6 naxatw-pb-[1.75rem] naxatw-pt-[1rem]  sm:naxatw-pt-[2rem] lg:naxatw-pt-[2.75rem] ${
          !collapseSidebar
            ? 'md:naxatw-w-[calc(100vw-5.75rem)]'
            : 'md:naxatw-w-[calc(100vw-14.625rem)]'
        }`}
      >
        {generateRoutes({ routes: adminDashboardRoutes })}
      </div>
    </div>
  );
}
