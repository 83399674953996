/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Strike from '@tiptap/extension-strike';
// import Paragraph from '@tiptap/extension-paragraph';
// import Text from '@tiptap/extension-text';
import Heading from '@tiptap/extension-heading';
import Document from '@tiptap/extension-document';
import Link from '@tiptap/extension-link';
import Toolbar from './Toolbar';
// import './editorStyles.scss';
import './style.css';

type RichTextEditorProps = {
  //   editorHtmlContent: string;
  //   setEditorHtmlContent?: (content: string) => any;
  bindvalue: string;
  onChange?: (content: string) => void;
  editable?: boolean;
  isEditorEmpty?: (status: boolean) => void;
  className?: string;
};

const extensions = [
  StarterKit,
  Document,
  // Paragraph,
  // Text,
  ListItem,
  BulletList,
  OrderedList,
  Heading,
  HorizontalRule,
  Strike,
  Link.configure({
    validate: href => /^https?:\/\//.test(href),
  }),
  Image.configure({
    inline: true,
  }),
];

const TiptapEditor = ({
  bindvalue,
  onChange,
  // editorHtmlContent,
  // setEditorHtmlContent,
  editable = true,
  isEditorEmpty,
  className,
}: RichTextEditorProps) => {
  const [editorHtmlContent, setEditorHtmlContent] = useState(bindvalue);
  //  const dispatch = useDispatch();
  const editor = useEditor({
    // @ts-ignore
    extensions,
    content: editorHtmlContent,
    onUpdate: ({ editor: editorx }) => {
      const html = editorx.getHTML();
      if (onChange && html !== editorHtmlContent) {
        onChange(html); // Only update if content has changed
      }
      setEditorHtmlContent(editorx.getHTML());
    },
    editable,
  });

  // useEffect(() => {
  //   if (!editor) return;

  //   // Only set content when bindvalue is different from current editor content
  //   if (editor.getHTML() !== bindvalue) {
  //     editor.commands.setContent(bindvalue);
  //     setEditorHtmlContent(bindvalue);
  //   }
  // }, [bindvalue, editor]);

  useEffect(() => {
    if (editable) {
      editor?.commands.clearContent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditorEmpty) {
      if (typeof editor?.isEmpty === 'undefined') {
        isEditorEmpty(true);
        return;
      }
      isEditorEmpty(editor?.isEmpty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorHtmlContent]);

  useEffect(() => {
    if (editor && bindvalue && editor.isEmpty) {
      editor.commands.setContent(bindvalue);
    }
  }, [bindvalue, editor]);

  if (!editor) {
    return null;
  }

  return (
    <div
      className={`naxatw-rounded-md naxatw-border naxatw-border-gray-300 naxatw-bg-white ${className}`}
    >
      {editable && <Toolbar editor={editor} />}
      <EditorContent
        editor={editor}
        className={`${editable ? 'naxatw-min-h-[200px] naxatw-border-none naxatw-p-2' : 'naxatw-min-h-[150px]'}`}
      />
    </div>
  );
};

export default TiptapEditor;
