/* eslint-disable no-unused-vars */
type Stakeholder = Record<string, any>;
type GroupedData = Record<string, any>[];
type Province = Record<string, any>;

export const groupStakeholdersByType = (
  stakeholders: Stakeholder[],
): GroupedData => {
  return stakeholders.reduce((finalData: GroupedData, stake: Stakeholder) => {
    const isGovernmentType = stake.stakeholder_type === 'government';
    const existedTypeData = finalData.find(
      data => data.type === stake.stakeholder_type,
    );
    const stakeholderData = {
      id: stake.id,
      reporting_id: stake.id,
      unit_ministry: stake.unit_ministry,
      unit_ministry_department: stake.unit_ministry_department,
      other_stakeholder: stake.other_stakeholder,
      local_government_stakeholder: stake.local_government_stakeholder,
    };

    if (existedTypeData) {
      existedTypeData.data = [...existedTypeData.data, stakeholderData];
    } else {
      finalData.push({
        type: stake.stakeholder_type,
        data: [stakeholderData],
      });
    }

    return finalData;
  }, []);
};

export const mapProvincesWithStakeholders = (
  provinces: Province[],
  stakeholders: Stakeholder[],
  filterFn: (stake: Stakeholder, province?: Record<string, any>) => boolean,
): Province[] => {
  return provinces.map(province => {
    const filteredStakeholders = stakeholders.filter(stake =>
      filterFn(stake, province),
    );
    const groupedArray = groupStakeholdersByType(filteredStakeholders);
    return {
      ...province,
      stakeholder: groupedArray,
    };
  });
};
