import {
  StackedBarChartTransformedProgram,
  StackedBarChartProgram,
} from '../types';

const transformData = (
  data: StackedBarChartProgram[],
): StackedBarChartTransformedProgram[] => {
  return data.map(program => {
    const transformed: StackedBarChartTransformedProgram = {
      name: program.abbreviation ? program.abbreviation : program.name,
      id: program.id,
      fullName: program.name || null,
    };

    // Process each component and transform
    program.project.forEach((comp, index) => {
      transformed[`name${index + 1}`] = comp.abbreviation
        ? comp.abbreviation
        : comp.name;
      transformed[`count${index + 1}`] = comp.count;
      transformed[`fullName${index + 1}`] = comp.name;
    });

    // If `count` exists on the main object, keep it as is
    if (program.count !== undefined) {
      transformed.count = program.count;
    }

    return transformed;
  });
};

export default transformData;
