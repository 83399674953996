/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { MapInstanceType } from '@Components/common/MapLibreComponents/types';
import { FlexColumn } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setMapStates } from '@Store/actions/mainDashboard';
import Spinner from '@Components/common/Spinner';
import { useLocation } from 'react-router-dom';
import OverlayLayers from './OverlayLayers.tsx';

interface IMapToolsProps {
  map?: MapInstanceType | null;
  mapRef?: any;
  isFetching?: boolean;
  sourceIdToLoad?: string;
  extent?: any;
}

const MapAdditionalTools = ({
  map,
  mapRef,
  isFetching,
  sourceIdToLoad,
  extent = [
    [79.05859375, 25.745610382199022],
    [88.033447265625, 31.44867367928756],
  ],
}: IMapToolsProps) => {
  const [isSourceLoaded, setIsSourceLoaded] = useState(false);
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();

  const showDownloadPopover = useTypedSelector(
    state => state.mainDashboard.map.showDownloadPopover,
  );

  useEffect(() => {
    map?.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === sourceIdToLoad)
        setIsSourceLoaded(ev?.isSourceLoaded);
    });
  }, [map, sourceIdToLoad]);

  return (
    <FlexColumn className="actions map-tools naxatw-absolute naxatw-left-[1.37%] naxatw-top-[1.31rem] naxatw-z-[10] naxatw-rounded-lg naxatw-border naxatw-border-solid naxatw-shadow-[0px_2px_8px_0px_rgba(16,24,40,0.08)]">
      <OverlayLayers />
      <div
        title="Zoom to extent"
        className="naxatw-cursor-pointer naxatw-border-b naxatw-border-solid naxatw-border-b-grey-600 naxatw-bg-white naxatw-p-2 naxatw-pb-[0.2rem] hover:naxatw-bg-grey-200"
        onClick={() => {
          if (!map) return;
          map.fitBounds(extent, {
            padding: { top: 100, bottom: 60, left: 30, right: 30 },
            maxZoom: 8.5,
          });
        }}
      >
        <Icon name="crop_free" className="naxatw-text-[#212121]" />
      </div>
      <div
        title="Download"
        className={`download-popover naxatw-cursor-pointer naxatw-border-b naxatw-p-2 naxatw-pb-[0.2rem] hover:naxatw-bg-grey-200 ${showDownloadPopover ? 'naxatw-bg-grey-200' : 'naxatw-bg-white'}`}
        onClick={() => {
          if (pathname?.includes('dashboard')) {
            dispatch(
              setMapStates({
                key: 'showDownloadPopover',
                value: !showDownloadPopover,
              }),
            );
          } else {
            const elementToRemove = mapRef.current.querySelectorAll('.actions');
            const parentElementList: any = [];
            elementToRemove.forEach((element: any) => {
              const parentElement = element.parentNode;
              parentElementList.push(parentElement);
              parentElement.removeChild(element);
            });

            const style = document.createElement('style');
            document.head.appendChild(style);
            style.sheet?.insertRule(
              'body > div:last-child img { display: inline-block; }',
            );

            html2canvas(mapRef.current).then(canvas => {
              const link = document.createElement('a');
              link.href = canvas.toDataURL();
              link.download = 'map-with-legend.png';
              link.click();
            });

            elementToRemove.forEach((element: any, index: number) => {
              parentElementList?.[index].appendChild(element);
            });
          }
        }}
      >
        <Icon
          name="download_2"
          className="download-popover naxatw-text-[#212121]"
        />
      </div>
      <div className=" naxatw-flex naxatw-items-center naxatw-justify-center naxatw-bg-white">
        {(isFetching || (sourceIdToLoad && !isSourceLoaded)) && (
          <Spinner className="naxatw-my-1 naxatw-h-5 naxatw-w-5 naxatw-fill-primary-700" />
        )}
      </div>
    </FlexColumn>
  );
};

export default MapAdditionalTools;
