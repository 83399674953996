import { api, fastApi, authenticated } from '.';

export const getTopFiveSectorsByComponent = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-sectors-project/', {
    params,
  });
};

export const getTopFiveSectorsByProgramme = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-sectors-program/', {
    params,
  });
};

export const getProgrammesByBudgetAllocated = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-program-budget/', {
    params,
  });
};

export const getStakeholdersSankeyData = (params: Record<string, any>) => {
  return authenticated(fastApi).get(
    '/infographics/stakeholder-engaged-with-program/',
    { params },
  );
};

export const getTopPartnerByBudget = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-partner-budget/', {
    params,
  });
};

export const getTopComponentProgram = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-project-program/', {
    params,
  });
};

export const getFederalWiseCount = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/federal_wise_count/', {
    params,
  });
};

export const getPopupData = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/program-details-map/', {
    params,
  });
};

export const getGovernmentStakeHoldersDataPro = (params: any) => {
  return authenticated(fastApi).get('/dashboard/stakeholder-type-piechart/', {
    params,
  });
};

export const getOverlayLayerList = () => api.get('/layer-list/');

export const getFilterList = (params: any) => {
  return api.get(`/filter-list/`, { params });
};

export const getFilterProgramList = (params: any) => {
  return api.get(`/program-filter-list/`, { params });
};

export const getFilterPartnerList = (params: any) => {
  return api.get(`/first-tier-partner-filter-list/`, { params });
};

export const getFilterSectorList = (params: any) => {
  return api.get(`/sector-filter-list/`, { params });
};

export const getFilterMarkerList = (params: any) => {
  return api.get(`/marker-filter-list/`, { params });
};

// knowledge Library Filters starts
export const getFilterRepoProgrammeList = (params: any) => {
  return authenticated(api).get(`/knowledge-repo-program-filter-list/`, {
    params,
  });
};

export const getFilterRepoSectorList = (params: any) => {
  return authenticated(api).get('knowledge-repo-sector-filter-list/', {
    params,
  });
};

export const getFilterRepoFileTypeList = (params: any) => {
  return authenticated(api).get(`/file-type-filter-list/`, { params });
};

// knowledge Library Filters ends

export const getChartDescription = (params: any) =>
  fastApi.get('/dashboard/chart_description/', { params });

export const getMunicipalityList = (params: Record<string, any>) =>
  authenticated(api).get('/municipality/', { params });

export const getDistrictList = (params: Record<string, any>) =>
  authenticated(api).get('/district/', { params });

export const getProvinceList = (params: Record<string, any>) =>
  authenticated(api).get('/province/', { params });

export const downloadMapCsvData = (params: any) => {
  return authenticated(api).get('/download-federal-info/', {
    params,
    responseType: 'blob',
  });
};

export const downloadMapPopUpData = (params: any) => {
  return authenticated(api).get('/download-federal-info/', {
    params,
    responseType: 'blob',
  });
};

export const getBudgetExpenditureTypeChart = (params: any) => {
  return authenticated(fastApi).get('/dashboard/budget-expenditure-piechart/', {
    params,
  });
};
