import { useSpring, animated } from '@react-spring/web';
import ComponentsTable from '@Components/AdminPanel/Components/ComponentsTable';
import AdminHeader from '@Components/common/AdminHeader';
import { useNavigate } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useTypedSelector } from '@Store/hooks';
import AdminHeaderSwitchTab from '../ComponentsHeader';

const Components = () => {
  const navigate = useNavigate();

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <div className="data-entry-form-container naxatw-h-full naxatw-space-y-6">
      <div className="naxatw-flex naxatw-flex-col naxatw-gap-y-5 xl:naxatw-flex-row xl:naxatw-items-end xl:naxatw-justify-between">
        <AdminHeader
          title="5WH Data Entry"
          onAddNewClick={() => navigate('/data-bank/project/add')}
          needAddNewButton={
            userGroupPermission?.includes('Can add project') ?? false
          }
        />
      </div>
      <AdminHeaderSwitchTab />
      {userGroupPermission?.includes('Can view project') ? (
        <animated.div style={{ ...springs }} className="naxatw-h-full">
          <ComponentsTable />
        </animated.div>
      ) : null}
    </div>
  );
};

export default hasErrorBoundary(Components);
