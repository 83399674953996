/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from '@Components/common/DataTable';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { deleteUser, getUserList } from '@Services/userSecurity';
import isEmpty from '@Utils/isEmpty';

const UserTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [userIDToDelete, setUserIDToDelete] = useState<string>('');

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['user-security/user-management'],
  );

  const {
    mutate: deleteUsers,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteUser(userIDToDelete),
    onSuccess: () => {
      toast.success('User deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['user-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'USERNAME',
      accessorKey: 'user__username',
    },
    {
      header: 'EMAIL',
      accessorKey: 'email',
    },
    {
      header: 'ROLE',
      accessorKey: 'role__name',
    },
    {
      header: 'PROGRAMME',
      accessorKey: '',
      cell: ({ row }: any) => {
        const programmeList = row?.original?.programs;
        return (
          <div className="programme-container">
            {isEmpty(programmeList) ? (
              <p>-</p>
            ) : (
              <FlexColumn className="programmeitems">
                <p className="naxatw-leading-5">
                  {programmeList
                    ?.map((programme: Record<string, any>) => programme.name)
                    .join(', ')}
                </p>
              </FlexColumn>
            )}
          </div>
        );
      },
    },
    {
      header: 'LOGIN COUNT',
      accessorKey: 'login_count',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() =>
                navigate(
                  `/data-bank/user-security/user-management/edit/${cellId}`,
                )
              }
            />
            <ToolTip
              name="password"
              message="Change Password"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() =>
                navigate(
                  `/data-bank/user-security/user-management/change-password/${cellId}`,
                )
              }
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setUserIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];
  return (
    <div className="naxatw-h-[calc(100%-7rem)] naxatw-w-full lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="user-tabledata"
        queryFn={getUserList}
        isPaginated
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteUsers()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(UserTable);
