import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import AdminHeader from '@Components/common/AdminHeader';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import ComponentUpdateTable from './ComponentUpdateTable';
import AdminHeaderSwitchTab from '../ComponentsHeader';
// import PartnerReportingHeader from './PartnerReportingHeader';

const PartnerReporting = () => {
  const navigate = useNavigate();

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <div className="naxatw-h-full  naxatw-space-y-6">
      <AdminHeader
        title="5WH Data Entry"
        onAddNewClick={() => {
          navigate('/data-bank/project-data/registration');
        }}
        needAddNewButton={
          userGroupPermission?.includes('Can add project data') ?? false
        }
        hasBulkUpload={false}
      />

      {/* <PartnerReportingHeader /> */}
      <AdminHeaderSwitchTab />

      {userGroupPermission?.includes('Can view project data') ? (
        <animated.div style={{ ...springs }} className="naxatw-h-full">
          <ComponentUpdateTable />
        </animated.div>
      ) : null}
    </div>
  );
};

export default PartnerReporting;
