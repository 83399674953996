/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface exploreByIndicatorsStates {
  map: {
    activeViewBy: string;
    activeViewAs: string;
  };
  selectedIndicator: string | null;
}
const initialState: exploreByIndicatorsStates = {
  map: {
    activeViewBy: 'province',
    activeViewAs: 'Map',
  },
  selectedIndicator: null,
};

const exploreByIndicatorsSlice = createSlice({
  name: 'exploreByIndicators',
  initialState,
  reducers: {
    setActiveViewBy: (state, action: PayloadAction<string>) => {
      state.map.activeViewBy = action.payload;
    },
    setActiveViewAs: (state, action: PayloadAction<string>) => {
      state.map.activeViewAs = action.payload;
    },
    setSelectedIndicator: (state, action: PayloadAction<string>) => {
      state.selectedIndicator = action.payload;
    },
  },
});

export { exploreByIndicatorsSlice };
export default exploreByIndicatorsSlice.reducer;
