import { useSpring, animated } from '@react-spring/web';
import ExploreHeader from '../common/ExploreHeaders';
import TopSection from './TopSection';
import BottomSection from './BottomSection';

const ExploreByIndicators = () => {
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  return (
    <>
      <div className="naxatw-mx-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-7 naxatw-pb-[3.589rem]">
        <div className="naxatw-mx-auto naxatw-w-11/12">
          <div className="naxatw-mb-5 naxatw-flex">
            <ExploreHeader />
          </div>
          <animated.div
            style={{ ...springs }}
            className="naxatw-flex naxatw-flex-col naxatw-gap-4"
          >
            <TopSection />
            <BottomSection />
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default ExploreByIndicators;
