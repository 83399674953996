import { v4 as uuidv4 } from 'uuid';

// interface ICountDataProps {
//   characteristic: string;
//   value: number | string;
//   nepal: number | string;
// }
interface ICountDataProps {
  // data: ICountDataProps[];
  data: Record<string, any>;
  title: string;
  columns: any;
}

const CountData = ({ data, title, columns }: ICountDataProps) => {
  return (
    <>
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-3 ">
        <div className="naxatw-h-[0.03125rem] naxatw-w-full naxatw-bg-[#C0C7DA]" />
        <h4 className=" naxatw-text-ellipsis naxatw-whitespace-nowrap naxatw-text-center naxatw-text-xs naxatw-font-medium naxatw-leading-normal naxatw-tracking-[-0.00875rem] naxatw-text-[#06367D] md:naxatw-text-sm">
          {title}
        </h4>
        {columns.map((columnData: any) => (
          <div key={uuidv4()}>
            <div className="naxatw-h-[0.03125rem] naxatw-w-full naxatw-bg-[#C0C7DA]" />
            <div className="naxatw-flex naxatw-h-[4.25rem] naxatw-w-full naxatw-items-center naxatw-gap-2.5 ">
              <p className="naxatw-w-full naxatw-text-center naxatw-text-sm naxatw-font-medium naxatw-text-[#06367D] md:naxatw-text-xl">
                {`${data[columnData.value] || 'N/A'}${data[columnData.value] ? columnData.dataType : ''}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CountData;
