/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable valid-typeof */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import MultiStep from '@Components/common/FormComponent/Multistep';
import Icon from '@Components/common/Icon';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { Button } from '@Components/RadixComponents/Button';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ComponentFormTypeEnum,
  ComponentFormValidationSchemaProps,
  getSchema,
} from '@Validations/ComponentForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteComponenMarker,
  deleteComponentSectorByID,
  deleteComponentEstimatedBudget,
  getComponentByID,
  getEstimatedBugetComponentByID,
  getMarkerComponentByID,
  getSectorComponentByID,
  patchComponenMarker,
  patchComponentEstimatedBudget,
  getCampaignGoalsByID,
  patchComponent,
  patchComponentSector,
  postComponenMarker,
  postComponent,
  postComponentSector,
  postComponentBudget,
  postCampaignGoals,
  deleteCampaignGoal,
  validateBasicInfoFields,
} from '@Services/components';
import isEmpty from '@Utils/isEmpty';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import {
  compareObjects,
  convertStringToBoolean,
  removeEmptyFields,
  removeNullUndefinedFields,
} from '@Utils/index';
import { SectorData, MarkerData } from '@Constants/Types/type';
import CheckBoxSkeleton from '@Components/common/FormComponent/CheckBoxSkeleton';
import { animated, useSpring } from '@react-spring/web';
import { MultiStepTitle } from '@Constants/FormConstants/componentFormConstants';
import { ComponentFormType } from '@Constants/Types/FormTypes/component';
import SectorComponentForm from './SectorComponentForm';
import MarkerComponentForm from './MarkerComponentForm';
import EstimatedBudgetAllocationForm from './EstimatedBudgetAllocationForm';
import CampaignGoalsForm from './CampaignGoalsForm';
import IndicatorForm from './IndicatorForm';
import BasicDetailForm from './BasicDetailForm';

const FormComponent = ({ type }: Record<string, any>) => {
  switch (type) {
    case 'basicInfo':
      return <BasicDetailForm />;
    case 'estimatedBudgetAllocation':
      return <EstimatedBudgetAllocationForm />;
    case 'sectors':
      return <SectorComponentForm />;
    case 'markers':
      return <MarkerComponentForm />;
    case 'campaignGoals':
      return <CampaignGoalsForm />;
    default:
      return <IndicatorForm />;
  }
};

const Skeleton = ({ type }: Record<string, any>) => {
  switch (type) {
    case 'basicInfo':
      return <FormSkeleton numRows={4} className="naxatw-w-full" />;
    case 'estimatedBudgetAllocation':
      return <FormSkeleton numRows={4} className="naxatw-w-full" />;
    default:
      return <CheckBoxSkeleton />;
  }
};

const ComponentForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectID } = useParams();
  const [formType, setFormType] = useState<ComponentFormType>('basicInfo');
  const [isSectorValid, setIsSectorValid] = useState<boolean>(false);
  const [completedForm, setCompletedForm] = useState<string[]>([]);
  const [actualMarker, setActualMarker] = useState<MarkerData[]>([]);
  const [actualSector, setActualSector] = useState<SectorData[]>([]);
  const [actualCampaginGoals, setActualCampaginGoals] = useState<
    Record<string, any>
  >([]);
  const [actualBudget, setActualBudget] = useState({});
  const [actualEstimatedBudget, setActualEstimatedBudget] = useState<any>([]);
  const [markerValueBeforePatch, setMarkerValueBeforePatch] = useState({});
  const [sectorValueBeforePatch, setSectorValueBeforePatch] = useState({});
  const getIdFromKey = (key: string): number => parseInt(key.split('_')[1], 10);
  const componentValidation = getSchema(formType);

  const formMethods = useForm<ComponentFormValidationSchemaProps>({
    mode: 'onChange',
    defaultValues: {
      is_extension: '',
      extended_date: '',
      start_date: '',
      end_date: '',
      program: undefined,
      first_tier_partner: undefined,
      estimated_budget: {
        donor: [{ donorName: '' }],
      },
    },
    resolver: zodResolver(componentValidation),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { dirtyFields, isSubmitting, isValid, errors },
  } = formMethods;

  const sectorsData = watch('sector');
  useEffect(() => {
    if (!sectorsData) return;
    if (Object.keys(sectorsData).length) {
      const sectorValues = Object.values(sectorsData);
      const isValidSector = sectorValues.includes(true);
      setIsSectorValid(isValidSector);
    } else {
      setIsSectorValid(false);
    }
  }, [sectorsData]);

  // delete marker data
  const {
    mutateAsync: deleteComponentMarker,
    isLoading: isdeleteComponentMarkerLoading,
  } = useMutation({
    mutationFn: id => deleteComponenMarker(id),
  });

  // delete sector data
  const {
    mutateAsync: deleteComponentSector,
    isLoading: isdeleteComponentSectorLoading,
  } = useMutation({
    mutationFn: id => deleteComponentSectorByID(id),
  });

  // delete estimated budget data
  const {
    mutateAsync: deleteComponentEstimatedBudgetData,
    isLoading: isdeleteComponentEstimatedBudgetLoading,
  } = useMutation({
    mutationFn: id => deleteComponentEstimatedBudget(id),
  });

  // patch basic info data
  const {
    mutateAsync: patchComponentData,
    isLoading: ispatchComponentLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) => patchComponent(payload),
  });

  // patch sector data
  const {
    mutateAsync: patchComponentSectorData,
    isLoading: ispatchComponentSectorDataLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) => patchComponentSector(payload),
  });

  // patch marker data
  const {
    mutateAsync: patchComponentMarkerData,
    isLoading: ispatchComponentMarkerDataLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) => patchComponenMarker(payload),
  });

  // patch estimated budget data
  const {
    mutateAsync: patchComponentEstimatedBudgetData,
    isLoading: ispatchComponentEstimatedDataLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      patchComponentEstimatedBudget(payload),
  });

  // fetching component data to populate on form for edit
  const { isFetching: isComponentBasicDataFetching } = useQuery({
    queryKey: ['component'],
    enabled: !!projectID,
    queryFn: () => getComponentByID(projectID || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      if (data) {
        setActualBudget({ budget: data?.budget });
        Object.keys(data)?.forEach((key: any) => {
          if (key === 'is_extension') {
            const booleanValueAsString = String(
              typeof data[key] === 'boolean' ? data[key] : '',
            );
            setValue(key, booleanValueAsString);
          } else {
            setValue(key, data[key]);
          }
        });
      }
    },
  });

  // fetching ComponentSector data to populate on form for edit
  const { isFetching: isfetchingComponentSector } = useQuery({
    queryKey: ['component-sector', projectID],
    queryFn: () => getSectorComponentByID(projectID),
    enabled: !!projectID,
    select: data => data?.data,
    onSuccess: data => {
      setActualSector(data);
      const sectorData = data;
      const checkedData = sectorData.reduce(
        (acc: Record<string, boolean>, currSector: Record<string, any>) => {
          const parentKey = `parent_${currSector?.sector}`;
          if (!(parentKey in acc)) {
            acc[parentKey] = true;
          }
          return acc;
        },
        {},
      );
      Object.keys(checkedData)?.forEach(checkedItemKey =>
        setValue(`sector.${checkedItemKey}`, checkedData[checkedItemKey]),
      );
      setSectorValueBeforePatch(checkedData);
    },
  });

  // fetching ComponentMarker data to populate on form for edit
  const { isFetching: isfetchingComponentMarker } = useQuery({
    queryKey: ['component-marker', projectID],
    queryFn: () => getMarkerComponentByID(projectID),
    enabled: !!projectID,
    select: data => data?.data,
    onSuccess: data => {
      setActualMarker(data);
      const markerData = data;
      if (!markerData) return;
      const checkedData = markerData?.reduce(
        (acc: Record<string, any>, currMarker: Record<string, any>) => {
          if (`parent_${currMarker?.marker__category__id}` in acc) {
            acc[`child_${currMarker?.marker}`] = true;
          } else {
            acc[`parent_${currMarker?.marker__category__id}`] = true;
            acc[`child_${currMarker?.marker}`] = true;
          }
          return acc;
        },
        {},
      );

      Object.keys(checkedData)?.forEach(checkedItemKey =>
        setValue(`marker.${checkedItemKey}`, checkedData[checkedItemKey]),
      );
      setMarkerValueBeforePatch(checkedData);
    },
  });

  // fetching Estimated Data to populate on edit on form for edit
  const { isFetching: isComponentEstimatedDataFetching } = useQuery({
    queryKey: ['component-estimated-budget', projectID],
    enabled: !!projectID,
    queryFn: () => getEstimatedBugetComponentByID(projectID || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: (data: any) => {
      if (data) {
        setActualEstimatedBudget(data[0]);
        const actualData = data[0];
        const finalActualData: any =
          removeNullUndefinedFields(actualData) || {};
        const onBudget: any = [];
        const onTreasury: any = [];
        Object.keys(finalActualData)?.forEach((key: any) => {
          if (key === 'is_donor') {
            const isDonorStr = finalActualData[key] ? 'true' : 'false';
            setValue(`estimated_budget.${key}` as string, isDonorStr);
          } else if (key === 'donor') {
            const makeArr: any = finalActualData[key]
              .split(',')
              .map((item: Record<string, any>) => ({
                id: '',
                donorName: item,
              }));
            setValue(`estimated_budget.${key}` as string, makeArr);
          } else if (key === 'on_budget' && finalActualData[key]) {
            onBudget.push('On-Budget');
          } else if (key === 'off_budget' && finalActualData[key]) {
            onBudget.push('Off-Budget');
          } else if (key === 'on_treasury' && finalActualData[key]) {
            onTreasury.push('On-Treasury');
          } else if (key === 'off_treasury' && finalActualData[key]) {
            onTreasury.push('Off-Treasury');
          } else {
            setValue(`estimated_budget.${key}` as string, finalActualData[key]);
          }
        });
        setValue('estimated_budget.budget_type' as any, onBudget);
        setValue('estimated_budget.treasury_type' as any, onTreasury);
      }
    },
  });

  // fetching Campaign Goals to populate on edit on form for edit
  const { isFetching: isCampaginGoalsDataFetching } = useQuery({
    queryKey: ['component-campaign-goals', projectID],
    enabled: !!projectID,
    queryFn: () => getCampaignGoalsByID(projectID || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      if (isEmpty(data)) return;
      setActualCampaginGoals(data);
      data?.forEach((item: Record<string, any>) => {
        setValue(`campaign_goals.${item.campaign_goals}`, true);
      });
    },
  });

  // post basic info data
  const { mutateAsync: postComponentData, isLoading: ispostComponentLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postComponent(payloadData),
    });

  // post component estimated budget
  const {
    mutateAsync: postComponentBudgetData,
    isLoading: isPostEstimatedBudget,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postComponentBudget(payloadData),
  });

  // post sector data
  const {
    mutateAsync: postComponentSectorData,
    isLoading: ispostComponentSectorDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postComponentSector(payloadData),
  });

  // post marker data
  const {
    mutateAsync: postComponentMarkerData,
    isLoading: ispostComponentMarkerDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postComponenMarker(payloadData),
  });

  // post component campaign goals
  const {
    mutateAsync: postCampaignGoalsData,
    isLoading: isPostCampaignGoalLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postCampaignGoals(payloadData),
  });

  // submit Sector For Patch
  const submitSectorForPatch = async (
    dataObject: Record<string, any>,
    sector: SectorData[],
  ) => {
    const requests = Object.entries(dataObject).map(async ([key, value]) => {
      const id = getIdFromKey(key);
      if (value === false) {
        const idToDelete: any = sector?.find(
          (sec: any) => sec.sector === id,
        )?.id;
        await deleteComponentSector(idToDelete);
      } else {
        await postComponentSectorData({
          sector: id,
          project: projectID,
        });
      }
    });

    await Promise.all(requests);
  };

  // submit Marker for patch
  const submitMarkerForPatch = async (
    dataObject: Record<string, any>,
    markers: MarkerData[],
  ) => {
    if (isEmpty(dataObject)) return;

    const removeParentMarker = Object.fromEntries(
      Object.entries(dataObject).filter(([key]) => !key.startsWith('parent_')),
    );

    if (isEmpty(removeParentMarker)) return;

    const requests = Object.entries(removeParentMarker).map(
      async ([key, value]) => {
        const id = getIdFromKey(key);
        if (value === true) {
          await postComponentMarkerData({
            marker: id,
            project: projectID,
          });
        } else {
          const markerIdToDelete: any = markers?.find(
            item => item.marker === id,
          )?.id;

          if (markerIdToDelete) {
            await deleteComponentMarker(markerIdToDelete);
          }
        }
      },
    );

    await Promise.all(requests);
  };

  //! submit Estimated Budget for patch
  const submitEstimatedForPatch = async (
    updatedData: Record<string, any> | undefined,
  ) => {
    const estimatedBudgetToPatch = projectID
      ? compareObjects(actualEstimatedBudget, updatedData)
      : {};

    const finalDataToPatch: Record<string, any> = removeNullUndefinedFields(
      estimatedBudgetToPatch,
    );

    const {
      budget_type: budgetType,
      treasury_type: treasuryType,
      is_donor: isDonor,
      donor,
      rdel_expenditure: rdelExpenditure,
      cdel_expenditure: cdelExpenditure,
      rdel_icf_expenditure: rdelIcfExpenditure,
      cdel_icf_expenditure: cdelIcfExpenditure,
    } = finalDataToPatch;

    if (budgetType) {
      const onBudgetSelected = budgetType?.includes('On-Budget') ?? false;
      if (onBudgetSelected === false) {
        finalDataToPatch.on_budget_on_treasury_amount = null;
        finalDataToPatch.on_budget_on_treasury_percentage = null;
        finalDataToPatch.on_budget_off_treasury_amount = null;
        finalDataToPatch.on_budget_off_treasury_percentage = null;
      }
      finalDataToPatch.on_budget = onBudgetSelected;
      const offBudgetSelected = budgetType?.includes('Off-Budget') ?? false;
      if (offBudgetSelected === false) {
        finalDataToPatch.off_budget_on_treasury_amount = null;
        finalDataToPatch.off_budget_on_treasury_percentage = null;
        finalDataToPatch.off_budget_off_treasury_amount = null;
        finalDataToPatch.off_budget_off_treasury_percentage = null;
      }
      finalDataToPatch.off_budget = offBudgetSelected;
    }

    if (treasuryType) {
      const onTreasurySelected = treasuryType?.includes('On-Treasury') ?? false;
      if (onTreasurySelected === false) {
        finalDataToPatch.on_budget_on_treasury_amount = null;
        finalDataToPatch.on_budget_on_treasury_percentage = null;
        finalDataToPatch.off_budget_on_treasury_amount = null;
        finalDataToPatch.off_budget_on_treasury_percentage = null;
      }
      finalDataToPatch.on_treasury = onTreasurySelected;

      const offTreasurySelected =
        treasuryType?.includes('Off-Treasury') ?? false;

      if (offTreasurySelected === false) {
        finalDataToPatch.on_budget_off_treasury_amount = null;
        finalDataToPatch.on_budget_off_treasury_percentage = null;
        finalDataToPatch.off_budget_off_treasury_amount = null;
        finalDataToPatch.off_budget_off_treasury_percentage = null;
      }
      finalDataToPatch.off_treasury = offTreasurySelected;
    }

    const donorNames =
      donor?.map((d: { donorName: string }) => d.donorName) || [];

    if (donorNames.length > 0) {
      finalDataToPatch.donor = donorNames.join(',');
    }

    if (isDonor === 'true') {
      finalDataToPatch.is_donor = true;
    } else {
      finalDataToPatch.is_donor = false;
      finalDataToPatch.donor = '';
    }

    if (rdelExpenditure === false) {
      finalDataToPatch.rdel_budget = null;
      finalDataToPatch.rdel_percentage = null;
    }

    if (cdelExpenditure === false) {
      finalDataToPatch.cdel_budget = null;
      finalDataToPatch.cdel_percentage = null;
    }

    if (rdelIcfExpenditure === false) {
      finalDataToPatch.rdel_icf_budget = null;
      finalDataToPatch.rdel_icf_percentage = null;
    }

    if (cdelIcfExpenditure === false) {
      finalDataToPatch.cdel_icf_budget = null;
      finalDataToPatch.cdel_icf_percentage = null;
    }

    const { budget_type, treasury_type, ...updatedPatchedData } =
      finalDataToPatch;

    await patchComponentEstimatedBudgetData({
      ...updatedPatchedData,
      id: actualEstimatedBudget?.id,
      project: Number(projectID),
    });
  };

  // submit CamGoals for patch
  const submitCampaignGoalsForPatch = async (
    updatedData: Record<string, any>,
  ) => {
    if (updatedData === undefined) return;
    const trueCampaignGoals: number[] = [];
    const falseCampaignGoals: number[] = [];

    // put checked and unchecked list in array
    updatedData?.forEach((item: any, index: number) => {
      if (item === true) {
        trueCampaignGoals.push(index);
      } else if (item === false) {
        falseCampaignGoals.push(index);
      }
    });

    // delete unchecked data
    if (!isEmpty(falseCampaignGoals)) {
      const promise = falseCampaignGoals.map(async (item: number) => {
        const matchedId = actualCampaginGoals?.find(
          (id: Record<string, any>) => id.campaign_goals === item,
        )?.id;

        if (matchedId) {
          await deleteCampaignGoal(matchedId);
        }
      });
      await Promise.all(promise);
    }

    // post checked data if new data added
    if (!isEmpty(trueCampaignGoals)) {
      const allCamGoals = actualCampaginGoals?.map(
        (item: Record<string, any>) => item?.campaign_goals,
      );

      const finalCamGoalsToPost = trueCampaignGoals?.filter(
        element => !allCamGoals.includes(element),
      );

      const promise = finalCamGoalsToPost?.map(async (item: any) => {
        await postCampaignGoalsData({
          project: projectID,
          campaign_goals: +item,
        });
      });
      await Promise.all(promise);
    }
  };

  // Submit Component Data
  const submitComponent = async (data: Record<string, any>) => {
    if (isEmpty(data)) return null;

    const response = projectID
      ? await patchComponentData(data)
      : await postComponentData(data);
    return response?.data?.id || '';
  };

  // Submit post estimated data
  const submitEsimatedBudgetForPost = async (
    data: any | undefined,
    responseId: number,
  ) => {
    const {
      budget_type: budgetType,
      treasury_type: treasuryType,
      donor,
      is_donor: isDonor,
      ...remainingData
    } = data;
    const donarNames: any = [];

    if (!isEmpty(donor)) {
      donor.forEach((d: any) => donarNames.push(d.donorName));
    }

    const dataToPost = removeEmptyFields(remainingData);
    if (budgetType?.includes('On-Budget')) {
      dataToPost.on_budget = true;
    }
    if (budgetType?.includes('Off-Budget')) {
      dataToPost.off_budget = true;
    }
    if (treasuryType?.includes('On-Treasury')) {
      dataToPost.on_treasury = true;
    }
    if (treasuryType?.includes('Off-Treasury')) {
      dataToPost.off_treasury = true;
    }

    if (!isEmpty(donarNames)) {
      dataToPost.donor = donarNames?.join(',');
    }
    if (isDonor === 'true') {
      dataToPost.is_donor = true;
    } else {
      dataToPost.is_donor = false;
    }

    await postComponentBudgetData({
      ...dataToPost,
      project: responseId,
    });
  };

  // submit Component Marker
  const submitMarkerForPost = async (
    data: Record<string, any>,
    id: Record<string, any>,
  ) => {
    const checkedmarkerCheckboxID = Object.entries(data)
      .filter(([key, value]) => key.startsWith('child_') && value === true)
      .map(([key]) => key.split('_')[1]);

    if (isEmpty(checkedmarkerCheckboxID)) return;
    const promises = checkedmarkerCheckboxID?.map(async (markerID: any) => {
      await postComponentMarkerData({ marker: markerID, project: id });
    });
    await Promise.all(promises);
  };

  // submit Component Sector
  const submitSectorForPost = async (
    data: Record<string, any>,
    id: Record<string, any>,
  ) => {
    if (isEmpty(data)) return;

    // remove false chekbox
    const filteredSector = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value),
    );

    const sectorCheckboxID = Object.keys(filteredSector)?.map(
      (element: any) => {
        const parts = element.split('_');
        return parts[1];
      },
    );

    const promises = sectorCheckboxID?.map(async (sectorID: any) => {
      await postComponentSectorData({ sector: sectorID, project: id });
    });
    await Promise.all(promises);
  };

  // submit campaign gols for post
  const submitCampaignGoalsForPost = async (
    data: Record<string, any>,
    responseId: number,
  ) => {
    if (!Object.values(data).includes(true)) return;
    const campaignGoalsIDs = Object.keys(data).filter(key => data[key]);

    const promises = campaignGoalsIDs.map(camGoalId =>
      postCampaignGoalsData({
        project: responseId,
        campaign_goals: +camGoalId,
      }),
    );

    await Promise.all(promises);
  };

  // to validate 3 fields (programid, component id, first tier partner)
  const {
    mutateAsync: postValiateFields,
    isLoading: ispostValiateFieldsLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      validateBasicInfoFields(payload),
    onSuccess: res => {
      const response = res?.data?.details ?? 'sucess';
      if (response !== 'sucess') {
        throw new Error(response);
      }
    },
  });

  // check edit fields
  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: Record<string, any> = {};
    Object.keys(allValues).forEach(key => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  // note: take patch as update
  const handleFormSubmit = async () => {
    try {
      const values = getValues();
      const editFields = getDirtyFieldValues();
      const {
        estimated_budget: estimatedBudgetData,
        marker: markerData,
        sector: sectorData,
        indicators: indicatorData,
        campaign_goals: campaignGoals,
        is_extension: isExtension,
        extended_date: extendedDate,
        ...componentsData
      } = values;

      const componentsValue = {
        is_extension: convertStringToBoolean(isExtension || ''),
        extended_date: convertStringToBoolean(isExtension || '')
          ? extendedDate
          : '',
        ...componentsData,
      };

      const {
        marker: markerValueAfterPatch,
        sector: sectorValueAfterPatch,
        estimated_budget: estimatedValueAfterPatch,
        campaign_goals: campaignGoalsValueAfterPatch,
        ...componentValueAfterPatch
      } = editFields;

      const editComponentValue = componentValueAfterPatch;

      if (projectID) {
        if (editFields.hasOwnProperty('is_extension')) {
          editComponentValue.is_extension = convertStringToBoolean(
            editFields?.is_extension,
          );
          editComponentValue.extended_date = convertStringToBoolean(
            editFields?.is_extension,
          )
            ? extendedDate
            : '';
        }

        editComponentValue.id = projectID;
      }

      // post or patch component data
      const responseId = projectID
        ? await submitComponent(editComponentValue)
        : await submitComponent(componentsValue);

      const sectorToBePatch = projectID
        ? compareObjects(sectorValueBeforePatch, sectorValueAfterPatch)
        : {};

      const markerToBePatch = projectID
        ? compareObjects(markerValueBeforePatch, markerValueAfterPatch)
        : {};

      if (projectID) {
        await submitSectorForPatch(sectorToBePatch, actualSector);
        await submitMarkerForPatch(markerToBePatch, actualMarker);
        await submitEstimatedForPatch(estimatedBudgetData);
        await submitCampaignGoalsForPatch(campaignGoalsValueAfterPatch);
      } else {
        await submitEsimatedBudgetForPost(estimatedBudgetData, responseId);
        await submitSectorForPost(sectorData, responseId);
        await submitMarkerForPost(markerData, responseId);
        await submitCampaignGoalsForPost(campaignGoals, responseId);
      }
      queryClient.invalidateQueries({
        queryKey: [
          'component-sector',
          'component-marker',
          'component-estimated-budget',
          'component-campaign-goals',
        ],
      });
      reset();
      toast.success(
        projectID
          ? 'Project updated successfully'
          : 'Project added successfully',
      );
      navigate('/data-bank/projects');
    } catch (error) {
      toast.error('Error occured while submitting!');
    }
  };

  // check validation of 3 fields
  const checkValidation = async () => {
    const allValues = getValues();
    const { first_tier_partner: firstTierPartner, code, program } = allValues;

    try {
      await postValiateFields({
        programId: program,
        componentCode: Number(code),
        partnerId: firstTierPartner,
      });
      return true;
    } catch (err: any) {
      toast.error(err?.message);
      return false;
    }
  };

  // handles next button and submit data
  const handleNextFormType = async () => {
    switch (formType) {
      case 'basicInfo':
        if (projectID) {
          setFormType(ComponentFormTypeEnum.EstimatedBudgetAllocation);
          setCompletedForm([...completedForm, 'basicInfo']);
          break;
        } else {
          const isInValid = await checkValidation();
          if (isInValid) {
            setFormType(ComponentFormTypeEnum.EstimatedBudgetAllocation);
            setCompletedForm([...completedForm, 'basicInfo']);
          }
          break;
        }

      case 'estimatedBudgetAllocation':
        setFormType(ComponentFormTypeEnum.Sectors);
        setCompletedForm([...completedForm, 'estimatedBudgetAllocation']);
        break;

      case 'sectors':
        setFormType(ComponentFormTypeEnum.Marker);
        setCompletedForm([...completedForm, 'sectors']);
        break;

      case 'markers':
        setFormType(ComponentFormTypeEnum.CampaignGoals);
        setCompletedForm([...completedForm, 'markers']);
        break;

      case 'campaignGoals':
        handleFormSubmit();
        break;
      default:
        break;
    }
  };

  // handles previous button
  const handleBack = () => {
    switch (formType) {
      case 'estimatedBudgetAllocation':
        setFormType(ComponentFormTypeEnum.BasicInfo);
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'basicInfo'),
        );
        break;

      case 'sectors':
        setFormType(ComponentFormTypeEnum.EstimatedBudgetAllocation);
        setCompletedForm(
          completedForm.filter(
            filledForm => filledForm !== 'estimatedBudgetAllocation',
          ),
        );
        break;

      case 'markers':
        setFormType(ComponentFormTypeEnum.Sectors);
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'sectors'),
        );
        break;

      case 'campaignGoals':
        setFormType(ComponentFormTypeEnum.Marker);
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'markers'),
        );
        break;
      default:
        break;
    }
  };

  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  return (
    <animated.div
      className="naxatw-flex naxatw-w-full naxatw-justify-center md:naxatw-h-[calc(100vh-8rem)] md:naxatw-gap-6"
      style={{ ...props }}
    >
      <div className="naxatw-flex naxatw-h-full naxatw-w-full  naxatw-flex-col naxatw-space-y-6 md:naxatw-w-fit">
        <BreadCrumb
          heading={`Project ${projectID ? 'Edit' : 'Registration'} Form`}
          onBackClick={() => navigate('/data-bank/projects')}
        />

        <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-6 md:naxatw-h-[calc(100%-4rem)] lg:naxatw-flex-row">
          <div className="naxatw-w-full naxatw-rounded-2xl naxatw-bg-white naxatw-px-6 naxatw-py-5 md:naxatw-h-fit md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem] lg:naxatw-h-full lg:naxatw-min-w-[18.75rem] 2xl:naxatw-w-[22.75rem] 2xl:naxatw-max-w-[22.75rem]">
            <div className="naxatw-flex naxatw-flex-row  naxatw-gap-10  lg:naxatw-flex-col">
              {MultiStepTitle?.map((step, index) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <MultiStep
                    key={step.id}
                    id={step.id}
                    title={step.name}
                    position={index + 1}
                    isactive={formType === step.value}
                    iscompleted={completedForm.includes(step.value)}
                    onClick={handleSubmit(() => {
                      setFormType(step.value);
                    })}
                  />
                );
              })}
            </div>
          </div>
          <div className="naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white md:naxatw-h-full md:naxatw-w-[34.75rem] md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem] 2xl:naxatw-w-[40rem] 2xl:naxatw-max-w-[40rem]">
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(handleNextFormType)}
                className="naxatw-flex naxatw-h-full naxatw-flex-col"
              >
                <div className="naxatw-mr-[4px] naxatw-flex-1 naxatw-pt-5">
                  <div className="">
                    {isComponentBasicDataFetching ||
                    isfetchingComponentSector ||
                    isComponentEstimatedDataFetching ||
                    isCampaginGoalsDataFetching ||
                    isfetchingComponentMarker ? (
                      <div className="naxatw-px-6">
                        <Skeleton type={formType} />
                      </div>
                    ) : (
                      <FormComponent type={formType} />
                    )}
                  </div>
                </div>

                <div
                  className={`naxatw-flex naxatw-shadow-formshadow  ${
                    formType === ComponentFormTypeEnum.BasicInfo
                      ? 'naxatw-justify-end'
                      : 'naxatw-justify-between'
                  } naxatw-px-7 naxatw-py-5`}
                >
                  {formType !== ComponentFormTypeEnum.BasicInfo ? (
                    <Button
                      size="normal"
                      type="button"
                      onClick={() => {
                        handleBack();
                      }}
                      variant="link"
                    >
                      <Icon name="arrow_back" />
                      Previous
                    </Button>
                  ) : null}
                  <div className="naxatw-cursor-not-allowed">
                    <Button
                      size="normal"
                      type="submit"
                      variant="primary"
                      className="disabled:!naxatw-cursor-not-allowed"
                      disabled={
                        isSubmitting ||
                        // @ts-ignore
                        (formType === 'sectors' ? !isSectorValid : !isValid)
                      }
                      isLoading={
                        ispostComponentLoading ||
                        isPostEstimatedBudget ||
                        ispostComponentMarkerDataLoading ||
                        isPostCampaignGoalLoading ||
                        ispostComponentSectorDataLoading ||
                        ispatchComponentLoading ||
                        ispatchComponentSectorDataLoading ||
                        ispatchComponentMarkerDataLoading ||
                        ispatchComponentEstimatedDataLoading ||
                        isdeleteComponentSectorLoading ||
                        isdeleteComponentMarkerLoading ||
                        isdeleteComponentEstimatedBudgetLoading ||
                        ispostValiateFieldsLoading
                      }
                    >
                      {formType === ComponentFormTypeEnum.CampaignGoals ? (
                        'Save'
                      ) : (
                        <>
                          Next
                          <Icon name="arrow_forward" />
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default hasErrorBoundary(ComponentForm);
