import { authenticated, api } from '.';

export const getDailyTrivia = () => {
  return authenticated(api).get('trivia-question/');
};

export const postDailyTrivia = (params: Record<string, any>) => {
  return authenticated(api).post('trivia-question/', params);
};

export const patchDailyTrivia = (params: Record<string, any>) => {
  const { id, ...triviaData } = params;
  return authenticated(api).patch(`trivia-question/${id}/`, triviaData);
};

export const getDailyTriviaById = (id: any) => {
  return authenticated(api).get(`trivia-question/${id}/`);
};

export const deleteDailyTrivia = (id: any) => {
  return authenticated(api).delete(`trivia-question/${id}/`);
};
