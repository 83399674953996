/* eslint-disable no-unused-vars */
import React from 'react';
import Icon from '@Components/common/Icon';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@Components/RadixComponents/DropDownMenu';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { NavLink, useLocation } from 'react-router-dom';
import { INavbarLinkData } from '@Constants/navbarData';

type nestedNavLinkProps = {
  navbarItem: INavbarLinkData;
  setDropdownOpen: (openStatus: boolean) => void;
};

const NestedNavLink = ({ navbarItem, setDropdownOpen }: nestedNavLinkProps) => {
  const { pathname } = useLocation();
  return (
    <DropdownMenu onOpenChange={openStatus => setDropdownOpen(openStatus)}>
      <DropdownMenuTrigger className="naxatw-group naxatw-border-b-2 naxatw-border-transparent naxatw-px-3 naxatw-py-2 !naxatw-leading-[initial] naxatw-outline-none naxatw-duration-200 hover:naxatw-border-b-[#417EC9]">
        <FlexRow className={` naxatw-items-center`}>
          <p className=" naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-matt-100 group-hover:naxatw-text-[#417EC9]">
            {navbarItem.name}
          </p>
          <Icon
            name="arrow_drop_down"
            className="naxatw-mt-1 group-hover:naxatw-text-[#417EC9]"
          />
        </FlexRow>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className=" naxatw-z-[60] naxatw-w-[19.825rem] naxatw-overflow-hidden naxatw-rounded-lg naxatw-bg-white naxatw-p-0 !naxatw-py-0 !naxatw-shadow-[0px_0px_25px_0px_rgba(0,0,0,0.1)]"
        align="start"
      >
        <FlexColumn gap={6} className="naxatw-p-6">
          {navbarItem.children?.map(navbarChildItem => (
            <NavLink key={navbarChildItem.id} to={navbarChildItem.link}>
              <DropdownMenuItem className="naxatw-group naxatw-flex naxatw-cursor-pointer naxatw-gap-4 !naxatw-p-0 hover:naxatw-text-[#06367D] focus:!naxatw-bg-transparent">
                <Icon name={navbarChildItem.icon} />
                <FlexColumn>
                  <p className="naxatw-text-[1.125rem] naxatw-font-medium naxatw-leading-6 naxatw-tracking-[-0.0225rem]     naxatw-text-matt-100 group-hover:naxatw-text-[#06367D]">
                    {navbarChildItem.name}
                  </p>
                  <p className="naxatw-text-base naxatw-tracking-[-0.02rem] naxatw-text-matt-200">
                    {navbarChildItem.shortDescription}
                  </p>
                </FlexColumn>
              </DropdownMenuItem>
            </NavLink>
          ))}
        </FlexColumn>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NestedNavLink;
