import CustomDonutChart from '../DonutChart';
import CustomBarChart from '../BarChart';
import CustomHorizontalBarChart from '../HorizontalBarChart';
import { ChartTypes } from '../types';
import CustomLineChart from '../LineChart';
import CustomTreeChart from '../CustomTreeMap';
import VerticalBarChart from '../VerticalBarChart';
import CustomSankeyChart from '../SankeyChart';
import StackedBarChart from '../StackedBarChart';
import CustomPieChart from '../CustomPieChart';

export default function getChartOfType(type: ChartTypes) {
  switch (type) {
    case 'bar':
      return CustomBarChart;
    case 'donut':
      return CustomDonutChart;
    case 'horizontalBar':
      return CustomHorizontalBarChart;
    case 'line':
      return CustomLineChart;
    case 'treeMap':
      return CustomTreeChart;
    case 'verticalBar':
      return VerticalBarChart;
    case 'sankeyChart':
      return CustomSankeyChart;
    case 'stackedBarChart':
      return StackedBarChart;
    case 'pieChart':
      return CustomPieChart;
    default:
      return null;
  }
}
