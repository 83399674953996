/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Tab = {
  selectedTab: string;
  selectedProvinceOptions: any[];
  selectedComparisonIndicatorsOptions: any[];
  fetchData: boolean;
  compareBy: string;
  selectedDistrict: any[];
  selectedProvince: any[];
  selectedMunicipalityOptions: string[];
  selectedMunicipalityData: any[];
};
const initialState: Tab = {
  selectedTab: 'Geography',
  selectedProvinceOptions: [],
  selectedComparisonIndicatorsOptions: [],
  fetchData: false,
  compareBy: 'Province',
  selectedDistrict: [],
  selectedProvince: [],
  selectedMunicipalityOptions: [],
  selectedMunicipalityData: [],
};
export const portfolioProfiles = createSlice({
  name: 'provincialProfileSlice',
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
    setSelectedProvinceOptions: (state, action: PayloadAction<any[]>) => {
      state.selectedProvinceOptions = action.payload;
    },
    setComparisonIndicatorsOptions: (state, action: PayloadAction<any[]>) => {
      state.selectedComparisonIndicatorsOptions = action.payload;
    },
    removeSelectedProvinceOptions: (state, action: PayloadAction<any>) => {
      state.selectedProvinceOptions = state.selectedProvinceOptions.filter(
        province => province !== action.payload,
      );
    },
    setSelectedProvince: (state, action: PayloadAction<any>) => {
      state.selectedProvince = action.payload;
    },
    setSelectedDistrictOption: (state, action: PayloadAction<any>) => {
      state.selectedDistrict = action.payload;
    },
    setSelectedMunicipality: (state, action: PayloadAction<string[]>) => {
      state.selectedMunicipalityOptions = action.payload;
    },
    setSelectedMunicipalityData: (state, action: PayloadAction<any>) => {
      state.selectedMunicipalityData = action.payload;
    },
    removeSelectedMunicipalityOptions: (state, action: PayloadAction<any>) => {
      state.selectedMunicipalityOptions =
        state.selectedMunicipalityOptions.filter(
          municipality => municipality !== action.payload,
        );
    },
    removeSelectedProvince: (state, action: PayloadAction<any>) => {
      state.selectedProvince = state.selectedProvince.filter(
        province => province !== action.payload,
      );
    },

    removeSelectedDistrict: (state, action: PayloadAction<any>) => {
      state.selectedDistrict = state.selectedDistrict.filter(
        province => province !== action.payload,
      );
    },

    removeComparisonIndicatorsOptions: (state, action: PayloadAction<any>) => {
      state.selectedComparisonIndicatorsOptions =
        state.selectedComparisonIndicatorsOptions.filter(
          indicator => indicator !== action.payload,
        );
    },
    setFetchData: state => {
      state.fetchData = !state.fetchData;
    },
    setCompareByTab: (state, action: PayloadAction<any>) => {
      state.compareBy = action.payload;
    },
    emptyAllData: state => {
      state.selectedProvinceOptions = [];
      state.selectedComparisonIndicatorsOptions = [];
      state.selectedProvince = [];
      state.selectedDistrict = [];
      state.selectedMunicipalityOptions = [];
    },
  },
});

export default portfolioProfiles.reducer;
