/* eslint-disable no-nested-ternary */
import { useTypedSelector } from '@Store/hooks';

interface IIndicatorsChartComponentProps {
  comparisonData: any;
  selectedOptions: any[];
  // isLoading?: boolean;
}

const IndicatorsChartComponent = ({
  comparisonData,
  selectedOptions,
  // isLoading = false,
}: IIndicatorsChartComponentProps) => {
  const { compareBy, selectedComparisonIndicatorsOptions } = useTypedSelector(
    state => state.portfolioProfiles,
  );
  function getBackgroundColor(provinceIndex: number) {
    switch (provinceIndex) {
      case 0:
        return 'naxatw-bg-[#16B1A7]';
      case 1:
        return 'naxatw-bg-[#A26599]';
      case 2:
        return 'naxatw-bg-[#719ED7]';
      default:
        return '#16B1A7';
    }
  }
  return (
    <>
      <div
        className={`naxatw-flex naxatw-w-11/12 naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-py-5 naxatw-shadow-sm ${selectedOptions.length > 0 && comparisonData.length > 0 ? 'naxatw-block' : 'naxatw-hidden'}`}
      >
        <div className="naxatw-flex naxatw-min-w-[25%] naxatw-max-w-[35%] naxatw-flex-col naxatw-gap-3 ">
          <div className="naxatw-h-[4.5rem] naxatw-w-full naxatw-border-b naxatw-border-primary-200" />
          {selectedOptions?.map((option: any, optionIndex: any) => (
            <div
              className={`naxatw-h-[4rem] naxatw-w-full naxatw-border-primary-200 ${optionIndex !== selectedOptions.length - 1 && 'naxatw-border-b'}`}
              key={option.id}
            >
              <p className="naxatw-p-[0.62rem] naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-normal naxatw-tracking-[-0.0175rem] naxatw-text-matt-200">
                {compareBy === 'Municipality'
                  ? option.label
                  : `${option.value} Province`}
              </p>
            </div>
          ))}
        </div>
        <div className="scrollbar naxatw-flex naxatw-w-[75%] naxatw-overflow-x-auto">
          {selectedComparisonIndicatorsOptions?.map(indicator => (
            <div
              className="naxatw-flex naxatw-min-w-[30%] naxatw-flex-col naxatw-gap-3 xl:naxatw-w-full xl:naxatw-min-w-[15%]"
              key={indicator}
            >
              <div className="naxatw-h-[4.5rem] naxatw-w-full naxatw-border-b naxatw-border-primary-200 naxatw-px-2 naxatw-py-3">
                <p className="naxatw-text-[0.75rem] naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                  {indicator.label}
                </p>
              </div>
              {selectedOptions?.map((option, optionIndex) => {
                // gets the province id from the comparison data
                const comaprisonOptionId = comparisonData?.findIndex(
                  (data: any) =>
                    data[
                      compareBy === 'Province'
                        ? 'province__id'
                        : 'municipality__id'
                    ] === option.id,
                );
                // gets the values of the selected indicator
                const values = comparisonData.map(
                  (value: any) => value[indicator.value],
                );
                // Get the max value for normalization
                const maxValue = Math.max(...values);
                const indicatorValue =
                  comaprisonOptionId !== -1 &&
                  comparisonData[comaprisonOptionId][indicator.value];

                // Calculate the width as a percentage of the maximum value
                const calculatedWidth = maxValue
                  ? `${(indicatorValue / maxValue) * 75}%`
                  : '0%';

                return (
                  <div
                    className={`naxatw-h-[4rem] naxatw-w-full ${optionIndex !== selectedOptions.length - 1 && 'naxatw-border-b'} naxatw-border-primary-200 naxatw-px-2`}
                    key={indicator}
                  >
                    <div
                      className={` naxatw-rounded-s-[0.25rem] 
                             ${getBackgroundColor(optionIndex)} naxatw-p-[0.62rem] naxatw-text-[0.75rem] naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-white`}
                      //  sets the width of the indicator value based on the values
                      style={{
                        width: calculatedWidth, // Use calculated dynamic width
                      }}
                    >
                      {indicatorValue || '-'}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default IndicatorsChartComponent;
// calculatedWidth;
