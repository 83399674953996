/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const VideosTutorialFormValidationSchema = z.object({
  title: z.string().trim().min(1, 'Title is Required'),
  description: z.string().optional().nullable(),
  video: z
    .union([
      z.string().nonempty({ message: 'Video URL is required' }),
      z.array(z.any()).min(1, { message: 'Video is required' }),
    ])
    .nullable()
    .refine(video => video !== null, { message: 'Video is required' }),
});

export const userManualFormValidationSchema = z.object({
  title: z.string().trim().min(1, 'Title is Required'),
  description: z.string().optional().nullable(),
  file: z
    .union([
      z.string().nonempty({ message: 'Document is required' }),
      z.array(z.any()).min(1, { message: 'Document is required' }),
    ])
    .nullable()
    .refine(video => video !== null, { message: 'Document is required' }),
});

export const faqFormValidationSchema = z.object({
  title: z.string().trim().min(1, 'Title is Required'),
  description: z.string().trim().min(1, 'Description is Required'),
});
