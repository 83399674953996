/* eslint-disable no-nested-ternary */
export default function convertToCurrencySystem(labelValue: number) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(1)} B`
    : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(1)} M`
      : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(1)} K`
        : Math.abs(Number(labelValue));
}
