/* eslint-disable camelcase */
import useOverallCountData from '@Hooks/getOverallCountData';
import { convertToCurrencySystem } from '@Utils/index';

const InfoHeader = () => {
  const { data: provinceData } = useOverallCountData();
  const {
    program_count,
    project_count,
    sector_count,
    province_count,
    municipality_count,
    allocated_budget,
    district_count,
  } = provinceData || {};

  return (
    <div className="info-wrapper">
      <div className="information-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2 naxatw-px-1 naxatw-py-[0.625rem] naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-200 sm:naxatw-gap-0">
        {/* --- province --- */}
        <p>
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            BEK is
          </span>{' '}
          Operating in{' '}
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            {province_count || 0} Provinces,
          </span>{' '}
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            {district_count || 0} Districts
          </span>{' '}
          &{' '}
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            {' '}
            {municipality_count || 0} Palikas{' '}
          </span>
        </p>
        <span className="naxatw-mx-2 naxatw-h-[20px] naxatw-w-[1px] naxatw-bg-[#000]" />
        {/* --- programme,component & sector --- */}
        <p>
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            {program_count || 0} Programmes
          </span>{' '}
          with{' '}
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            {project_count || 0} Projects{' '}
          </span>
          across{' '}
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            {sector_count || 0} Sectors
          </span>
        </p>
        <span className="naxatw-mx-2 naxatw-h-[20px] naxatw-w-[1px] naxatw-bg-[#000]" />
        {/* --- budget --- */}
        <p className="naxatw-text-sm">
          Allocated budget: &#163;{' '}
          <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
            {convertToCurrencySystem(allocated_budget) || 0}
          </span>
        </p>
      </div>
    </div>
  );
};

export default InfoHeader;
