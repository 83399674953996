import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import MultiStep from '@Components/common/FormComponent/Multistep';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { multiStepGoalTitle } from '@Constants/FormConstants/goalsForm';
import {
  GoalsFormValidationSchemaProps,
  GoalFormTypeEnum,
  getGoalSchema,
} from '@Validations/GoalsForm';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  postGoals,
  patchGoals,
  postGoalsProgramme,
  getGoalsByProvinceId,
  deleteGoalProgram,
} from '@Services/goals';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';
import isEmpty from '@Utils/isEmpty';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { isTypeNumber } from '@Utils/index';
import AssignGoals from './AssignGoals';
import Goal from './Goal';

const FormComponent = ({ type }: Record<string, any>) => {
  switch (type) {
    case 'goal':
      return <Goal />;
    case 'assignGoals':
      return <AssignGoals />;
    default:
      return <Goal />;
  }
};

const ProvincialPrioritiesForm = () => {
  const navigate = useNavigate();
  const { provinceId } = useParams();
  const location = useLocation();
  const [formType, setFormType] = useState<'goal' | 'assignGoals'>('goal');
  const [completedForm, setCompletedForm] = useState<string[]>([]);
  const [actualProgrammeList, setActualProgrammeList] = useState<any>([]);
  const prioritiesValidation = getGoalSchema(formType);
  const queryClient = useQueryClient();

  const queryParams = new URLSearchParams(location.search);
  const locationParam = queryParams.get('location');

  const formMethods = useForm<GoalsFormValidationSchemaProps>({
    mode: 'onChange',
    defaultValues: {
      fiscal_year_date: '',
      goals_lists: [],
      programme_list: [],
    },

    resolver: zodResolver(prioritiesValidation),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { isDirty, isSubmitting },
  } = formMethods;

  // post goal data
  const { mutateAsync: postGoalsData, isLoading: ispostGoalLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) => postGoals(payloadData),
    });

  // patch goal data
  const { mutateAsync: patchGoalsData, isLoading: ispatchGoalLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) => patchGoals(payloadData),
    });

  // delete goal program
  const {
    mutateAsync: deleteGoalProgramData,
    isLoading: isdeleteGoalProgramLoading,
  } = useMutation({
    mutationFn: (id: number) => deleteGoalProgram(id),
  });

  // fetching goals data to populate on form for edit
  const { isFetching: isfetchingGoalData } = useQuery({
    queryKey: ['get-goal-location-id', provinceId],
    queryFn: () => getGoalsByProvinceId(provinceId),
    enabled: !!provinceId,
    select: (data: any) => data?.data,
    onSuccess: (goalsData: any) => {
      if (isEmpty(goalsData?.results)) return;
      setActualProgrammeList(goalsData.results);
      const setGoals: Record<string, any>[] = [];
      const setProgrammmeList: any[] = new Array(goalsData?.results?.length)
        .fill(null)
        .map(() => []);

      setValue('province', Number(provinceId));
      goalsData?.results.forEach((item: Record<string, any>, index: number) => {
        setValue('fiscal_year_date', item.fiscal_year_date);
        setGoals.push({ id: item.id, name: item.goal });
        item.programs.forEach((programList: any) => {
          setProgrammmeList?.[index]?.push({
            programId: programList.program__id,
            individualId: programList.id,
          });
        });
      });

      setValue('goals_lists', setGoals);
      setValue('programme_list', setProgrammmeList);
    },
  });

  // post goal programmes
  const postGoalPrograms = async (programmeIds: number[], goalId: number) => {
    await Promise.all(
      programmeIds.map(async (programmeId: any) => {
        if (!programmeId.individualId) {
          await postGoalsProgramme({
            goal_location: goalId,
            program: programmeId,
          });
        }
      }),
    );
  };

  // submit goal data for pacth
  const submitGoalForPatch = async (data: Record<string, any>) => {
    if (isEmpty(actualProgrammeList)) {
      await Promise.all(
        data?.map(async (programmeItem: Record<string, any>) => {
          const goalResponseId = await postGoalsData({
            goal: programmeItem.goal,
            province: +programmeItem.province,
            fiscal_year_date: programmeItem?.fiscal_year_date,
          });

          await postGoalPrograms(
            programmeItem.programList,
            goalResponseId?.data?.id,
          );
        }),
      );
      toast.success('Provincial Priorities Added Successfully');
    } else {
      if (isDirty === false) return;

      const isFiscalYearChanged =
        actualProgrammeList[0]?.fiscal_year_date !==
        data?.[0]?.fiscal_year_date;

      if (isFiscalYearChanged) {
        await Promise.all(
          data?.map(async (goal: Record<string, any>) => {
            await patchGoalsData({
              id: goal?.id,
              fiscal_year_date: data?.[0]?.fiscal_year_date,
            });
          }),
        );
      }

      await Promise.all(
        data?.map(async (goal: Record<string, any>) => {
          if (goal.id === '') {
            const goalResponseId = await postGoalsData({
              goal: goal.goal,
              province: +goal.province,
              fiscal_year_date: isFiscalYearChanged
                ? data?.[0]?.fiscal_year_date
                : goal?.fiscal_year_date,
            });

            await postGoalPrograms(goal.programList, goalResponseId?.data?.id);
          } else {
            const matchedProgramme = actualProgrammeList?.find(
              (programme: Record<string, any>) => programme?.id === goal?.id,
            );

            if (matchedProgramme) {
              const isProgrammeDeletable = isTypeNumber(goal?.programList);

              const programmeIdToPost = goal?.programList?.filter(
                (progrmmeId: number) =>
                  !matchedProgramme?.programs.some(
                    (program: Record<string, any>) =>
                      program?.program__id === progrmmeId,
                  ),
              );

              if (isProgrammeDeletable) {
                const programmeIdToDel = matchedProgramme?.programs?.filter(
                  (programme: Record<string, any>) =>
                    !goal?.programList?.includes(programme.program__id),
                );

                if (!isEmpty(programmeIdToDel)) {
                  await Promise.all(
                    programmeIdToDel?.map(
                      async (programmeId: Record<string, any>) => {
                        await deleteGoalProgramData(programmeId.id);
                      },
                    ),
                  );
                }
              }

              if (!isEmpty(programmeIdToPost)) {
                await postGoalPrograms(programmeIdToPost, goal.id);
              }
            }
          }
        }),
      );
      toast.success('Provincial Priorities Updated Successfully');
    }
  };

  // final submit handler
  const handleFormSubmit = async () => {
    try {
      const allValues = getValues();
      const {
        programme_list: programmeList,
        goals_lists: goalLists,
        fiscal_year_date: fiscalYear,
      } = allValues;

      // manipulate list for post
      const manipulatedGoalsData = programmeList.map(
        (item: number, index: number) => ({
          goal: goalLists[index]?.name,
          id: goalLists[index]?.id,
          programList: item,
          province: provinceId,
          fiscal_year_date: fiscalYear,
        }),
      );

      await submitGoalForPatch(manipulatedGoalsData);
      navigate(`/data-bank/provincial-profile?location=${locationParam}`);
      queryClient.invalidateQueries({
        queryKey: ['get-goal-location'],
      });
      reset();
    } catch (error) {
      toast.error('Error occured while submitting!');
    }
  };

  // handle next button
  const handleNextFormType = () => {
    switch (formType) {
      case 'goal':
        setFormType(GoalFormTypeEnum.AssignGoals);
        setCompletedForm([...completedForm, 'goal']);
        break;
      case 'assignGoals':
        handleFormSubmit();
        break;
      default:
        break;
    }
  };

  // handle previous button
  const handleBack = () => {
    switch (formType) {
      case 'assignGoals':
        setFormType(GoalFormTypeEnum.Goal);
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'goal'),
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="naxatw-flex naxatw-w-full naxatw-justify-center md:naxatw-h-[calc(100vh-8.6rem)] md:naxatw-gap-6">
      <div className="naxatw-flex naxatw-h-full naxatw-w-full  naxatw-flex-col naxatw-space-y-6 md:naxatw-w-fit">
        <BreadCrumb
          heading="Provincial Priorities / Form"
          onBackClick={() => navigate('/data-bank/provincial-profile')}
        />

        <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-6 md:naxatw-h-[calc(100%-4rem)] lg:naxatw-flex-row">
          <div className="naxatw-w-full naxatw-rounded-2xl naxatw-bg-white naxatw-px-6 naxatw-py-5 md:naxatw-h-fit md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem] lg:naxatw-h-full lg:naxatw-min-w-[18.75rem] 2xl:naxatw-w-[22.75rem] 2xl:naxatw-max-w-[22.75rem]">
            <div className="naxatw-flex naxatw-flex-row  naxatw-gap-10  lg:naxatw-flex-col">
              {multiStepGoalTitle?.map((step, index) => {
                return (
                  <MultiStep
                    key={step.id}
                    title={step.name}
                    id={step.id}
                    position={index + 1}
                    isactive={formType === step.value}
                    iscompleted={completedForm.includes(step.value)}
                    onClick={() => setFormType(step.value)}
                  />
                );
              })}
            </div>
          </div>

          {/* form */}
          <div className="naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white md:naxatw-h-full md:naxatw-w-[34.75rem] md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem] 2xl:naxatw-w-[40rem] 2xl:naxatw-max-w-[40rem]">
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(handleNextFormType)}
                className="naxatw-flex naxatw-h-full naxatw-flex-col"
              >
                <div className="scrollbar naxatw-w-full  naxatw-flex-1 naxatw-overflow-y-auto naxatw-px-6 naxatw-pt-5">
                  {isfetchingGoalData ? (
                    <FormSkeleton numRows={3} className="naxatw-w-full" />
                  ) : (
                    <FormComponent type={formType} />
                  )}
                </div>
                <div
                  className={`naxatw-sticky naxatw-bottom-0 naxatw-flex naxatw-bg-white ${
                    formType === GoalFormTypeEnum.AssignGoals
                      ? 'naxatw-justify-between'
                      : 'naxatw-justify-end'
                  } naxatw-px-7 naxatw-pb-3 naxatw-pt-5`}
                >
                  {formType === GoalFormTypeEnum.AssignGoals ? (
                    <Button
                      size="normal"
                      type="button"
                      onClick={() => {
                        handleBack();
                      }}
                      variant="link"
                    >
                      <Icon name="arrow_back" />
                      Previous
                    </Button>
                  ) : null}
                  <Button
                    size="normal"
                    type="submit"
                    variant="primary"
                    isLoading={
                      ispostGoalLoading ||
                      ispatchGoalLoading ||
                      isSubmitting ||
                      isdeleteGoalProgramLoading
                    }
                    disabled={isSubmitting}
                  >
                    {formType === GoalFormTypeEnum.Goal ? (
                      <>
                        Next
                        <Icon name="arrow_forward" />
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ProvincialPrioritiesForm);
