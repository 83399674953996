import noGoalsFound from '@Assets/images/no-goals-found.svg';

const NoGoal = () => {
  return (
    <div className="no-goal-img !naxatw-m-0 naxatw-h-full naxatw-content-center naxatw-text-center">
      <img
        src={noGoalsFound}
        alt="no-goal-found"
        className="naxatw-mx-auto naxatw-mb-[1.4375rem] naxatw-h-[3.3rem] naxatw-w-[2.9rem]"
      />
      <h5 className="naxatw-mb-1 !naxatw-tracking-[-0.045rem] naxatw-text-matt-100">
        No Goals{' '}
      </h5>
      <p className="naxatw-body-sm">
        Please type goal name and press save to add goals
      </p>
    </div>
  );
};

export default NoGoal;
