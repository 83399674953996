import { FormFieldProps } from '@Constants/interface/FormInterface';
/* eslint-disable import/prefer-default-export */
export const dailtTriviaFormFields: FormFieldProps[] = [
  {
    label: 'Question',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Question',
    id: 'question',
    name: 'question',
    required: true,
  },
  {
    label: 'Option 1',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Option 1',
    id: 'option_a',
    name: 'option_a',
    required: true,
  },
  {
    label: 'Option 2',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Option 2',
    id: 'option_b',
    name: 'option_b',
    required: true,
  },
  {
    label: 'Option 3',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Option 3',
    id: 'option_c',
    name: 'option_c',
    required: true,
  },
  {
    label: 'Select Correct Option',
    inputType: 'text',
    type: 'select',
    placeholder: 'Select Correct Option',
    id: 'correct_answer',
    name: 'correct_answer',
    choose: 'id',
    required: true,
  },
];

export const correctAnswerOptions = [
  {
    id: 'A',
    label: 'Option 1',
  },
  {
    id: 'B',
    label: 'Option 2',
  },
  {
    id: 'C',
    label: 'Option 3',
  },
];
