/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { cn } from '@Utils/index';
import Checkbox from '../CheckBox';

interface IGroupCheckBoxProps {
  groupData: {
    id: number;
    name: string;
    [key: string]: any;
  }[];
  subGroupName?: string;
  className?: string;
  checkedItems: { [key: string]: boolean };
  onCheckboxChange: (id: number) => void;
  isParentOnly?: boolean;
}
interface groupList {
  id: number;
  name: string;
  [key: string]: any;
}
export interface ICheckboxProps {
  id: number;
  name: string;
  [key: string]: any;
}

const keysWithSingleSelectCheckBox = ['Group Marker'];

const GroupCheckBox = ({
  groupData,
  subGroupName,
  checkedItems,
  onCheckboxChange,
  className,
  isParentOnly = false, // default both parent and child can be selected
}: IGroupCheckBoxProps) => {
  const handleParentCheckBoxChange = (parentId: number) => {
    const parentKey = `parent_${parentId}`;
    const parent = groupData?.find(p => p.id === parentId);
    const isChecked = !checkedItems[parentKey];
    const newCheckedItems: any = { ...checkedItems, [parentKey]: isChecked };
    const children = parent?.[subGroupName || ''] || [];
    if (isChecked) {
      // If the parent is checked, only check the first child
      if (children.length > 0) {
        const firstChild = children[0];
        newCheckedItems[`child_${firstChild.id}`] = true;
      }
    } else {
      // If the parent is unchecked, uncheck all children
      children.forEach((child: Record<string, any>) => {
        newCheckedItems[`child_${child.id}`] = false;
      });
    }

    // Update the form state with the new checked items
    onCheckboxChange(newCheckedItems);
  };

  const handleParentOnlyCheckBoxChange = (parentId: number) => {
    const parentKey = `parent_${parentId}`;
    const isChecked = !checkedItems[parentKey];
    const newCheckedItems: any = { ...checkedItems, [parentKey]: isChecked };
    onCheckboxChange(newCheckedItems);
  };

  const handleChildCheckBoxChange = (parentId: number, childId: number) => {
    const childKey = `child_${childId}`;
    const parentKey = `parent_${parentId}`;
    const isChecked = !checkedItems[childKey];
    const newCheckedItems = { ...checkedItems, [childKey]: isChecked };

    const parent = groupData.find(item => item.id === parentId);
    const childrenList = parent?.[subGroupName as string] || [];

    if (keysWithSingleSelectCheckBox.includes(parent?.name || '')) {
      // Uncheck all other children in the same group
      childrenList.forEach((child: ICheckboxProps) => {
        if (child.id !== childId) {
          newCheckedItems[`child_${child.id}`] = false;
        }
      });
    }

    // Update parent checkbox state
    const anyChildChecked = childrenList.some(
      (child: Record<string, any>) => newCheckedItems[`child_${child.id}`],
    );
    newCheckedItems[parentKey] = anyChildChecked; // Set parent to true if any child is checked

    // If no child is checked, explicitly set parent to false
    if (!anyChildChecked) {
      newCheckedItems[parentKey] = false;
    }

    onCheckboxChange(newCheckedItems as any);
  };

  return (
    <div>
      {groupData?.map((parentItem: groupList) => {
        return (
          <div
            key={parentItem.id}
            className={cn(
              'naxatw-flex naxatw-flex-col naxatw-gap-2 naxatw-px-3 naxatw-py-2',
              className,
            )}
          >
            <Checkbox
              label={parentItem.name}
              id={`parent_${parentItem.id}`}
              checked={!!checkedItems[`parent_${parentItem.id}`]}
              onChange={() =>
                isParentOnly
                  ? handleParentOnlyCheckBoxChange(parentItem.id)
                  : handleParentCheckBoxChange(parentItem.id)
              }
            />
            {checkedItems[`parent_${parentItem?.id}`] &&
              isParentOnly === false &&
              parentItem?.[subGroupName || '']?.map((child: ICheckboxProps) => (
                <div
                  key={`${child.id}-${child.name}`}
                  className={cn(
                    'naxatw-ml-6 naxatw-flex naxatw-flex-col naxatw-gap-2',
                    className,
                  )}
                >
                  <Checkbox
                    id={`child_${child.id}}`}
                    label={child.name}
                    checked={!!checkedItems[`child_${child.id}`]}
                    onChange={() =>
                      handleChildCheckBoxChange(parentItem?.id, child.id)
                    }
                  />
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default GroupCheckBox;
