import { fastApi, authenticated, api } from '.';

// eslint-disable-next-line import/prefer-default-export
export const getReportList = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/core/project-report-data/', {
    params,
  });
};

export const downloadReportList = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/core/component-report-data/', {
    params,
    responseType: 'blob',
  });
};

export const generateReportList = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/core/generate-project-data-report/', {
    params,
  });
};

export const generateReportListStakeholder = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/core/report-stakeholder/', {
    params,
  });
};
export const getReportFilterKeys = (params: Record<string, any>) => {
  return authenticated(api).get('/report-filter/', { params });
};
