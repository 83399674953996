import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import html2canvas from 'html2canvas';
import { downloadMapCsvData } from '@Services/mainDashboard';
import { downloadMapDataOptions } from '@Constants/map';
import { useTypedSelector } from '@Store/hooks';

const DownloadPopover = ({
  mapRef,
  onClickOutside,
}: {
  mapRef: any;
  onClickOutside?: () => void;
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );
  const statusFilterParmas = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );
  const activeViewBy = useTypedSelector(
    state => state.mainDashboard.map.activeViewBy,
  );

  // download csv data
  const { refetch: downloadCsvData } = useQuery({
    queryKey: ['download-map-csv-data'],
    queryFn: async () =>
      downloadMapCsvData({
        by: activeViewBy,
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParmas,
      }),
    enabled: false,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `${activeViewBy}-data.csv`;
      a.click();
      onClickOutside?.();
    },
  });

  // download jpg
  const downloadMapJpg = () => {
    const elementToRemove = mapRef.current.querySelectorAll('.actions');
    const parentElementList: any = [];
    elementToRemove.forEach((element: any) => {
      const parentElement = element.parentNode;
      parentElementList.push(parentElement);

      parentElement.removeChild(element);
    });

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }',
    );

    html2canvas(mapRef.current).then(canvas => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL();
      link.download = 'map-with-legend.png';
      link.click();
    });

    elementToRemove.forEach((element: any, index: number) => {
      parentElementList?.[index].appendChild(element);
    });
  };

  // handle download Click
  const handleDownloadClick = (dowloadType: string) => {
    if (dowloadType === 'png') {
      onClickOutside?.();
      downloadMapJpg();
    }
    if (dowloadType === 'csv') downloadCsvData();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (popupRef.current && !target?.classList.contains('download-popover')) {
        onClickOutside?.();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClickOutside]);

  return (
    <div
      className="download-popover-container download-popover actions  naxatw-absolute naxatw-top-[5.8rem] naxatw-z-[50] naxatw-rounded-[4px] naxatw-border-[1px] naxatw-border-[#EAECF0] naxatw-bg-white naxatw-text-[#475467]"
      ref={popupRef}
      style={{
        left: `calc(1.37% + 41px)`,
      }}
    >
      <ul className="download-options download-popover">
        {downloadMapDataOptions?.map(option => (
          <li
            key={option.id}
            className="download-popover naxatw-cursor-pointer  naxatw-px-3 naxatw-py-2 naxatw-text-sm naxatw-text-matt-100 hover:naxatw-bg-grey-100"
          >
            <button
              type="button"
              className="download-popover"
              onClick={() => {
                handleDownloadClick(option.type);
              }}
            >
              {option?.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DownloadPopover;
