/* eslint-disable no-nested-ternary */
import Portal from '@Components/common/Layouts/Portal';
import SelectionBox from '@Components/common/PdfDesign/SelectionBox';
import ProgrammeDetailsPdfPopup from '@Components/ProgrammeDetails/PdfDesign';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { programmeDetailsCheckboxData } from '@Constants/pdfGeneration';
import { resetCheckBoxProgrammeDetails } from '@Store/actions/adminPdfAction';
import { useTypedSelector } from '@Store/hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ITopHeaderProps {
  title: string;
}

const TopHeader = ({ title }: ITopHeaderProps) => {
  const [showCheckList, setShowCheckList] = useState(false);
  const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programmeDetails = useTypedSelector(
    state => state.adminPdfSlice.programmeDetails,
  );

  return (
    <>
      {pdfPreviewOpen && (
        <Portal>
          <ProgrammeDetailsPdfPopup
            handleClose={() => {
              setPdfPreviewOpen(false);
              dispatch(resetCheckBoxProgrammeDetails());
            }}
            checkList={programmeDetails}
          />
        </Portal>
      )}
      {showCheckList && (
        <Portal>
          <SelectionBox
            handleClose={() => setShowCheckList(false)}
            handlePdfPreview={() => setPdfPreviewOpen(true)}
            checkBoxData={programmeDetailsCheckboxData}
            pdfType="programmeDetail"
          />
        </Portal>
      )}
      <div className="naxatw-flex naxatw-max-w-[90rem] naxatw-justify-between">
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
          <ToolTip
            name="arrow_back"
            message="Back to Programmes"
            className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-[#0B2E62]"
            iconClick={() => navigate('/explore-by/programmes')}
          />
          <p className="naxatw-text-xl naxatw-font-medium naxatw-text-[#0B2E62]">
            {title}
          </p>
        </div>
        {!componentId && (
          <Button variant="secondary" onClick={() => setShowCheckList(true)}>
            <ToolTip
              name="download"
              message="Export Programme"
              className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
            />
            Export
          </Button>
        )}
      </div>
    </>
  );
};

export default TopHeader;
