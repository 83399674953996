import noDataMarker from '@Assets/images/no-data-marker.svg';
import Icon from '@Components/common/Icon';

interface INoDataAvailableProps {
  hasCloseBtn?: boolean;
  onClose?: () => void;
  text?: string;
}

const NoDataAvailable = ({
  hasCloseBtn,
  onClose = () => {},
  text,
}: INoDataAvailableProps) => {
  return (
    <div className="naxatw-absolute naxatw-left-[calc(50%-6rem)] naxatw-top-[calc(50%-6rem)] naxatw-z-30 naxatw-w-[16.2rem] naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-px-2 naxatw-py-4">
      {hasCloseBtn && (
        <div className="naxatw-absolute naxatw-right-2 naxatw-top-2">
          <Icon name="close" onClick={() => onClose()} />
        </div>
      )}
      <img src={noDataMarker} alt="marker" />
      {!text && (
        <p className="naxatw-mt-2 naxatw-text-center naxatw-text-sm naxatw-font-bold">
          No Data Found
        </p>
      )}
      <p className="naxatw-text-center">
        {text || 'There is no data to display on Map.'}
      </p>
    </div>
  );
};

export default NoDataAvailable;
