import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Tab = {
  viewAs?: string;
};
const initialState: Tab = {
  viewAs: 'Chart',
};
export const setPortfolioResultsSwitchTab = createSlice({
  name: 'portfolioResultsSwitchTab',
  initialState,
  reducers: {
    setViewAsSwitchTab: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.viewAs = action.payload;
    },
  },
});

export default setPortfolioResultsSwitchTab.reducer;
