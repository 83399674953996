import {
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  LineChart,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { IChartProps } from '../types';

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const uniquePayload = payload.reduce((finalArray: any, current: any) => {
      if (!finalArray.find((item: any) => item.dataKey === current.dataKey)) {
        finalArray.push(current);
      }
      return finalArray;
    }, []);

    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        <p className="label naxatw-font-bold">{label}</p>
        {uniquePayload?.map((uPayload: any) => {
          if (uPayload.dataKey !== 'name')
            return (
              <div
                key={uPayload.dataKey}
                className=" naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-x-6"
              >
                <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-1">
                  <div
                    className="naxatw-h-3 naxatw-w-3 naxatw-rounded-sm"
                    style={{ backgroundColor: `${uPayload?.color}` }}
                  />
                  <span className="naxatw-capitalize">{uPayload?.dataKey}</span>
                </div>
                <p className="naxatw-font-semibold">{uPayload?.value}</p>
              </div>
            );
          return <></>;
        })}
      </div>
    );
  }

  return null;
};

function CustomLineChart({ data, fills }: IChartProps) {
  const lineKeys = Object.keys(data?.[0]).splice(1);

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minHeight={260}
      maxHeight={260}
    >
      <LineChart
        width={730}
        height={250}
        data={data || []}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid vertical={false} stroke="#EBEBEB" strokeWidth={0.5} />
        <XAxis
          dataKey="name"
          style={{
            fontWeight: 500,
            fontSize: '12px',
            fill: 'rgb(71, 84, 103)',
            strokeWidth: '0.5px',
          }}
          stroke="#BDBDBD"
          tickLine={false}
        />
        <YAxis
          style={{
            fontWeight: 500,
            fill: 'rgb(71, 84, 103)',
            fontSize: '12px',
            strokeWidth: '0.5px',
          }}
          stroke="#BDBDBD"
          tickLine={false}
        />
        <Tooltip content={CustomTooltip} />
        {lineKeys?.map((key, i) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={fills ? fills[i] : '#8884d8'}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default hasErrorBoundary(CustomLineChart);
