/* eslint-disable no-unused-vars */
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Input } from '@Components/common/FormUI';
import IconButton from '@Components/common/IconButton';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';

interface IDownstreamPartner {
  formValues: Record<string, any>;
  handleDownStreamChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDownstreamDelete: (id: number) => void;
  handleAddDownstream: () => void;
}

const DownstreamPartner = ({
  formValues,
  handleDownStreamChange,
  handleDownstreamDelete,
  handleAddDownstream,
}: IDownstreamPartner) => {
  return (
    <FlexColumn className="naxatw-w-full naxatw-gap-[0.5rem] ">
      <InputLabel label="Downstream Partners" />
      <div className="naxatw-space-y-2">
        {formValues?.downstream_partner?.map((partner: Record<string, any>) => (
          <FlexRow key={partner.id}>
            <Input
              value={partner.value}
              name={partner.id}
              onChange={handleDownStreamChange}
              placeholder="Downstream Partner"
            />
            {formValues?.downstream_partner?.length > 1 && (
              <IconButton
                name="close"
                iconClassName="naxatw-text-[#CED5DF] naxatw-duration-200 hover:!naxatw-text-red-600 naxatw-cursor-pointer"
                onClick={() => handleDownstreamDelete(partner.id)}
              />
            )}
          </FlexRow>
        ))}
      </div>
      <div className="naxatw-flex naxatw-justify-end">
        <Button
          disabled={
            !formValues?.downstream_partner?.every(
              (partner: Record<string, any>) => partner.value,
            )
          }
          variant="link"
          className="naxatw-w-fit"
          onClick={e => {
            e.preventDefault();
            handleAddDownstream();
          }}
        >
          <Icon name="add" />
          Add
        </Button>
      </div>
    </FlexColumn>
  );
};

export default DownstreamPartner;
