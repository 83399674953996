import { TooltipWrapper } from '@Components/RadixComponents/ToolTip';
import Icon from '@Components/common/Icon';

interface ExtenstionProps {
  tooltipMessage: string;
  icon: string;
  onClick?: () => void;
  className?: string;
  iconType?: string;
}

const TipTapExtensionContainer = ({
  tooltipMessage,
  icon,
  onClick,
  className,
  iconType = 'material-symbols-outlined',
}: ExtenstionProps) => {
  return (
    <TooltipWrapper
      tooltipMessage={tooltipMessage}
      disabled
      triggerStyleClassName="naxtw-p-[2px] naxatw-rounded naxatw-items-center naxatw-w-[28px] naxatw-overflow-hidden naxatw-flex hover:naxatw-bg-teal-green-100 naxatw-cursor-pointer naxatw-duration-200 naxatw-transition-all naxatw-ease-in"
    >
      <Icon
        type={iconType}
        name={icon}
        onClick={onClick}
        className={`${className} naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-text-gray-800`}
      />
    </TooltipWrapper>
  );
};

export default TipTapExtensionContainer;
