/* eslint-disable import/prefer-default-export */
import { FormFieldProps } from '@Constants/interface/FormInterface';

type disaggregationsType = {
  disaggregation_unit: string;
  id: string;
};

type programComponentsType = {
  id: string;
  component: number;
  program: number;
};

export type indicatorFormStateType = {
  indicator: {
    indicator: string;
    indicator_definition: string;
    measurement_unit: string;
  };
  programme: number | null;
  disaggregations: disaggregationsType[];
  programComponents: programComponentsType[];
};

export const defualtDisaggregation = { id: '', disaggregation_unit: '' };

export const defaultIndicatorsFormValues: indicatorFormStateType = {
  indicator: {
    indicator: '',
    indicator_definition: '',
    measurement_unit: '',
  },
  programme: null,
  disaggregations: [defualtDisaggregation],
  programComponents: [],
};

export const indicatorsFormFields: FormFieldProps[] = [
  {
    label: 'Indicator',
    inputType: 'text',
    type: 'input',
    placeholder: 'Label',
    id: 'indicator',
    name: 'indicator',
    isVisible: true,
  },
  {
    label: 'Indicator Definition',
    inputType: 'text',
    type: 'input',
    placeholder: 'Label',
    id: 'indicator_definition',
    name: 'indicator_definition',
    isVisible: true,
  },
  {
    label: 'Indicator Measurement Unit',
    type: 'radio',
    id: 'measurement_unit',
    name: 'measurement_unit',
  },
];

export const tooltipInfo = [
  {
    title: 'Select Supplier',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Select Program',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Select Component',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];
