import { z } from 'zod';

export const IndicatorsFormValidationSchema = z.object({
  indicator: z.object({
    indicator: z.string().trim().min(1, 'Indicator is Required'),
    indicator_definition: z
      .string()
      .trim()
      .min(1, 'Indicator definition is Required'),
    measurement_unit: z.string().trim().min(1, 'Measurement unit is Required'),
  }),

  disaggregations: z
    .array(
      z.object({
        disaggregation_unit: z
          .string()
          .trim()
          .min(1, 'Disaggregation is Required'),
      }),
    )
    .min(1, 'Disaggregations is Required'),
  // programComponents: z
  //   .any()
  //   .array()
  //   .min(1, 'Atleast one component should be selected'),
});

export type IndicatorsFormSchemaProps = z.infer<
  typeof IndicatorsFormValidationSchema & {
    [key: string]: any;
  }
>;
