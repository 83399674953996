/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { NavLink, useLocation } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import { FlexRow } from '@Components/common/Layouts';
import {
  adminSubSideBar,
  otherUserSubSideBarDashboardLinks,
} from '@Constants/adminSidebarData';
import { IRoute } from '@Routes/types';

function getSideBarLinks(role: string, permissions: string[] | null) {
  if (!role) return [];
  switch (role) {
    case 'System Admin':
      return adminSubSideBar;
    default:
      return otherUserSubSideBarDashboardLinks(permissions);
  }
}

const SubSidebar = ({
  setActiveTab,
}: {
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { pathname } = useLocation();
  const userProfile = useTypedSelector(state => state.common.userProfile);

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const sidebarLinks = userProfile?.group_name
    ? getSideBarLinks(userProfile.group_name, userGroupPermission)
    : [];

  return (
    <div className="sub-sidebar-container [#417EC9]">
      {sidebarLinks?.map(({ name, path }: IRoute) => (
        <div
          key={name}
          className=" naxatw-bg-[#012069]"
          onClick={() => setActiveTab('Others')}
        >
          <NavLink to={path} className="naxatw-text-white">
            <FlexRow
              className={`naxatw-h-[2.5rem] naxatw-w-[10.75rem] naxatw-cursor-pointer naxatw-items-center hover:naxatw-bg-primary-600 ${pathname?.includes(path as string) ? 'naxatw-bg-[#417EC9]' : ''}`}
            >
              <p className="naxatw-whitespace-nowrap naxatw-px-4 naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem]">
                {name}
              </p>
            </FlexRow>
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default SubSidebar;
