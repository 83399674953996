import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import IconButton from '@Components/common/IconButton';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  correctAnswerOptions,
  dailtTriviaFormFields,
} from '@Constants/FormConstants/dailytrivia';
import groupFormElements from '@Utils/index';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { useForm } from 'react-hook-form';
import {
  DailyTriviaSchemaProps,
  DailyTriviaValidationSchema,
} from '@Validations/DailyTriviaForm';
import {
  postDailyTrivia,
  getDailyTriviaById,
  patchDailyTrivia,
} from '@Services/daillyTrivia';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

interface IDailyTriviaFormProps {
  onClose: () => void;
}

const DailyTriviaForm = ({ onClose }: IDailyTriviaFormProps) => {
  const { triviaId } = useParams();
  const dailyTriviaFormField = groupFormElements(dailtTriviaFormFields);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const formMethods = useForm<DailyTriviaSchemaProps>({
    mode: 'onChange',

    resolver: zodResolver(DailyTriviaValidationSchema),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isSubmitting, isDirty },
    register,
    control,
    reset,
  } = formMethods;

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
  };

  const { mutateAsync: postDailyTriviaData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postDailyTrivia(payloadData),
  });

  const { mutateAsync: patchDailyTriviaData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchDailyTrivia(payloadData),
  });

  const dailytriviaDropdownOptions = {
    correct_answer: correctAnswerOptions,
  };

  // fetch permissionlist to populate on edit
  const { isFetching: isTriviaFetching } = useQuery({
    queryKey: ['get-daily-trivia'],
    queryFn: () => getDailyTriviaById(triviaId),
    enabled: !!triviaId,
    onSuccess: response => {
      if (!response?.data) return;
      const fetchedData = response?.data;
      Object.keys(fetchedData).forEach((key: any) => {
        setValue(key, fetchedData[key]);
      });
    },
  });

  // check edit fields
  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: Record<string, any> = {};
    Object.keys(allValues).forEach(key => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  // post trivia
  const postDailyTriviaForm = async (submittedData: DailyTriviaSchemaProps) => {
    await postDailyTriviaData(submittedData);
  };

  // patch trivia
  const patchDailyTriviaForm = async () => {
    if (isDirty === false) return;
    const editFields = getDirtyFieldValues();
    await patchDailyTriviaData({ ...editFields, id: triviaId });
  };

  const handleFormSubmit = async (submittedData: DailyTriviaSchemaProps) => {
    try {
      if (triviaId) {
        await patchDailyTriviaForm();
      } else {
        await postDailyTriviaForm(submittedData);
      }

      queryClient.invalidateQueries({
        queryKey: ['trivia-tabledata'],
      });
      reset();
      toast.success(
        triviaId
          ? 'Daily Trivia updated successfully'
          : 'Daily Trivia  added successfully',
      );
      navigate('/data-bank/daily-trivia/');
    } catch (error: any) {
      const caughtError = error?.response?.data?.message;
      toast.error(caughtError || 'Error occured while submitting!');
    }
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-max-w-[80%] naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center">
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem] xl:naxatw-w-[40%]">
        <div className="naxatw-w-ful naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
          <BreadCrumb
            heading={`Daily Trivia ${triviaId ? 'Edit' : 'Add'} Form`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>
        <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
          <div className="naxatw-w-full">
            <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
              <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                <FlexColumn className="scrollbar  naxatw-h-[calc(100vh-23.8rem)]  naxatw-w-full  naxatw-space-y-5 naxatw-overflow-y-scroll naxatw-pr-2">
                  {isTriviaFetching ? (
                    <FormSkeleton numRows={4} className="!naxatw-w-full" />
                  ) : (
                    dailyTriviaFormField?.map(
                      (fieldRow: Record<string, any>) => (
                        <FlexRow key={fieldRow.id}>
                          {fieldRow?.map((field: FormFieldProps) => {
                            const { id } = field;
                            return (
                              <FormControl
                                className="naxatw-w-full naxatw-gap-[0.5rem] "
                                key={`${field.id}- ${field.name}`}
                              >
                                <InputLabel
                                  label={field.label}
                                  astric={field.required}
                                  id={field.id}
                                />
                                {getInputElement(
                                  {
                                    ...field,
                                    id: `${field.id}`,
                                  },
                                  /* @ts-ignore */
                                  formProps,
                                  /* @ts-ignore */
                                  dailytriviaDropdownOptions?.[id] || [],
                                  /* @ts-ignore */
                                )}
                                <>
                                  {
                                    /* @ts-ignore */
                                    formProps.errors[id] && (
                                      <ErrorMessage
                                        /* @ts-ignore */
                                        message={formProps.errors[id]?.message}
                                      />
                                    )
                                  }
                                </>
                              </FormControl>
                            );
                          })}
                        </FlexRow>
                      ),
                    )
                  )}
                </FlexColumn>
              </div>

              <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                <Button
                  size="sm"
                  variant="primary"
                  className="naxatw-px-4 naxatw-py-2"
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyTriviaForm;
