/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import {
  deleteKnowledgeRepository,
  // getKnowledgeRepository,
  getKnowledgeRepositoryTableData,
} from '@Services/knowledgeRepository';
import { useTypedSelector } from '@Store/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const KnowledgeRepositoryTable = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [iDToDelete, setIDToDelete] = useState<string>('');

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['knowledge-repository'],
  );

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const isContextualInformation = activeTab?.includes('contextual') ?? false;

  const {
    mutate: deleteKnowledgeRepositoryData,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteKnowledgeRepository(iDToDelete),
    onSuccess: () => {
      toast.success('Record deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['knowledge-repository-tabledata'],
      });
    },
  });

  const columns = isContextualInformation
    ? [
        {
          header: 'DOCUMENT NAME',
          accessorKey: 'title',
        },
        {
          header: 'DOCUMENT TYPE',
          accessorKey: 'file_type',
        },
        {
          header: 'ASSOCIATED PROGRAMME',
          accessorKey: 'program',
        },
        {
          header: 'PROVINCE',
          accessorKey: 'province__name',
        },
        {
          header: 'DATE',
          accessorKey: 'published_date',
        },
        {
          header: '',
          accessorKey: 'icon',
          cell: ({ row }: any) => {
            const cellId = row?.original?.id;
            return (
              <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
                {userGroupPermission?.includes(
                  'Can change knowledge repository',
                ) ? (
                  <ToolTip
                    name="edit"
                    message="Edit"
                    className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
                    iconClick={() => {
                      navigate(
                        `/data-bank/knowledge-repository/contextual/edit/${cellId}`,
                      );
                    }}
                  />
                ) : null}

                {userGroupPermission?.includes(
                  'Can delete knowledge repository',
                ) ? (
                  <ToolTip
                    name="delete"
                    message="Delete"
                    className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
                    iconClick={() => {
                      setOpenDeleteConfirmation(prev => !prev);
                      setIDToDelete(cellId);
                    }}
                  />
                ) : null}
              </FlexRow>
            );
          },
        },
      ]
    : [
        {
          header: 'DOCUMENT NAME',
          accessorKey: 'title',
        },
        {
          header: 'DOCUMENT TYPE',
          accessorKey: 'file_type',
        },
        {
          header: 'ASSOCIATED PROGRAMME',
          accessorKey: '',
          cell: ({ row }: any) => {
            const programmes = row?.original?.program;
            return (
              <p className="naxatw-leading-normal">{programmes?.join(',')} </p>
            );
          },
        },
        {
          header: 'DATE',
          accessorKey: 'published_date',
        },
        {
          header: '',
          accessorKey: 'icon',
          cell: ({ row }: any) => {
            const cellId = row?.original?.id;
            return (
              <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
                {userGroupPermission?.includes(
                  'Can change knowledge repository',
                ) ? (
                  <ToolTip
                    name="edit"
                    message="Edit"
                    className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
                    iconClick={() => {
                      navigate(
                        `/data-bank/knowledge-repository/edit/${cellId}`,
                      );
                    }}
                  />
                ) : null}
                {userGroupPermission?.includes(
                  'Can delete knowledge repository',
                ) ? (
                  <ToolTip
                    name="delete"
                    message="Delete"
                    className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
                    iconClick={() => {
                      setOpenDeleteConfirmation(prev => !prev);
                      setIDToDelete(cellId);
                    }}
                  />
                ) : null}
              </FlexRow>
            );
          },
        },
      ];

  return (
    <div className="naxatw-h-[calc(100%-11rem)] naxatw-w-full lg:naxatw-h-[calc(100%-8.6rem)]">
      <DataTable
        columns={columns}
        queryKey="knowledge-repository-tabledata"
        queryFnParams={isContextualInformation}
        queryFn={() =>
          isContextualInformation
            ? getKnowledgeRepositoryTableData({ is_contextual_indicator: true })
            : getKnowledgeRepositoryTableData({
                is_contextual_indicator: false,
              })
        }
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteKnowledgeRepositoryData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(KnowledgeRepositoryTable);
