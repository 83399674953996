import { useSpring, animated } from '@react-spring/web';
import { exploreByGeographyCardsData as geographyData } from '@Constants/exploreByGeography';
import { useNavigate } from 'react-router-dom';
import Cards from './Cards';
import ExploreHeader from '../common/ExploreHeaders';

const ExploreByGeography = () => {
  const navigate = useNavigate();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <>
      <div className="naxatw-m-auto naxatw-h-full naxatw-w-full naxatw-max-w-[90rem] naxatw-py-7">
        <div className="naxatw-mx-auto naxatw-h-full naxatw-w-11/12">
          <div className="naxatw-mb-5">
            <ExploreHeader />
          </div>
          <animated.div style={{ ...springs }}>
            <div className="naxatw-flex naxatw-items-center naxatw-justify-center lg:naxatw-h-[calc(100vh-15rem)]">
              <div className="naxatw-flex naxatw-flex-col">
                <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-1 naxatw-items-center naxatw-justify-start naxatw-gap-4  md:naxatw-grid-cols-2 lg:naxatw-grid-cols-3">
                  {geographyData.map(geography => (
                    <Cards
                      key={geography.provinceId}
                      provinceId={geography.provinceId}
                      img={geography.img}
                      heading={geography.heading}
                      link={geography.link}
                    />
                  ))}
                </div>
                <p className="naxatw-px-2 naxatw-py-6 naxatw-text-sm naxatw-font-medium naxatw-leading-normal naxatw-text-matt-200">
                  Note: Provincial Profiles are available only for priority
                  provinces. However, data for other provinces can be accessed
                  through the{' '}
                  <span
                    className=" hover:naxatw-text-secondary-400 hover:naxatw-underline"
                    onClick={() => navigate('/dashboard/map')}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    BEK Footprint
                  </span>
                  ,{' '}
                  <span
                    className=" hover:naxatw-text-secondary-400 hover:naxatw-underline"
                    onClick={() => navigate('/explore-by/programmes')}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    Explore by Programme
                  </span>
                  , and{' '}
                  <span
                    className=" hover:naxatw-text-secondary-400 hover:naxatw-underline"
                    onClick={() => navigate('/explore-by/indicators')}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    Explore by Contextual Indicators{' '}
                  </span>
                  pages.
                </p>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default ExploreByGeography;
