/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const accountSettingsPasswordValidation = z
  .object({
    oldPassword: z.string().min(1, 'Old Password Required'),
    password: z
      .string()
      .min(8, 'Min 8 characters required')
      .regex(/[a-zA-Z]/, 'At least one alphabet required')
      .regex(/[0-9]/, 'At least one Number required')
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        'At least one Special character required',
      ),
    confirmPassword: z.string().min(1, 'Confirm Password Required'),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });
