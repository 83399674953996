/* eslint-disable import/prefer-default-export */

import { z } from 'zod';

// const federalData = z.Array()

// const stakeHoldersUnitDataSchema = z.object({
//   unit_ministry: z
//     .union([z.number(), z.string()])
//     .optional()
//     .refine(value => value !== undefined || value !== '', {
//       message: 'unit_ministry is required',
//     }),
//   other_stakeholder: z.string().optional(),
// });

// // const stakeholderDataSchema = z.array(stakeHoldersUnitDataSchema);

// const stakeholderSchema = z.array(
//   z.object({
//     type: z.string().optional(),
//     data: z.array(stakeHoldersUnitDataSchema).optional(),
//   }),
// );
// const federalDataSchema = z.object({
//   id: z.number().optional(),
//   label: z.string().optional(),
//   stakeholder: stakeholderSchema.optional(),
// });
// const federalData = z.array(federalDataSchema).optional();

export const ComponentRegistrationValidationSchema = z.object({
  program: z.number({ message: 'Program is Required' }),
  component: z.number({ message: 'Project is Required' }),
  // stakeholder: federalData.optional(),
});
