import { useState } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import Modal from '@Components/common/Modal';
import ToolTip from '@Components/RadixComponents/ToolTip';

interface ChartCardHeaderProps {
  title: string;
  needFullScreen?: boolean;
  iconClick?: any;
  headerContent?: React.ReactNode;
  content?: React.ReactNode;
}

const ChartCardHeader = ({
  title,
  needFullScreen = true,
  iconClick,
  content,
  headerContent,
}: ChartCardHeaderProps) => {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <div className="header-container naxatw-flex naxatw-items-center naxatw-justify-between naxatw-px-[1.5rem] naxatw-py-[1.25rem]">
      <p className="naxatw-text-[1.125rem] naxatw-font-bold naxatw-text-matt-100">
        {title}
      </p>
      <div className="actions naxatw-flex naxatw-h-fit naxatw-items-center naxatw-gap-4">
        <ToolTip
          name="open_in_full"
          className={`!naxatw-text-xl naxatw-text-primary-300 ${
            needFullScreen ? 'naxatw-block' : 'naxatw-hidden'
          }`}
          message="Full Screen"
          iconClick={() => setFullScreen(true)}
        />
        <ToolTip
          name="download"
          className="naxatw-text-primary-300"
          message="Download"
          iconClick={iconClick || {}}
        />
      </div>
      {fullScreen && (
        <Portal>
          <Modal
            show={fullScreen}
            className="naxatw-h-fit naxatw-w-[80vw] naxatw-max-w-[60rem]"
            title=""
            headerContent={headerContent}
            onClose={() => setFullScreen(false)}
          >
            {content}
          </Modal>
        </Portal>
      )}
    </div>
  );
};

export default ChartCardHeader;
