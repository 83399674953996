/* eslint-disable import/prefer-default-export */
export const tutorialCardsData = [
  {
    id: 1,
    title: 'Video Tutorial',
    description:
      'Engaging video lessons on key topics, offering quick and easy learning support for enhanced understanding.',
    iconName: 'smart_display',
    link: '/dvs-tutorial/video-tutorial',
  },
  {
    id: 2,
    title: 'Manual',
    description:
      'A comprehensive guide for all roles in DVS, providing step-by-step instructions to navigate and utilize the system effectively.',
    iconName: 'description',
    link: '/dvs-tutorial/manual',
  },
];

export const mostAskedQuestionData = [
  {
    id: 1,
    question: 'What is the capital of France?',
    answer:
      "The capital of France is Paris, known for its historic architecture, fashion, and cultural landmarks. It's home to the Eiffel Tower, Louvre Museum, and serves as a major hub for art, fashion, and history.",
  },
  {
    id: 2,
    question: 'What is 2 + 2?',
    answer:
      'The sum of 2 + 2 is 4. This basic arithmetic operation helps build the foundation of mathematical concepts and problem-solving skills used in everyday life and more complex calculations.',
  },
  {
    id: 3,
    question: 'What color is the sky?',
    answer:
      "The sky typically appears blue due to the scattering of sunlight by Earth's atmosphere. Shorter blue wavelengths scatter more than other colors, giving the sky its blue appearance on clear, sunny days.",
  },
  {
    id: 4,
    question: 'What is the largest planet?',
    answer:
      'Jupiter is the largest planet in our solar system, known for its Great Red Spot and powerful magnetic field. This gas giant has dozens of moons and is primarily composed of hydrogen and helium.',
  },
  {
    id: 5,
    question: "Who wrote 'Hamlet'?",
    answer:
      "'Hamlet' was written by William Shakespeare, an iconic playwright. This tragic play explores themes of revenge, madness, and morality, and is considered one of Shakespeare's most influential and widely studied works.",
  },
];

export const sampleVideoData = [
  {
    id: 1,
    url: 'https://www.w3schools.com/html/mov_bbb.mp4',
    title: 'Big Buck Bunny',
    description:
      'A short animated film featuring a large rabbit and his interactions with other forest creatures.',
  },
  {
    id: 2,
    url: 'https://www.w3schools.com/html/movie.mp4',
    title: 'Sintel',
    description:
      'An animated short film about a young girl named Sintel, who embarks on a journey to rescue her dragon.',
  },
  {
    id: 3,
    url: 'https://www.w3schools.com/html/mov_bbb.mp4',
    title: 'Sample Video 3',
    description: 'A simple video used for testing purposes.',
  },
  {
    id: 4,
    url: 'https://www.w3schools.com/html/movie.mp4',
    title: 'Sample Video 4',
    description:
      'Another sample video to demonstrate video playback functionality.',
  },
  {
    id: 5,
    url: 'https://www.w3schools.com/html/mov_bbb.mp4',
    title: 'Sample Video 5',
    description: 'Yet another sample video for preview and testing purposes.',
  },
  {
    id: 6,
    url: 'https://www.w3schools.com/html/mov_bbb.mp4',
    title: 'Sample Video 6',
    description: 'A short video showcasing a calm nature scene.',
  },
  {
    id: 7,
    url: 'https://www.w3schools.com/html/movie.mp4',
    title: 'Sample Video 7',
    description: 'A sample video with a beautiful sunset view.',
  },
  {
    id: 8,
    url: 'https://www.w3schools.com/html/mov_bbb.mp4',
    title: 'Sample Video 8',
    description: 'A short video of a peaceful forest with chirping birds.',
  },
  {
    id: 9,
    url: 'https://www.w3schools.com/html/movie.mp4',
    title: 'Sample Video 9',
    description: 'An educational video demonstrating a coding tutorial.',
  },
  {
    id: 10,
    url: 'https://www.w3schools.com/html/mov_bbb.mp4',
    title: 'Sample Video 10',
    description:
      'A fun animated video with bright colors and engaging graphics.',
  },
];

export const tutorialsAdminHeaderSwitchTabData = [
  {
    id: 1,
    title: 'Video Tutorial',
    value: 'tutorials/videos',
  },
  {
    id: 2,
    title: 'Manual',
    value: 'tutorials/manual',
  },
  {
    id: 3,
    title: 'FAQs',
    value: 'tutorials/faqs',
  },
];
