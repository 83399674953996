/* eslint-disable no-underscore-dangle */
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useDispatch } from 'react-redux';
import { setChartStates } from '@Store/actions/mainDashboard';
import {
  mapGovernmentStakeHolderColors,
  mapGovernmentStakeHoldersTitle,
  mapBudgetExpenditureColors,
  mapBudgetExpenditureTitle,
} from '@Constants/mainDashboard';
import { IChartProps } from '../types';

function getChartColor(chartId: string | undefined) {
  if (!chartId) return [];
  switch (chartId) {
    case 'budget-expenditure-type':
      return mapBudgetExpenditureColors;
    case 'government-stakeholders':
      return mapGovernmentStakeHolderColors;
    default:
      return [];
  }
}

function getChartTitle(chartId: string | undefined) {
  if (!chartId) return [];
  switch (chartId) {
    case 'budget-expenditure-type':
      return mapBudgetExpenditureTitle;
    case 'government-stakeholders':
      return mapGovernmentStakeHoldersTitle;
    default:
      return [];
  }
}

const CustomTooltip = ({ active, payload, chartId }: any) => {
  if (active && payload && payload.length) {
    const titleMapping: any = getChartTitle(chartId);

    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        {payload.map((tooltipItem: Record<string, any>) => {
          return (
            <div
              key={tooltipItem.name}
              className="naxatw-flex naxatw-items-center"
            >
              <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-grey-800">
                {titleMapping[
                  chartId === 'government-stakeholders'
                    ? tooltipItem.payload.payload.stakeholder_type
                    : tooltipItem.name
                ] || '-'}
              </p>
              <span className="colon-separator naxatw-mx-1">:</span>
              <p className="naxatw-text-xs naxatw-font-bold  naxatw-tracking-[-0.015rem] naxatw-text-grey-800">
                {Number(tooltipItem?.value).toLocaleString() || '-'}%
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

const renderLabel = (label: any) => {
  return `${Number(label?.value)?.toFixed(2)?.toLocaleString()}%`;
};

const CustomPieChart = ({
  data,
  chartId,
  showPound,
  hasDetails,
}: IChartProps) => {
  const dispatch = useDispatch();
  const colorMapping: any = getChartColor(chartId);

  function getChartDataById(chartName: string | undefined) {
    if (!chartName) return [];
    switch (chartName) {
      case 'government-stakeholders':
        return data[0]?.stakeholders;
      default:
        return data;
    }
  }

  const pieChartData = getChartDataById(chartId);

  const COLORS = getChartDataById(chartId)?.map(
    (pieData: Record<string, any>) =>
      chartId === 'government-stakeholders'
        ? colorMapping[pieData.stakeholder_type]
        : colorMapping[pieData._id] || '#8884d8',
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={600} height={400}>
        <Pie
          data={pieChartData}
          dataKey="percentage"
          nameKey="_id"
          cx="50%"
          cy="50%"
          outerRadius={150}
          labelLine={false}
          label={entry => renderLabel(entry)}
        >
          {pieChartData?.map(
            (singleObj: Record<string, any>, index: number) => (
              <Cell
                key={singleObj.value}
                fill={COLORS[index]}
                style={{ outline: 'none' }}
                className={
                  hasDetails
                    ? '!naxatw-pointer-events-none naxatw-cursor-pointer lg:!naxatw-pointer-events-auto'
                    : ''
                }
                onClick={() => {
                  if (hasDetails) {
                    dispatch(
                      setChartStates({ key: 'chartDetails', value: singleObj }),
                    );
                    dispatch(
                      setChartStates({ key: 'showLegendDetails', value: true }),
                    );
                  }
                }}
              />
            ),
          )}
        </Pie>
        <Tooltip
          content={
            <CustomTooltip
              data={pieChartData}
              chartId={chartId}
              showPound={showPound}
            />
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
