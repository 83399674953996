import React, { useState } from 'react';
import { Button } from '@Components/RadixComponents/Button';
import { Input } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import {
  getSectorDropdownList,
  getSubSectorById,
  patchSubSector,
  postSubSector,
} from '@Services/sectors';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DropDown from '@Components/common/DropDown';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';

import { FlexColumn } from '@Components/common/Layouts';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { validateForm } from '@Constants/FormConstants/sectorsForm';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

type formStateType = {
  name: string;
  sector_group: string;
};

const initialState = {
  name: '',
  sector_group: '',
};

const initialErrorState = {
  name: false,
  sector_group: false,
};

type propType = {
  onClose: () => void;
};

const SubSectorForm = ({ onClose }: propType) => {
  const navigate = useNavigate();
  const { subSectorId } = useParams();
  const queryClient = useQueryClient();

  const [formState, setFormState] = useState<formStateType>(initialState);
  const [error, setError] = useState(initialErrorState);

  const { data: sectorDropdownOptions, isFetching: isSectorListFetching } =
    useQuery({
      queryKey: ['sector-dropdown-list'],
      queryFn: () => getSectorDropdownList(),
      select: data => {
        const resp = data?.data;
        const updatedOptions = resp?.map((option: Record<string, any>) => ({
          ...option,
          label: option?.name,
          value: option?.name,
        }));
        return updatedOptions;
      },
      onError: () => toast.error('Error Occured!. Please try again.'),
    });

  // fetch sector to populate sub-sector name & sector Id if it is an update
  const { isFetching: isSubSectorFetching } = useQuery({
    queryKey: ['sub-sector-by-id', subSectorId],
    enabled: !!subSectorId,
    queryFn: () => getSubSectorById(subSectorId || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      setFormState(data);
    },
  });

  const {
    mutateAsync: addSubSectorData,
    isLoading: isAddSubSectorDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postSubSector(payloadData),
    onError: () => toast.error('Could not add Sub Sector. Please try again.'),
  });

  const {
    mutateAsync: updateSubSectorData,
    isLoading: isUpdateSubSectorDataLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      patchSubSector(payload.id, payload.formState),
    onError: () =>
      toast.error('Could not update Sub Sector. Please try again.'),
  });

  const saveSubSector = async () => {
    let formError = false;
    const errorState = validateForm(formState);
    setError(errorState);
    Object.values(errorState).forEach(val => {
      if (val) formError = true;
    });

    if (formError) return;

    if (!subSectorId) {
      await addSubSectorData(formState);
    } else {
      await updateSubSectorData({ id: subSectorId, formState });
    }

    queryClient.invalidateQueries({ queryKey: ['sub-sector-tabledata'] });
    toast.success(
      subSectorId
        ? 'Sub Sector updated successfully'
        : 'Sub Sector added successfully',
    );
    navigate('/data-bank/sectors/sub-sector');
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading={`Sub Sector / ${subSectorId ? 'Edit' : 'Add'}`}
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>

      <div className="naxatw-h-[19.5rem] naxatw-w-full">
        <FlexColumn className="naxatw-h-full naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-px-6 naxatw-pt-5">
          {isSubSectorFetching || isSectorListFetching ? (
            <FormSkeleton numRows={1} />
          ) : (
            <>
              <div className="naxatw-flex-1">
                <FlexColumn className="naxatw-w-full   naxatw-gap-[0.469rem] ">
                  <InputLabel
                    id="sector-name"
                    label="Sub Sector Name"
                    // tooltipMessage="Sub Sector Name"
                    astric
                  />
                  <Input
                    type="text"
                    placeholder="Sector"
                    className="!naxatw-w-full"
                    value={formState.name}
                    onChange={e => {
                      setFormState(prev => ({
                        ...prev,
                        name: e.target.value,
                      }));
                      if (error.name)
                        setError(prev => ({ ...prev, name: false }));
                    }}
                  />
                  {error.name && (
                    <p className="naxatw-pt-1 naxatw-text-sm naxatw-text-red-500">
                      Sub Sector name is Required
                    </p>
                  )}
                </FlexColumn>
                <FlexColumn className="naxatw-w-full naxatw-gap-[0.469rem]">
                  <InputLabel
                    id="sector-name"
                    label="Sector Name"
                    tooltipMessage="Sector Name"
                    astric
                  />
                  <DropDown
                    placeholder="Sector"
                    options={sectorDropdownOptions || []}
                    value={formState.sector_group}
                    onChange={value => {
                      setFormState(prev => ({
                        ...prev,
                        sector_group: value,
                      }));
                      if (error.sector_group)
                        setError(prev => ({ ...prev, sector_group: false }));
                    }}
                    choose="id"
                    className="!naxatw-w-full"
                  />
                  {error.sector_group && (
                    <p className="naxatw-pt-1 naxatw-text-sm naxatw-text-red-500">
                      Sector is Required
                    </p>
                  )}
                </FlexColumn>
              </div>
              <div className="naxatw-px-6 naxatw-py-5 naxatw-text-center">
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  className="naxatw-w-fit naxatw-px-4"
                  onClick={() => saveSubSector()}
                  isLoading={
                    isAddSubSectorDataLoading || isUpdateSubSectorDataLoading
                  }
                  disabled={
                    isAddSubSectorDataLoading ||
                    isUpdateSubSectorDataLoading ||
                    isSubSectorFetching ||
                    isSectorListFetching
                  }
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default hasErrorBoundary(SubSectorForm);
