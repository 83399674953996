import { useEffect, useState } from 'react';
import AdminHeader from '@Components/common/AdminHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import RoleTable from './RoleTable';
import RoleForm from './RoleForm';

const RoleManagement = () => {
  const navigate = useNavigate();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  return (
    <div className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6">
      <AdminHeader
        title="Role Management"
        registerButtonText="Add"
        onAddNewClick={() => {
          navigate('/data-bank/user-security/role-management/add');
        }}
      />
      <RoleTable />
      {toggleForm && (
        <Portal>
          <RoleForm
            onClose={() => {
              navigate('/data-bank/user-security/role-management');
            }}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(RoleManagement);
